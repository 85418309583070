import React, { useRef, useState } from 'react';
import { uploadFile } from '../../../../../share/fileUploader';
import { consultationDeleteFile, consultationSendFile } from '../../../../api/patientApi';
import { useDispatch } from 'react-redux';
import { fetchFeedback, getPatientConsultation } from '../../../../api/PatientConsultationsSlice';
import { LoadFileStl } from './consultationFiles.styled';

export const ConsultationFiles = ({ consultationId, files, feedbackId }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState({ status: false, action: '' });
    const loadFile = useRef();

    const handleFileUpload = (e) => {
        e.preventDefault();
        uploadFile(
            loadFile,
            {
                multiple: false,
                accept: ['.jpg', '.png', '.jpeg', '.gif'],
            },
            sendUpResult
        );
    };

    const sendUpResult = async (file) => {
        setIsLoading({ status: true, action: 'Загрузка ...' });
        consultationSendFile({ consultationId, file }).then(() => {
            dispatch(getPatientConsultation(consultationId)).then(() => {
                if (feedbackId) {
                    dispatch(fetchFeedback(feedbackId));
                }
            });
            setIsLoading({ status: false, action: '' });
        });
    };

    const handleDeleteFile = (id) => {
        setIsLoading({ status: true, action: 'Удаление ...' });
        consultationDeleteFile(id).then(() => {
            dispatch(getPatientConsultation(consultationId)).then(() => {
                if (feedbackId) {
                    dispatch(fetchFeedback(feedbackId));
                }
            });
            setIsLoading({ status: false, action: '' });
        });
    };

    return (
        <LoadFileStl>
            <input type="file" id="loadFile" ref={loadFile} />
            {isLoading.status && <span className="btn">{isLoading.action}</span>}
            {!isLoading.status && files.length < 3 && (
                <label htmlFor="loadFile" className="btn" onClick={(e) => handleFileUpload(e)}>
                    Загрузить файл
                </label>
            )}
            <div className='files'>
                {files.map((file) => (
                    <div className="file" key={file.id}>
                        <a href={file.file} target="_blank" rel="noreferrer">
                            <img src="/img/icons/file.png" alt="file" />
                            Документ
                        </a>
                        <span onClick={() => handleDeleteFile(file.id)}>X</span>
                    </div>
                ))}
            </div>
        </LoadFileStl>
    );
};
