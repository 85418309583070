import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postReview, requestReviews } from './reviewsApi';
import { apiRequest } from '../../share/api';

export const fetchPortalReviews = createAsyncThunk('portalReviews/fetchPortalReviews', async (_, { getState }) => {
    try {
        const next = getState().portalReviews.next;
        if (next) {
            return await requestReviews(next);
        }
        const response = await apiRequest.get('/feedback_service');
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const fetchPostPortalReview = createAsyncThunk(
    'portalReviews/fetchPostPortalReview',
    async (data) => {
        try {
            postReview(data);
        } catch (e) {
            console.log(e);
        }
    }
);

const initialState = {
    reviews: [],
    next: null,
    isLoading: false,
    isError: false,
};

const portalReviewsSlice = createSlice({
    name: 'portalReviews',
    initialState,
    reducers: {
        resetState(state) {
            state.reviews = [];
            state.next = null;
            state.isLoading = false;
            state.isError = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPortalReviews.fulfilled, (state, action) => {
            const { results, next } = action.payload;
            state.reviews.push(...results);
            state.next = next;
        });
    },
});

export const { resetState } = portalReviewsSlice.actions;
export default portalReviewsSlice.reducer;
