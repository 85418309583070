import React from 'react';

import { StyledLkDoctorNavigation } from './lkDoctorNavigation.styled';
import { MainMenu } from '../../share/mainMenu/mainMenu';
import { useLocation } from 'react-router-dom';
import {
    DateHint,
    DateHintAppointment,
    TimeHint,
    TimeHintAppointment,
} from '../lkDoctorCalendar/calendarComponents/CalendarHint';
import { useSelector } from 'react-redux';

const LkDoctorNavigation = () => {
    const { pathname } = useLocation();

    const naviData = [
        {
            key: 1,
            title: 'Профиль',
            linkPathTo: '/lk/doctor-profile/profile',
        },
        {
            key: 2,
            title: 'Мои консультации',
            linkPathTo: '/lk/doctor-profile/consultations',
            children: [
                {
                    key: 2.1,
                    title: 'Запланированные',
                    linkPathTo: '/lk/doctor-profile/consultations/future',
                },
                {
                    key: 2.2,
                    title: 'Прошедшие',
                    linkPathTo: '/lk/doctor-profile/consultations/past',
                },
            ],
        },
        {
            key: 3,
            title: 'Пациенты',
            linkPathTo: '/lk/doctor-profile/patients',
        },
        {
            key: 4,
            title: 'Календарь',
            linkPathTo: '/lk/doctor-profile/doctor-calendar',
            children: [
                {
                    key: 4.1,
                    title: 'Настройки',
                    linkPathTo: '/lk/doctor-profile/doctor-calendar/settings',
                },
                {
                    key: 4.2,
                    title: 'Просмотр',
                    linkPathTo: '/lk/doctor-profile/doctor-calendar/viewer',
                },
            ],
        },
        {
            key: 5,
            title: 'Кошелек',
            linkPathTo: '/lk/doctor-profile/wallet',
            // см. компонент Wallet
            // children: [
                 // {
                 //     key: 5.1,
                 //     title: 'Банковские реквизиты',
                 //     linkPathTo: '/lk/doctor-profile/wallet/wallet-details',
                 // },
                 // {
                 //     key: 5.1,
                 //     title: 'Транзакции',
                 //     linkPathTo: '/lk/doctor-profile/wallet/transactions',
                 // },
            // ],
        },
        {
            key: 6,
            title: 'Настройки',
            linkPathTo: '/lk/doctor-profile/settings',
        },
    ];
    
    return (
        <StyledLkDoctorNavigation>
            <MainMenu naviData={naviData} />
            {pathname === '/lk/doctor-profile/doctor-calendar/settings' && (
                <div className="date-time-hint">
                    <DateHint />
                    <DateHintAppointment />
                    <TimeHint />
                    <TimeHintAppointment />
                </div>
            )}
        </StyledLkDoctorNavigation>
    );
};

export default LkDoctorNavigation;
