import React, {useState} from 'react';
import { EditDataList } from '../../shared/EditDataList';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { convertDoctorDataBirthday } from '../../../../lkDoctor/lkDoctorProfile/viewDoctor/helpers/convertDoctorDataBirthday';
import { fetchPatientProfile, fetchPatient_post } from '../../../api/PatientProfileSlice';

export function EditData({ user, toggle }) {
    const [isLoaderData, setIsLoaderData] = useState(false)

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            user: convertDoctorDataBirthday(user),
        },
    });
    const { isValid, isSubmitting } = useFormState({ control: methods.control });

    const dispatch = useDispatch();

    const onSubmit = ({ user: userSubmit }) => {
        setIsLoaderData(true);

        let birthdayInSeconds = 0;
        // перед отправкой на сервер форматируем дату в секунды по UTC
        if (userSubmit.birthday?.replace(/\D/g, '')) {
            const birthdayDate = new Date(
                Date.UTC(
                    userSubmit.birthday.slice(6),
                    userSubmit.birthday.slice(3, 5) - 1,
                    userSubmit.birthday.slice(0, 2)
                )
            );
            birthdayInSeconds = Math.floor(birthdayDate.getTime() / 1000);
        }
        const user = {
            ...userSubmit,
            birthday: birthdayInSeconds,
            phone: userSubmit?.phone?.replace(/[\s()-]/g, ''),
            city: userSubmit.city.value,
        };

        dispatch(fetchPatient_post({ user }))
            .then(() => {dispatch(fetchPatientProfile())})
            .then(()=> setIsLoaderData(false))
            .then(() => toggle(false));

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    if (!user?.first_name) return null;

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="inputs">
                    <EditDataList user={user} />
                </div>
                <button className="btn__recive" type="submit" disabled={!isValid || isSubmitting || isLoaderData}>
                    {!isLoaderData ? 'Сохранить' : 'Сохранение...' }
                </button>
            </form>
        </FormProvider>
    );
}
