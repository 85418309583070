import React from 'react';
import { StyledVacanciesContent } from './vacanciesContent.styled';
import { VacanciesForm } from '../vacanciesForm/VacanciesForm';
import { useMediaQuery } from 'react-responsive';

export function VacanciesContent() {

  const isMboile = useMediaQuery({ query: '(max-width: 567px)' });

  return (
    <StyledVacanciesContent>
      {isMboile && <p className='vacancies__descr'>
        Присоединяйтесь к Докт24, чтобы помочь нам обеспечить оптимальную доступную медицину в любой точке
        мира! Заполните короткую форму, и мы свяжемся с вами в течение 24-х часов.
      </p>
      }
      <div className="vacancies__image" >
        <img src="/img/vacancies/vacancies.png" alt="Contact us" />
      </div>
      <div className='vacancies__right'>
        {!isMboile && <p className='vacancies__descr'>
          Присоединяйтесь к Докт24, чтобы помочь нам обеспечить оптимальную доступную медицину в любой точке
          мира! Заполните короткую форму, и мы свяжемся с вами в течение 24-х часов.
        </p>
        }
        <div className='vacancies__form'>
          <VacanciesForm />
        </div>

      </div>
    </StyledVacanciesContent>
  );
}
