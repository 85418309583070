import styled from 'styled-components';

export const StyledTopBanner = styled.section`
    background-color: #f7f7f7;
    padding-bottom: 180px;
    padding-top: 20px;
    .topBanner__inner {
        .topBanner__top {
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            @media(max-width:767px) {
                display: flex;
                flex-direction: column;
            }
            .topBanner__top-left {
                border-radius: 16px;
                overflow: hidden;
                flex: 1;
                .topBanner__top-img {
                    width: 100%;
                    max-height: 444px;
                    object-fit: cover;
                    height: 100%;
                }
                div {
                    height: 100%;
                }
            }
            .topBanner__top-right {
                flex: 1;
                border-radius: 16px;
                background-color: #fff;
                padding: 40px 24px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: 400px;
                .topBanner__top-title {
                    font-size: 44px;
                    line-height: 46px;
                    &--wrapper {
                        color: #19be6f;
                    }
                    &--bold {
                        color: #212121;
                        font-weight: 500;
                    }
                    @media(max-width:600px) {
                        font-size: 36px;
                        line-height: 40px;
                    }
                    @media(max-width:450px) {
                        font-size: 30px;
                        line-height: 36px;
                    }
                }
                .topBanner__top-text {
                    font-size: 20px;
                    line-height: 30px;
                    span {
                        color: #19be6f;
                    }
                }
                .topBanner__top-btn {
                    font-size: 20px;
                    line-height: 30px;
                    border: none;
                    border-radius: 10px;
                    padding: 13px 37px;
                    color: #fff;
                    background-color: #19be6f;
                    cursor: pointer;
                    width: fit-content;
                }
            }
        }
        .topBanner__statistics {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
            gap: 20px;
            .topBanner__statistics-item {
                background-color: #fff;
                display: flex;
                flex-direction: column;
                gap: 8;
                padding: 20px;
                border-radius: 12px;
                .topBanner__statistics-number {
                    font-size: 24px;
                    line-height: 31px;
                    font-weight: 500;
                }
                .topBanner__statistics-description {
                    font-size: 18px;
                }
            }
        }
    }
`;
('');
