import React, { useEffect } from 'react';
import { DataBlockStl } from './patientParametrs.styled';
import { useNavigate } from 'react-router-dom';

export const ViewParametrs = ({ patientProfile }) => {
    const navigate = useNavigate();
    const { user } = patientProfile;
    const { last_name } = user;

    useEffect(() => {
        if (last_name === '') {
            navigate('/lk/patient-profile/profile/edit');
        }
    }, [last_name])

    if (!patientProfile) return null;

    const { height, weight, blood_type } = patientProfile;

    return (
        <DataBlockStl>
            {height !== 0 && 
                <div className="info__wrapper">
                    <span className="info__label">Рост, см</span>
                    <p className="info__data">{`${height}`}</p>
                </div>
            }
            {weight !== 0 &&
                <div className="info__wrapper">
                    <span className="info__label">Вес, кг</span>
                    <p className="info__data">{weight}</p>
                </div>
            }
            {blood_type !== 'не указано' &&
                <div className="info__wrapper">
                    <span className="info__label">Группа крови</span>
                    <p className="info__data">{blood_type}</p>
                </div>
            }
        </DataBlockStl>
    );
};
