import styled from 'styled-components';
import React from 'react';
import { ReactComponent as Cleaner } from '../../../../src/img/icons/cross-gray.svg';

export const FieldWrapperStl = styled.label`
    --primary-accent: #19be6f;
    --secondary-error: #ff3636;
    --secondary-grey: #b6b7ba;
    --secondary-accent-hover: #169056;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    // убираем стрелки для инпута number
    .input-price[type="number"] {
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
        appearance: textfield;
    }
    .input-price[type="number"]::-webkit-outer-spin-button,
    .input-price[type="number"]::-webkit-inner-spin-button {
        display: none;
    }
    //
    .input-price {
        width: 100%;
    }

    > input,
    > textarea {
        max-width: 422px;
        width: 100%;
        appearance: none;
        border: 1px solid var(--secondary-grey);
        border-radius: 8px;
        padding: 11.5px 40px 11.5px 16px;
        font-size: 16px;
        margin-bottom: 3px;
        text-overflow: ellipsis;
        &::placeholder {
            color: var(--secondary-grey);
        }
        &:hover {
            border-color: ${(props) => (props.disabled ? '#b6b7ba' : '#19be6f')};
        }
        &:focus {
            outline: none;
            border-color: var(--primary-accent);
            &::placeholder {
                color: transparent;
            }
        }
        &.invalid {
            border-color: var(--secondary-error);
        }
    }
    &:disabled {
        pointer-events: none;
    }

    @media (max-width: 768px) {
        > input,
        > textarea {
            &::placeholder {
                font-size: 14px;
            }
        }
    }
`;

export const LabelStl = styled.span`
    display: flex;
    align-items: center;
    font-weight: 400;
    margin-bottom: 7px;
    line-height: 130%;
    font-size: 14px;
    .fieldWraper__red {
        color: #FF3636;
    }
    .editPensil {
        position: static;
        margin: 0 0 0 auto;
    }
`;

export const ClearStl = styled.svg`
    position: absolute;
    right: 16px;
    top: 35px;
`;

export const ErrorStl = styled.p`
    min-height: 14px;
    color: #ff3636;
    font-size: 12px;
`;
export const FieldWrapper = ({ error, label, children, onClear, clearCondition, disabled, isRequired, isPrice=false }) => {
    return (
        <FieldWrapperStl disabled={disabled}>
            {(label !== undefined && !isPrice) &&
                <LabelStl>
                    {label}
                    {isRequired && <span className='fieldWraper__red'>*</span>}
                </LabelStl>}
            {children}
            {!disabled && clearCondition && clearCondition !== '+7 (' && <ClearStl as={Cleaner} onClick={onClear} />}
            <ErrorStl>{error?.message}</ErrorStl>
        </FieldWrapperStl>
    );
};
