import React from 'react';
import InputText from '../../../share/formFields/newElements/InputText';
import { validateInputLength } from '../../../share/formFields/validateInput';

export const AllergyList = ({ name }) => {

    return (
        <>
            <InputText
                label='Аллерген'
                placeholder='Пыль'
                name={`${name}.title`}
                validation={validateInputLength(50)}
            />
            <div className='label-last'>
               <InputText
                    label='Реакция'
                    placeholder='Зуд'
                    name={`${name}.value`}
                    validation={validateInputLength(200)}
                /> 
            </div>
            
        </>
        
    );
};
