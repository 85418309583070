import React from 'react';
import styled from 'styled-components';

const StyledFlex = styled.div`
    display: ${(props) => props.display || 'flex'};
    flex-direction: ${(props) => props.direction || 'row'};
    align-items: ${(props) => props.align || 'stretch'};
    justify-content: ${(props) => props.justify || 'stretch'};
    margin: ${(props) => props.margin || '0'};
    margin-top: ${(props) => props.marginTop || '0'};
    padding: ${(props) => props.padding || '0'};
    position: ${(props) => props.position || 'relative'};
    width: ${(props) => props.width || 'auto'};
    height: ${(props) => props.height || 'auto'};
    min-width: ${(props) => props.minWidth || ''};
    left: ${(props) => props.left + 'px' || '0px'};
    flex-wrap: ${(props) => props.flexWrap || 'nowrap'};
    cursor: ${(props) => props.cursor || 'inherit'};
    border: ${(props) => props.border || 'none'};
    border-radius: ${(props) => props.borderRadius || 'none'};
    gap: ${(props) => props.gap || '0'};
    overflow-y: ${(props) => props.overflowY || 'unset'};
    flex-grow: ${(props) => props.flexGrow || '0'};
    top: ${(props) => props.top || '0'};
`;

export default function Flex(props) {
    return <StyledFlex {...props}/>;
}
