import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { dateFormatterShortened } from '../../../../share/helpers';
import { PatientConsultation } from './patientPageConsultation.styled';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMeDoctor } from '../../../api/doctorUserProfileSlice';
import { Spinner } from '../../../../share/Spinner';

export default function PatientPageConsultation() {
    const dispatch = useDispatch();
    const [consultationsSort, setConsultationsSort] = useState([]);
    const doctorId = useSelector((state) => state.doctorProfile.doctorProfile?.user?.uid);
    const consultations = useSelector((state) => state.doctorPatients.consultations);
    const isLoading = useSelector((state) => state.doctorPatients.isLoading);
    const isPreloader = useSelector((state) => state.doctorProfile.isPreloader);

    useEffect(() => {
        doctorId === undefined && dispatch(fetchMeDoctor());
        setConsultationsSort([]);
        consultations.length > 0 &&
            doctorId !== undefined &&
            setConsultationsSort(consultations.filter((item) => item.doctor.id === doctorId));
    }, [consultations.length, doctorId]);
    const setTextStatucConsultation = (status, registration_log) => {
        const isDoctorCancelled = doctorId !== registration_log?.cancelled_by?.uid;

        switch (status) {
            case 0:
                return 'Консультация запланирована';
            case 1:
                return 'Консультация состоялась';
            case 2:
                return !isDoctorCancelled ? 'Вы отменили консультацию' : 'Пациент отменил консультацию';
            case 9:
                return 'Консультация не проводилась';
            default:
                return 'Консультация';
        }
    };
    if (isLoading || isPreloader) return <Spinner />;
    return (
        <PatientConsultation>
            {consultationsSort.length > 0 ? (
                <div className="patient__consultations">
                    <h2 className="patient__consultationsHeader">Консультации</h2>
                    {consultationsSort.map((consultation) => (
                        <div className="patient__consultation" key={consultation.id}>
                            <p
                                className={`patient__consultationData ${
                                    consultation.status === 2 ? 'patient__consultationStatusCancel' : ''
                                }`}
                            >
                                {dateFormatterShortened(consultation.date)}
                            </p>
                            <p
                                className={`patient__consultationStatus ${
                                    consultation.status === 2 ? 'patient__consultationStatusCancel' : ''
                                }`}
                            >
                                {setTextStatucConsultation(consultation.status, consultation.registration_log)}
                            </p>
                            <Link
                                to={`/lk/doctor-profile/consultations/past/${consultation.id}`}
                                className="patient__consultationEnter"
                            >
                                Подробнее
                            </Link>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="patient__consultations">
                    <h2 className="patient__consultationsHeader">Консультаций нет</h2>
                </div>
            )}
        </PatientConsultation>
    );
}
