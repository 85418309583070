import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { SectionStl } from '../viewDoctor.styled';
import { ViewWork } from './ViewWork';
import { ModerationLabel } from '../../../../share/ModerationLabel';
import { EditWork } from './EditWork';

export const DoctorWork = () => {
    const [editWork, toggleEditWork] = useState(false);
    const { work } = useSelector((state) => state.doctorProfile?.doctorProfile);

    return (
        <SectionStl>
            <TitleWithPencil toggle={toggleEditWork} title={'Опыт работы'} />
            {work.some((el) => !el.is_moderated) ? <ModerationLabel /> : null}
            {editWork ? <EditWork toggle={toggleEditWork} work={work} /> : <ViewWork work={work} />}
        </SectionStl>
    );
};
