import styled from 'styled-components';

export const ArticleCommentsStyled = styled.div`
    .comments {
        display: grid;
        grid-template-columns: 1fr;
        gap: 25px;
        margin-bottom: 25px;
    }

    .comment {
        position: relative;
        display: grid;
        grid-template-columns: 94px 1fr 1fr;
        grid-auto-rows: 25px 35px 1fr;
        border-radius: 8px;
        border: 2px solid var(--secondary-surface, #f4f4f4);
        padding: 20px;
        column-gap: 20px;
        row-gap: 12px;
    }

    .dateOfCreation {
        position: absolute;
        top: 20px;
        grid-column: 1/4;
        justify-self: end;
        p {
            color: var(--secondary-text, #666);
            text-align: right;
            font-family: Rubik;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }
    }

    .authorAvatar {
        grid-column: 1/2;
        grid-row: 1/4;
        width: 94px;
        height: 94px;
        border-radius: 50%;
        overflow: hidden;

        img {
            max-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }

    .commentHeader {
        grid-column: 2/4;
        grid-row: 1/2;

        .authorName {
            color: var(--primary-text, #212121);
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
        }
    }

    .commentInfo {
        grid-column: 2/4;
        grid-row: 2/4;
        align-items: center;

        .textContent {
            word-break: break-word;
            color: var(--secondary-text, #666);
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
        }
    }

    .articleFooterButton {
        cursor: pointer;
        text-align: end;
        .showCommentsButton {
            color: var(--primary-accent, #19be6f);
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }
    }

    @media (max-width: 600px) {
        .comments {
            gap: 15px;
            margin-bottom: 15px;
        }
        .comment {
            grid-template-columns: 54px 1fr 1fr;
            grid-auto-rows: auto;
            padding: 16px;
            gap: 12px;
        }
        .dateOfCreation {
            top: 16px;
            p {
                font-size: 12px;
            }
        }
        .authorAvatar {
            grid-row: 2/3;
            width: 54px;
            height: 54px;
        }
        .commentHeader {
            align-self: center;
            grid-row: 2/3;
            .authorName {
                font-size: 16px;
            }
        }
        .commentInfo {
            grid-column: 1/4;
            grid-row: 3/4;
        }
        .showCommentsButton,
        .textContent {
            font-size: 14px;
        }
    }
`;
