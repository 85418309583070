import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ViewDoctorSpecialization } from './ViewDoctorSpecialization';
import { useState } from 'react';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { EditDoctorSpecialization } from './EditDoctorSpecialization';
import { SectionStl } from '../viewDoctor.styled';
import { ModerationLabel } from '../../../../share/ModerationLabel';

export const DoctorSpecialization = () => {
    const dispatch = useDispatch();
    const [editData, toggleEditData] = useState(false);
    const doctorProfile = useSelector((state) => state.doctorProfile?.doctorProfile);

    return (
        <SectionStl>
            <TitleWithPencil toggle={toggleEditData} title={'Специализация'} />
            {doctorProfile?.specialization?.some((el) => !el.is_moderated) ? <ModerationLabel /> : null}
            {editData ? (
                <EditDoctorSpecialization toggle={toggleEditData} />
            ) : (
                <ViewDoctorSpecialization doctorProfile={doctorProfile} />
            )}
        </SectionStl>
    );
};
