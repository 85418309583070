import React, { useEffect, useLayoutEffect } from 'react';
import InputText from '../../../share/formFields/newElements/InputText';
import { useFormContext } from 'react-hook-form';
import Checkbox from '../../../share/formFields/newElements/Checkbox';
import InputDate from '../../../share/formFields/newElements/InputDate';
import { validateInputOrganization, validateInputPosition, validateWorkDate } from '../../../share/formFields/validateInput';

export function WorkList({ name }) {
    const { watch, setValue, setError, trigger } = useFormContext();
    const index = name.split('.')[1];

    // отслеживаем текущее поле checkbox
    const untilNow = watch(`${name}.until_now`);

    // отслеживаем массив work
    const watchedWork = watch('work');

    // useLayoutEffect(() => {
    //     trigger(name);
    // }, [untilNow]);

    // при выборе until_now(true) - нужно очистить поле end_date
    useEffect(() => {
        if (untilNow) {
            setValue(`${name}.end_date`, '');
            setError(`${name}.end_date`, { requared: true, message: 'Введите дату' });
        }
    }, [untilNow]);

    // если где то в массиве work есть поле until_now(true) и его индекс не равен текущему списку - скрыть checkbox
    const hideField = watchedWork.some((item, i, arr) => {
        return (!item.until_now && i != index) || arr.length === 1;
    });

    // конвертер из 99/9999 в timeStamp - чтобы сравнивнить даты
    const createTime = (str) => {
        return new Date(str.slice(-4), +str.slice(0, 2)).getTime();
    };

    //набираем в поле  end_date - сравним с полем start_date
    const isLargeThanStart = (value) => {
        // мы сравниаем текущую дату end_date c уже набранной start_date
        const isEndDateEqual = createTime(value) == createTime(watchedWork[index].end_date);
        const isStartDateLarge = createTime(value) < createTime(watchedWork[index].start_date);
        if (isEndDateEqual && isStartDateLarge) {
            // trigger(name);
            return 'Дата конца не может быть больше даты начала';
        }
        return true;
    };

    return (
        <>
            <InputText
                name={`${name}.organization`}
                label="Организация"
                placeholder="Название"
                validation={validateInputOrganization(true)}
                isRequired={true}
            />

            <InputText
                name={`${name}.position`}
                label="Должность"
                placeholder="Название"
                validation={validateInputPosition(true)}
                isRequired={true}
            />
            <p className="work__title">Период работы</p>
            <div className="work__block">
                <div className="work__date">
                    <InputDate
                        defaultValue="99/9999"
                        name={`${name}.start_date`}
                        label="с"
                        placeholder="ММ/ГГГГ"
                        validation={validateWorkDate(true)}
                        isRequired={true}
                    />
                </div>
                {!untilNow && (
                    <div className="work__date">
                        <InputDate
                            defaultValue="99/9999"
                            name={`${name}.end_date`}
                            label="по"
                            placeholder="ММ/ГГГГ"
                            validation={validateWorkDate(true)}
                            isRequired={true}
                        />
                    </div>
                )}
            </div>
            {hideField && (
                <div className="work__date-checkbox">
                    <Checkbox name={`${name}.until_now`} />
                    <span>По настоящее время</span>
                </div>
            )}
        </>
    );
}
