import React from 'react';
import { ReviewsMainStyled, InfoContainerStyled } from './reviewsMain.styled';
import Container from '../../share/Container';
import { MainHeader } from '../../mainHeader/mainHeader';
import { ReviewsBanner } from '../reviewsBanner/ReviewsBanner';
import { ReviewsList } from '../reviewsList/ReviewsList';
import { ReviewsForm } from '../reviewsForm/ReviewsForm';

export const ReviewsMain = () => {
    return (
        <ReviewsMainStyled>
            <Container>
                <MainHeader title={'Отзывы'} />
                <InfoContainerStyled>
                    <ReviewsBanner />
                    <ReviewsForm />
                    <ReviewsList />
                </InfoContainerStyled>
            </Container>
        </ReviewsMainStyled>
    );
};
