import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from '../share/api';

export const fetchAppeal = createAsyncThunk(
	'contacts/fetchAppeal',
	async (dataAppeal) => {
		try {
			const res = await apiRequest.post(`/feedback_service/`, dataAppeal);
			return res;
		} catch (err) {
			console.log(err);
		}
	}
);

export const fetchSubscription = createAsyncThunk(
	'contacts/fetchSubscription',
	async (email) => {
		try {
			const res = await apiRequest.post(`/email/`, email);
			return res;
		} catch (err) {
			console.log(err);
		}
	}
);

const contacts = createSlice({
  name: "contacts",
  initialState: {
    error: null,
    dataAppeal: null,
	 subscription: {
		email: null,
		error: null
	 }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAppeal.pending, (state, action) => {
      state.dataAppeal = null;
      state.error = null;
    });
    builder.addCase(fetchAppeal.fulfilled, (state, action) => {
		state.dataAppeal = action?.payload?.data;
      state.error = !action?.payload ? true : null;
    });
    builder.addCase(fetchAppeal.rejected, (state, action) => {
      state.dataAppeal = null;
      state.error = action.payload;
    });

	 builder.addCase(fetchSubscription.pending, (state, action) => {
      state.subscription.email = null;
      state.subscription.error = null;
    });
    builder.addCase(fetchSubscription.fulfilled, (state, action) => {
		state.subscription.email = action?.payload?.data;
      state.subscription.error = !action?.payload ? true : null;
    });
    builder.addCase(fetchSubscription.rejected, (state, action) => {
      state.subscription.email = null;
      state.subscription.error = action.payload;
    });
  },
});

export default contacts.reducer;