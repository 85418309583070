import React from 'react';
import { FormProvider, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { filterDeletedBlocks } from '../helpers/filterDeletedBlocks';
import { useDispatch } from 'react-redux';
import { fetchLoadAdvancedTraining, fetchMeDoctor, putAdvancedTraining } from '../../../api/doctorUserProfileSlice';
import { deleteMyAdvancedTraining } from '../../../api/api';
import { convertDateFields } from '../helpers/convertDateFields';
import { TrainigList } from '../../shared/TrainigList';

export function EditAdvancedTraining({ toggle, advanced_training }) {
    const dispatch = useDispatch();
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            // (дефолтные поля)конвертируем дату из секунд в yyyy
            advanced_training: convertDateFields(advanced_training),
        },
    });

    // для управления массивом
    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: 'advanced_training',
    });

    // если поля испачканы - узнаем здесь
    const { dirtyFields, isValid, isSubmitting } = useFormState({ control: methods.control });

    const onSubmit = ({ advanced_training: currentList }) => {
        const promises = [];
        // вычислим удаленные блоки и удалим их с бека
        const deletedBlocks = filterDeletedBlocks(advanced_training, currentList);
        deletedBlocks?.forEach((item) => {
            promises.push(deleteMyAdvancedTraining(item.id));
        });

        currentList.forEach((item, i) => {
            const block = {
                ...item,
                end_date: new Date(item.end_date, 1, 2).getTime() / 1000,
            };
            // если новый блок
            if (block.newField) {
                const formData = new FormData();
                formData.append('organization', block.organization);
                formData.append('position', block.position);
                formData.append('end_date', block.end_date);
                formData.append('file', block.file[0]);
                promises.push(dispatch(fetchLoadAdvancedTraining(formData)));
            }
            // если измененный
            if (!block.newField && dirtyFields?.advanced_training?.[i]) {
                const formData = new FormData();
                formData.append('organization', block.organization);
                formData.append('position', block.position);
                formData.append('end_date', block.end_date);
                // если испачкано поле file - обновим
                if (dirtyFields?.advanced_training?.[i].file) {
                    formData.append('file', block.file[0]);
                }
                promises.push(dispatch(putAdvancedTraining({ formData, id: block.id })));
            }
        });

        Promise.all(promises)
            .then(() => {
                toggle(false);
            })
            .then(() => {
                dispatch(fetchMeDoctor());
            });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const onAdd = () => {
        const newBlock = {
            organization: '',
            position: '',
            end_date: '',
            file: '',
            newField: true,
        };
        append(newBlock);
    };

    const inputs = fields.map((education, index) => {
        return (
            <section className="block" key={education.id}>
                <TrainigList name={`advanced_training.${index}`} />
                {fields.length > 1 && (
                    <span className="delete" onClick={() => remove(index)}>
                        Удалить блок
                    </span>
                )}
            </section>
        );
    });

    return (
        <FormProvider {...methods}>
            <form className="formDynamicInput" onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="inputs">{inputs}</div>
                <button className="btn__addBlock" onClick={onAdd}>
                    {`+ Добавить квалификацию`}
                </button>
                <button className="btn__recive" type="submit" disabled={!isValid || isSubmitting}>
                    Сохранить
                </button>
            </form>
        </FormProvider>
    );
}
