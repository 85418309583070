import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FlipSwitch from './flipSwitch';
import { DoctorSettingsDiv } from './lkDoctorSettings.styled';
import { Modal } from '../../share/Modal';
import PopUpConfirm from '../../share/PopUpConfirm';
import { fetchLogout } from '../../authentification/api/authentificationSlice';


const LkDoctorSettings = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogOut = () => {
        dispatch(fetchLogout());
        navigate('/');
    };

    const deleteProfile = () => { console.log("delete") };

    const [popUpExitProfile, setPopUpExitProfile] = useState(false);
    const [popUpDeleteProfile, setPopUpDeleteProfile] = useState(false);

    return (
        <DoctorSettingsDiv>
            <h2 className='settings-header'>Управление личным кабинетом</h2>
            <div className='settings-item_wrapper'>
                <div className='switcher-box'>
                    <p className='settings__item'>Сохранить данные для входа в личный кабинет</p><FlipSwitch />
                </div>
                <div className='switcher-box'>
                    <p className='settings__item'>Данные о местоположении</p><FlipSwitch />
                </div>
            </div>
            <div className='divider' />
            <div className='settings-item_wrapper'>
                <p className='settings__item'><label onClick={() => setPopUpExitProfile(true)} className='settings__item-label'>Выйти</label> из личного кабинета.</p>
                {/* <p className='settings__item'>Вы можете <label onClick={() => { setPopUpDeleteProfile(true) }} className='settings__item-label'>удалить</label> свой личный кабинет.</p> */}
            </div>
            {
                popUpExitProfile && (
                    <Modal //autoClose={7500}
                        toggle={setPopUpExitProfile}>
                        <PopUpConfirm
                            onClose={() => setPopUpExitProfile(false)}
                            onConfirm={() => handleLogOut()}
                            contentHeader={
                                <span>
                                    Вы уверены, что хотите <br /> выйти из личного кабинета?
                                </span>}
                        />
                    </Modal>
                )
            }
            {
                popUpDeleteProfile && (
                    <Modal //autoClose={7500}
                        toggle={setPopUpDeleteProfile}>
                        <PopUpConfirm
                            onClose={() => setPopUpDeleteProfile(false)}
                            onConfirm={() => deleteProfile()}
                            contentHeader={
                                <span>
                                    Вы уверены, что хотите <br /> удалить личный кабинет?
                                </span>}
                        />
                    </Modal>
                )
            }
        </DoctorSettingsDiv>
    );
};

export default LkDoctorSettings;