import React from 'react';
import { RadioInput, RadioLabel, RadioText } from './RadioButton.styled';

const RadioButton = ({ id, name, isChecked, onChange, label }) => {
  return (
    <RadioLabel htmlFor={id}>
      <RadioInput
        id={id}
        name={name}
        checked={isChecked}
        onChange={onChange}
        value={''}
      />
      <div className={`image ${isChecked ? 'checked' : ''}`} />
      <RadioText>{label}</RadioText>
    </RadioLabel>
  );
};

export default RadioButton;
