import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { ViewAllergy } from './ViewAllergy';
import { SectionStl } from '../viewPatient.styled';
import { EditAllergy } from './EditAllergy';

export const Allergy = () => {
    const [editAllegies, toggleEditAllergies] = useState(false);
    const patientProfile = useSelector((state) => state.patientProfile?.patientProfile);
    const {allergies} = patientProfile;

    return (
        <SectionStl>
            <TitleWithPencil toggle={toggleEditAllergies} title={'Аллергии'} />
            {editAllegies ? (
                <EditAllergy allergies={allergies} toggle={toggleEditAllergies} />
            ) : (
                <ViewAllergy allergies={allergies} />
            )}
        </SectionStl>
    );
};
