import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { fetchDoctorReceptions } from '../api/lkDoctorCalendarSlice';
import { CalendarStl } from './lkDoctorCalendar.styled';

export const LkDoctorCalendar = () => {
    const dispatch = useDispatch();

    const path = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchDoctorReceptions());
    }, [dispatch]);

    useEffect(() => {
        if (path.pathname === '/lk/doctor-profile/doctor-calendar') {
            navigate('/lk/doctor-profile/doctor-calendar/settings', { replace: true });
        }
    }, [path]);

    return (
        <CalendarStl>
            <Outlet />
        </CalendarStl>
    );
};
