import styled from 'styled-components';
import doctorsHome from '../../../../img/doctorHouse.jpg'
import iconLike from '../../../../img/icons/icon-like.svg'
import iconLikeActive from '../../../../img/icons/icon-like-active.svg'

export const StyledCard = styled.div`
  border: 1px solid #F4F4F4;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  
  .card__img{
    width: 100%;
    height: 245px;
    background-image: url(${(props) => props.avatar || doctorsHome});
    background-size: cover;
  }

  .card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 277px);
    margin: 16px;
  }

  .card__nameLike {
    display: flex;
    justify-content: space-between;
  }

  .card__like {
    min-width: 20px;
    height: 18px;
    margin: 7px 3px 0 0;
    background-image: url(${(props) => props.isFavorite ? iconLikeActive : iconLike});
    background-size: cover;
    border: none;
    background-color: transparent;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }

  .card__name {
    margin: 0 19px 12px 0;
    overflow: hidden;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #212121;
  }

  .card__specialization {
    margin-bottom:8px;
    font-size: 16px;
    color: #666666;
  }

  .card__experience {
    margin: 8px 0 16px;
    font-size: 16px;
    color: #666666;
  }

  .card__rating {
    font-size: 16px;
    color: #212121;
  }

  .card__ratingImg {
    width: 16px;
    margin: 0 4px 0 8px;
  }

  .card__price {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #212121;
  }

  .card__button {
    justify-self: end;
    width: 100%;
    min-height: 44px;
    background: #19BE6F;
    border: 2px solid #19BE6F;
    border-radius: 10px;
    cursor: pointer;
    padding: 5px;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    :hover {
      opacity: 0.8;
    }
   }
`
