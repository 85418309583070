import React from 'react'
import { TitleWithPencilStl } from './titleWithPencil.styled';
import { ReactComponent as Pensil } from '../../../../../img/icons/pencil.svg';

export const TitleWithPencil = ({ title, toggle }) => {
    return (
        <TitleWithPencilStl>
            <h2 className="title">{title}</h2>
            <Pensil onClick={() => toggle(prev => !prev)} />
        </TitleWithPencilStl>
    )
}
