import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import ReactSelect, { components } from 'react-select';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { SelectCityStl } from './selectCity.styled';
import { ReactComponent as Cross } from '../../../../../img/icons/cross-gray.svg';
import { FieldWrapperStl, ErrorStl, LabelStl } from '../../FieldWrapper';

const SelectCommonFieldStl = styled(FieldWrapperStl)`
    .selected {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8px;
        gap: 8px;
        &__option {
            border-radius: 10px;
            background-color: #8af0bf;
            display: flex;
            flex-direction: row;
            &-label {
                padding: 7px 8px 6px 15px;
                user-select: none;
            }
            &-clear {
                display: flex;
                align-items: center;
                padding-right: 8px;
                path {
                    stroke: #19be6f;
                }
                &:hover path {
                    stroke: #169056;
                }
            }
        }
    }
    > svg {
        display: none; // прячем крест при вводе
    }
    .rs__value-container {
        width: 100%;
        max-width: 370px;
    }
`;

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <span></span>
        </components.DropdownIndicator>
    );
};

const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <Cross />
        </components.ClearIndicator>
    );
};

const Clear = forwardRef(function Clear(props, ref) {
    const show = useRef(false);
    const [force, setForce] = useState(false);

    useImperativeHandle(
        ref,
        () => {
            return {
                toShow(inputValue) {
                    const boolInput = Boolean(inputValue);

                    if (boolInput !== show.current) {
                        show.current = boolInput;
                        setForce(() => boolInput);
                    }
                },
            };
        },
        []
    );

    return <Cross className={`${show.current ? 'visible' : ''}`} />;
});

const SelectCity = ({
    name,
    validation = {},
    options,
    placeholder,
    label,
    radioButton,
    narrow,
    isPreloaderCity,
    isCity,
    isRequired,
    onChange = () => {},
}) => {

    const defaultValidation = {
        required: { value: false, message: 'Поле обязательно для заполнения' },
        ...validation,
        validate: {
            ...validation.validate,
        }
    };

    const { control, trigger } = useFormContext();

    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: defaultValidation,
    });

    let components = {
        DropdownIndicator,
        ClearIndicator,
    };

    const refClear = useRef(null);

    useEffect(()=>{
        // field.onChange(field.value);
        field.value && field.onChange(field.value);
    },[])

    const handleBlur = () => {
        trigger(field.name);
    };

    return (
        <SelectCommonFieldStl>
            {label !== undefined && <LabelStl>
                {label}
                {isRequired && <span className='fieldWraper__red'>*</span>}
            </LabelStl>}
            <SelectCityStl isCity={isCity}
                as={ReactSelect}
                {...field}
                onChange={(value) => {
                    field.onChange(value);
                }}
                onInputChange={(value) => {
                    onChange(value);
                    refClear.current.toShow(value);
                }}
                onBlur={handleBlur}
                components={components}
                options={options}
                placeholder={placeholder}
                isSearchable={true}
                isClearable
                noOptionsMessage = {(value) => 
                    {
                        let message = '';
                        isPreloaderCity ? 
                            message = 'Поиск...' :
                            value.inputValue?.length < 2 ? 
                                message = 'Введите город' : 
                                message = 'Город не найден';
                        return message;
                    }
                }
                classNamePrefix="rs"
                narrow={narrow}
                radioButton={radioButton}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        borderColor: error ? '#ff3636' : state.isFocused ? '#19BE6F' : '#B6B7BA',
                    }),
                    // крестик сброса при выбранном значении
                    clearIndicator: (base, state) => ({
                        ...base,
                        padding: '5px',
                        display: 'flex',
                    }),
                    dropdownIndicator: (base, state) => ({
                        ...base,
                        display: 'none', // прячем стрелку селекта
                    }),
                }}
            />
            <Clear ref={refClear} />
            <ErrorStl>{error?.message}</ErrorStl>
        </SelectCommonFieldStl>
    );
};

export default SelectCity;
