import styled from 'styled-components';

export const SelectedPatientStyled = styled.section`
    display: flex;
    flex-direction: column;

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .inputSearch {
        position: relative;
        > label input {
            background-color: #f4f4f4;
            padding-left: 55px;
            padding-top: 13px;
            color: #b6b7ba;
            border-radius: 10px;
            border: none;
        }
        label {
            svg {
                top: 10px;
            }
        }
        > svg {
            position: absolute;
            left: 17px;
            top: 12px;
        }
    }

    form {
        margin-bottom: 35px;
        max-width: 422px;
    }

    .patientNo__title {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 500;
        line-height: 130%;
        color: #666;
    }

    .btns {
        text-align: right;
        span {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #19be6f;
            cursor: pointer;
            padding: 3px;
            &:first-child {
                margin-right: 42px;
            }
            &:last-child {
                svg {
                    margin-left: 4px;
                }
            }
        }
    }

    @media (max-width: 850px) {
        .patientNo__title {
            margin-top: 60px;
            font-size: 22px;
        }
    }

    @media (max-width: 450px) {
        .patientNo__title {
            margin-top: 40px;
            font-size: 20px;
        }
    }
`;

export const Grid = styled.ul`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    margin-bottom: 35px;
    padding: 0;
    column-gap: 20px;
    row-gap: 35px;
    list-style-type: none;
    li {
        width: 100%;
    }
`;
