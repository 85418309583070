import React, { useState } from 'react';
import { StyledLkDoctorProfile, StyledUserLogo } from './lkDoctorProfile.styled';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLoadMyAvatar, fetchDeleteMyAvatar, fetchMeDoctor, fetchSpecializations, fetchUniversity } from '../api/doctorUserProfileSlice';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import RoundPhoto from '../../share/RoundPhoto';
import { Loader } from '../loader/loader';
import PhotoAlt from '../../../img/photo_alt.png';
import ImageHover from '../../../img/photo_hover.png';
import { uploadFile } from '../../share/fileUploader';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { fetchMeUser } from '../../authentification/api/authentificationSlice';

const LkDoctorProfile = () => {
    const [errorLoadAvatar, setErrorLoadAvatar] = useState(false);
    const [isLoad, setIsLoad] = useState(false);

    const dispatch = useDispatch();
    const path = useLocation();
    const redirect = useNavigate();
    const { isPreloader: loader, doctorProfile } = useSelector((state) => state.doctorProfile);
    const refAvatar = useRef();

    useEffect(()=>{
        let delayAvatarId = setTimeout(() => setErrorLoadAvatar(false), 5000);
        return () => {clearTimeout(delayAvatarId)};
    },[isLoad]);
    
    useEffect(() => {
        dispatch(fetchSpecializations());
        window.scrollTo(0, 0);
    }, [dispatch, path]);

    useEffect(() => {
        dispatch(fetchUniversity());
    }, [dispatch, path]);

    useEffect(() => {
        if (path.pathname === '/lk/doctor-profile/profile') {
            redirect('/lk/doctor-profile/profile/view', { replace: true });
        }
    }, [path]);

    const addPhoto = () => {
        const sendUpResult = (file) => {
            file.size > 2097152 ?
                (setErrorLoadAvatar(true), setIsLoad(!isLoad)) :
                dispatch(fetchLoadMyAvatar(file));
        };
        uploadFile(
            refAvatar,
            {
                multiple: false,
                accept: ['.jpg', '.png', '.jpeg', '.gif'],
            },
            sendUpResult
        );
    };

    const handleDeleteAvatar = () => {
        console.log('delete file')
    }

    if (!doctorProfile) {
        return <Loader />;
    }
    const { avatar } = { ...doctorProfile.user };

    return (
        <StyledLkDoctorProfile>
            <div className='avatar'>
                <StyledUserLogo onClick={addPhoto}>
                    <img src={ImageHover} />
                    <RoundPhoto size="156px" addr={avatar ? `${avatar}` : PhotoAlt} />
                    <input type="file" name="photo" id="loadPhoto" ref={refAvatar} />
                </StyledUserLogo> 
  
                {errorLoadAvatar || !avatar ? '' : 
                    <button className="deleteAvatarButton" onClick={handleDeleteAvatar}>Удалить фото</button>
                }
                {errorLoadAvatar && <span className="errorLoadAvatar">Размер файла должен быть не более 2 МБ</span>}
            </div>
            <div className="data">
                <Outlet />
            </div>
        </StyledLkDoctorProfile>
    );
};

export default LkDoctorProfile;
