import styled from 'styled-components';

export const OnlineConsultationStl = styled.div`
    padding: 52px 40px 40px 40px;
    max-width: 414px;
    background: rgb(255, 255, 255);
    border-radius: 24px;
    .consultation {
        text-align: center;
        & > *:not(:last-child) {
            margin-bottom: 35px;
        }
        &__icon {
            height: 105px;
            margin: 0 auto;
        }
        &__title {
            font-weight: 500;
            font-size: 20px;
        }
        &__doctor {
            margin-bottom: 27px;
            &-name {
                font-size: 18px;
                margin-bottom: 14px;
            }
            &-skill {
                display: flex;
                flex-direction: column;
                gap: 4px;
                list-style: none;
                > li {
                    color: #666;
                }
            }
        }
        &__details {
            .details {
                img {
                    padding: 0 8px 0 0;
                    height: 24px;
                }
                &__cell {
                    display: inline-flex;
                    align-items: center;
                    &:first-of-type {
                        padding: 0 16px 0 0;
                    }
                    span {
                        color: #666;
                    }
                }
                &:first-of-type {
                    margin-bottom: 8px;
                }
            }
        }
        &__button {
            width: 334px;
        }
    }
    @media(max-width: 434px) {
        .consultation__button {
            width: 100%;
        }
    }
    
`;
