import React from 'react';
import { useLocation } from 'react-router-dom';
import { StyledCloseConsultationModal } from './closeConsultationModal.styled';
import {
    cancelPatientConsultation,
    getPatientConsultation,
    removeConsultation,
} from '../../api/PatientConsultationsSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as LogoModal } from './logo_modal.svg';
import { ReactComponent as Cross } from '../../../../img/icons/cross-green.svg';
import { showTimer } from '../../../timerConsultation/api/timerSlice';

export const CloseConsultationModal = ({ setFirstConfirm, consultation, reload = false }) => {
    const { pathname } = useLocation();
    const typeConsult = pathname.includes('future') ? 'future' : 'past';
    const dispatch = useDispatch();
    const id = consultation.id;

    const closeCloseConsultation = () => {
        setFirstConfirm(false);
    };

    const finishConsultation = async () => {
        dispatch(showTimer(false));
        setFirstConfirm(false);
        await dispatch(cancelPatientConsultation(consultation.id)).then(() => {
            dispatch(removeConsultation({ typeConsult, id }));
            dispatch(getPatientConsultation(consultation.id));
        });
    };

    return (
        <StyledCloseConsultationModal>
            <div className="modalCancel">
                <Cross className="modalCancel_cross__icon" onClick={closeCloseConsultation} />
                <LogoModal />
                <p className="modalCancel__text">Вы уверены, что хотите отменить консультацию?</p>
                {/* <p className="modalCancel__link">Посмотреть “Условия отмены консультации пациентом”</p> */}
                <button onClick={finishConsultation} className="modalCancel__close">
                    Отменить
                </button>
                <p className="modalCancel__conditions">Если до начала консультации осталось менее 24 часов и вы хотите отменить запись — с вас будет удержана компенсация в размере 1/3 от стоимости несостоявшейся консультации. </p>
            </div>
        </StyledCloseConsultationModal>
    );
};
