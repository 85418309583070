import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormDoctor from './FormDoctor';
import { useSelector } from 'react-redux';
import { Loader } from '../../loader/loader';

const EditDoctor = () => {
    const { user } = useSelector((state) => state.doctorProfile?.doctorProfile);
    const isLoaderProfile = useSelector((state) => state.doctorProfile.isPreloader);
 
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            // получал warning
            // что поля из неконтролируемых переходят в
            // контрулируемые(явно задаем пустую строку)
            user: {
                first_name: '',
                last_name: '',
                patronymic: '',
                birthday: '',
                gender: 0,
                phone: user.phone,
                email: user.email,
                city: '',
            },
            higher_education: [
                {
                    university: '',
                    specialization: '',
                    end_date: '',
                },
            ],
            advanced_training: [
                {
                    organization: '',
                    position: '',
                    end_date: '',
                    file: '',
                },
            ],
            work: [
                {
                    organization: '',
                    position: '',
                    start_date: '',
                    end_date: '',
                    until_now: '',
                },
            ],
            video_chat_price: '',
            // чтобы вытащить телефон из default
        },
    });

    return (
        <>
            {isLoaderProfile ? <Loader/> :
                <FormProvider {...methods}>
                    <FormDoctor methods={methods} />
                </FormProvider>    
            }
        </>
    );
};

export default EditDoctor;
