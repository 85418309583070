import React from 'react';
import styled from 'styled-components';

const TitleH1Stl = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  color: ${(props) => props.color || '#212121'};
  @media ${({ theme }) => theme.device.laptop} {
    font-size: 38px;
  }
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 24px;
  }
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 20px;
  }
`;

const TitleH1 = (props) => {
  return <TitleH1Stl {...props} />;
};
export default TitleH1;
