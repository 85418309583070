import styled from 'styled-components';
import iconGoBack from "../../../../../img/icons/icon-arrow-back.svg";

export const DoctorConsultationPageStyled = styled.div`
    .red {
        color: #ff3636;
    }
    .go-back-btn {
        color: #19be6f;
        font-size: 16px;
        line-height: 150%;
        cursor: pointer;
        margin-bottom: 50px;
        &:before {
            content: url(${iconGoBack});
            padding-right: 4px;
        }
    }
    .consultation-data {
        display: flex;
        flex-direction: column;
        gap: 35px;
        max-width: 690px;
    }
    .statusConsultation {
        transition: all 0.3s;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
    }
    .schedule {
        display: flex;
        flex-direction: column;
        gap: 8px;
        & img {
            margin-right: 8px;
        }

        & span {
            transition: all 0.3s;
            font-size: 18px;
            color: #062a3f;
        }
        &-data--box {
            display: flex;
            gap: 24px;
        }
        &-date {
            display: flex;
            align-items: center;
        }

        &-time {
            display: flex;
            align-items: center;
        }
    }
    .patient-data {
        transition: all 0.3s;
        display: flex;
        gap: 24px;
        &__avatar {
            border-radius: 200px;
            overflow: hidden;
            flex-shrink: 0;

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                width: 84px;
                height: 84px;
                border-radius: 50%;
            }
        }
        &__name-and-age {
            gap: 4px;
            display: flex;
            flex-direction: column;
            &__name {
                font-size: 20px;
                line-height: 130%;
                color: #212121;
            }
            &__age {
                font-size: 18px;
                line-height: 150%;
                color: #666666;
            }
        }
    }
    .patient-comments {
        transition: all 0.3s;
        display: flex;
        flex-direction: column;
        gap: 16px;
        &__title {
            color: #212121;
            font-size: 18px;
            line-height: 130%;
            font-weight: 500;
        }
        &__text {
            color: #212121;
            font-size: 18px;
            line-height: 150%;
        }
        &__files {
            display: flex;
        }
    }

    @media (max-width: 768px) {
        max-width: 490px;
        margin: 0 auto;
        .patient-data {
            gap: 20px;
        }
    }
    @media (max-width: 455px) {
        .consultationFormButtons {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
    @media (max-width: 520px) {
        .statusConsultation {
            font-size: 18px;
        }
        .schedule-data--box {
            span {
                font-size: 16px;
            }
            img {
                width: 15px;
                height: 15px;
            }
        }
        .patient-data {
            &__name-and-age {
                &__name {
                    font-size: 16px;
                }
                &__age {
                    font-size: 14px;
                }
            }
        }
        .patient-comments {
            &__title {
                font-size: 18px;
            }
            &__text {
                font-size: 16px;
            }
        }
    }
`;

