import React from 'react';
import { CalendarStl } from './calendar.styled';
import { CalendarHeader } from './calendarHeader/CalendarHeader';
import { CalendarBody } from './calendarBody/CalendarBody';

export const Calendar = ({
    choosenDate,
    setChoosenDate,
    setChoosenDates,
    choosenDates,
    isDays = null,
}) => {
    return (
        <CalendarStl>
            <CalendarHeader choosenDate={choosenDate} setChoosenDate={setChoosenDate} />
            <CalendarBody
                choosenDate={choosenDate}
                setChoosenDate={setChoosenDate}
                isDays={isDays}
                setChoosenDates={setChoosenDates}
                choosenDates={choosenDates}
            />
        </CalendarStl>
    );
};
