import React from 'react';
import { useSelector } from 'react-redux';
import { DataBlockStl } from  '../viewPatient.styled';
import { dateFormatterYears } from '../../../../share/helpers';
import { Spinner } from '../../../../share/Spinner';

export const ViewOperations = ({ operations }) => {
    const isLoaderOperations = useSelector((state) => state.patientProfile?.isLoaderOperation);

    const operationsShow = operations.map((operation) => {
        return (
            <DataBlockStl key={operation.id}>
                <div className="info__wrapper">
                    <p className="info__data">{operation.description}</p>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">{dateFormatterYears(operation.date)}</p>
                </div>
            </DataBlockStl>
        );
    });

    return isLoaderOperations ? <Spinner/> : operationsShow;
};