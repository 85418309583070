import styled from 'styled-components';

export const StyledreviewPage = styled.section`
  width: 72vw;
  margin: 0 auto;
  
  .reviewsPlug {
    margin-bottom: 35px;
  }

  @media(max-width: 800px) {
    width: 69vw;
    .reviewsPlug {
      margin-bottom: 16px;
    }
  }

  @media(max-width: 425px) {
    width: 288px;
  }

  @media(max-width: 320px) {
    width: 90vw;
  }
`