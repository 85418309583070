import React from 'react';
import { ReviewCardStl } from './reviewCard.styled';
import RatingStars from '../../../share/RatingStars';
import {getDateForReviews} from '../../../share/helpers';
import unknown_user from '../../../../img/icons/doctorProfilIcons/unknown_user.png'
import RoundPhoto from '../../../share/RoundPhoto';

export const ReviewCard = ({ review, textRef=null }) => {
    
    return (
        <ReviewCardStl>
            <div className='docReview__card'>
                <RoundPhoto size="49px" addr={(review.patient_avatar) ? review.patient_avatar : unknown_user} />
                <div className='docReview__card_header'>
                    <h3 className='docReview__card_patient-name'>{review.patient_first_name}</h3>
                    <div>
                        <RatingStars score={review.rating_value} />
                        <p className='docReview__card_date' >{getDateForReviews(review.created_at)}</p>
                    </div>
                </div>
            </div>
            <div className='docReview__card_body' >
                <p className='docReview__card_description' ref={textRef} >
                    {review.text}
                </p>
            </div>
        </ReviewCardStl>
    );
}
    ;
