import React from 'react';
import { useLocation } from 'react-router-dom';
import { StyledCloseConsultationModal } from './closeConsultationModal.styled';
import { cancelConsultation, fetchConsultation, removeConsultation } from '../../api/lkDoctorConsultationsSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as LogoModal } from './logo_modal.svg';
import { showTimer } from '../../../timerConsultation/api/timerSlice';

export const CloseConsultationModal = ({ setFirstConfirm, consultation }) => {
    const { pathname } = useLocation();
    const typeConsult = pathname.includes('future') ? 'future' : 'past';
    const dispatch = useDispatch();
    const id = consultation.id;

    const finishConsultation = async () => {
        dispatch(showTimer(false));
        setFirstConfirm(false);
        await dispatch(cancelConsultation(consultation.id)).then(() => {
            dispatch(removeConsultation({ typeConsult, id }));
            dispatch(fetchConsultation(consultation.id));
        });
    };

    return (
        <StyledCloseConsultationModal>
            <div className="modalCancel">
                <LogoModal />
                <p className="modalCancel__text">Вы уверены, что хотите отменить консультацию?</p>
                {/* <p className="modalCancel__link">Посмотреть “Условия отмены консультации врачом”</p> */}
                <button onClick={finishConsultation} className="modalCancel__close">
                    Отменить
                </button>
            </div>
        </StyledCloseConsultationModal>
    );
};
