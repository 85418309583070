import React from 'react';
import InputText from '../../../share/formFields/newElements/InputText';
import InputDate from '../../../share/formFields/newElements/InputDate';
import Select from '../../../share/formFields/newElements/Select';
import { SelectStl } from '../viewPatient/patientParametrs/patientParametrs.styled';
import { BLOODTYPES } from './constant';
import { validAteInputParametrs } from '../../../share/formFields/validateInput';

export const EditParametrsList = ({patientProfile}) => {
    
    return (
        <>
            <div className="work__date">
                <InputDate
                    name="patientProfile.height"
                    label="Рост" placeholder="170"
                    defaultValue="999"
                    validation={validAteInputParametrs(true)}
                    isRequired={true}
                />
            </div>
            <div className="work__date">
                <InputDate
                    name="patientProfile.weight"
                    label="Вес"
                    placeholder="60"
                    defaultValue="999"
                    validation={validAteInputParametrs(true)}
                    isRequired={true}
                />
            </div>
            <SelectStl>
                <Select
                    // isMulti
                    name="patientProfile.blood_type"
                    options={BLOODTYPES?.map((type) => ({ value: type.name, label: type.name }))}
                    defaultValue={ patientProfile.blood_type }
                    placeholder={ patientProfile.blood_type }
                    validation={{ required: false, validate: false}}
                    onChange={() => {}}
                    label="Группа крови"
                /> 
            </SelectStl>
        </>
        
    );
};


