import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StyledSearchContainer, StyledSearchInput } from './searchInput.styled';
import { fetchMainSearch } from '../mainSearchSlice';
import useOutsideClick from '../../../hooks/useOutsideClick';
import SearchResult from './searchResult';

function SearchInput({ isSearchActive, setSearchActive = () => {} }) {
    const dispatch = useDispatch();
    const [iconVisible, setIconVisible] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const handleChange = (event) => {
        const newValue = event.target.value.replace(/[^А-Яа-яЁёa-zA-Z]/g, '');
        setSearchValue(newValue.trim());
    };
    const navigate = useNavigate();
    const goSearch = (e) => {
        if (e.key === 'Enter') {
            navigate('/searchDoctors');
            setSearchActive(!isSearchActive);
        } else return;
    };
    const input = useRef(null);

    useEffect(() => {
        dispatch(fetchMainSearch(searchValue === '' || searchValue === '  ' ? null : searchValue));
    }, [dispatch, searchValue]);

    function handleDropSearch(e) {
        if (e.target.name !== 'search_btn') {
            setSearchValue('');
            setIconVisible(true);
            setSearchActive(!isSearchActive);
        }
    }

    useOutsideClick(input, handleDropSearch, isSearchActive);

    return (
        <StyledSearchContainer>
            <StyledSearchInput>
                <img className="input__icon" src="/img/header/searchIcon.svg" />

                <input
                    ref={input}
                    className="search__input"
                    type="text"
                    required
                    minLength="1"
                    maxLength="30"
                    title=""
                    placeholder="Поиск врача по Ф.И.О"
                    // В макете не предусмотрен текст, оставил на будущее
                    // placeholder='Поиск по специализации, фамилии'
                    value={searchValue}
                    autoFocus
                    onChange={handleChange}
                    onKeyUp={(e) => goSearch(e)}
                />
            </StyledSearchInput>

            {searchValue !== '' && <SearchResult setSearchActive={setSearchActive} setSearchValue={setSearchValue} />}
        </StyledSearchContainer>
    );
}
export default SearchInput;
