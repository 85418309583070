import styled from 'styled-components';

export const WeekDaysStl = styled.div`
    display: grid;
    grid: 40px / repeat(7, 40px);
    align-items: center;
    justify-items: center;
    color: #070a13;
    /* margin-bottom: 4px; */
 
    
`;
