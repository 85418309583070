import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchGetFutureConsultations, fetchGetPastConsultations, resetPatientConsultations } from '../api/PatientConsultationsSlice';

export const LkPatientConsultation = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const redirect = useNavigate();

    useEffect(() => {
        dispatch(resetPatientConsultations());
        dispatch(fetchGetFutureConsultations());
        dispatch(fetchGetPastConsultations());
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (pathname === '/lk/patient-profile/consultation') {
            redirect('/lk/patient-profile/consultation/future-consultation', { replace: true });
        }
    }, [pathname]);

    return <Outlet />;
};
