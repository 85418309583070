import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { ReactComponent as Certificate } from '../../../../img/certificate.svg';
import { ReactComponent as CertificateDelete } from '../../../../img/certificate-delete.svg';
import { BASE_URL } from '../../../../consts';

const InputFileWrapper = styled.div`
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    .custom-file-button {
        display: inline-block;
        background-color: transparent;
        cursor: pointer;
        & .inputFileWrapper__heading {
            color: #19be6f;
        }
    }

    .custom-file-button span {
        pointer-events: none;
    }

    input[type='file'] {
        position: absolute;
        inset: 0;
        opacity: 0;
        width: 0;
        cursor: pointer;
    }
    .certificate-block {
        display: flex;
        align-items: center;
        gap: 5px;

        svg {
            cursor: pointer;
        }
    }

    .certificate-wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .error-message {
        font-size: 12px;
        color: #ff3636;
    }
    .inputFileWrapper__red {
        color: #FF3636;
    }
`;

const InputFile = ({ name, validation, isRequired }) => {
    const { control, trigger } = useFormContext();

    const defaultValidation = {
        required: { value: false, message: 'Поле обязательно для заполнения' },
        ...validation,
    };
    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules: defaultValidation });

    const showFile = (imageURL) => {
        // console.log('show');
        window.open(imageURL, '_blank');
    };

    const getFileUrl = () => {
        let fileUrl = '';
        if (typeof field.value === 'string') {
            fileUrl = `${BASE_URL.replace('/api/v1/', '')}${field.value}`;
        } else {
            fileUrl = URL.createObjectURL(field.value['0']);
        }
        return fileUrl;
    };

    const handleBlur = () => {
        trigger(field.name);
    };

    return (
        <InputFileWrapper>
            {!field.value?.length > 0 ? (
                <>
                    <label htmlFor={name} className="custom-file-button">
                        <span className='inputFileWrapper__heading'>Загрузить сертификат</span>
                        {isRequired && <span className='inputFileWrapper__red'>*</span>}
                        {error ? <p className="error-message">{error.message}</p> : null}
                    </label>
                    <input
                        {...field}
                        className={`${!error ? '' : 'invalid'}`}
                        type="file"
                        id={name}
                        onBlur={handleBlur}
                        onChange={(e) => {
                            // console.log("file");

                            field.onChange(e.target.files);
                            trigger(field.name);
                        }}
                    />
                </>
            ) : (
                <div className="certificate-block">
                    <div className="certificate-wrapper" onClick={() => showFile(getFileUrl())}>
                        <Certificate />
                        {/* <span>
                            {(typeof(field.value) === 'string') ?
                                decodeURIComponent(field.value.split('/').slice(-1)[0]) :
                                decodeURIComponent(field.value[0].name)}
                        </span> */}
                        <span>Сертификат</span>
                    </div>
                    <CertificateDelete onClick={() => field.onChange('')} />
                </div>
            )}
        </InputFileWrapper>
    );
};

export default InputFile;
