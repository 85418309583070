import React from 'react';
import styled from 'styled-components';

const SwitcherStl = styled.label`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;

    .switch {
        height: 21px;
        width: 41px;
        display: inline-block;
        position: relative;

        &__input {
            appearance: none;

            &:checked + .switch__slider {
                background-color: #19be6f;

                &::before {
                    transform: translateX(20px);
                }
            }
        }

        &__slider {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #b6b7ba;
            border-radius: 21px;
            transition: 0.16s background-color;

            &::before {
                content: '';
                position: absolute;
                top: 1px;
                left: 1px;
                height: 19px;
                width: 19px;
                background-color: white;
                border-radius: 50%;
                transition: 0.16s transform;
            }
        }
    }
`;

const Switcher = ({ className, onChange, text, checked}) => {
    return (
        <SwitcherStl className={className}>
            <span>{text}</span>
            <span className="switch">
                <input className="switch__input" type="checkbox" onChange={onChange} checked={checked} />
                <span className="switch__slider"></span>
            </span>
        </SwitcherStl>
    );
};

export default Switcher;
