import React, { useEffect, useRef, useState } from 'react';
import { ReviewCard } from '../../doctorProfile/doctorReviews/reviewCard/ReviewCard';
import dataCardDTO from './dataCardDTO';
import { ReviewStyled } from './review.styled';

export default function Review({ review }) {
    const textRef = useRef();
    const fullText = useRef();
    const card = dataCardDTO(review);
    const isShort = card.text.length < 101;
    const text = isShort ? card.text : (card.text.slice(0, 100)+ '...');
    const [textContent, setTextContent] = useState(text);
    const [seeMore, setSeeMore] = useState(false);
    

    const onClick = () => {
        setSeeMore((seeMore) => !seeMore);
    };

    useEffect(() => {
        let timerId = null;
        // по скрытму блоку находится высота - развернутое и свернутое состояние
        if (seeMore && textRef.current && fullText.current) {
            setTextContent(card.text);

        }
        if (!seeMore && textRef.current && fullText.current) {
            timerId = setTimeout(() => {
                setTextContent(text)
            }, 900);
        }
        const heightText = fullText.current.offsetHeight;
        textRef.current.style.height = `${heightText}px`;
        return (() => {
            if(timerId) {clearTimeout(timerId)};

        }
        )
    }, [seeMore]);


    return (
        <ReviewStyled>
            {/* блок с текстом, вычислить высоту */}
            <p className="docReview__card_description docReview__card_description_hidden" ref={fullText}>
                {seeMore===false ? text : card.text}
            </p>
            <div className={'review__container'} >
                <ReviewCard review={{ ...card, text: textContent} } textRef={textRef} />
            </div>
            {!seeMore && !isShort && (
                <button className="reviews__btn btn_more" onClick={onClick}>
                    Развернуть
                </button>
            )}
            {seeMore && (
                <button className="reviews__btn btn_more" onClick={onClick}>
                    Свернуть
                </button>
            )}
        </ReviewStyled>
    );
}
