import styled from 'styled-components';

export const RequestAgainStl = styled.div`
    text-align: center;
    max-width: 333px;

    .info {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        > span {
            color: #19be6f;
           
        }
    }

    .btn-again {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #19be6f;
        cursor: pointer;
        margin-bottom: 49px;
        display: block;
    }
`;
