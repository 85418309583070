import React, { memo } from 'react';
import { Parser } from 'html-to-react';
import { ArticleDescriptionStyled } from './articleDescription.styled';
import { ReactComponent as AuthorIcon } from '../../../../img/newsImg/newsIcons/news-author-icon.svg';
import { ReactComponent as FormattedDate } from '../../../../img/newsImg/newsIcons/news-date-icon.svg';
import { SocialMedia } from '../socialMedia/SocialMedia';
import { getDateForBlog } from '../../../share/helpers';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LeftArrowIcon } from '../../../../img/icons/icon-arrow-back.svg';

const ArticleDescription = ({ themeId, article }) => {
    const parser = new Parser();
    const navigate = useNavigate();

    return (
        <ArticleDescriptionStyled>
            <div className="backToPostsButton" onClick={() => navigate(`/news/${themeId}`)}>
                <LeftArrowIcon />
                <p>Назад к списку</p>
            </div>
            <div className="articleContent">
                <div className="articleTopContent">
                    <img className="previewArticle" src={article?.cover_img} alt="" />
                    <div className="articleInfo">
                        <div className="infoBlock">
                            <AuthorIcon />
                            <p className="infoCreated">{article?.author}</p>
                        </div>
                        {article?.created_at && (
                            <div className="infoBlock">
                                <FormattedDate />
                                <p className="infoCreated">{getDateForBlog(article?.created_at)}</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="articleText_container">{parser.parse(article?.text)}</div>
                <SocialMedia />
            </div>
        </ArticleDescriptionStyled>
    );
};
export default memo(ArticleDescription);
