import React from 'react';
import { QuoteStyled } from './quoteStyled';
import quotes from '../../../../img/newsImg/newsIcons/newsPhil-icon.png';

export const Quote = ({ quote }) => {
    return (
        <QuoteStyled>
            <img className="quotes" src={quotes} alt="" />
            <p className="text">{quote?.text}</p>
            <p className="author">{quote?.author}</p>
        </QuoteStyled>
    );
};
