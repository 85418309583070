import React from 'react';
import { BlogMenuStyled } from './blogMenu.styled';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export const BlogMenu = () => {
    const navigate = useNavigate();
    const themes = useSelector((state) => state.blog.themes);
    const { themeId } = useParams();

    const renderLinks = themes.map((theme) => (
        <p
            key={theme.id}
            className={+themeId === theme.id ? 'themeLink activeTheme' : 'themeLink'}
            onClick={() => navigate(`/news/${theme.id}`)}
        >
            {theme.title}
        </p>
    ));

    return <BlogMenuStyled>{renderLinks}</BlogMenuStyled>;
};
