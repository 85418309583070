import React from 'react';
import { DaysStl } from './days.styled';
import { useCalendar } from '../../../helpers/useCalendar';
import { setClassName } from '../../../helpers/setClassName';
import { useLocation } from 'react-router-dom';
import { chooseDays } from '../../../helpers/helpers';

export const Days = ({ choosenDate, setChoosenDate, isDays, setChoosenDates, choosenDates }) => {
    const { pathname } = useLocation();
    const {
        previousMonthDays,
        nextMonthDays,
        beforeSettingsDays,
        settingsDays,
        afterSettingsDays,
        currentMonthDaysWithSettings,
    } = useCalendar(choosenDate);
    const pathnameBoolean = pathname === '/lk/doctor-profile/doctor-calendar/viewer';
    const handleSttingsDayClick = (day) => {
        if (pathname === '/lk/doctor-profile/doctor-calendar/settings') {
            if (isDays) {
                day.choosen = !day.choosen;
                setChoosenDates(settingsDays.filter((day) => day.choosen));
            } else {
                setChoosenDates(chooseDays(settingsDays, day));
            }
        } else {
            setChoosenDates(chooseDays(currentMonthDaysWithSettings, day));
        }
    };
    
    const handleClick = (day) => {
        setChoosenDate(new Date(day.year, day.month, day.day, 0, 0, 0));
    };

    return (
        <DaysStl pathname={pathnameBoolean}>
            {previousMonthDays.map((day) => (
                <div
                    key={day.day}
                    onClick={() => {
                        handleClick(day);
                    }}
                    className="calendar__days_unused"
                >
                    {day.day}
                </div>
            ))}
            {pathnameBoolean ? (
                <>
                    {currentMonthDaysWithSettings.map((day, index, arr) => {
                        const classes = setClassName(day, index, arr, choosenDates);
                        return (
                            <div
                                key={day.day}
                                onClick={() => {
                                    handleSttingsDayClick(day);
                                }}
                                className={`${classes} calendar__days_unused`}
                            >
                                {day.day}
                            </div>
                        );
                    })}
                </>
            ) : (
                <>
                    {beforeSettingsDays.map((day) => (
                        <div key={day.day} className="calendar__days_unused calendar__days_unused-noClick">
                            {day.day}
                        </div>
                    ))}
                    {settingsDays.map((day, index, arr) => {
                        const classes = setClassName(day, index, arr, choosenDates);
                        return (
                            <div
                                key={day.day}
                                onClick={() => {
                                    handleSttingsDayClick(day);
                                }}
                                className={classes}
                            >
                                {day.day}
                            </div>
                        );
                    })}
                    {afterSettingsDays.map((day) => (
                        <div key={day.day} className="calendar__days_unused calendar__days_unused-noClick">
                            {day.day}
                        </div>
                    ))}
                </>
            )}
            {nextMonthDays.map((day) => (
                <div
                    key={day.day}
                    onClick={() => {
                        handleClick(day);
                    }}
                    className="calendar__days_unused"
                >
                    {day.day}
                </div>
            ))}
        </DaysStl>
    );
};
