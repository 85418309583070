import styled from 'styled-components';
import doctorHouse from '../../../img/doctorHouse.jpg';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #F4F4F4;
  background-color: #ffffff;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.05));


  .doctorsCard_top {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 12px;
  }
`;

export const CardImage = styled.div`
    position: relative;
    width: 100%;
    height: 331px;
    margin: 0 auto;
    flex-shrink: 0;
    background-image: url(${(props) => props.image || doctorHouse});
    background-size: cover;
    background-position: center top;
`;

export const CardOptionsNote = styled.div`
    position: relative;
    bottom: ${(props) => props.bottom || null};
    display: flex;
    justify-content: ${(props) => props.justContent || 'center'};
    width: 100%;
    padding: ${(props) => props.padding || '18px 16px 0 16px;'};
    margin: 0 auto;
    
    min-height: ${(props) => props.minHeight || '0px'};
    .price {
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        color: #062a3f;
        min-width: max-content;
    }
`;

export const CardDoctorText = styled.div`
    display: flex;
    justify-content: space-between;
    word-wrap: break-word;
    width: 100%;
    margin-bottom: auto;
    .doctor_name {
        display: inline;
        font-weight: 500;
        font-size: 22px;
        line-height: 130%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .doctor__raiting_img {
        transform: translateY(15%);
    }

  word-wrap: break-word;
  width: 100%;

  .doctor_name {
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .doctor__raiting_img {
    transform: translateY(15%);
  }
`;

export const StarStl = styled.img`
    transform: translateY(15%);
    color: red;
`;

export const Rating = styled.div`
    display: inline;
    margin-left: 6px;
    font-size: 18px;
    color: #062a3f;
`;
