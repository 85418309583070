import { fileLoader, apiRequest } from '../../share/api';

export const getMyProfileDoctor = async () => {
    return await apiRequest.get(`/user/me/doctor`);
};

// Получение всех специализаций
export const getSpecializations = async () => {
    return await apiRequest.get(`/doctors/specializations`);
};

// добавление специализации врачу
export const postSpecialization = async (id) => {
    await apiRequest.post(`/doctors/specialization_doctor/`, {
        specialization: id,
    });
};
// Удаление специализации врача по id
export const deleteSpecialization = async (id) => {
    await apiRequest.delete(`/doctors/specialization_doctor/${id}`);
};
// Получение специализации врача
export const getDoctorSpecializations = async () => {
    return await apiRequest.get(`/doctors/specialization_doctor/`);
};
{
    /*Получение всех университетов */
}
export const getUniversity = async () => {
    return await apiRequest.get(`/doctors/school/`);
};

export const getMyCertificate = async () => {
    return await apiRequest.get(`/doctors/certificate/doctor/my`);
};
{
    /*Получение сертификатов врача*/
}
export const getMyDoctorCertificate = async () => {
    return await apiRequest.get(`/doctors/certificate/my`);
};

// Удаление сертификата по id
export const deleteMyDoctorCertificate = async (id) => {
    return await apiRequest.delete(`/doctors/certificate/${id}`);
};
{
    /*Отправление сертификатов */
}
export const setDoctorSertificate = async ({ title, file }) => {
    fileLoader.post(`/doctors/certificate/my`, { title, document: file });
};
{
    /*Получение списка болезней которые лечит врач*/
}
export const getDoctorDisease = async () => {
    return await apiRequest.get(`/doctors/doctor_disease`);
};

// Получение данных об опыте работы врача
export const getMyWorkExpiriens = async () => {
    return await apiRequest.get(`/doctors/work`);
};

// Отправка данных об опыте работы врача
export const setMyWorkExpiriens = async (data) => {
    await apiRequest.post(`/doctors/work/`, {
        organization: data.organization,
        position: data.position,
        start_date: data.start_date,
        until_now: data.until_now,
        end_date: data.until_now ? data.start_date : data.end_date,
    });
};

// Изменение данных об опыте работы врача по id
export const putMyWorkExpiriens = async (data) => {
    await apiRequest.put(`/doctors/work/${data.id}`, data);
};

// Удаление данных об опыте работы врача по id
export const deleteMyWorkExpiriens = async (id) => {
    await apiRequest.delete(`/doctors/work/${id}/`);
};

// Получение данных о повышение квалификации врача
export const getMyAdvancedTraining = async () => {
    return await apiRequest.get(`/doctors/advanced_training`);
};

// Отправление данных о повышение квалификации врача
export const setMyAdvancedTraining = async (data) => {
    await apiRequest.post(`/doctors/advanced_training/`, data);
};

// изменение данных о повышение квалификации врача по id
export const putMyAdvancedTraining = async ({ formData, id }) => {
    await apiRequest.put(`/doctors/advanced_training/${id}`, formData);
};

// удаление данных о повышение квалификации врача по id
export const deleteMyAdvancedTraining = async (id) => {
    apiRequest.delete(`/doctors/advanced_training/${id}`);
};

export const loadAdvancedTraining = async (data) => {
    return await fileLoader.post(`/doctors/advanced_training/`, data);
};

// отмена консультации
export const setCancelDoctorConsultation = async (id) => {
    return await apiRequest.patch(`/consultations/cansel/${id}`);
};

// Получение данных об образовании врача
export const getDoctorHigherEducation = async () => {
    return await apiRequest.get(`/doctors/higher_education/`);
};

//Отправление данных об образовании врача
export const setDoctorHigherEducation = async (data) => {
    const response = await apiRequest.post(`/doctors/higher_education/`, {
        university: data.university,
        specialization: data.specialization,
        qualification: data.qualification,
        start_date: data.end_date,
        end_date: data.end_date,
    });
    return response;
};

// изменение данных об образовании по id
export const putDoctorHigherEducation = async (data) => {
    const response = await apiRequest.put(`/doctors/higher_education/${data.id}`, {
        university: data.university,
        specialization: data.specialization,
        qualification: data.qualification,
        start_date: data.end_date,
        end_date: data.end_date,
    });
    return response;
};

// удаление данных об образовании по id
export const deleteDoctorHigherEducation = async (id) => {
    await apiRequest.delete(`/doctors/higher_education/${id}`);
};

export const setDoctorDisease = async (data) => {
    const response = await apiRequest.post(`/doctors/doctor_disease/`, { content: data });

    return response;
};
export const setMyProfileDoctor = async (data) => {
    const response = await apiRequest.post(`/user/me/doctor`, {
        user: {
            first_name: data.user.first_name,
            last_name: data.user.last_name,
            patronymic: data.user.patronymic,
            birthday: data.user.birthday,
            email: data.user.email,
            gender: data.user.gender,
            country: data.user.country,
            city: data.user.city,
        },
        education_story: data.education_story,
        work_experience: data.work_experience,
        seniority: data.seniority,
        rank: data.rank,
        scientific_degree: data.scientific_degree,
        category: data.category,
        adl_age_category: data.adl_age_category,
        chi_age_category: data.chi_age_category,
        text_chat_price: data.text_chat_price,
        video_chat_price: data.video_chat_price,
        education_type_label: data.education_type_label,
    });
    return response;
};
{
    /* Получение пациента по ID если была хотя бы одна консультация */
}
export const getPatient = async (id) => {
    const result = await apiRequest.get(`patients/${id}`);
    return result;
};
{
    /* Получение списка консультаций врача */
}
export const getDoctorConsultations = async ({ status = 0, date = 'date', next = null }) => {
    if (next) {
        return await apiRequest.get(next);
    }
    const result = await apiRequest.get(`/consultations/doctor/?status=${status}&ordering=${date}`);
    return result;
};
{
    /* Получение списка консультаций врача по дате*/
}
export const getDoctorConsultationsCurrentDay = async (date) => {
    const result = await apiRequest.get(`/consultations/doctor/?page_size=50&by_date=${date}`);
    return result;
};
/**
 * Получение всех консультаций врача по статусу.
 * 0 - новая,
 * 1 - состоялась,
 * 2 - отменена,
 * 9 - не проводилась.
 */
export const getDoctorConsultationsByStatus = async (...args) => {
    const result = await apiRequest.get(`/consultations/doctor/?status=${args.join(',')}`);
    return result;
};
/**
 * Получение всех консультаций врача.
 */
export const getDoctorAllConsultations = async () => {
    const result = await apiRequest.get(`/consultations/doctor/?page_size=50`);
    return result;
};

{
    /* Получение банковских реквизитов врача */
}
export const getDoctorBankDetails = async () => {
    const result = await apiRequest.get(`/doctors/bank_details/`);
    return result;
};
{
    /* Изменение банковских реквизитов врача */
}
export const setDoctorBankDetails = async (data) => {
    const result = await apiRequest.post(`/doctors/bank_details/`, data);
    return result;
};

/**
 * Получение всех записей врача.
 */
export const getDoctorReceptions = async () => {
    const result = await apiRequest.get(`reception_time/`);
    return result;
};

/**
 * Добавление записи врача.
 * @param {number} timestamp временная метка в секундах.
 */
export const setDoctorReception = async (timestamp) => {
    const result = await apiRequest.post(`reception_time/`, {
        time: timestamp,
    });
    return result;
};

export const updateDoctorReceptions = async (data) => {
    const result = await apiRequest.post(`reception_time/update/`, {
        begin_time: data.begin ?? 0,
        finish_time: data.finish ?? 0,
        reception_times: data.timeStamps,
    });
    return result;
};

/**
 * Удаление записи из расписания врача.
 * Удаление успешно, только если нет консультации.
 * @param {number} id Id записи.
 */
export const deleteDoctorReceptionById = async (id) => {
    await apiRequest.delete(`reception_time/${id}`);
};

{
    /* Получение списка консультаций пациента с врачем */
}
export const getConsultationsByID = async (id) => {
    const result = await apiRequest.get(`/consultations/doctor/patient_consultations/${id}`);
    return result;
};

{
    /* Получение сертификатов врача */
}
export const getCertificate = async (id) => {
    return await apiRequest.get(`/doctors/certificate/doctor/${id}`);
};

{
    /* Получение списка платежей врача */
}

export const getPayments = async (data, next) => {
    if (next) {
        return await apiRequest.get(next);
    }
    if (data) {
        const { year, month } = data;
        if (month) {
            return await apiRequest.get(`/payments/doctor/?year=${year}&month=${month}`);
        }
        return await apiRequest.get(`/payments/doctor/?year=${year}`);
    }
    return await apiRequest.get(`/payments/doctor`);
};

{
    /* Получение консультации доктора по id*/
}
export const getDoctorConsultation = async (consultationId) => {
    return await apiRequest.get(`/consultations/doctor/${consultationId}`);
};

{
    /* Удаление сертификатов врача */
}
export const deleteCertificate = async (id) => {
    return await apiRequest.delete(`/doctors/certificate/${id}`);
};

// Изменение рекомендации для консультации пациента(консультация состоялась)

export const updateConsultationInfo = async ({ id, str }) => {
    return await apiRequest.patch(`/consultations/doctor/${id}`, {
        status: 1,
        recommendation: str,
    });
};

export const changeStatusOfConsultationToPassedRequest = async (id) => {
    return await apiRequest.put(`/consultations/passed/${id}`)
}

export const setRecommendation = async (data) => {
    return await apiRequest.post(`/consultations/recommendation/`, data);
};

/**
 * Получение пациентов доктора.
 */
export const getAllPatientsOfDoctor = async (search, next = '') => {
    if (next) {
        return await apiRequest.get(next);
    }
    return await apiRequest.get(`patients/doctor/list/?page_size=6&search=${search}`);
};

// получение отзыва по id
export const getFeedback = async (id) => {
    return await apiRequest.get(`/feedback/${id}`);
};
