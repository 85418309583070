import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import InputText from '../../../share/formFields/newElements/InputText';
import InputDate from '../../../share/formFields/newElements/InputDate';
import SearchResultsInputEducation from '../../../share/formFields/SearchResultsInputEducation';
import { validateInputEducation, validateInputSpecialization, validateRequired } from '../../../share/formFields/validateInput';

export function EducationList({ name }) {
    const { getValues, setValue } = useFormContext();
    const { allUniversity } = useSelector((state) => state.doctorProfile);
    const [searchResults, setSearchResults] = useState([]);
    const valueInput = getValues(`${name}.university`);
    const [educationInput, setEducationInput] = useState(valueInput);

    // получаем совпадения с полем university из allUniversity
    const filterUniversities = (universities, value) => {
        return universities?.filter((univer) => {
            return univer.name.toLowerCase().includes(value.toLowerCase());
        });
    };

    // при клике на item из dropdown
    const onItemClick = (value) => {
        setEducationInput(value);

        // надо чекнуть может лишнее
        setValue(`${name}.university`, value, { shouldDirty: true });
    };

    useLayoutEffect(() => {
        if (educationInput && educationInput.length >= 2) {
            const results = filterUniversities(allUniversity, educationInput);
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }
    }, [educationInput]);

    return (
        <>
            <InputText
                name={`${name}.university`}
                label="Учебное заведение"
                placeholder="Название"
                validation={validateInputEducation(true)}
                isRequired={true}
                onChange={setEducationInput}
            />
            {searchResults?.length ? (
                <SearchResultsInputEducation searchResults={searchResults} onItemClick={onItemClick} />
            ) : null}

            <InputText
                name={`${name}.specialization`}
                label="Специальность"
                placeholder="Название"
                validation={validateInputSpecialization(true)}
                isRequired={true}
            />
            <div className="work__date">
                <InputDate
                    defaultValue="9999"
                    name={`${name}.end_date`}
                    label="Год окончания"
                    placeholder="ГГГГ"
                    validation={validateRequired(true)}
                    isRequired={true}
                />
            </div>
        </>
    );
}
