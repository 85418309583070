import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllDoctors, getDoctor, getReviews, getСonsultationID } from './api';
import { fetchMyConsultationFullDelete } from '../../lkPatient/api/PatientConsultationsSlice';

const initialState = {
    doctors: [],
    doctor: null,
    next: null,
    count: null,
    isLoading: false,
    consultation: {
        id: null,
        doctor: null,
        // описание проблемы по умолчанию
        comment: '',
        time: null,
    },
};

export const fetchGetDoctors = createAsyncThunk('doctors/fetchGetDoctors', async (data, { getState }) => {
    try {
        const next = getState().doctors.next;
        const res = await getAllDoctors(data, next);
        return res.data;
    } catch (err) {
        console.log(err);
    }
});

export const fetchGetDoctor = createAsyncThunk('doctors/fetchGetDoctor', async (id) => {
    try {
        const res = await getDoctor(id);
        return res.data;
    } catch (err) {
        console.log(err);
    }
});

export const fetchReviews = createAsyncThunk('doctorInfo/fetchReviewsList', async ({ id, countReviews }) => {
    const response = await getReviews(id, countReviews);
    return response;
});

export const fetchRecordConsultations = createAsyncThunk(
    'RecordConsultations/fetchRecordConsultations',
    async (consultation) => {
        const response = await getСonsultationID(consultation);
        return response;
    }
);

const doctorsSlice = createSlice({
    name: 'doctors',
    initialState,
    reducers: {
        setConsultation: (state, action) => {
            state.consultation.doctor = action.payload.doctor;
            state.consultation.time = action.payload.time;
        },
        setTimeConsultation: (state, action) => {
            state.consultation.time = action.payload;
        },
        resetDoctors: (state) => {
            state.doctors = [];
            state.next = null;
        },
        resetDoctor: (state) => {
            state.doctor = null;
        },
        resetConsultation: (state) => {
            state.consultation = {
                id: null,
                doctor: null,
                comment: '',
                time: null,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetDoctors.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchGetDoctors.fulfilled, (state, action) => {
                const newDoctors = action.payload.data?.users.filter(
                    (user) => !state.doctors.some((doc) => doc.id === user.id)
                );
                state.doctors.push(...newDoctors);
                state.next = action.payload.next;
                state.count = action.payload.count;
                state.isLoading = false;
            })
            .addCase(fetchGetDoctor.fulfilled, (state, action) => {
                state.doctor = action.payload.user;
                const doctor = state.doctors.find((doc) => doc.id === action.payload.user.id);
                if (doctor) {
                    doctor.is_favorite = action.payload.user.is_favorite;
                }
            })
            .addCase(fetchRecordConsultations.fulfilled, (state, action) => {
                state.consultation.id = action.payload.consultation_id;
            })
            .addCase(fetchMyConsultationFullDelete.fulfilled, (state) => {
                state.consultation.id = null;
                state.consultation.doctor = null;
                state.consultation.time = null;
            })
            .addCase(fetchReviews.fulfilled, (state, action) => {
                state.doctorReviews = action.payload;
            });
    },
});

export const { setConsultation, setTimeConsultation, resetDoctors, resetConsultation, resetDoctor } =
    doctorsSlice.actions;
export default doctorsSlice.reducer;
