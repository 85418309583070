import styled from 'styled-components';

export const BlogArticleStyled = styled.div`
    max-width: 687px;
    width: 100%;
    display: grid;
    gap: 35px;

    /* чтобы поправить верстку приходящую с бека - костыль пока у бека не появится редактора для админки по лучше */
    li {
        white-space: normal !important;
        margin-bottom: 10px;
    }

    @media (max-width: 768px) {
        max-width: 728px;
        margin-top: 35px;
    }

    /*  */
    .articleComments {
        display: flex;
        flex-direction: column;
        gap: 35px;
    }
    /*  */
    .commentsTitle {
        color: var(--primary-text, #212121);
        font-family: Rubik;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 31.2px;

        @media (max-width: 768px) {
            font-size: 22px;
            line-height: 28.6px;
        }
        @media (max-width: 500px) {
            font-size: 20px;
            line-height: 26px;
        }
    }

    @media (max-width: 600px) {
        .articleComments {
            gap: 30px;
        }
    }
`;
