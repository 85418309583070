import React, { useState } from 'react';
import { StyledDoctorSchedule } from './doctorSchedule.styled';
import { Schedule } from './schedule/schedule';
import { FreeTime } from './freeTime/FreeTime';
import { useSelector } from 'react-redux';
import { VisitNoticeModal } from '../../ourDoctorsModals/visitNotice/VisitNoticeModal';
import { fetchRecordConsultations, resetConsultation, setConsultation } from '../../api/doctorsSlise';
import { useDispatch } from 'react-redux';
import { fetchMyConsultationFullDelete } from '../../../lkPatient/api/PatientConsultationsSlice';
import { postVizit } from '../../api/api';
import { useNavigate } from 'react-router-dom';

export const DoctorSchedule = ({ doctor }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeNav, setActiveNav] = useState('online'); // activeNav = online | clinic | home
    const [selectedDay, setSelectedDay] = useState({});
    const [showModal, setShowModal] = useState(false);
    const consultation = useSelector((state) => state.doctors.consultation);
    const whoAreYou = useSelector((state) => state.authentification.whoAreYou);
    const isAuth = useSelector((state) => state.authentification.isAuth);
    const { patientProfile } = useSelector((state) => state.patientProfile);
    const { id, free_reception_time: freeTime, video_chat_price: price, nearest_reception_time: nearestTime } = doctor;

    const handleClick = (e) => {
        e.stopPropagation();
        setShowModal(true);
        if (!isAuth || whoAreYou === 'doctor') return;

        if (whoAreYou === 'patient') {
            if (activeNav === 'online') {
                // if (patientProfile) {
                //     navigate('/lk/patient-profile/profile')
                // } else if (patientProfile.user.first_name === "") {
                //     navigate('/lk/patient-profile/profile')
                // }
                !patientProfile ?
                    navigate('/lk/patient-profile/profile') :
                    (patientProfile.user.first_name === "" && navigate('/lk/patient-profile/profile'));

                const consultationRequest = {
                    doctor_id: id,
                    request: consultation.comment,
                    recommendation: 'string',
                    price: price,
                    is_payed: false,
                    status: 0,
                    date: consultation.time ? consultation.time : nearestTime,
                };
                dispatch(fetchRecordConsultations(consultationRequest));
                dispatch(setConsultation({ doctor, time: consultationRequest.date }));
            }
            if (activeNav === 'clinic') {
                const data = {
                    doctor_id: doctor.id,
                    visit_type: 1,
                };
                postVizit(data);
            }
            if (activeNav === 'home') {
                const data = {
                    doctor_id: doctor.id,
                    visit_type: 0,
                };
                postVizit(data);
            }
        }
    };

    const handleCancelClick = () => {
        setShowModal(false);
    };

    const closeModal = (e) => {
        if (!e && activeNav === 'online' && consultation.id) {
            dispatch(fetchMyConsultationFullDelete(consultation.id));
            dispatch(resetConsultation());
        }
        setShowModal(false);
    };

    const toggleNav = (event) => {
        if (!event.target.checked) return;
        setActiveNav(event.target.value);
    };

    return (
        <StyledDoctorSchedule>
            <div className={'navMenu'} onClick={(e) => e.stopPropagation()}>
                <input
                    className="navMenu__input"
                    name="doctor_schedule_input"
                    value="online"
                    type="radio"
                    id={`online-${id}`}
                    onClick={toggleNav}
                />
                <label htmlFor={`online-${id}`}>
                    <p className={activeNav === 'online' ? 'active' : ''}>Онлайн</p>
                </label>

                <input
                    className="navMenu__input"
                    name="doctor_schedule_input"
                    value="clinic"
                    type="radio"
                    id={`clinic-${id}`}
                    onClick={toggleNav}
                />
                <label htmlFor={`clinic-${id}`}>
                    <p className={activeNav === 'clinic' ? 'active' : ''}>В&nbsp;клинике</p>
                </label>

                <input
                    className="navMenu__input"
                    name="doctor_schedule_input"
                    value="home"
                    type="radio"
                    id={`home-${id}`}
                    onClick={toggleNav}
                />
                <label htmlFor={`home-${id}`}>
                    <p className={activeNav === 'home' ? 'active' : ''}>На&nbsp;дом</p>
                </label>
            </div>
            <div className="calendar-container">
                {/* вдруг опять макет перепишут
                    {activeNav !== 'home' && (
                    <Schedule setSelectedDay={setSelectedDay} selectedDay={selectedDay} freeTime={freeTime} />
                )} */}
                {activeNav === 'online' && (
                    <>
                        <Schedule setSelectedDay={setSelectedDay} selectedDay={selectedDay} freeTime={freeTime} />
                        <FreeTime selectedDay={selectedDay} freeTime={freeTime} price={price} id={id} />
                    </>
                )}
                {activeNav === 'clinic' && (
                    <p className="calendar-container__text calendar-container__text_clinic">
                        Доступное время можно уточнить у оператора после оставления заявки.
                    </p>
                )}
                {activeNav === 'home' && (
                    <p className="calendar-container__text">
                        Вызов на дом осуществляется в пределах Москвы и ближайшего Подмосковья. Подробности можно
                        уточнить у оператора после оставления заявки.
                    </p>
                )}
            </div>
            <div className={'price'}>
                <p>Стоимость консультации</p>
                <p>{doctor.video_chat_price}&nbsp;₽</p>
            </div>

            <button
                disabled={freeTime.length === 0}
                className={`scheduleBtn ${freeTime.length === 0 ? 'scheduleBtn-disabled' : ''}`}
                onClick={(e) => handleClick(e)}
            >
                {activeNav === 'online' ? `Записаться` : `Оставить заявку`}
            </button>

            {showModal && (
                <VisitNoticeModal
                    closeModal={closeModal}
                    handleCancelClick={handleCancelClick}
                    typeOfNotice={activeNav}
                    isAuth={isAuth}
                />
            )}
        </StyledDoctorSchedule>
    );
};
