import React from 'react';
import styled from 'styled-components';

const FlipSwitchDiv = styled.div`
display: flex;
align-items: center;
.switch{
    display: inline-block;
    position: relative;
    width: 41px;
    height: 21px;
}
.switch__input{
    height: 0;
    width: 0;
    opacity: 0;
}
.switch__slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #B6B7BA;
    border-radius: 12px;
    transition: 0.3s;
}
.switch__slider::before{
    content: "";
    position: absolute;
    cursor: pointer;
    left: 2.44%;
    right: 51.22%;
    top: 4.76%;
    bottom: 4.76%;
    background: #FFFFFF;
    border-radius: 50%;
    transition: 0.3s;
}
.switch__input:checked + .switch__slider{
    background-color: #19BE6F;
}
.switch__input:checked + .switch__slider::before {
    transform: translateX(20px);
}
`;

const FlipSwitch = () => {

    return (
        <FlipSwitchDiv>
            <label className='switch'>
                <input type="checkbox" className="switch__input" />
                <span className='switch__slider'></span>
            </label>
        </FlipSwitchDiv>
    );
};

export default FlipSwitch;