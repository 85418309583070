import React from 'react';
import { PatientConsultationCard } from './patientConsultationCard/patientConsultationCard';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchGetFutureConsultations, fetchGetPastConsultations } from '../../api/PatientConsultationsSlice';
import { Spinner } from '../../../share/Spinner';
import { ScrollToTopArrowUp } from '../../../share/ScrollToTopArrowUp';
import { Attention } from '../attention/Attention';

export const ConsultationList = ({ list, pathname, isLoading }) => {
    const dispatch = useDispatch();
    const text = pathname.includes('future') ? 'запланированных' : 'завершенных';

    const requestConsultationsDoctor = () => {
        if (pathname.includes('future')) {
            dispatch(fetchGetFutureConsultations());
        } else {
            dispatch(fetchGetPastConsultations());
        }
    };

    return (
        <>
            {list?.consultations?.length > 0 ? (
                <>
                    {pathname.includes('future') && <Attention />}
                    {list.consultations.map((consultation) => (
                        <PatientConsultationCard key={consultation.id} consultation={consultation} />
                    ))}
                </>
            ) : (!isLoading &&
                <div>
                    <p className={'no-consult'}>На данный момент у Вас нет {text} консультаций</p>
                    <button className={'no-consult-btn'}>
                        <Link className={'link'} to={'/doctors'}>
                            Записаться к врачу
                        </Link>
                    </button>
                </div>
            )}

            {isLoading ? (
                <Spinner />
            ) : (
                <div className="buttonsPaginate">
                    {list.next !== null && (
                        <button className="buttonAddDoctors" onClick={requestConsultationsDoctor}>
                            Показать больше
                        </button>
                    )}
                    <ScrollToTopArrowUp />
                </div>
            )}
        </>
    );
};
