export function setClassName(day, index, arr, choosenDates) {
    let classes = ['day'];

    if (day.receptions?.some((el) => el.id !== null)) {
        classes.push('calendar__days_receipts');
    } else {
        classes.push('calendar__days_no-receipts');
    }

    if (day.choosen) {
        classes.push('calendar__days_choosen');
    }

    if (arr[index - 1] && arr[index - 1].receptions?.some((el) => el.id !== null)) {
        if (classes.includes('calendar__days_receipts')) {
            classes.push('calendar__days_leftPosition');
        }
    }

    if (arr[index + 1] && arr[index + 1].receptions?.some((el) => el.id !== null)) {
        if (classes.includes('calendar__days_receipts')) {
            classes.push('calendar__days_rightPosition');
        }
    }

    if (day.receptions?.some((el) => el.consultation !== null)) {
        classes.push('calendar__days_record');
    }

    if (choosenDates.some((el) => el.choosen && el.day === day.day)) {
        classes.push('calendar__days_choosen');
        if (choosenDates.some((el) => el.choosen && el.day - 1 === day.day)) {
            classes.push('calendar__days_rightPosition');
        }
    
        if (choosenDates.some((el) => el.choosen && el.day + 1 === day.day)) {
            classes.push('calendar__days_leftPosition');
        } 
    }

    if (day.choosen && choosenDates.some((el) => el.choosen && el.day - 1 === day.day)) {
        classes.push('calendar__days_rightPosition');
    }

    if (day.choosen && choosenDates.some((el) => el.choosen && el.day + 1 === day.day)) {
        classes.push('calendar__days_leftPosition');
    }

    if (day.day === new Date().getDate() && day.month === new Date().getMonth()) {
        classes.push('calendar__days_today');
    }

    if (choosenDates.length < 1) {
        classes = classes.filter((cls) => cls !== 'calendar__days_choosen');
    }

    return classes.length > 0 ? classes.join(' ') : '';
}

export function setClassNameTime(el) {
    const { consultation, workTime } = el;
    const classes = ['time'];
    workTime ? classes.push('time__isWork') : classes.push('');
    consultation ? classes.push('time__consultation') : classes.push('');

    return classes.join(' ');
}
