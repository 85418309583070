import React from 'react';
import Container from '../../componets/share/Container';
import {ReviewsRegulationsStyled} from './reviewsRegulations.styled';
import {MainHeader} from '../../componets/mainHeader/mainHeader';


export const ReviewsRegulations = () => {
  const title = 'Правила публикации'

  return (
      <ReviewsRegulationsStyled>
        <Container padding="36px 0 0">
          <MainHeader title={title}/>
          <ol>
            <li className="li_name">
              <h3>Общие положения:</h3>
              <ol>
                <li className="li_body">
                  <p>
                    Настоящие Правила публикации отзывов (далее — Правила) определяют условия и процесс публикации
                    Отзывов об услугах и врачах онлайн-сервиса Doct24.ru (сайт https://doct24.ru).
                  </p>
                </li>
              </ol>
            </li>

            <li className="li_name">
              <h3>
                Термины и понятия, используемые в настоящих Правилах:
              </h3>
              <ol>
                <li className="li_body">
                  <p>
                    Отзыв — личное мнение об услугах/врачах сайта онлайн-сервиса Doct24.ru.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Сайт — совокупность информационно-графических материалов, а также программ для ЭВМ, баз данных и
                    других программных средств, обеспечивающих их доступность в сети интернет по адресу
                    https://doct24.ru и представляющий собой объект интеллектуальной собственности.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Пользователь сайта — любой посетитель сайта doct24.ru.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Администрация сайта — сотрудники по управлению сайтом, действующие от имени ООО «ДОКТ24»
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Контент сайта — охраняемые результаты интеллектуальной деятельности, а именно аннотации, статьи,
                    иллюстрации, обложки, графические, текстовые, видео, фотографические, производные, составные и иные
                    произведения, пользовательские интерфейсы, визуальные интерфейсы, название товарного знака, логотип,
                    программы для ЭВМ, базы данных. А также дизайн, структура, выбор, общий стиль и расположение данного
                    Контента, входящего в состав сайта и другие объекты интеллектуальной собственности все вместе и/или
                    по отдельности, содержащиеся на сайте.
                  </p>
                </li>
              </ol>
            </li>

            <li className="li_name">
              <h3>Правила публикации Отзывов:</h3>
              <ol>
                <li className="li_body">
                  <p>
                    Оставлять Отзывы могут только Пользователи, зарегистрированные на сайте https://doct24.ru.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Отзывы публикуются только после их проверки модератором. По возможности сохраняется авторская
                    орфография и пунктуация, исправляются только опечатки.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Срок проверки и публикации Отзывов — до 5 рабочих дней. В случае выявления Отзыва Пользователя,
                    соответствующего п. 4 настоящих Правил, срок проверки последующих Отзывов данного Пользователя может
                    быть увеличен на неопределённый срок.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Не допускается размещение Отзывов за вознаграждение или подарки от третьих лиц, в рамках
                    конкурсовили акций, проводимых третьими лицами, а также для любых коммерческих целей.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    В связи с тем, что Отзывы являются составной частью сайта, они не могут быть удалены и отозваны
                    Пользователем. При этом в случае несоответствия Отзыва требованиям, указанным в настоящих Правилах,
                    Администрация сайта вправе удалить такой Отзыв самостоятельно.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Пользователь даёт ООО «ДОКТ24» разрешение публиковать Отзыв на сайте совместно с другими Отзывами об
                    этом же враче либо услуге.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Пользователь даёт ООО «ДОКТ24» разрешение удалять, по своему усмотрению, любые Отзывы о любых
                    врачах/услугах.
                  </p>
                </li>
              </ol>
            </li>

            <li className="li_name">
              <h3>Запрещается размещение Отзывов:</h3>
              <ol>
                <li className="li_body">
                  <p>
                    Содержащих нецензурную лексику.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Содержащих большое количество лексических, орфографических и других ошибок.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Содержащих отзывы о различных услугах/врачах, выполненные по одинаковым текстовым шаблонам.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Не содержащих указания на причины, по которым Пользователю понравился или не понравился врач или
                    оказанная им услуга.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Имеющих целью вступить в дискуссию либо оскорбить других Пользователей.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Содержащих персональные данные третьих лиц, без разрешения таких лиц.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Нарушающих права, свободы и интересы третьих лиц, содержащих заведомо ложную информацию и информацию
                    порочащую честь достоинство и репутацию третьих лиц (в том числе юридических), нарушающих
                    законодательство Российской Федерации, нормы морали и нравственности.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Содержащих материалы рекламного характера (в том числе номера телефонов, адреса, ссылки на другие
                    сайты)
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Нарушающих права третьих лиц, права на патент, товарный знак и другие интеллектуальные права. В
                    случае нарушения интеллектуальных прав третьих лиц Пользователь несёт ответственность перед такими
                    лицами самостоятельно.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Содержащих любой другой посторонний текст, не связанный с комментируемым товаром.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Содержащих материалы с других сайтов или из средств массовой информации.
                  </p>
                </li>
              </ol>
            </li>

            <li className="li_name">
              <h3>Прочие условия:</h3>
              <ol>
                <li className="li_body">
                  <p>
                    Пользователь соглашается с тем, что данные в его Отзыве становятся общедоступными и доступк ним
                    имеет неограниченный круг лиц. ООО «ДОКТ24» не несёт ответственность за действия третьих лиц,
                    которые могут использовать эти данные в целях, не связанных с настоящими Правилами.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    ООО «ДОКТ24» не несёт ответственности за содержание опубликованных на сайте https://doct24.ru
                    Отзывов, так как они выражают персональное мнение пользователей.
                  </p>
                </li>
                <li className="li_body">
                  <p>
                    Пользователь подтверждает, что ознакомился со всеми положениями настоящих Правил публикации Отзывов,
                    понимает, принимает их и будет руководствоваться ими при публикации Отзывов.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </Container>
      </ReviewsRegulationsStyled>
  );
};
