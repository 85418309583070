import React from 'react';
import { EditParametrsList } from '../../shared/EditParametrsList';
import { useDispatch } from 'react-redux';
import { fetchPatientProfile, fetchPatient_create } from '../../../api/PatientProfileSlice';
import { FormProvider, useForm, useFormState } from 'react-hook-form';

export function EditParametrs({ patientProfile, toggle }) {
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            patientProfile,
        },
    });

    const { isValid, isSubmitting } = useFormState({ control: methods.control });

    const dispatch = useDispatch();

    const onSubmit = ({ patientProfile: patientProfileSubmit }) => {
        const patientProfile = {
            ...patientProfileSubmit,
            height: Number(patientProfileSubmit.height),
            weight: Number(patientProfileSubmit.weight),
            blood_type:
                typeof patientProfileSubmit.blood_type === 'string'
                    ? patientProfileSubmit.blood_type
                    : patientProfileSubmit.blood_type.value,
            other: '',
        };

        dispatch(fetchPatient_create(patientProfile))
            .then(() => toggle(false))
            .then(() => {
                dispatch(fetchPatientProfile());
            });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="personalData">
                    <EditParametrsList patientProfile={patientProfile} />
                </div>
                <button className="btn__recive" type="submit" disabled={!isValid || isSubmitting}>
                    Сохранить
                </button>
            </form>
        </FormProvider>
    );
}
