import styled from 'styled-components';

export const StyledInfoBlock = styled.section`
    background-image: url('img/homePage/infoBlock.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    height: 731px;
    background-color: rgb(226, 241, 242);
    padding: 90px 0 76px;
    .infoBlock__inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .infoBlock__title {
            font-size: 40px;
            line-height: 52px;
            font-weight: 500;
            @media (max-width: 500px) {
                font-size: 34px;
                line-height: 40px;
            }
            @media (max-width: 400px) {
                font-size: 30px;
                line-height: 36px;
            }
        }
        .infoBlock__list {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
            gap: 20px;
            list-style: none;
            @media (max-width: 450px) {
                grid-template-columns: 1fr;
            }
            .infoBlock__list-item {
                padding: 16px 12px 30px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
                /* box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2); */
                backdrop-filter: blur(10px);
                border-radius: 16px;
                .infoBlock__list-title {
                    font-size: 22px;
                    line-height: 26px;
                    margin-bottom: 4px;
                    font-weight: 500;
                }
                .infoBlock__list-description {
                    font-size: 16px;
                    line-height: 130%;
                    font-weight: 300;
                }
            }
        }
    }
`;
