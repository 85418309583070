import React from 'react';
import { useSelector } from 'react-redux';
import { DataBlockStl } from  '../viewPatient.styled';
import { Spinner } from '../../../../share/Spinner';

export const ViewMedication = ({ medicines }) => {
    const isLoaderMedication = useSelector((state) => state.patientProfile?.isLoaderMedicines);

    const medicationShow = medicines.map((medication) => {
        return (
            <DataBlockStl key={medication.id}>
                <div className="info__wrapper">
                    <p className="info__data">{medication.title}</p>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">{medication.dosage}</p>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">{medication.frequency}</p>
                </div>
            </DataBlockStl>
        );
    });

    return isLoaderMedication ? <Spinner/> : medicationShow;
};