import React from 'react';
import InputText from '../../../share/formFields/newElements/InputText';
import InputFile from '../../../share/formFields/newElements/InputFile';
import InputDate from '../../../share/formFields/newElements/InputDate';
import { validateInputOrganization, validateRequired } from '../../../share/formFields/validateInput';

export function TrainigList({ name }) {

    return (
        <>
            <InputText
                name={`${name}.organization`}
                label="Организация"
                placeholder="Название"
                validation={validateInputOrganization(true)}
                isRequired={true}
            />
            <InputText
                name={`${name}.position`}
                label="Название программы"
                placeholder="Название"
                validation={validateInputOrganization(true)}
                isRequired={true}
            />
            <div className="work__date">
                <InputDate
                    name={`${name}.end_date`}
                    label="Год окончания"
                    placeholder="ГГГГ"
                    defaultValue="9999"
                    validation={validateRequired(true)}
                    isRequired={true}
                />
            </div>
            <InputFile name={`${name}.file`} validation={validateRequired(true)} isRequired={true}/>
        </>
    );
}
