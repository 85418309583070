import React from 'react';
import InputText from '../../../share/formFields/newElements/InputText';
import InputTel from '../../../share/formFields/newElements/InputTel';
import InputDate from '../../../share/formFields/newElements/InputDate';
import InputEmail from '../../../share/formFields/newElements/InputEmail';
import SelectCity from '../../../share/formFields/newElements/selectCity/SelectCity';
import RadioGroup from '../../../share/formFields/newElements/RadioGroup';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCity, resetCity } from '../../api/doctorUserProfileSlice';
import { validateBirthdayInput, validateInputEmail, validateInputTextName, validateRequired } from '../../../share/formFields/validateInput';

export function DoctorDataList({ name, user }) {

    const {city, isPreloaderCity} = useSelector((state) => state.doctorProfile);
    const cityDoctor = useSelector((state) => state.doctorProfile?.doctorProfile.user.city);
    const dispatch = useDispatch();

    const handleOnChange = (searchString) => {
        {searchString?.length >= 2 ? dispatch(fetchCity(searchString)) : dispatch(resetCity())}
    }

    return (
        <>
            <InputText
                deleteSpaces={/ +/g}
                name="user.last_name"
                label="Фамилия"
                placeholder="Иванов"
                validation={validateInputTextName(true)}
                isRequired={true}
                isFullName={true}
            />
            <InputText
                deleteSpaces={/ +/g}
                name="user.first_name"
                label="Имя"
                placeholder="Иван"
                validation={validateInputTextName(true)}
                isRequired={true}
                isFullName={true}
            />
            <InputText
                deleteSpaces={/ +/g}
                name="user.patronymic"
                label="Отчество"
                placeholder="Иванович"
                validation={validateInputTextName(false)}
                isFullName={true}
            />
            <div className="work__date">
                <InputDate
                    name="user.birthday"
                    label="Дата рождения"
                    placeholder="ДД/ММ/ГГГГ"
                    defaultValue="99/99/9999"
                    validation={validateBirthdayInput(true)}
                    isRequired={true}
                />
            </div>
            <RadioGroup
                // defaultChecked={0}
                defaultChecked={user.gender}
                name="user.gender"
                label="Пол"
                options={['Мужской', 'Женский']}
            />
            <InputTel
                validation={{ pattern: false }}
                name="user.phone"
                label="Номер телефона"
                placeholder="+7 (999) 999-99-99"
                isRequired={true}
                disabled
            />
            <InputEmail
                name="user.email"
                defaultValue="Example@gmail.com"
                label="Email"
                placeholder="Example@gmail.com"
                validation={{...validateRequired(true), ...validateInputEmail}}
                isRequired={true}
                disabled
            />
            <SelectCity
                name="user.city"
                label="Город"
                isCity={cityDoctor ? true : false}
                placeholder={cityDoctor ? cityDoctor : "Москва"}
                onChange={handleOnChange}
                options={city.map((item) => ({ value: item.name, label: item.name }))}
                isPreloaderCity={isPreloaderCity}
                validation={validateRequired(true)}
                isRequired={true}
            />
        </>
    );
}
