import React from 'react';
import styled from 'styled-components';

const TitleSectionStl = styled.section`
  width: 100%;
  padding: ${props => props.padding || '0 0 88px'};
  height: ${(props) => props.height || 'auto'};
`;

export const TitleSection = (props) => {
  return  <TitleSectionStl {...props}/>;
};
