import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StyledDoctorsTop } from './doctorsTop.styled';
import { fetchTopDoctors, fetchTopDoctorsAuth } from './api/doctorsTopSlice';
import Container from '../../share/Container';
import Card from './Card/Card';
import { Spinner } from '../../share/Spinner';
import useObserver from '../../../hooks/useObserver';

export const DoctorsTop = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuth = useSelector((state) => state.authentification.isAuth);
    const role = useSelector((state) => state.authentification.whoAreYou);
    const topDoctors = useSelector((state) => state.doctorsTopSlice.topDoctors);
    const isPreloader = useSelector((state) => state.doctorsTopSlice.isPreloader);
    const flagChangedFavDocs = useSelector((state) => state.favoriteDoctors.flagChangedFavDocs);
    const selector = 'doctors';
    const {show} = useObserver(selector);

    // запрос топ-врачей при открытии(перезагрузке сайта) и при переавторизации
    useEffect(() => {
        if (show) {
            isAuth ? dispatch(fetchTopDoctorsAuth()) : dispatch(fetchTopDoctors());
        }
    }, [role, show]);
    // console.log(role);
    // запрос топ врачей после запросов на добавление/удаление врачей в избранное
    useEffect(() => {
      if (show) {
        isAuth && dispatch(fetchTopDoctorsAuth());
      }
    }, [flagChangedFavDocs, show]);

    function showDoctorsTop() {
      navigate('/doctors');
    }
    return (
        <StyledDoctorsTop className={selector}>
            {topDoctors?.length > 0 && (
                <Container>
                  <h2 className="doctorsTop__heading">Компетентные врачи</h2>
                  {isPreloader ? (
                    <Spinner />
                  ) : (
                      <ul>
                        {topDoctors?.length > 0
                          ? topDoctors.map((item) => (
                              <Card
                                key={item.doctor.id}
                                name={item.doctor.first_name}
                                patronymic={item.doctor.patronymic}
                                lastname={item.doctor.last_name}
                                avatar={item?.doctor.avatar}
                                topDoctorsId={item.doctor.id}
                                price={item.doctor.video_chat_price}
                                specialization={item.specialization.name}
                                rating={item.doctor.ratings_rating}
                                seniority={item.doctor.seniority}
                                isFavorite={item.doctor.is_favorite}
                                slug={item.doctor.slug}
                                id={item.doctor.id}
                              />
                            ))
                          : null}
                      </ul>
                  )}
                  <button className="doctorsTop__button" onClick={showDoctorsTop}>
                      Смотреть больше врачей
                  </button>
                </Container>
            )}
        </StyledDoctorsTop>
    );
};
