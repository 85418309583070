import React from 'react';
import styled from 'styled-components';
import Checkbox from './Checkbox';
import { Link } from 'react-router-dom';
import { useController, useFormContext } from 'react-hook-form';

const LabelStl = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 35px;
    a {
        text-decoration: none;
        color: #19be6f;
    } 
    input {
        margin-right: 14px;
        align-self: flex-start;
    }
    
    @media(max-width: 768px) {
        p {
            font-size: 14px;
        }
    }
`;

const AgreementField = ({ name, validation }) => {
    const { control, trigger } = useFormContext();
    const defaultValidation = {
        required: true,
        ...validation,
    };

    const {
        field,
    } = useController({ name, control, rules: defaultValidation });

    return (
        <LabelStl>
            <Checkbox field={field} trigger={trigger}/>
            <p>
                Я принимаю условия
                <Link to={'/politics'} target="_blank">
                    &nbsp;пользовательского соглашения&nbsp;
                </Link>
                и даю
                <Link to={'/politics'} target="_blank">
                    &nbsp;согласие на обработку персональных данных
                </Link>
                .
            </p>
        </LabelStl>
    );
};

export default AgreementField;
