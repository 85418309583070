import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { NewFormSTL } from './newFormSTL.styled';
import { OperationsList } from '../../shared/OperationsList';

const PatientOperationsEdit = () => {
    const { control, trigger } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: 'operations',
        control,
    });

    const onAdd = () => {
        const newBlock = {
            description: '',
            date: '',
            newField: true, // Дополнительное свойство
        };
        append(newBlock);
    };

    return (
        <NewFormSTL>
            <h2 className="title">Перенесенные операции</h2>

            {fields.map((field, index) => {
                return (
                    <div key={field.id}>
                        <OperationsList name={`operations.${index}`} />
                        <button
                            type="button"
                            className="button button_del button_border"
                            onClick={() => remove(index)}
                        >
                            Удалить блок
                        </button>
                    </div>
                );
            })}
            <button
                type="button"
                className="button"
                onClick={() => {
                    onAdd();
                    trigger();
                }}
            >
                {`+ Добавить операцию`}
            </button>
        </NewFormSTL>
    );
};

export default PatientOperationsEdit;
