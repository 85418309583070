import React, { useEffect, useState } from 'react';
import { TimerConsultationStl } from './timerConsultationStl.styled';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showTimer } from '../api/timerSlice';

const TimerConsultation = ({ MAX_TIME_CONSULTATION, START_TIMER_AFTER_SECONDS, clearIntervalAndHideTimer }) => {
    const dispatch = useDispatch();
    const [timeScore, setTimeScore] = useState(1);
    const [isStartConsultation, setIsStartConsultation] = useState(false);
    const { upcomingConsultation, isShowTimer } = useSelector((state) => state.timer);
    const { whoAreYou, isAuth } = useSelector((state) => state.authentification);

    useEffect(() => {
        const nowInSec = Date.now() / 1000;
        const timeCons = upcomingConsultation?.date;
        const rightBorderTime = timeCons - START_TIMER_AFTER_SECONDS;
        let difference;
        if (nowInSec > rightBorderTime) {
            difference = (timeCons - nowInSec);
        } else {
            difference = (timeCons - rightBorderTime);
        }

        if (difference > 0 && difference <= START_TIMER_AFTER_SECONDS) {
            setTimeScore(Math.floor(difference));
        }
        if (difference <= 0) {
            setIsStartConsultation(true);
            setTimeScore(Math.abs(Math.floor(difference)));
        }
    }, [upcomingConsultation, document.hidden]);

    useEffect(() => {

        let timeBeforeStartConsultation;

        if (timeScore > 0 && !isStartConsultation) {
            timeBeforeStartConsultation = setInterval(() => {
                setTimeScore((prevTime) => prevTime - 1);
            }, 1000);
        }

        if (timeScore === 0) {
            setIsStartConsultation(true);
        }
        if (!isShowTimer || !isAuth) {
            clearIntervalAndHideTimer(timeBeforeStartConsultation);
        }

        return () => {
            clearInterval(timeBeforeStartConsultation);
        };

    }, [timeScore]);

    useEffect(() => {

        let timeAfterStartConsultation;

        if (isStartConsultation && timeScore < MAX_TIME_CONSULTATION) {
            timeAfterStartConsultation = setInterval(() => {
                setTimeScore((prevTime) => prevTime + 1);
            }, 1000);
        }

        if (MAX_TIME_CONSULTATION <= timeScore) {
            dispatch(showTimer(false));
        }

        if (!isShowTimer || !isAuth) {
            clearIntervalAndHideTimer(timeAfterStartConsultation);
        }

        return () => {
            clearInterval(timeAfterStartConsultation);
        };
    }, [timeScore, isStartConsultation]);

    const getPathToConsultation = (id) => {
        if (whoAreYou === 'patient') {
            return `/lk/patient-profile/consultation/future-consultation/${id}`;
        }
        if (whoAreYou === 'doctor') {
            return `/lk/doctor-profile/consultations/future/${id}`;
        }
    };

    return (
        <TimerConsultationStl isStartConsultation={isStartConsultation}>
            <NavLink
                className={({ isActive }) => (isActive ? 'btn-link btn-link_active' : 'btn-link')}
                to={getPathToConsultation(upcomingConsultation?.id)}
            >
                {isStartConsultation ? 'Консультация началась' : 'До начала консультации'}
                <span className="btn-link__timer">
                    {String(Math.floor(timeScore / 60)).padStart(2, '0')}:{String(timeScore % 60).padStart(2, '0')}
                </span>
            </NavLink>
        </TimerConsultationStl>
    );
};

export default TimerConsultation;
