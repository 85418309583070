import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearConsultation, getPatientConsultation, setDoctor } from '../../../api/PatientConsultationsSlice';
import { PatientConsultationPageStyled } from './patientConsultationPage.styled';
import { consultationStatus } from '../lkPatientConsultationHelpers';
import { Spinner } from '../../../../share/Spinner';
import { DoctorCardProfile } from '../../../../ourDoctors/doctorCard/doctorCardProfile/doctorCardProfile';
import { ProblemForm } from '../../problemForm/problemForm';
import { Attention } from '../../attention/Attention';
import { ConsultationButtons } from '../../consultationButtons/consultationButtons';
import { ConsultationTimeAndPrice } from '../../../../share/consultationTime/consultationTime';

import { Feedback } from './feedback/Feedback';

export default function PatientConsultationPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const currentConsultation = useSelector((state) => state.patientConsultations.currentConsultation);
    const newDoctor = useSelector((state) => state.doctors.doctor);

    useEffect(() => {
        dispatch(getPatientConsultation(id));

        return () => {
            dispatch(clearConsultation());
        };
    }, [id]);

    useEffect(() => {
        if (newDoctor) {
            dispatch(setDoctor(newDoctor));
        }
    }, [newDoctor]);

    if (!currentConsultation) {
        return <Spinner />;
    }

    const {
        status,
        date,
        recommendation,
        request,
        doctor,
        price,
        feedback,
        feedback_id,
        registration_log,
        patient: {
            user: { uid },
        },
    } = currentConsultation;
    const isPatientCancelled = uid === registration_log?.cancelled_by?.uid;

    return (
        <PatientConsultationPageStyled>
            <div onClick={() => navigate(-1)} className={'go-back-btn'}>
                Назад к списку
            </div>
            <div className="consultation-data">
                <div className="schedule">
                    {consultationStatus(status, isPatientCancelled)}
                    <ConsultationTimeAndPrice priceDisplay={true} date={date} price={price} />
                </div>
                <div className="patient-data">{doctor && <DoctorCardProfile doctor={doctor} />}</div>
                <div className="patient-comments">
                    {status === 0 ? (
                        <ProblemForm
                            id={id} problem={request}
                            consultationId={currentConsultation.id}
                            files={currentConsultation.files}
                            feedbackId={feedback_id}
                        />
                    ) : (
                        <>
                            <div className="patient-comments__title">Комментарий для врача</div>
                            <div className="patient-comments__text">{request}</div>
                        </>
                    )}
                </div>
                {status === 1 && (
                    <>
                        <div className="patient-comments">
                            <p className="patient-comments__title">Рекомендации</p>
                            <p className="patient-comments__text">{recommendation.text}</p>
                        </div>
                    </>
                )}
                {status === 0 && <Attention />}
                <ConsultationButtons consultation={currentConsultation} />
                {status === 1 && <Feedback consultationId={id} feedback={feedback} feedbackId={feedback_id} />}
            </div>
        </PatientConsultationPageStyled>
    );
}
