import styled from "styled-components";

export const CalendarHeaderStl = styled.div`
    padding: 7px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: 1px solid #b6b7ba;
    border-radius: 10px;
    
    .sdh {
        &__short-date {
            display: flex;
            gap: 4px;
        }

        &__year,
        &__month {
            font-size: 18px;
            text-transform: capitalize;
        }

        &__button {
            cursor: pointer;
            border: none;
            background: none;
            height: 24px;
            width: 24px;
            
            &_turn_left svg {
                transform: rotate(180deg);
            }
        }
    }
`