import React, { useEffect } from 'react';
import { DataBlockStl } from '../viewDoctor.styled';
import { changeCategoryLabelsEnding } from '../../../../share/helpers';

export const ViewDoctorSpecialization = ({ doctorProfile }) => {
    const { scientific_degree, scientific_degree_label, category, category_label, specialization } = doctorProfile;

    return (
        <DataBlockStl>
            <div className="info__wrapper">
                {specialization.map((spec, i) => (
                    <p key={i} className="info__data">
                        {spec.name}
                    </p>
                ))}
            </div>
            <div className="info__wrapper">
                <p className="info__data">{category === 0 ?
                    'Категория отсутствует' : 
                    `Врач ${changeCategoryLabelsEnding(doctorProfile)} категории`}
                </p>
            </div>
            <div className="info__wrapper">
                <p className="info__data info__data_uppercase">{scientific_degree === 0 ?
                    'Степень отсутствует' : 
                    scientific_degree_label}
                </p>
            </div>
        </DataBlockStl>
    );
};
