import React from 'react';
import { ReactComponent as SearchSvg } from '../../../img/icons/newDoctorIcons/search.svg';
import InputText from '../../share/formFields/InputText';
import Form from '../../share/formFields/Form';
import { isEnteredLetter } from '../../share/helpers';

export const Search = ({ setSearch }) => {
    const onSearch = (value) => {
        isEnteredLetter(value) && setSearch(value.toLowerCase());
    };

    return (
        <Form>
            <label className="inputSearch">
                <InputText
                    name="search"
                    placeholder={'Поиск по Ф.И.О'}
                    validation={{ required: false }}
                    onChange={onSearch}
                />
                <SearchSvg />
            </label>
        </Form>
    );
};
