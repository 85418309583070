import React, { Children, cloneElement, isValidElement } from 'react';
import { useForm } from 'react-hook-form';

const descendants = (children, trigger, control) => {
    return Children.map(children, (child) => {
        if (!isValidElement(child)) {
            return child;
        }

        let newProps = {};

        if (child.props.children) {
            newProps.children = descendants(child.props.children, trigger, control);
        }

        if (child.props.name) {
            newProps = {
                ...{ key: child.props.name, trigger, control },
            };
        }

        return cloneElement(child, { ...newProps });
    });
};

const Form = ({ mode = 'onChange', children, onSubmit }) => {
    const { handleSubmit, trigger, control } = useForm({ mode });

    return <form onSubmit={handleSubmit(onSubmit)}>{descendants(children, trigger, control)}</form>;
};

export default Form;
