import styled from 'styled-components';

export const CalendarBodyStl = styled.div`
    /* width: 280px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 0 auto; */
    padding: 20px;
    gap: 4px;
`;
