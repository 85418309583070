import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { SectionStl } from '../viewDoctor.styled';
import { ViewAdvancedTraining } from './ViewAdvancedTraining';
import { ModerationLabel } from '../../../../share/ModerationLabel';
import { EditAdvancedTraining } from './EditAdvancedTraining';

export const DoctorAdvancedTraining = () => {
    const [editAdvancedTraining, toggleAdvancedTraining] = useState(false);
    const { advanced_training } = useSelector((state) => state.doctorProfile?.doctorProfile);

    return (
        <SectionStl>
            <TitleWithPencil toggle={toggleAdvancedTraining} title={'Повышение квалификации'} />
            {advanced_training.some((el) => !el.is_moderated) ? <ModerationLabel /> : null}
            {editAdvancedTraining ? (
                <EditAdvancedTraining toggle={toggleAdvancedTraining} advanced_training={advanced_training} />
            ) : (
                <ViewAdvancedTraining advanced_training={advanced_training} />
            )}
        </SectionStl>
    );
};
