import React, { useState, useEffect } from 'react';
import { PhoneStl } from './phone.styled';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import { fetchAuthentification } from '../api/authentificationSlice';
import { LabelAgreement } from '../labelAgreement/LabelAgreement';

export const Phone = ({ who }) => {
    const [agreements, setAgreements] = useState(false);
    const [phone, setPhone] = useState('');
    const [enableBtn, setEnableBtn] = useState(false);
    const dispatch = useDispatch();

    const handlePhone = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        setPhone(value);
    };

    useEffect(() => {
        if (phone.length === 11 && agreements && who) {
            setEnableBtn(true);
        } else {
            setEnableBtn(false);
        }
    }, [phone, agreements, who]);

    const authMe = (e) => {
        e.preventDefault();
        if (enableBtn) {
            dispatch(fetchAuthentification(`+${phone}`));
        }
    };

    return (
        <PhoneStl onSubmit={authMe}>
            <div className="auth_number">
                <label htmlFor="phone">Телефон</label>
                <InputMask
                    inputMode="numeric"
                    id="phone"
                    mask={'+7 (999) 999-99-99'}
                    placeholder={'+7 (999) 999-99-99'}
                    onChange={handlePhone}
                    value={phone}
                    className="input__field"
                    autoFocus
                    autoComplete="off"
                />
            </div>
            <LabelAgreement agreements={agreements} setAgreements={setAgreements} />
            <button 
                className={`button ${!enableBtn ? 'button-disabled' : ''}`} 
                type="submit" 
                disabled={!enableBtn}>
                Далее
            </button>
        </PhoneStl>
    );
};
