import React from 'react';
import { DoctorAboutStl } from './doctorAbout.styled';
import { dateFormatterYears } from '../../share/helpers'

export const DoctorAbout = ({ doctor }) => {
    return (
        <DoctorAboutStl>
            <div className="about__wrapper">
                <div className="about__inner">
                    <h5 className="about__title about__title-desease">
                        Диагностика и лечение следующих заболеваний:
                    </h5>
                    <ul className="about__list">
                        {Array.isArray(doctor.healing_diseases) && doctor.healing_diseases[0] ?
                            doctor.healing_diseases.map((el, idx, arr) =>
                                <li className="about__list-item" key={el.id}>
                                    {el.disease_name}{idx + 1 === arr.length ? '.' : ';'}
                                </li>
                            ) : <h5 className="about__message">Врач не узазал перечень заболеваний</h5>
                        }
                    </ul>
                </div>

                <div className="about__inner">
                    <h5 className="about__title">Опыт работы:</h5>
                    <ul className="about__list">
                        {Array.isArray(doctor.work_expirience) && doctor?.work_expirience?.length ?
                            doctor.work_expirience.map((el, idx, arr) =>
                                <li className="about__list-item" key={el.id}>
                                    {dateFormatterYears(el.start_date)}-{dateFormatterYears(el.end_date)} гг.
                                    &mdash; {el.position}, {el.organization}
                                    {idx + 1 === arr.length ? '.' : ';'}
                                </li>
                            ) : <h5 className="about__message">
                                Сведения об опыте работы отсутствуют</h5>
                        }
                    </ul>
                </div>

                <div className="about__inner">
                    <h5 className="about__title">Образование:</h5>
                    <ul className="about__list">
                        {doctor.higher_education
                            ? doctor.higher_education.map(el => (
                                <li key={el.id} className="about__list-item">
                                    {dateFormatterYears(el.start_date)}-{dateFormatterYears(el.end_date)} гг.
                                    &mdash; {el.university}, {el.specialization}
                                </li>
                            ))
                            :
                            <h5 className="about__message">
                                Сведения об образовании отсутствуют
                            </h5>
                        }
                    </ul>
                </div>

                <div className="about__inner">
                    <h5 className="about__title">Повышение квалификации:</h5>
                    <ul className="about__list">
                        {Array.isArray(doctor?.advanced_training) && doctor?.advanced_training?.length ?
                            doctor.advanced_training.map((el) =>
                                <li className="about__list-item" key={el.id}>
                                    {dateFormatterYears(el.end_date)} г. &mdash; {el.organization}. {el.position}.
                                </li>) :
                            <h5 className="about__message">
                                Врач не указал дополнительную информацию о квалификации
                            </h5>
                        }
                    </ul>
                </div>
            </div>
        </DoctorAboutStl>
    );
}