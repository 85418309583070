// валидация длины от 2 до num знаков
export const validateInputLength = (num) => ({
    maxLength: {
        value: num,
        message: `Максимальная длина: ${num} символов`,
    },
    minLength: {
        value: 2,
        message: 'Минимальная длина: 2 символа',
    },
    pattern: {
        value: /./,
        message: '',
    },
});


// текст в блоках формы: аллергии, препаратов, опреаций и заболеваний
// export const validAteInputLength50 = {
//     maxLength: {
//         value: 50,
//         message: 'Максимальная длина: 50 символов',
//     },
//     minLength: {
//         value: 2,
//         message: 'Минимальная длина: 2 символа',
//     },
//     pattern: {
//         value: /./,
//         message: '',

//     },
// };
// export const validAteInputLength100 = {
//     maxLength: {
//         value: 100,
//         message: 'Максимальная длина: 100 символов',
//     },
//     minLength: {
//         value: 2,
//         message: 'Минимальная длина: 2 символа',
//     },
//     pattern: {
//         value: /./,
//         message: '',
//     },
// };
// export const validAteInputLength200 = {
//     maxLength: {
//         value: 200,
//         message: 'Максимальная длина: 200 символов',
//     },
//     minLength: {
//         value: 2,
//         message: 'Минимальная длина: 2 символа',
//     },
//     pattern: {
//         value: /./,
//         message: '',
//     },
// };

// текст в блоке формы с данными пациента
export const validateInputTextName = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 50,
            message: 'Максимальная длина: 50 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
        pattern: {
            value: /^[А-Яа-яЁёa-zA-Z\s\-]{2,50}$/,
            message: 'Поле может содержать символы алфавита, пробел, дефис',
        },
    };
};

export const validateInputOrganization = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 150,
            message: 'Максимальная длина: 150 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
        pattern: {
            value: /^[А-Яа-яЁёa-zA-Z0-9()№""\s\-]{2,150}$/,
            message: 'Значение может содержать символы алфавита, пробел, дефис, цифры, символы ()№""',
        },
    };
};

export const validateWorkDate = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        pattern: {
            value: /^(0[1-9]|1[0-2])\/\d{4}$/,
            message: 'Некорректная дата',
        },
        validate: {
            isFutureDate: (value) => {
                const workDate = new Date(value.slice(-4), +value.slice(0, 2) - 1);
                const currentDate = new Date();
                const enteredDate = new Date(workDate);

                if (enteredDate > currentDate) {
                    return 'Некорректная дата';
                }

                return true;
            },
            isPasteDate: (value) => {
                const workDate = new Date(value.slice(-4));
                // const minDate = new Date();
                // minDate.setFullYear(minDate.getFullYear() - 60); // 60 лет назад

                const minDate = new Date(0);
                minDate.setFullYear(minDate.getFullYear() - 20); // 1950 г

                if (workDate < minDate) {
                    // return 'Дата не может быть более чем 60 лет назад';
                    return 'Дата не может быть ранее 1950 года';
                }

                return true;
            },
        },
    };
};

// дата рождения в блоке формы с данными пациента
export const validateBirthdayInput = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        pattern: {
            value: /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
            message: 'Некорректная дата',
        },
        validate: {
            // нельзя ввести будущую дату/переопределим для др доктора
            isFutureDate: (value) => {
                const birthdayDate = new Date(Date.UTC(value.slice(6), value.slice(3, 5) - 1, value.slice(0, 2)));
                const currentDate = new Date();
                const enteredDate = new Date(birthdayDate);

                if (enteredDate > currentDate) {
                    return 'Некорректная дата';
                }

                return true;
            },
            // нельзя ввести слишком прошлую дату
            isPasteDate: (value) => {
                // оставлено если решат делать валидацию от возраста, а не от даты
                // const birthdayDate = new Date(Date.UTC(value.slice(6), value.slice(3, 5) - 1, value.slice(0, 2)));
                // const currentDate = new Date();
                // if (birthdayDate < currentDate.setFullYear(currentDate.getFullYear() - 100)) {
                //     return 'Дата не может быть более чем 100 лет назад';
                // }
                const birthdayDate = new Date(Date.UTC(value.slice(6))).getFullYear();
                if (birthdayDate < 1930) {
                    return 'Некорректная дата';
                }

                return true;
            },
            // если нет 18
            is18Old: (value) => {
                const birthdayDate = new Date(Date.UTC(value.slice(6), value.slice(3, 5) - 1, value.slice(0, 2)));
                const currentDate = new Date();
                if (birthdayDate > currentDate.setFullYear(currentDate.getFullYear() - 18)) {
                    return 'Нельзя зарегистрироваться на сайте, если Вам меньше 18 лет';
                }

                return true;
            },
        },
    };
};

export const validateRequired = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
    };
};

// валидация поля стоимости консультации
export const validatePrice = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        min: {
            value: 1,
            message: '',
        },
    };
};

// рост и вес в блоке формы с параметрами пациента
export const validAteInputParametrs = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        pattern: {
            value: /^[0-9]{1,3}$/,
            message: ' не число ',
        },
        validate: {},
    };
};

export const validateInputEmail = {
    required: 'Поле обязательно для заполнения',
    validate: {
        symbolInclude: (value) =>
            !/[^\w\-\@\.]/gi.test(value) ||
            'E-mail может содержать латинские буквы, цифры, дефис, нижнее подчеркивание',
        format: (value) => /\S+\@\S+\.\S{2,}/.test(value) || 'Допустимый формат почты: test@test.ru',
    },
    maxLength: {
        value: 70,
        message: 'В поле должно быть максимум 70 символов',
    },
};

// doctor LK
export const validateInputEducation = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 150,
            message: 'Максимальная длина: 150 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
        pattern: {
            value: /^[А-Яа-яЁёa-zA-Z0-9()№""\s\-\.]{2,150}$/,
            message: 'Значение может содержать символы алфавита, пробел, дефис, цифры, символы ()№"".',
        },
    };
};

export const validateInputSpecialization = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 50,
            message: 'Максимальная длина: 50 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
        pattern: {
            value: /^[А-Яа-яЁёa-zA-Z\s\-]{2,50}$/,
            message: 'Значение может содержать символы алфавита, пробел, дефис',
        },
    };
};

export const validateInputPosition = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 50,
            message: 'Максимальная длина: 50 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
        pattern: {
            value: /^[А-Яа-яЁёa-zA-Z0-9()№""\s\-]{2,50}$/,
            message: 'Значение может содержать символы алфавита, пробел, дефис, цифры, символы ()№""',
        },
    };
};
