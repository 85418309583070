import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchGetDoctorFutureConsultations, fetchGetDoctorPastConsultations, resetDoctorConsultations } from '../api/lkDoctorConsultationsSlice';

export const LkDoctorConsultations = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const redirect = useNavigate();

    useEffect(() => {
        dispatch(resetDoctorConsultations());
        dispatch(fetchGetDoctorFutureConsultations());
        dispatch(fetchGetDoctorPastConsultations());    
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (pathname === '/lk/doctor-profile/consultations') {
            redirect('/lk/doctor-profile/consultations/future', { replace: true });
        }
    }, [pathname]);

    return <Outlet />;
};
