import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppointmentStl } from './appointment.styled';

export const Appointment = () => {
    const navigate = useNavigate();

    function handleClick() {
        navigate('/signin');
        window.scrollTo(0, 0);
    }

    return (
        <AppointmentStl>
            <div className="appointment">
                <div className="appointment__icon">
                    <img src="/img/V_telemedicine_patient.svg" alt="header logo" />
                </div>
                <p className="appointment__text">Для того чтобы оставить комментарий необходимо войти</p>
                <button className="appointment__button" onClick={handleClick}>
                    Войти / Зарегистрироваться
                </button>
            </div>
        </AppointmentStl>
    );
};
