import React, { useState } from 'react';
import { ScheduleViewer } from './schedule/ScheduleViewer';
import { Calendar } from '../calendarComponents/calendar/Calendar';

export const CalendarViewer = () => {
    // выбраный день, по умолчанию сегодняшний
    const [choosenDate, setChoosenDate] = useState(new Date());
    const [toShowWhole, setToShowWhole] = useState(false);
    const [choosenDates, setChoosenDates] = useState([]);

    return (
        <div className="calendar">
            <div className="calendar__wrapper">
                <p className="calendar__title">Выберите день, для просмотра записей</p>
                <Calendar
                    choosenDate={choosenDate}
                    setChoosenDate={setChoosenDate}
                    choosenDates={choosenDates}
                    setChoosenDates={setChoosenDates}
                />
                <p className="calendar__hint">Для редактирования расписания откройте страницу “Настройка”</p>
            </div>
            <div className="calendar__wrapper">
                <p className="calendar__title">{toShowWhole ? 'Расписание' : 'Записи'}</p>
                <ScheduleViewer choosenDates={choosenDates} toShowWhole={toShowWhole} />
                <span className="calendar__button" onClick={() => setToShowWhole((prev) => !prev)}>
                    {toShowWhole ? 'Посмотреть только записи' : 'Посмотреть полное расписание'}
                </span>
            </div>
        </div>
    );
};
