import styled from 'styled-components';

export const Button = styled.button`
    display: block;
    width: 100%;
    padding: 13px;
    background: rgb(25, 190, 111);
    border-radius: 6px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    &:disabled {
        opacity: 0.7;
        cursor: auto;
    }
`;

