import { useState, useEffect } from 'react';

export const useTimeReceptions = (timeStamps, currentDate) => {
    const [timeReceptions, setTimeReceptions] = useState([]);
    useEffect(() => {
        const filteredTimeStamps = timeStamps.filter((timeStamp) => {
            // const timeZone = new Date().getTimezoneOffset();
            const timeStampDate = new Date(timeStamp.time * 1000);
            return (
                timeStampDate.getFullYear() === currentDate.year &&
                timeStampDate.getMonth() + 1 === currentDate.month &&
                timeStampDate.getDate() === currentDate.day
            );
        });

        const timeReceptions = [...filteredTimeStamps]
            .sort((a, b) => a.time - b.time)
            .map((timeStamp) => {
                // const timeZone = new Date().getTimezoneOffset();
                const timeStampDate = new Date(timeStamp.time * 1000);
                const hours = timeStampDate.getHours().toString().padStart(2, '0');
                const minutes = timeStampDate.getMinutes().toString().padStart(2, '0');

                return { timeStamp: timeStamp.time, timeString: `${hours}:${minutes}` };
            });

        setTimeReceptions(timeReceptions);
    }, [timeStamps, currentDate]);

    return [timeReceptions];
};
