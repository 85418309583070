import React from 'react';
import { FieldWrapper } from './FieldWrapper';
import { useController } from 'react-hook-form';

const InputText = ({
    name,
    label,
    placeholder,
    defaultValue = '',
    validation = {},
    onChange = () => {},
    trigger,
    control,
}) => {
    const defaultValidation = {
        required: 'Поле обязательно для заполнения',
        pattern: {
            value: /^[\p{L}]*$/u,
            message: 'Имя может содержать только символы алфавита',
        },
        maxLength: {
            value: 30,
            message: 'Максимальная длина: 30 символов',
        },
        ...validation,
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, defaultValue, rules: defaultValidation });

    const clearedView = '';

    return (
        <FieldWrapper
            error={error}
            label={label}
            clearCondition={field.value !== clearedView}
            onClear={() => {
                field.onChange(clearedView);
                onChange(clearedView);
                trigger();
            }}
        >
            <input
                {...field}
                className={`${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                id={name}
                type="text"
                onChange={(e) => {
                    field.onChange(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </FieldWrapper>
    );
};

export default InputText;
