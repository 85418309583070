export const convertDoctorWorkDate = (work) => {
    return work.map((item) => {
        let start_date;
        let end_date;
        if (item.start_date) {
            start_date = new Date(item.start_date * 1000).toLocaleDateString('en-US', {
                month: '2-digit',
                year: 'numeric',
            });
        }
        if (item.end_date) {
            end_date = new Date(item.end_date * 1000).toLocaleDateString('en-US', {
                month: '2-digit',
                year: 'numeric',
            });
        }
        return {
            ...item,
            start_date,
            end_date,
        };
    });
};
