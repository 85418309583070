import React from 'react';
import { useSelector } from 'react-redux';
import { StyledDoctorFavoritesList, MessageAbsenceDoctors } from './doctorFavoritesList.styled';
import { NavLink } from 'react-router-dom';
import { Spinner } from '../../../share/Spinner';
import FavoriteDoctorCard from '../favoriteDoctorCard/favoriteDoctorCard';
import { ReactComponent as ArrowUp } from "../../../../img/icons/newDoctorIcons/up-arrow.svg";

export const DoctorFavoritesList = ({favorite, requestFavoriteDoctor, search, isSpiner}) => {

    const { next } = useSelector((state) => state.favoriteDoctors);
    
    if (isSpiner && favorite?.length === 0) {
        return <Spinner></Spinner>;
    }

    return (
        <StyledDoctorFavoritesList>
            {favorite?.length > 0 ? 
                <>
                    <ul className='listFavoritDoctors'>
                        {favorite.map((item) => (
                            <FavoriteDoctorCard
                                key={item.doctor.id}
                                name={item.doctor.first_name}
                                patronymic={item.doctor.patronymic}
                                lastname={item.doctor.last_name}
                                avatar={item.doctor.avatar}
                                topDoctorsId={item.doctor.id}
                                price={item.doctor.video_chat_price}
                                specialization={item.doctor.specialization.length > 0 ? item.doctor.specialization[0]?.name : null}
                                rating={item.doctor.ratings_rating}
                                seniority={item.doctor.seniority}
                                isFavorite={item.doctor.is_favorite}
                                slug={item.doctor.slug}
                                search={search}
                                id={item.doctor.id}
                            />
                        ))}
                    </ul>

                    {isSpiner && <Spinner/>}

                    <div className='buttonsPaginate'>
                        {next !== null && 
                            <button className="buttonAddDoctors" onClick={requestFavoriteDoctor}>
                                Показать больше
                            </button>
                        }
                        <button className='buttonUp' onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>
                            Наверх
                            <ArrowUp />
                        </button>   
                    </div>
                </>
            : 
                <MessageAbsenceDoctors>
                    <p>Избранных врачей нет.</p>
                    <p><NavLink className={'nav-link'} to={'/doctors'}>Посмотреть</NavLink> всех врачей </p>
                </MessageAbsenceDoctors>
            }
        </StyledDoctorFavoritesList>
    );
};
