import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getDoctorConsultation,
    getDoctorConsultations,
    getFeedback,
    setCancelDoctorConsultation,
    setRecommendation,
    updateConsultationInfo,
} from './api';

export const fetchGetDoctorFutureConsultations = createAsyncThunk(
    'fetchGetDoctorFutureConsultations/getConsultations',
    async (_, { getState }) => {
        const next = getState().doctorConsultations.doctorConsultations.future.next;
        if (next) {
            const response = await getDoctorConsultations({ next });
            return response.data;
        }
        const response = await getDoctorConsultations({});

        return response.data;
    }
);

export const fetchGetDoctorPastConsultations = createAsyncThunk(
    'fetchGetDoctorPastConsultations/getConsultations',
    async (_, { getState }) => {
        const next = getState().doctorConsultations.doctorConsultations.past.next;
        if (next) {
            const response = await getDoctorConsultations({ status: '1,2,9', date: '-date', next });
            return response.data;
        }
        const response = await getDoctorConsultations({ status: '1,2,9', date: '-date' });

        return response.data;
    }
);

export const cancelConsultation = createAsyncThunk('cancelConsultation/cancelDoctorConsultation', async (id) => {
    console.log('cancelConsultation', id);
    const response = await setCancelDoctorConsultation(id);
    return response.data;
});

export const updateDoctorConsultationInfo = createAsyncThunk(
    'updateConsultation/updateDoctorConsultationInfo',
    async (payload) => {
        const response = await updateConsultationInfo({ id: payload.id, str: payload.textRecommendation });
        return response.data;
    }
);
export const getDoctorConsultationInfo = createAsyncThunk(
    'doctorConsultations/getDoctorConsultationInfo',
    async (consultationId) => {
        const response = await getDoctorConsultation(consultationId);
        // console.log(response);
        return response.data.data.consultation;
    }
);

export const fetchConsultation = createAsyncThunk('currentConsultation/fetchConsultation', async (id) => {
    const response = await getDoctorConsultation(id);
    return response.data.data.consultation;
});

export const fetchRecommendation = createAsyncThunk('currentConsultation/setRecommendation', async (data) => {
    const response = await setRecommendation(data);
    return response.data.consultation_id;
});

export const fetchFeedback = createAsyncThunk('currentConsultation/feedback', async (id) => {
    const response = await getFeedback(id);
    return response.data;
});

const initialState = {
    doctorConsultations: {
        future: {
            // статус 0
            next: null,
            consultations: [],
        },
        past: {
            // статус 1, 2, 9
            next: null,
            consultations: [],
        },
    },
    currentConsultation: null,
    error: null,
    draftRecommendations: [],
    isLoading: false,
    isModalSuccessfulConsultation: false,
    timeConsultation: 0,
    keyConsultationDoctor: null,
};
const doctorConsultations = createSlice({
    name: 'doctorConsultations',
    initialState,
    reducers: {
        removeConsultation: (state, action) => {
            const { typeConsult, id } = action.payload;
            state.doctorConsultations[typeConsult].consultations = state.doctorConsultations[
                typeConsult
            ].consultations.filter((item) => item.id !== id);
        },
        clearConsultation: (state) => {
            state.currentConsultation = null;
        },
        resetDoctorConsultations: (state) => {
            state.doctorConsultations = {
                future: {
                    next: null,
                    consultations: [],
                },
                past: {
                    next: null,
                    consultations: [],
                },
            };
        },
        setIsModalSuccessfulConsultationDoctor: (state, action) => {
            state.timeConsultation = 0;
            state.isModalSuccessfulConsultation = !state.isModalSuccessfulConsultation;
            state.timeConsultation = action.payload;
        },
        setKeyConsultationDoctor: (state, action) => {
            state.keyConsultationDoctor = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetDoctorFutureConsultations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchGetDoctorFutureConsultations.fulfilled, (state, action) => {
            const newConsultations = action.payload.data?.consultations?.filter(
                (consultation) =>
                    !state.doctorConsultations.future.consultations.some((item) => item.id === consultation.id)
            );
            if (state.doctorConsultations.future.next) {
                state.doctorConsultations.future.consultations.push(...newConsultations);
            } else {
                // action.payload.count === state.doctorConsultations.future.consultations.length ?
                //     state.doctorConsultations.future.consultations = action.payload.data.consultations :
                    state.doctorConsultations.future.consultations = newConsultations;
                
            }
            state.doctorConsultations.future.next = action.payload?.next;
            state.isLoading = false;
        });
        builder.addCase(fetchGetDoctorPastConsultations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchGetDoctorPastConsultations.fulfilled, (state, action) => {
            const newConsultations = action.payload.data?.consultations?.filter(
                (consultation) =>
                    !state.doctorConsultations.past.consultations.some((item) => item.id === consultation.id)
            );
            state.doctorConsultations.past.consultations.push(...newConsultations);
            state.doctorConsultations.past.next = action.payload?.next;
            state.isLoading = false;
        });
        builder.addCase(updateDoctorConsultationInfo.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateDoctorConsultationInfo.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(cancelConsultation.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(cancelConsultation.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchConsultation.fulfilled, (state, action) => {
            const consultationUpdated = action.payload;
            state.currentConsultation = consultationUpdated;
            const pastConsultations = state.doctorConsultations.past.consultations;
            const futureConsultations = state.doctorConsultations.future.consultations;
            const indexPast = pastConsultations.findIndex((item) => item.id === consultationUpdated.id);
            const indexFuture = futureConsultations.findIndex((item) => item.id === consultationUpdated.id);

            if (indexPast !== -1) {
                pastConsultations.splice(indexPast, 1, consultationUpdated);
            }
            if (indexFuture !== -1) {
                futureConsultations.splice(indexFuture, 1, consultationUpdated);
            }
        });
        builder.addCase(fetchFeedback.fulfilled, (state, action) => {
            state.currentConsultation.feedback = action.payload;
        });
    },
});

export const selectFutureConsultations = (state) => ({
    consultationsList: state.doctorConsultations.doctorConsultations.future.consultations,
    next: state.doctorConsultations.doctorConsultations.future.next,
    isLoading: state.doctorConsultations.isLoading,
});

export const selectPastConsultations = (state) => ({
    consultationsList: state.doctorConsultations.doctorConsultations.past.consultations,
    next: state.doctorConsultations.doctorConsultations.past.next,
    isLoading: state.doctorConsultations.isLoading,
});

export const {
    removeConsultation,
    clearConsultation,
    resetDoctorConsultations,
    setIsModalSuccessfulConsultationDoctor,
    setKeyConsultationDoctor,
} = doctorConsultations.actions;
export default doctorConsultations.reducer;
