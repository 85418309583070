import styled from 'styled-components';

export const StyledDoctorFavoritesList = styled.div`

	.listFavoritDoctors {
		width: 100%;
		list-style: none;
		margin: 0 auto;
		display: grid;
		grid-template-columns: repeat(auto-fill,minmax(220px,1fr));
		justify-content: center;
		gap: 20px;
	}

	.buttonsPaginate {
		display: flex;
		justify-content: flex-end;
		margin-top: 35px;
	}

	.buttonsPaginate button {
		border: none;
		background-color: transparent;
		color: #19BE6F;
		font-size: 16px;
		line-height: 150%;
		cursor: pointer;
        transition: opacity 0.5s linear;
        &:hover {
            opacity: 0.7;
        }
	}

	.buttonAddDoctors {
		margin-right: 42px;
	}	

	.buttonUp {
		display: flex;
		gap: 4px;
		align-items: center;
	}
`;

export const MessageAbsenceDoctors = styled.div`
  margin-top: 40px;
  margin-bottom: 80px;
	width: 100%;
  text-align: left;
		p {
			font-size: 22px;
			line-height: 120%;
			font-weight: 400;
			color: #062A3F;
		}
		a {
			text-decoration: none;
			color: #19BE6F;
			cursor: pointer;
		}
	@media (max-width: 768px) {
		// тут был бестолковый стиль, удалил. Медиазапрос остался
	}
  @media (max-width: 360px) {
    p {
      font-size: 18px;
    }
  }
`;
