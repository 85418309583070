import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { ViewMedication } from './ViewMedication';
import { SectionStl } from '../viewPatient.styled';
import { EditMedication } from './EditMedication';

export const Medication = () => {
    const [editMedication, toggleEditMedication] = useState(false);
    const patientProfile = useSelector((state) => state.patientProfile?.patientProfile);
    const {medicines} = patientProfile;

    return (
        <SectionStl>
            <TitleWithPencil toggle={toggleEditMedication} title={'Приём препаратов'} />
            {editMedication ? (
                <EditMedication medicines={medicines} toggle={toggleEditMedication} />
            ) : (
                <ViewMedication medicines={medicines} />
            )}
        </SectionStl>
    );
};
