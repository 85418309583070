import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RequestAgainStl } from './requestAgain.styled';
import { clearMessages, fetchAuthentification } from '../api/authentificationSlice';

export const RequestAgain = ({ setCode }) => {
    const phone = useSelector(state => state.authentification.phone_number)
    const [showButton, setShowButton] = useState(false);
    const [timeLeft, setTimeLeft] = useState(180);
    const dispatch = useDispatch();

    useEffect(() => {
        if (timeLeft === 0) {
            setShowButton(true); 
        } else {
            const timer = setTimeout(() => {
                setTimeLeft(timeLeft - 1); 
            }, 1000);
            return () => clearTimeout(timer); 
        }
    }, [timeLeft]);

    const handleClick = () => {
        setCode({ first: '', second: '', third: '', fourth: '' })
        setShowButton(false); 
        setTimeLeft(180);
        dispatch(clearMessages())
        dispatch(fetchAuthentification(phone));
    };

    return (
        <RequestAgainStl>
            {showButton ? (
                <span className='btn-again' onClick={handleClick}>
                    Запросить код повторно
                </span>
            ) : (
                <p className='info'>Повторная отправка кода <br /> будет доступна через <br /> <span>{timeLeft}</span> секунд</p>
            )}
        </RequestAgainStl>
    )
}
