import styled from 'styled-components';

export const StyledLkPatientNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  width: 200px;
 
  .nav-button {
    display: block;
    text-decoration: none;
    margin: 32px 0 40px;
    padding: 17px;
    border: 2px solid #19BE6F;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    background-color: #19BE6F;
    cursor: pointer;
    transition: opacity 0.5s linear;
    &:hover {
      opacity: 0.7;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;