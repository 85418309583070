import styled from 'styled-components';

export const StyledNavbar = styled.ul`
    display: flex;
    gap: 40px;
    justify-content: center;
    grid-area: navbar;

    @media (max-width: 767px) {
        gap: 0;
        flex-direction: column;
        margin-bottom: 20px;
    }

    /* @media (max-width: 768px) {
    } */

    @media (max-width: 400px) {
        padding-bottom: 10px;
        border-bottom: 1px solid #b6b7ba;
    }

    .navlink__item {
        list-style-type: none;
        padding: 11.5px 0;
    }
    .navlink__item:last-child {
        margin-right: 0;
    }
    .navlink__link,
    .menu__link {
        padding: 0;
        font-family: 'Rubik';
        font-size: 16px;
        line-height: 19px;

        color: #212121;
        text-decoration: none;
        transition: opacity 0.5s linear, background-color 0.5s linear;
        cursor: pointer;

        @media (max-width: 767px) {
            padding: 0;
            line-height: 130%;
            &--static {
                display: block;
                cursor: default;
            }
        }
    }

    .navlink__link:hover {
        opacity: 0.6;
    }

    .menu__link:hover {
        opacity: 0.6;
        @media (max-width: 767px) {
            background-color: #f4f4f4;
            opacity: 1;
        }
    }

    .last {
        position: relative;
        cursor: default;
        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .navbar__menu {
        max-width: 200px;
        position: absolute;
        left: -15px;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
        top: 35px;
        gap: 10px;
        list-style-type: none;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        @media (max-width: 767px) {
            .menu__item {
                padding: 11.5px 16px;
            }

            position: static;
        }
    }
    .menu__link.active,
    .navlink__link.active {
        color: #19be6f;
    }
`;
