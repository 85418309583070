import styled from 'styled-components';

export const BlogStyled = styled.div`
    display: flex;
    justify-content: space-between;

    .centerSpinner {
        margin-right: auto;
        margin-left: auto;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
