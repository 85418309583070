import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { filterDeletedBlocks } from '../helpers/filterDeletedBlocks';
import { deleteDoctorExpiriens, fetchMeDoctor, putDoctorExpiriens } from '../../../api/doctorUserProfileSlice';
import { useDispatch } from 'react-redux';
// import { WorkList } from './WorkList';
import { setMyWorkExpiriens } from '../../../api/api';
import { dateToUnixMY } from '../../../../share/helpers';
import { convertDoctorWorkDate } from '../helpers/convertDoctorWorkDate';
import { WorkList } from '../../shared/WorkList';

export function EditWork({ work, toggle }) {
    const dispatch = useDispatch();
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            // (дефолтные поля)конвертируем дату из секунд в mm/yyyy
            work: convertDoctorWorkDate(work),
        },
    });

    // для управления массивом
    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: 'work',
    });

    // если поля испачканы - узнаем здесь и про многое полезное еще
    const { dirtyFields, isValid, isSubmitting } = useFormState({ control: methods.control });

    const onSubmit = ({ work: currentList }) => {
        const promises = [];
        // вычислим удаленные блоки и удалим их с бека
        const deletedBlocks = filterDeletedBlocks(work, currentList);
        deletedBlocks?.forEach((item) => {
            promises.push(dispatch(deleteDoctorExpiriens(item.id)));
        });

        currentList.forEach((block, i) => {
            // конвертируем дату перед отправкой на бек из mm/yyyy в секунды
            const start_date = dateToUnixMY(block.start_date);
            const end_date = dateToUnixMY(block.end_date);

            const newWork = {
                ...block,
                start_date,
                end_date: end_date ? end_date : start_date,
                until_now: block.until_now ? block.until_now : false,
            };

            // если новый блок
            if (newWork.newField) {
                const { newField, ...request } = newWork;
                promises.push(setMyWorkExpiriens(request));
            }
            // если измененный
            if (!block.newField && dirtyFields?.work?.[i]) {
                const { organization, position, start_date, end_date, until_now, id } = newWork;
                promises.push(
                    dispatch(putDoctorExpiriens({ organization, position, start_date, end_date, until_now, id }))
                );
            }
        });

        Promise.all(promises)
            .then(() => toggle(false))
            .then(() => {
                dispatch(fetchMeDoctor());
            });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const onAdd = () => {
        const newBlock = {
            organization: '',
            position: '',
            start_date: '',
            end_date: '',
            until_now: '',
            newField: true,
        };
        append(newBlock);
    };

    const inputs = fields.map((work, index) => {
        return (
            <section className="block" key={work.id}>
                <WorkList name={`work.${index}`} />
                {fields.length > 1 && (
                    <span className="delete" onClick={() => remove(index)}>
                        Удалить блок
                    </span>
                )}
            </section>
        );
    });

    return (
        <FormProvider {...methods}>
            <form className="formDynamicInput" onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="inputs">{inputs}</div>
                <button className="btn__addBlock" onClick={onAdd}>
                    {`+ Добавить место работы`}
                </button>
                <button className="btn__recive" type="submit" disabled={!isValid || isSubmitting}>
                    Сохранить
                </button>
            </form>
        </FormProvider>
    );
}
