import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import ReactSelect, { components } from 'react-select';
import { useController, useFormContext, useFormState } from 'react-hook-form';
import styled from 'styled-components';

import { SelectStl } from '../selectStl.styled';
import { ReactComponent as Cross } from '../../../../img/icons/cross-gray.svg';
import { ReactComponent as Arrow } from '../../../../img/icons/Arrow WEB.svg';
import { FieldWrapperStl, ErrorStl, LabelStl } from '../FieldWrapper';
import { useEffect } from 'react';

const SelectCommonFieldStl = styled(FieldWrapperStl)`
    .selected {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8px;
        gap: 8px;
        &__option {
            border-radius: 10px;
            background-color: #8af0bf;
            display: flex;
            flex-direction: row;
            &-label {
                padding: 7px 8px 6px 15px;
                user-select: none;
            }
            &-clear {
                display: flex;
                align-items: center;
                padding-right: 8px;
                path {
                    stroke: #19be6f;
                }
                &:hover path {
                    stroke: #169056;
                }
            }
        }
    }
    > svg {
        position: absolute;
        right: 24px;
        bottom: 27px;
        display: none;
        &.visible {
            display: block;
        }
        &:hover path {
            stroke: #19be6f;
        }
    }
    .rs__value-container {
        width: 100%;
        max-width: 370px;
    }
    .rs__control  {
        width: 100%;
        max-width: 423px;
    }
`;

const SingleValue = (props) => {
    return <components.SingleValue {...props}></components.SingleValue>;
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Arrow />
        </components.DropdownIndicator>
    );
};

const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <Cross />
        </components.ClearIndicator>
    );
};

const Option = (props) => {
    return (
        <components.Option {...props} className="rs__option_radbtn">
            <div className="rs__option-btn">
                <svg className="svg" height="24px" width="24px">
                    <circle className="radio-outline" cx="12" cy="12" r="11" fill="white" strokeWidth="2" />
                    <circle className="radio-dot" cx="12" cy="12" r="8" />
                </svg>
            </div>
            <span>{props.children}</span>
        </components.Option>
    );
};

const Clear = forwardRef(function Clear(props, ref) {
    const show = useRef(false);
    const [force, setForce] = useState(false);

    useImperativeHandle(
        ref,
        () => {
            return {
                toShow(inputValue) {
                    const boolInput = Boolean(inputValue);

                    if (boolInput !== show.current) {
                        show.current = boolInput;
                        setForce(() => boolInput);
                    }
                },
            };
        },
        []
    );

    return <Cross className={`${show.current ? 'visible' : ''}`} />;
});

const SelectedOptions = ({ field, trigger }) => {
    const selected = field.value;
    
    if (!Array.isArray(selected)) return null;

    return (
        <div className="selected">
            {selected.map((choise) => (
                <div key={choise.value} className="selected__option">
                    <div className="selected__option-label">{choise.label}</div>
                    <div
                        className="selected__option-clear"
                        id={choise.value}
                        onClick={() => {
                            field.onChange(selected.filter((el) => el.value !== choise.value));
                            trigger(field.name);
                        }}
                    >
                        <Cross />
                    </div>
                </div>
            ))}
        </div>
    );
};

const Select = ({
    name,
    validation = {},
    options,
    placeholder,
    label,
    isMulti,
    isClearable,
    isDisabled = false,
    radioButton,
    narrow,
    defaultValue,
    isRequired,
    // см. componets\lkDoctor\lkDoctorWallet\transactions\Filters.jsx
    style={
        showDropdownIndicator: false,
    },
    onChange = () => {},
}) => {
    const defaultValidation = {
        required: { value: false, message: 'Поле обязательно для заполнения' },
        validate: {
            isValidValue: (value) => {
                if (Array.isArray(value) && value.length) {
                    return true;
                }
                return 'Поле обязательно для заполнения';
            },
        },
        ...validation,
    };

    const { control, trigger } = useFormContext();

    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
        rules: defaultValidation,
    });

    let components = {
        DropdownIndicator,
        ClearIndicator,
        ...(radioButton && { SingleValue, Option }),
    };

    const refClear = useRef(null);

    const handleBlur = () => {
        trigger(field.name);
    };

    return (
        <SelectCommonFieldStl >
            {label !== undefined && <LabelStl>
                {label}
                {isRequired && <span className='fieldWraper__red'>*</span>}
            </LabelStl>}
            <SelectedOptions field={field} trigger={trigger} />
            <SelectStl
                as={ReactSelect}
                {...field}
                onChange={(value) => {
                    field.onChange(value);
                    onChange(value);
                    trigger(field.name);
                }}
                onInputChange={(value) => {
                    refClear.current.toShow(value);
                }}
                onBlur={handleBlur}
                components={components}
                options={options}
                placeholder={placeholder}
                hideSelectedOptions={false}
                isSearchable={false}
                isClearable={isClearable}
                isDisabled={isDisabled}
                {...(isMulti && {
                    isMulti,
                    isSearchable: true,
                    controlShouldRenderValue: false,
                    closeMenuOnSelect: false,
                    noOptionsMessage: () => 'Нет совпадений',
                })}
                classNamePrefix="rs"
                narrow={narrow}
                radioButton={radioButton}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        borderColor: error ? '#ff3636' : state.isFocused ? '#19BE6F' : '#B6B7BA',
                    }),
                    clearIndicator: (base, state) => ({
                        ...base,
                        display: !isMulti && state.hasValue ? 'flex' : 'none',
                    }),
                    dropdownIndicator: (base, state) => ({
                        ...base,
                        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                        display: (state.selectProps.inputValue || (!isMulti && state.hasValue)) ? (style.showDropdownIndicator ? 'flex' : 'none') : 'flex',
                    }),
                }}
            />
            <Clear ref={refClear} />
            <ErrorStl>{error?.message}</ErrorStl>
        </SelectCommonFieldStl>
    );
};

export default Select;
