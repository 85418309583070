import styled from 'styled-components';

export const WalletStl = styled.div`
    max-width: 422px;

    @media (max-width: 1024px) {
        margin: 0 auto;
    }

    .filter-container {
        justify-content: space-between;
    }

    .btns {
        text-align: right;
        span {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #19be6f;
            cursor: pointer;
            padding: 3px;
            &:first-child {
                margin-right: 42px;
            }
            &:last-child {
                svg {
                    margin-left: 4px;
                }
            }
        }
    }
`;
