import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import star from '../../../../img/icons/star.png';
import { StyledCard } from './Card.styled';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSetFavoriteDoctor, fetchRemoveFavoriteDoctorUid } from '../../../lkPatient/api/favoriteDoctorsSlice';
import { Spinner } from '../../../share/Spinner';
import { getSeniorityWithEnd } from '../../../share/helpers';
import { Modal } from '../../../share/Modal';
import { Appointment } from '../../../ourDoctors/ourDoctorsModals/appointment/Appointment';

const Card = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isPreloaderLike, setIsPreloaderLike] = useState(false);
    const [isModalOpen, setOpenModal] = useState(false);

    const isTopDoctorsAuth = useSelector((state) => state.doctorsTopSlice.isTopDoctorsAuth);
    const role = useSelector((state) => state.authentification.whoAreYou);
    const isAuth = useSelector((state) => state.authentification.isAuth);

    // смена статуса спинера лайка после запроса на добавление/удаление лайка и повторной загрузки топ-врачей
    useEffect(() => {
        setIsPreloaderLike(false);
    }, [isTopDoctorsAuth]);
    
    function handleLike() {
        setIsPreloaderLike(true);
        props.isFavorite
            ? dispatch(fetchRemoveFavoriteDoctorUid(props.topDoctorsId))
            : dispatch(fetchSetFavoriteDoctor(props.topDoctorsId));
    }

    function showDoctor() {
        if (!isAuth) {
            setOpenModal(true);
        } else {
            navigate(`/doctors/${props.id}/${props.slug}`);
        }
    }

    const closeModal = () => setOpenModal(false);

    return (
        <StyledCard avatar={props.avatar} isFavorite={props.isFavorite}>
            <div className="card__img" onClick={showDoctor}></div>
            <div className="card__content">
                <div className="card__nameLike">
                    <p className="card__name">
                        {props.lastname} {props.name} {props.patronymic}
                    </p>
                    {role === 'patient' &&
                        (isPreloaderLike ? (
                            <Spinner marginTop={'0px'} width={'20px'} height={'20px'} />
                        ) : (
                            <button className="card__like" onClick={handleLike}></button>
                        ))}
                </div>
                <p className="card__specialization">{props.specialization}</p>
                <div>
                    <p className="card__experience">
                        Стаж {getSeniorityWithEnd(props.seniority)}
                        <span className="card__rating">
                            {props.rating > 0 && <img className="card__ratingImg" src={star} alt="rating" />}
                            {props.rating}
                        </span>
                    </p>
                    <p className="card__price">от {props.price} &#8381;</p>
                    <button className="card__button" onClick={showDoctor}>Записаться</button>
                </div>
            </div>
            {isModalOpen && (
                <Modal toggle={closeModal}>
                    <Appointment />
                </Modal>
            )}
        </StyledCard>
    );
};

export default Card;
