import React from 'react';
import StatisticsCard from './StatisticsCard';
import styled from 'styled-components';
import Container from '../../share/Container';

const StatSectionStl = styled.section`
    padding-top: 137px;
    padding-bottom: 137px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F4F4F4;
    font-style: normal;

    @media (max-width: 900px) {
        padding: 50px 0;
    }

    .grid-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;

        @media (max-width: 900px) {
            display: block;
        }
    }
`;

const ChidCont = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 180px);
    gap: 20px;

    @media (max-width: 900px) {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        gap: 50px;
    }
`;

function Statistic() {
    const data = [
        {
            id: 1,
            quantity: '3500',
            description: 'Довольных пациентов',
        },
        {
            id: 2,
            quantity: '541+',
            description: 'Оказанной помощи',
        },
        {
            id: 3,
            quantity: '40+',
            description: 'Спасенных жизней',
        },
        {
            id: 4,
            quantity: '678',
            description: 'Нанятых сотрудников',
        },
    ];

    return (
        <StatSectionStl>
            {/* ЭТО БАНЕР С АКЦИЯМИ */}
            {/* ВРЕМЕННО УБРАН ДО БУДУЮЩЕЙ РЕАЛИЗАЦИИ */}

            {/*<ChidCont2>*/}
            {/*  <Container>*/}
            {/*    <Promotion bc='white'/>*/}
            {/*  </Container>*/}
            {/*</ChidCont2>*/}

            <Container>
                <div className="grid-wrapper">
                    <ChidCont>
                        {data.map((content, index) => (
                            <StatisticsCard
                                quantity={content.quantity}
                                description={content.description}
                                key={index}
                            />
                        ))}
                    </ChidCont>
                </div>
            </Container>
        </StatSectionStl>
    );
}
export default Statistic;
