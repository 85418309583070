export function setClassNameForDay(day, freeTime, selectedDay) {
    const classesForDay = new Set(['day', 'day-no-receptions']);
    selectedDay.day === day.day && classesForDay.add('day-select');
    const today = new Date();
    if (day.day === today.getDate() && day.month === today.getMonth() + 1 && day.year === today.getFullYear()) {
        classesForDay.add('day-today');
    }
    for (let time of freeTime) {
        const date = new Date(time.time * 1000);
        const dayRecept = date.getDate();
        const monthRecept = date.getMonth() + 1;
        const yearRecept = date.getFullYear();
        if (day.day === dayRecept && day.month === monthRecept && day.year === yearRecept) {
            classesForDay.add('day-is-receptions');
        }
    }
    if (classesForDay.has('day-is-receptions')) {
        classesForDay.delete('day-no-receptions');
    }
    if (classesForDay.has('day-select')) {
        classesForDay.delete('day-today');
    }

    return [...classesForDay].join(' ');
}
