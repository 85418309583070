import styled from 'styled-components';

export const DoctorSettingsDiv = styled.div`
    font-weight: 400;
    line-height: 150%;
    width: 100%;
    max-width: 510px;
    height: 280px;
    .settings-header {
        text-align: left;
        font-size: 24px;
        font-weight: 500;
    }
    .settings-item_wrapper {
        margin: 32px 0;
    }
    .settings__item {
        font-size: 18px;
        &:last-child {
            margin-top: 20px;
        }
    }
    .settings__item-label {
        color: #19be6f;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            color: red;
        }
    }
    .divider {
        width: 100%;
        border-bottom: 1px solid #b6b7ba;
    }
    .switcher-box {
        display: flex;
        justify-content: space-between;
        &:last-child {
            margin-top: 20px;
        }
    }
`;
