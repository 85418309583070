export const BLOODTYPES = [
    { id: 1, name: 'не указано' },
    { id: 2, name: 'O (I) Rh-' },
    { id: 3, name: 'O (I) Rh+' },
    { id: 4, name: 'A (II) Rh-' },
    { id: 5, name: 'A (II) Rh+' },
    { id: 6, name: 'B (III) Rh-' },
    { id: 7, name: 'B (III) Rh+' },
    { id: 8, name: 'AB (IV) Rh-' },
    { id: 9, name: 'AB (IV) Rh+' },
];