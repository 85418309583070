import { apiRequest, fileLoader } from '../../share/api';

export const consultations = async ({ status = 0, date = 'date', next = null }) => {
    if (next) {
        return await apiRequest.get(next);
    }
    const result = await apiRequest.get(`/consultations/?status=${status}&ordering=${date}`);
    return result;
};

// отправка файла для консультации по ее id
export const consultationSendFile = async ({ consultationId, file }) => {
    await fileLoader.post(`/consultations/${consultationId}/files/`, { file });
};

// удаление файла из консультации по id файла
export const consultationDeleteFile = async (id) => {
    await apiRequest.delete(`/consultations/files/${id}/`);
};

// получение консультации пациента по ее id
export const getConsultation = async (consultationId) => {
    return await apiRequest.get(`/consultations/${consultationId}`);
};

/* Отмена консультации пациентом */
export const setClosePatientConsultation = async (id) => {
    return await apiRequest.patch(`/consultations/cansel/${id}`);
};

export const consultationUpdate = async (id, data) => {
    console.log(id, data, '+updateConsult');
    return await apiRequest.patch(`/consultations/${id}`, { ...data });
};

export const consultationDelete = async (id) => {
    console.log(id, 'deletingConsult');
    return await apiRequest.delete(`/consultations/${id}/`);
};

export const getMyProfile = async () => {
    return await apiRequest.get(`/user/me/patient`);
};

//аллергии пациента
export const getMyAllergies = async () => {
    return await apiRequest.get(`/patients/allergies/`);
};
export const postMyAllergies = async (data) => {
    const { title, value } = data;
    return await apiRequest.post(`/patients/allergies/`, { title, value });
};
export const putMyAllergiesById = async ({id, title, value}) => {
    return await apiRequest.put(`/patients/allergies/${id}/`, {id, title, value});
};
export const deleteMyAllergiesById = async (id) => {
    return await apiRequest.delete(`/patients/allergies/${id}/`, {});
};
// не используются
// export const getMyAllergiesById = async (id) => {
//     return await apiRequest.get(`/patients/allergies/${id}/`, {});
// };
// export const postMyAllergiesById = async (id) => {
//     return await apiRequest.post(`/patients/allergies/${id}/`, {});
// };
// export const putMyMedicationById = async (id) => {
//     return await apiRequest.put(`/patients/allergies/${id}/`, {});
// };
// export const deleteMyMedicationById = async (id) => {
//     return await apiRequest.delete(`/patients/allergies/${id}/`, {});
// };


//операции пациента
export const getMyOperation = async () => {
    return await apiRequest.get(`/patients/operations/`);
};
export const postMyOperation = async (data) => {
    const { description, date } = data;
    return await apiRequest.post(`/patients/operations/`, { description, date });
};
export const putMyOperationById = async ({id, description, date}) => {
    return await apiRequest.put(`/patients/operations/${id}/`, {id, description, date});
};
export const deleteMyOperationById = async (id) => {
    return await apiRequest.delete(`/patients/operations/${id}/`, {});
};

// препараты пациента
export const getMyMedicines = async () => {
    return await apiRequest.get(`/patients/medicines/`);
};
export const postMyMedicines = async (data) => {
    const { title, dosage, frequency } = data;
    return await apiRequest.post(`/patients/medicines/`, { title, dosage, frequency });
};
export const putMyMedicinesById = async ({id, title, dosage, frequency}) => {
    return await apiRequest.put(`/patients/medicines/${id}/`, {title, dosage, frequency});
};
export const deleteMyMedicinesById = async (id) => {
    return await apiRequest.delete(`/patients/medicines/${id}/`, {});
};
// не используются
// export const getMyMedicinesList = async () => {
//     return await apiRequest.get(`/patients/medicines/`, {});
// };
// export const getMyMedicationById = async (id) => {
//     return await apiRequest.get(`/patients/medicines/${id}/`, {});
// };

// заболевания пациента
export const getMyDiseases = async () => {
    return await apiRequest.get(`/patients/patient_diseases/`);
};
export const postChronicDiseases = async (data) => {
    const { title, value } = data;
    return await apiRequest.post(`/patients/patient_diseases/`, { title, value });
};
export const putMyDiseasesById = async ({id, title, value}) => {
    return await apiRequest.put(`/patients/patient_diseases/${id}/`, {title, value});
};
export const deleteMyDiseasesById = async (id) => {
    return await apiRequest.delete(`/patients/patient_diseases/${id}/`, {});
};

// создание профиля нового пациента
export const patient_create = async (data) => {
    const response = await apiRequest.post(`user/me/patient`, {
        user: {
            first_name: data.user.first_name,
            last_name: data.user.last_name,
            patronymic: data.user.patronymic,
            birthday: data.user.birthday,
            email: data.user.email,
            gender: data.user.gender,
            country: data.user.country,
            city: data.user.city,
        },
        height: data.height.length > 0 && data.height,
        height:  Number.isFinite(data.height) && data.height,
        weight:  Number.isFinite(data.weight) && data.weight,
        blood_type: data.blood_type.length > 0 && data.blood_type,
    });
    return response;
};

// редактирование личных данных в кабинете пациента
export const postPatient = async (data) => {
    const response = await apiRequest.post(`user/me/patient`, {
        user: {
            first_name: data.user.first_name,
            last_name: data.user.last_name,
            patronymic: data.user.patronymic,
            birthday: data.user.birthday,
            email: data.user.email,
            gender: data.user.gender,
            country: data.user.country,
            city: data.user.city,
        },
    });
    return response;
};

export const getPayment = async (id) => {
    console.log(id);
    return await apiRequest.get(`/payments/${id}`, {});
};
export const createPayment = async (id) => {
    console.log(id);
    return await apiRequest.post(`/payments/`, { consultation: id });
};
export const createPaymentConfirm = async (id) => {
    console.log(id);
    return await apiRequest.post(`/payments/confirmation/`, { consultation: id });
};
export const createPaymentBack = async (id) => {
    console.log(id);
    return await apiRequest.post(`/payments/refund/`, { consultation: id });
};
export const createPaymentConfirmation = async (id) => {
    return await apiRequest.post(`/payments/retrieve/`, { consultation: id });
};
export const getFavoriteDoctors = async () => {
    //return await apiRequest.get('/patients/favorities/');
    return await apiRequest.get('/doctors/favorites/by_patient/?page_size=10');
};
// export const setFavoriteDoctors = async (id) => {
//    return await apiRequest.post('/patients/favorities/', { doctor_uid: id });
// 	//return await apiRequest.post('/doctors/favorites/by_patient/', { doctor_uid: id });
// };

export const setFavoriteDoctors = async (id) => {
    return await apiRequest.post('/doctors/favorites/by_patient/', { doctor_uid: id });
};

export const removeFavoriteDoctors = async (id) => {
    //return await apiRequest.delete(`/patients/favorities/${id}/`);
    return await apiRequest.delete(`/doctors/favorites/by_patient/${id}/`);
};

export const removeFavoriteDoctorsUid = async (uid) => {
    return await apiRequest.delete(`/doctors/favorites/by_doctor/${uid}`);
};

export const getAllDoctors = async (data) => {
    return await apiRequest.get(`/doctors/?search=${data.search}&page_size=${data.page_size}`);
};

export const feedbackСonsultation = async ({ data, consultationId }) => {
    return await apiRequest.post(`/feedback/consultation/${consultationId}/`, data);
};

export const getFeedback = async (id) => {
    return await apiRequest.get(`/feedback/${id}`);
};
