import React from 'react';
import Form from '../share/formFields/Form';
import InputText from '../share/formFields/newElements/InputText';
import InputTel from '../share/formFields/newElements/InputTel';
import InputEmail from '../share/formFields/newElements/InputEmail';
import AgreementField from '../share/formFields/AgreementField';
import Select from '../share/formFields/newElements/Select';
import TextareaField from '../share/formFields/newElements/TextareaField';

import { ContactFormStl } from './contactForm.styled';
import { topics } from './topics.js';
import { SumbitButton } from '../share/formFields/SubmitButton';
import { FormProvider, useForm } from 'react-hook-form';

export default function ContactForm() {
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            topic: '',
            phoneNumber: '',
            message: '',
            email: '',
            checkbox: false,
        },
    });
    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <ContactFormStl>
            <p className="subtitle">Оставьте ваши контактные данные и мы постараемся вам помочь</p>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <fieldset>
                        <InputText
                            name="name"
                            placeholder="Александр"
                            label="Имя"
                            validation={{
                                required: { value: true, message: 'Поле обязательно для заполнения' },

                                maxLength: {
                                    value: 152,
                                    message: 'Максимальная длина: 152 символа',
                                },
                                minLength: {
                                    value: 2,
                                    message: 'Минимальная длина: 2 символа',
                                },
                            }}
                        />
                        <Select
                            name="topic"
                            options={topics}
                            placeholder="Выберите тему обращения"
                            validation={{
                                validate: {
                                    isValidValue: (value) => {
                                        if (value) {
                                            return true;
                                        }
                                        return 'Поле обязательно для заполнения';
                                    },
                                },
                            }}
                            label="Тема обращения"
                        />
                        <InputTel name="phoneNumber" placeholder="+7 (999) 999-99-99" label="Телефон" />
                        <InputEmail name="email" placeholder="example@gmail.com" label="E-mail" />
                        <TextareaField name="message" placeholder="Введите текст обращения" />
                    </fieldset>
                    <AgreementField name="checkbox" />
                    <SumbitButton name="submit" content="Отправить" />
                </form>
            </FormProvider>
        </ContactFormStl>
    );
}
