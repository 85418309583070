import styled from 'styled-components';

export const InfoBlockStl = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;

    .back {
        display: flex;
        gap: 6px;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #19be6f;
        cursor: pointer;
        > svg {
            transform: rotate(-90deg);
        }
    }

    .info {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #282828;
        max-width: 333px;
        > span {
            font-weight: 600;
            margin: 0 5px;
        }
    }

    @media (max-width: 430px) {
        padding: 0;
    }
`;
