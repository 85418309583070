import React from 'react';
import Button from '../share/Button';
import { Link } from 'react-router-dom';
import { StyledCardBody, StyledCardImage, StyledCardWrapper } from './card.styled';


const Card = ({image, title, description, btnParams}) => {
    return (
        <StyledCardWrapper>
            <StyledCardBody>
                <StyledCardImage image={image} />
                    <div className='card__content'>
                    <h3 className='card__content-title'>{title}</h3>
                    <p className='card__content-description'>{description}</p>
                    <Link to={btnParams.link}>
                        <Button XL green>
                            {btnParams.name}
                        </Button>
                    </Link>
                    </div>
            </StyledCardBody>
        </StyledCardWrapper>

    );
};

export default Card;
