import React from 'react';
import { DataBlockStl } from  '../viewPatient.styled';
import { useSelector } from 'react-redux';
import {Spinner} from '../../../../share/Spinner'

export const ViewAllergy = ({ allergies }) => {
    const isLoaderAllergy = useSelector((state) => state.patientProfile?.isLoaderAlleries);

    const allergiesShow = allergies.map((allergie) => {
        return (
            <DataBlockStl key={allergie.id}>
                <div className="info__wrapper">
                    <p className="info__data">{allergie.title}</p>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">{allergie.value}</p>
                </div>
            </DataBlockStl>
        );
    });

    return isLoaderAllergy ? <Spinner/> : allergiesShow;
};