import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledPatientConsultationCard } from './patientConsultationCard.styled';
import { consultationStatus } from '../lkPatientConsultationHelpers';
import { ConsultationButtons } from '../../consultationButtons/consultationButtons';

import { ReactComponent as LikeFilled } from '../../../../../img/icons/doctorProfilIcons/like_profile.svg';
import { ReactComponent as LikeEmpty } from '../../../../../img/icons/doctorProfilIcons/like_profile_empty.svg';
import { Spinner } from '../../../../share/Spinner';
import { useDispatch } from 'react-redux';
import { fetchRemoveFavoriteDoctorUid, fetchSetFavoriteDoctor } from '../../../../lkPatient/api/favoriteDoctorsSlice';
import { fetchGetDoctor } from '../../../../ourDoctors/api/doctorsSlise';
import { setDoctor } from '../../../api/PatientConsultationsSlice';
import { useSelector } from 'react-redux';
import { ConsultationTimeAndPrice } from '../../../../share/consultationTime/consultationTime';

export const PatientConsultationCard = ({ consultation }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const newDoctor = useSelector((state) => state.doctors.doctor);
    const { status, date, doctor, id, price, registration_log } = consultation;
    const { last_name, first_name, patronymic, avatar, specialization, id: doctorId } = doctor;
    let fullName = `${last_name} ${first_name} ${patronymic ? patronymic : ''}`;

    const isPatientCancelled = doctorId !== registration_log?.cancelled_by?.uid;

    const showMoreConsultation = () => {
        navigate(`${id}`);
    };

    const removeConsultation = () => {
        // нет в свагере
    };

    const spec = specialization?.map((s) => {
        return <span key={s.id}>{s.name}</span>;
    });

    useEffect(() => {
        if (newDoctor) {
            dispatch(setDoctor(newDoctor));
        }
    }, [newDoctor]);

    const onClickLike = (e) => {
        e.stopPropagation();
        setIsLoading(true);
        dispatch(fetchSetFavoriteDoctor(doctor.id))
            .then(() => {
                dispatch(fetchGetDoctor(doctor.id));
            })
            .finally(() => setIsLoading(false));
    };
    const onClickDisLike = (e) => {
        e.stopPropagation();
        setIsLoading(true);
        dispatch(fetchRemoveFavoriteDoctorUid(doctor.id))
            .then(() => {
                dispatch(fetchGetDoctor(doctor.id));
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <StyledPatientConsultationCard onClick={showMoreConsultation}>
            <div className="photoBlock">
                <img src={avatar} alt="photo" />
                <span className="like-wrapper" onClick={doctor.is_favorite ? onClickDisLike : onClickLike}>
                    {isLoading ? (
                        <Spinner marginTop={'0px'} width={'20px'} height={'20px'} />
                    ) : doctor.is_favorite ? (
                        <LikeFilled className="like" />
                    ) : (
                        <LikeEmpty className="like" />
                    )}
                </span>
            </div>
            {consultationStatus(status, isPatientCancelled)}
            <div className="patientName">{fullName}</div>
            <div className="specialization">{spec}</div>
            <ConsultationTimeAndPrice priceDisplay={true} card={true} date={date} price={price} />
            <ConsultationButtons consultation={consultation} />
        </StyledPatientConsultationCard>
    );
};
