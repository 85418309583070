import React, { useEffect, useState } from 'react';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import useFirebase from './useFirebase';
import { firebaseConfig } from './firebaseConfig';
import { apiRequest } from '../../componets/share/api';
import { useDispatch, useSelector } from 'react-redux';
import { showTimer } from '../../componets/timerConsultation/api/timerSlice';

function PushNotificationHandler() {
    const dispatch = useDispatch();
    const app = useFirebase(firebaseConfig);
    const [permissionGranted, setPermissionGranted] = useState(false);
    const { isAuth, user, whoAreYou } = useSelector((state) => state.authentification);

    useEffect(() => {
        if (!app) {
            return; // Защита от null, если приложение еще не проинициализировано
        }

        const messaging = getMessaging(app);

        const handlePermission = async () => {
            try {
                const permission = await Notification.requestPermission();
                setPermissionGranted(permission === 'granted');
            } catch (error) {
                console.error('Error requesting notification permission:', error);
            }
        };

        const handleToken = async () => {
            try {
                const currentToken = await getToken(messaging, {
                    vapidKey: 'BDOU99-h67HcA6JeFXHbSNMu7e2yNNu3RzoMj8TM4W88jITfq7ZmPvIM1Iv-4_l2LxQcYwhqby2xGpWwzjfAnG4',
                });
                if (currentToken) {
                    const token = await apiRequest.get(`/notifications/device_token/?search=${currentToken}`);
                    console.log(token.data);
                    if (token?.data?.count) return;

                    await apiRequest.post(`/notifications/device_token/`, { device_token: currentToken });
                    // Здесь вы можете отправить токен на ваш сервер, например, с использованием API-запроса
                    // axios.post('/api/registerToken', { token: currentToken })
                } else {
                    console.log('No FCM token available.');
                }
            } catch (error) {
                console.error('Error getting FCM token:', error);
            }
        };

        const handleNotification = (payload) => {
            const response = JSON.parse(payload.notification.body);
            console.log('Received a push notification: ', response);
            let title = 'Заголовок уведомления';

            //определим заголовк уведомления
            switch (response.push_type) {
                case 'canceled_consultation':
                    title = 'Консультация отменена';
                    dispatch(showTimer(false));
                    break;
                case 'payed_consultation':
                    title = 'Консультация оплачена';
                    dispatch(showTimer(true));
                    break;
                case 'consultation_start_29':
                    title = 'До консультации осталось 30 минут';
                    break;
                case 'consultation_start_14':
                    title = 'До консультации осталось 15 минут';
                    dispatch(showTimer(true));
                    break;

                default:
                    break;
            }

            // Создаем кастомное браузерное уведомление
            if (Notification.permission === 'granted') {
                const notification = new Notification(title, {
                    body: response.message,
                    // Другие настройки уведомления
                });

                // Добавьте логику обработки нажатия на уведомление, если нужно
                notification.onclick = () => {
                    console.log('Notification clicked');
                    // Добавьте здесь логику для обработки нажатия на уведомление
                };
            }
        };

        if (!permissionGranted) {
            handlePermission();
        } else {
            handleToken();
            onMessage(messaging, handleNotification);
        }
    }, [app, permissionGranted]);

    return <></>;
}

export default PushNotificationHandler;
