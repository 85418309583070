import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BlogStyled } from './blog.styled';
import { MainHeader } from '../../componets/mainHeader/mainHeader';
import Container from '../../componets/share/Container';
import { BlogMenu } from '../../componets/blog/blogMenu/BlogMenu';
import { Spinner } from '../../componets/share/Spinner';
import { fetchPostsByTheme, fetchQuotes, fetchThemes } from '../../componets/blog/api/blogSlice';

const Blog = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { themes, posts, isLoading, quotes } = useSelector((state) => state.blog);
    const { pathname } = useLocation();
    const { themeId } = useParams();

    useEffect(() => {
        if (!Object.keys(posts).length) {
            dispatch(fetchPostsByTheme({ themeId: themeId ? themeId : 1, getMorePosts: false }));
        }
    }, []);

    useEffect(() => {
        if (!themes.length) {
            dispatch(fetchThemes());
        }
    }, []);

    useEffect(() => {
        if (!quotes.length) {
            dispatch(fetchQuotes());
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (pathname === '/news') {
            navigate('/news/5');
        }
    }, [pathname]);

    return (
        <Container>
            <MainHeader title={'Блог'} />
            <BlogStyled>
                {isLoading && !themes.length ? (
                    <div className="centerSpinner">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <BlogMenu />
                        <Outlet />
                    </>
                )}
            </BlogStyled>
        </Container>
    );
};
export default Blog;
