import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Container from '../componets/share/Container';
import Card from '../componets/about/Сard';
import Advantages from '../componets/about/Advantages';
import { MainHeader } from '../componets/mainHeader/mainHeader';
import { StyledAboutContent, StyledAboutPage } from './about.styled';
import { identifyWhoAreYou } from '../componets/header/helpers/identifyWhoAreYou';
import { useSelector } from 'react-redux';

function About() {
    const { pathname } = useLocation();
    const whoAreYou = useSelector(state => state.authentification.whoAreYou);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const aboutCardArray = [
        {
            id: 1,
            title: '24 часа на связи',
            description:
                'Ответим на ваши вопросы в любое время суток и оперативно организуем онлайн-консультацию c врачом.',
            img: '/img/aboutCardsImages/leftCard.png',
            btnParams: { name: 'Связаться с нами', link: '/contacts' },
        },
        {
            id: 2,
            title: 'Врачи со всей страны',
            description: 'Подберём нужного квалифицированного врача.',
            img: '/img/aboutCardsImages/centralCard.png',
            btnParams: { name: 'Выбрать врача', link: '/doctors' },
        },
        {
            id: 3,
            title: 'Все под рукой',
            description: 'Ведите Вашу медкарту, фиксируйте показатели самочувствия, контролируйте прием лекарств',
            img: '/img/aboutCardsImages/rightCard.png',
            btnParams: { name: 'Личный кабинет', link: identifyWhoAreYou(whoAreYou) },
        },
    ];    

    function renderAboutCards(array) {
        return array.map((item) => {
            return (
                <Card
                    key={item.id}
                    title={item.title}
                    description={item.description}
                    image={item.img}
                    btnParams={item.btnParams}
                />
            );
        })
    }

    return <StyledAboutPage>
        <Container>
            <MainHeader title={'О нас'} />
            <p className='about__description-upper'>Доктор24 лечебно-научно-профессиональный портал для пациентов и врачей</p>

            <section className='about__cards-display'>
                {renderAboutCards(aboutCardArray)}
            </section>

            <StyledAboutContent>
                <p className='about__description-middle'>
                    Доктор24 помогает получить консультацию врачей из любой точки мира с получением услуг
                    переводчика, общением с доктоБОТом и дает обеспечение оптимальной доступной медицины для
                    граждан.
                </p>

                <section className='about__advantages-image'>
                    <Advantages />
                </section>

                <p className='about__description-lower'>
                    Мы даем возможность получить квалифицированную помощь у врача любой специальности с функцией
                    переводчика из любой точки мира, где есть интернет. <br />
                    При необходимости, Доктор24 окажет помощь в организации госпитализации в стационары в
                    любой точке мира.
                </p>
            </StyledAboutContent>

        </Container>
    </StyledAboutPage>
};
export default About;
