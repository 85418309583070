import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { ViewEducation } from './ViewEducation';
import { SectionStl } from '../viewDoctor.styled';
import { useDispatch } from 'react-redux';
import { fetchUniversity } from '../../../api/doctorUserProfileSlice';
import { ModerationLabel } from '../../../../share/ModerationLabel';
import { EditDoctorEducation } from './EditDoctorEducation';

export const DoctorEducation = () => {
    const [editEducation, toggleEditEducation] = useState(false);
    const { higher_education } = useSelector((state) => state.doctorProfile?.doctorProfile);
    //   const dispatch = useDispatch();

    //   useEffect(() => {
    //     dispatch(fetchUniversity());
    // }, []);

    return (
        <SectionStl>
            <TitleWithPencil toggle={toggleEditEducation} title={'Образование'} />
            {higher_education.some((el) => !el.is_moderated) ? <ModerationLabel /> : null}
            {editEducation ? (
                <EditDoctorEducation higher_education={higher_education} toggle={toggleEditEducation} />
            ) : (
                <ViewEducation education={higher_education} />
            )}
        </SectionStl>
    );
};
