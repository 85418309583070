export const convertDateFields = (data) => {
    // Функция для преобразования полей типа "date" в дефолтных значениях
    return data.map((item) => {
        if (item.end_date) {
            return {
                ...item,
                end_date: new Date(item.end_date * 1000).getFullYear(),
                start_date: new Date(item.start_date * 1000).getFullYear(),
            };
        }
        return item;
    });
};
