import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearPhone } from '../api/authentificationSlice';
import { InfoBlockStl } from './info.styled';
import { formatPhoneNumber } from '../../share/helpers';
import { ReactComponent as ArrowSvg } from '../../../img/icons/newDoctorIcons/up-arrow.svg';

export const InfoBlock = ({}) => {
    const {phone_number, messagePageCode} = useSelector((state) => state.authentification);
    const dispatch = useDispatch();

    return (
        <InfoBlockStl>
            <span className="back" onClick={() => dispatch(clearPhone())}>
                <ArrowSvg />
                Назад
            </span>
            <div className="info">
                {messagePageCode}
                {/* На указанный вами номер <br /> */}
                {/* <span>{formatPhoneNumber(phone)}</span> */}

                {/* На указанную Вами почту <br /> */}
                {/* <span>{phone_number}</span> */}

                {/* поступит <br /> одноразовый код, введите 4 цифры */}
            </div>
        </InfoBlockStl>
    );
};
