import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppointmentStl } from './appointment.styled';
import { fetchLogout } from '../../../authentification/api/authentificationSlice';

export const Appointment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { whoAreYou } = useSelector((state) => state.authentification);
    const handleClick = () => {
        if (whoAreYou === 'doctor') {
            dispatch(fetchLogout()).then(() => navigate('/signin'));
            return;
        }
        navigate('/signin');
    };
    
    return (
        <AppointmentStl>
            <div className="appointment">
                <div className="appointment__icon">
                    <img src="/img/V_telemedicine_patient.svg" alt="header logo" />
                </div>
                <p className="appointment__text">Для записи необходимо войти в качестве пациента</p>
                <button className="appointment__button" onClick={handleClick}>
                    Войти / Зарегистрироваться
                </button>
            </div>
        </AppointmentStl>
    );
};
