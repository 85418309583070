import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDoctor_create, fetchMeDoctor } from '../../../api/doctorUserProfileSlice';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import InputPrice from '../../../../share/formFields/newElements/InputPrice';
import { validatePrice, validateRequired } from '../../../../share/formFields/validateInput';

export function EditDoctorPrice({ toggle }) {
    const [isLoaderPrice, setIsLoaderPrice] = useState(false)
    const { video_chat_price: price, user } = useSelector((state) => state.doctorProfile?.doctorProfile);
    
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            price,
        },
    });

    const dispatch = useDispatch();

    const { isValid, isSubmitting } = useFormState({ control: methods.control });

    const onSubmit = (data) => {
        setIsLoaderPrice(true);
        if (data.price) {
            while(data.price.charAt(0) === '0') {
                data.price = data.price.substring(1);
            }    
        }

        dispatch(fetchDoctor_create({ user, video_chat_price: data.price }))
            .then(() => dispatch(fetchMeDoctor()))
            .then(()=> setIsLoaderPrice(false))
            .then(() => toggle(false));

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <InputPrice
                    name="price"
                    label="Стоимость консультации, руб."
                    placeholder="1000"
                    isRequired={true}
                    // validation={validatePrice(true)}
                    // isPrice={true}
                /> 
                <button className="btn__recive" type="submit" disabled={!isValid || isSubmitting || isLoaderPrice}>
                    {!isLoaderPrice ? 'Сохранить' : 'Сохранение...' }
                </button>
            </form>
        </FormProvider>
    );
}
