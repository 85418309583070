import React, { useEffect, useState } from 'react';
import { Parser } from 'html-to-react';
import { ArticleCommentsStyled } from './articleComments.styled';
import { dateFormatterFullMnth } from '../../../share/helpers';
import avatar from '../../../../img/user-avatar-template.png';
import { useDispatch } from 'react-redux';
import { fetchArticlePage } from '../../api/blogSlice';
import { Spinner } from '../../../share/Spinner';

export const ArticleComments = ({ comments, postId, hasNextLink, isLoading }) => {
    const parser = new Parser();
    const dispatch = useDispatch();
    const [isLoadingComments, setIsLoadingComments] = useState(false);

    function showMoreComments() {
        setIsLoadingComments(true);
        dispatch(fetchArticlePage(postId));
    }

    useEffect(() => {
        if (!isLoading) {
            setIsLoadingComments(false);
        }
    }, [isLoading]);

    return (
        <ArticleCommentsStyled>
            {Boolean(!comments?.length) && (
                <p className="emptyComments">Комментарии не найдены. Будьте тем, кто прокомментирует первым!</p>
            )}
            <div className="comments">
                {comments?.map((com) => (
                    <div className="comment" key={com.id}>
                        <div className="dateOfCreation">
                            <p className="dateComment">
                                {dateFormatterFullMnth(new Date(com.updated_at).getTime() / 1000)}
                            </p>
                        </div>
                        <div className="authorAvatar">
                            <img
                                src={com.avatar ? `https://dj-front.doct24.com/media/${com.avatar}` : `${avatar}`}
                                alt=""
                            />
                        </div>
                        <div className="commentHeader">
                            <p className="authorName">{com.author.split(' ')[0]}</p>
                        </div>
                        <div className="commentInfo">
                            <div className="textContent">{parser.parse(com.text)}</div>
                        </div>
                    </div>
                ))}
            </div>
            {isLoadingComments && (
                <div className="centerSpinner">
                    <Spinner />
                </div>
            )}
            {Boolean(hasNextLink) && (
                <div className="articleFooterButton" onClick={showMoreComments}>
                    <p className="showCommentsButton">Показать больше</p>
                </div>
            )}
        </ArticleCommentsStyled>
    );
};
