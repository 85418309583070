import React, { useState } from 'react';
import { StyledRecommendationForm } from './recommendationForm.styled';
import { fetchConsultation, fetchFeedback, fetchRecommendation } from '../../api/lkDoctorConsultationsSlice';
import { useDispatch } from 'react-redux';
import { ConsultationFiles } from '../consultationList/doctorConsultationPage/consultationFiles/ConsultationFiles';

export const RecommendationForm = ({ id, recommendation, feedbackId, doctorFiles }) => {
    const dispatch = useDispatch();
    const [textRecommendation, setTextRecommendation] = useState(recommendation.text);
    const onSubmit = (e) => {
        e.preventDefault();
        const data = {
            consultation_id: id,
            text: textRecommendation,
            status: 'sent',
        };
        dispatch(fetchRecommendation(data)).then(() => {
            dispatch(fetchConsultation(id)).then(() => {
                if (feedbackId) {
                    dispatch(fetchFeedback(feedbackId));
                }
            });
        });
    };

    return (
        <StyledRecommendationForm>
            <form className="consultationForm" onSubmit={onSubmit}>
                <h4 className="consultationFormHeader">Рекомендации</h4>
                <textarea
                    className="consultationFormInput"
                    name="consultationFormInput"
                    id="consultationFormInput"
                    cols="30"
                    rows="10"
                    value={textRecommendation}
                    onChange={(e) => setTextRecommendation(e.target.value)}
                />
                <ConsultationFiles feedbackId={feedbackId} files={doctorFiles} consultationId={id} />

                <div className="consultationFormButtons">
                    <button
                        className="consultationFormButtonSign"
                        disabled={!textRecommendation}
                        onSubmit={(e) => {
                            onSubmit(e);
                        }}
                    >
                        Подписать
                    </button>
                </div>
            </form>
        </StyledRecommendationForm>
    );
};
