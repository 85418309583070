import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../share/api";


const initialState = {
    doctors: [],
    doctorsLoadingStatus: 'start',

    pageNumberPaginate: 1,

    doctorsCount: 0,//общее количество докторов в респонзе для расчета количества кнопок пагинации
    // numberOfPaginateButton: 1,
}



const _transformDoctors = (doctors) => {
    return {
        id: doctors.id,
        avatar: doctors.avatar,
        name: doctors.first_name,
        lastname: doctors.last_name,
        patronymic: doctors.patronymic,
        rating: doctors.ratings[0] ? doctors.ratings[0].value : 0,
        video_chat_price: doctors.video_chat_price,
        specialization: doctors.specialization,
        slug: doctors.slug,
    }
}


export const fetchMainSearch = createAsyncThunk(
    'mainSearch/fetchMainSearch',
    async (searchValue) => {
        const response = await apiRequest.get(`/doctors/?search=${searchValue}`);
        return response.data
    }
);



const mainSearchSlice = createSlice({
    name: 'mainSearch',
    initialState,
    reducers: {

        changePageNumberPaginate: (state, action) => {
            state.pageNumberPaginate = action.payload
            
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMainSearch.pending, state => {state.doctorsLoadingStatus = 'loading'})
            .addCase(fetchMainSearch.fulfilled, (state, action) => {
                state.doctorsLoadingStatus = 'idle';
                const transformDoctors = action.payload.data.users ?  action.payload.data.users.map(_transformDoctors) : null;
                state.doctors = [...transformDoctors];
                state.doctorsCount = action.payload.count

            })
            .addCase(fetchMainSearch.rejected, state => {
                state.doctorsLoadingStatus = 'error';
            })

    }
});

const {actions, reducer} = mainSearchSlice;

export default reducer;
export const { changePageNumberPaginate } = actions;