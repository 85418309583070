import styled from 'styled-components';

export const StyledReviewAddForm = styled.div`
  margin-top: 60px;
  
  form {
    width: 73.5%;
    display: flex;
    flex-direction: column;
  }

  .form_none {
    display: none;
  }
  
  .formHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .formHeader-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #212121;
  }
  
  .formHeader-symbols {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #666666;
  }

  .formHeader-symbols_error {
    color: red;
  }
  
  .reviewArea {
    resize: none;
    padding: 10px;
    width: 100%;
    border: 1px solid #F6F6F6;
    border-radius: 10px;
  }
  
  .rules {
    margin-top: 10px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #666666;
  }

  .rules-link {
    color: #19BE6F;
    text-decoration: none;
    transition: opacity 0.5s linear;
    cursor: pointer;
  }
   .rules-link:hover {
    opacity: 0.6;
   }

  .reviewBtn {
    padding: 17px 0;
    text-align: center;
    margin-top: 40px;
    width: 35.5%;
    background: #19BE6F;
    border-radius: 10px;
    border: 0;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #FFFFFF;
    transition: opacity 0.5s linear;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
    &:disabled {
      opacity: 0.6;
      cursor: auto;
    }
  }

  @media(max-width: 1000px) {
    form {
      width: 530px;
    }
  }

  @media(max-width: 768px) {
    margin-top: 32px;
  
    form {
      width: 100%;
    }
    
    .formHeader {
      display: none;
    }
    
    .reviewArea {
      width: 100%;
      max-height: 104px;
      border: 1px solid #B6B7BA;
    }
    
    .rules {
      display: none;
    }

    .reviewBtn {
      padding: 15px 0px;
      margin-top: 24px;
      min-width: 47%;
      font-size: 16px;
    }
  }
  // @media(max-width: 550px) {
  //   display: none;
  // }
`