import React from 'react';
import InputText from '../../../share/formFields/newElements/InputText';
import InputDate from '../../../share/formFields/newElements/InputDate';
import { validateInputLength } from '../../../share/formFields/validateInput';

export const DiseasesList = ({ name }) => {

    return (
        <>
            <InputText
                label='Заболевание'
                placeholder='Название'
                name={`${name}.title`}
                validation={validateInputLength(200)}
            />
            <div className="work__date">
                <InputDate name={`${name}.value`} label="Год обнаружения" placeholder="ГГГГ" defaultValue="9999" />
            </div>
        </>
        
    );
};


