import styled from 'styled-components';

export const StyledRecommendationForm = styled.div`
    .consultationForm {
        display: flex;
        flex-direction: column;
    }

    .consultationFormHeader {
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        color: #212121;
    }

    .consultationFormInput {
        /* width: 90%; */
        height: 140px;
        margin: 16px 0;
        padding: 10px;
        resize: none;
        border: 1px solid #b6b7ba;
        border-radius: 10px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .consultationFormButtons {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        @media (max-width: 768px) {
            button {
                flex: 1;
                margin-top: 14px;
            }
        }
        /* @media only screen and (min-width: 768px) and (max-width: 820px) {
            button {
                flex: 1;
            }
        } */
    }
    button {
        padding: 10px 20px;
        background: #ffffff;
        border: 2px solid #19be6f;
        border-radius: 6px;
        color: #19be6f;
        cursor: pointer;
        margin-top: 24px;
        &:hover {
            opacity: 0.8;
        }
    }

    .consultationFormButtonSign {
        background: #19be6f;
        color: #ffffff;
    }
    .consultationFormButtonSign:disabled {
        cursor: default;
        opacity: 0.7;
        &:hover {
            opacity: 0.7;
        }
    }
`;
