
import styled from 'styled-components';

export const StyledTopDRCarousel = styled.section`
    min-height: 860px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    overflow: hidden;
    @media (max-width: 1300px) {
        & > div {
            max-width: 858px;
        }
    }   
    @media (max-width: 970px) {
        & > div {
            max-width: 600px;
        }
    }
    @media (max-width: 720px) {
        & > div {
            max-width: 500px;
        }
    }
    @media (max-width: 600px) {
        & > div {
            max-width: 300px;
        }
    }
    
    .slick-slide > div {
        display: flex;
        justify-content: center;
    }
    
    .slick-slide img {
        @media (max-width: 450px) {
            width: 206px;
        }
    }

    .slick-dots {
        display: flex !important;
        justify-content: center;
        font-size: 0;
        gap: 20px;
        button {
            font-size: 0;
            width: 14px;
            height: 14px;
            background-color: #c4c4c4;
            border: none;
            border-radius: 50%;
        }
        .slick-active button {
            background-color: #06a7b3;
        }
    }
    .next-arrow {
        width: 42px;
        height: 42px;
        position: absolute;
        right: -80px;
        top: 220px;
        z-index: 1;
        cursor: pointer;
        @media (max-width: 450px) {
            width: 8px;
            height: 34px;
            right: -16px;
        }
    }
    .prev-arrow {
        width: 42px;
        height: 42px;
        position: absolute;
        left: -47px;
        top: 220px;
        z-index: 1;
        cursor: pointer;
        @media (max-width: 450px) {
            width: 8px;
            height: 34px;
            left: -24px;
        }
    }
`;

export const StyledTopDrCarouselTitle = styled.title`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 60px 0;
    h2 {
        font-family: Rubik;
        font-size: 42px;
        font-weight: 500;
        line-height: 130%;
        color: #212121;
    }
    @media (max-width: 650px) {
        h2 {
            font-size: 30px;
        }
    }
    `;