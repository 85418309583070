import React, { useState } from 'react';
import RadioGroup from '../../../../share/formFields/newElements/RadioGroup';
import { useDispatch } from 'react-redux';
import { fetchDoctor_create, fetchMeDoctor } from '../../../api/doctorUserProfileSlice';
import Select from '../../../../share/formFields/newElements/Select';
import { useSelector } from 'react-redux';
import { deleteSpecialization, postSpecialization } from '../../../api/api';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { SpecializationList } from '../../shared/SpecializationList';

export const EditDoctorSpecialization = ({ toggle }) => {
    const dispatch = useDispatch();
    const { allSpecializations } = useSelector((state) => state.doctorProfile);
    const { specialization, scientific_degree, category, user } = useSelector(
        (state) => state.doctorProfile.doctorProfile
    );

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            specialization,
            scientific_degree,
            category,
        },
    });

    const { isValid, isSubmitting } = useFormState({ control: methods.control });

    // данная конструкция форматирует специальности для селекта и находит id каждого для корректного отображения
    const formatedSpecializations = specialization.map((spec) => {
        const value = allSpecializations.find((item) => item.id === spec.id).id;
        return { value, label: spec.name };
    });

    const onSubmit = (data) => {
        const promises = [];
        // определяем массив специальностей которых не было на момент submit
        const sentSpec = data.spec.filter(
            (item) => !formatedSpecializations.some((innerItem) => innerItem.value === item.value)
        );
        // определяем массив специальностей которые были удалены перед submit
        const removeSpec = formatedSpecializations.filter(
            (item) => !data.spec.some((innerItem) => innerItem.value === item.value)
        );
        // отправляем новые данные на бэк
        sentSpec.forEach((spec) => {
            promises.push(postSpecialization(spec.value));
        });
        // удаляем данные с бэка
        removeSpec.forEach((spec) => {
            promises.push(deleteSpecialization(spec.value));
        });

        // отправляем изменения категории и степени(в зависимости от того что менялось)
        let obj;
        if (category !== data.category || scientific_degree !== data.scientific_degree) {
            obj = {
                user,
                ...(category !== data.category && { category: data.category }),
                ...(scientific_degree !== data.scientific_degree && { scientific_degree: data.scientific_degree }),
            };
        }
        Promise.all(promises)
            .then(() => toggle(false))
            .then(() => dispatch(fetchDoctor_create(obj)))
            .then(() => dispatch(fetchMeDoctor()));

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="specialization">
                    <SpecializationList
                        allSpecializations={allSpecializations}
                        formatedSpecializations={formatedSpecializations}
                        scientific_degree={scientific_degree}
                        category={category}
                    />
                </div>
                <button className="btn__recive" type="submit" disabled={!isValid || isSubmitting}>
                    Сохранить
                </button>
            </form>
        </FormProvider>
    );
};
