import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { NewFormSTL } from './components/newFormSTL.styled';
import PatientData from './components/PatientDataEdit';
import PatientParametrsEdit from './components/PatientParametrsEdit';
import PatientAllergyEdit from './components/PatientAllergyEdit';
import PatientOperationsEdit from './components/PatientOperationsEdit';
import PatientMedicationEdit from './components/PatientMedicationEdit';
import PatientDiseasesEdit from './components/PatientDIseasesEdit';
import {
    fetchPatient_create,
    postPatientAllergies,
    postPatientChronicDiseases,
    postPatientMedicines,
    postPatientOperation
} from '../../api/PatientProfileSlice';

const FormPatient = ({ methods }) => {
    const patientProfile = useSelector((state) => state.patientProfile?.patientProfile);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(false);

    // проверяем первый раз входит пользователь на сайт или нет, и если да, то редиректим на view
    const { last_name } = patientProfile?.user;
    useEffect(() => {
        if (last_name) {
            navigate('/lk/patient-profile/profile/view');
        }
    }, [last_name]);

    const onSubmit = (data) => {
        setIsLoader(true);
        const promises = [];

        let birthdayInSeconds = 0;
        const birthdayDate = new Date(
            Date.UTC(
                data.user.birthday.slice(6),
                data.user.birthday.slice(3, 5) - 1,
                data.user.birthday.slice(0, 2)
            )
        );
        birthdayInSeconds = Math.floor(birthdayDate.getTime() / 1000);
        
        promises.push(
            dispatch(
                fetchPatient_create({
                    user: {
                        ...data.user,
                        birthday: birthdayInSeconds,
                        city: data.user.city.value,
                    },
                    height: Number(data.patientProfile?.height),
                    weight: Number(data.patientProfile?.weight),
                    blood_type: 
                        typeof data.patientProfile.blood_type === 'string' ?
                            'не указано' :
                            data.patientProfile.blood_type.value,
                })
            )
        );

        // аллергии
        if (data.allergies) {
            data.allergies.forEach((el) => {
                promises.push(
                    dispatch(
                        postPatientAllergies(el)
                    )
                );
            });
        }

        // операции
        if (data.operations) {
            data.operations.forEach((el) => {
                const dateFormat = Math.floor(new Date(el.date).getTime() / 1000)
                promises.push(
                    dispatch(
                        postPatientOperation({...el, date: dateFormat})
                    )
                );
            });
        }

        // препараты
        if (data.medicines) {
            data.medicines.forEach((el) => {
                promises.push(
                    dispatch(
                        postPatientMedicines(el)
                    )
                );
            });
        }

        // заболевания
        if (data.diseases) {
            data.diseases.forEach((el) => {
                promises.push(
                    dispatch(
                        postPatientChronicDiseases(el)
                    )
                );
            });
        }

        Promise.all(promises)
            .then(() => {
                navigate('/lk/patient-profile/profile/view');
                setIsLoader(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoader(false);
            });
    };

    const {
        formState: { isValid },
    } = useFormContext();

    return (
        <NewFormSTL>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <PatientData />
                    <h2 className="title">
                        Медицинские данные
                        <span className="info__label">Не обязательно для заполнения</span>
                    </h2>
                    <PatientParametrsEdit />
                    <PatientAllergyEdit />
                    <PatientOperationsEdit />
                    <PatientMedicationEdit />
                    <PatientDiseasesEdit />
                    <button disabled={!isValid || isLoader} className="btn__recive">
                        {isLoader ? 'Сохранение...' : 'Отправить данные'}
                    </button>
                </form> 
        </NewFormSTL>
    );
};

export default FormPatient;
