import styled from "styled-components";

export const StyledProblemForm = styled.div`
    .consultationForm {
        display: flex;
        flex-direction: column;
    }

    .consultationFormHeader {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        color: #212121;
    }

    .consultationFormInput {
        /* width: 90%; */
        height: 140px;
        margin: 20px 0;
        padding: 10px;
        resize: none;
        border: 1px solid #b6b7ba;
        border-radius: 10px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .consultationFormTextError {
        min-height: 16px;
        position: relative;
        top: -15px;
        color: #FF3636;
        font-family: Rubik;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
    }
    
    .consultationFormButtons {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        gap: 16px;
        @media (max-width: 820px) {
            button {
                flex: 1;
            }
        }
        /* @media only screen and (min-width: 768px) and (max-width: 820px) {
            button {
                flex: 1;
            }
        } */
    }
    .consultationFormButtonComment {
        max-width: 249px;
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #666666;
    }
    button {
        min-width: 206px;
        max-height: 42px;
        padding: 10px 20px;
        background: #ffffff;
        border: 2px solid #19be6f;
        border-radius: 6px;
        color: #19be6f;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
    
    .consultationFormButtonSign {
        background: #19be6f;
        color: #ffffff;
        &:hover {
            opacity: 0.7;
        }
        &:disabled {
            cursor: default;
            opacity: 0.5;
        }
    }

`;