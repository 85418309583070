import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledModalSuccessfulConsultation } from './modalSuccessfulConsultation.styled';
import logoModal from '../../../../img/icons/autorization/logo_popupErrorAutorization.svg';

export const ModalSuccessfulConsultation = ({closePopupSuccessfulConsultation, timeConsultation}) => {

    const navigate = useNavigate();

    const writeReview = () => {
        closePopupSuccessfulConsultation();
        navigate('/reviews');
    }

    return (
        <StyledModalSuccessfulConsultation>
            <div className="modalSuccessful">
                <img src={logoModal} className="modalSuccessful__logo" alt='логотип'/>
                <p className="modalSuccessful__time">{`${Math. floor(timeConsultation/60)} мин ${timeConsultation%60} сек`}</p>
                <h1 className="modalSuccessful__heading">Видео-консультация завершена</h1>
                <p className="modalSuccessful__text">Мы будем благодарны за обратную связь!</p>
                <div className="modalSuccessful__buttons">
                    <button className="modalSuccessful__close" onClick={closePopupSuccessfulConsultation}>
                        Закрыть
                    </button>
                    <button className="modalSuccessful__feedback" onClick={writeReview}>
                        Оставить отзыв
                    </button>
                </div>
            </div>
        </StyledModalSuccessfulConsultation>
    );
};
