import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { DoctorPriseStl } from './doctorPrise.styled';
import { TitleWithPencilStl } from './doctorPrise.styled';
import { EditDoctorPrice } from './EditDoctorPrice';
import { ReactComponent as Pensil } from '../../../../../img/icons/pencil.svg';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { SectionStl } from '../viewDoctor.styled';

export const DoctorPrice = () => {
    const [editData, toggleEditData] = useState(false);
    const { video_chat_price: price } = useSelector((state) => state.doctorProfile?.doctorProfile);

    return (
        // <DoctorPriseStl >
        //     <TitleWithPencilStl>
        //         <h2 className={editData ? "title__price" : "title"}>
        //             {editData ? "Стоимость консультации, руб." : "Стоимость консультации"}
        //         </h2>
        //         <Pensil onClick={() => toggleEditData(!editData)} />
        //     </TitleWithPencilStl>
        //     {editData ? (
        //         <EditDoctorPrice toggle={toggleEditData} />
        //     ) : (
        //         <div className="info__wrapper">
        //             <p className="info__data">{price} руб.</p>
        //         </div>
        //     )}
            
        // </DoctorPriseStl>
        <SectionStl>
            <TitleWithPencil toggle={toggleEditData} title={'Стоимость консультации'} />
            {/* <ModerationLabel moderation={is_moderated} /> */}
            {editData ?
                <EditDoctorPrice toggle={toggleEditData} /> :
                <div className="info__wrapper">
                    <p className="info__data">{price} руб.</p>
                </div>}
        </SectionStl>
    );
};
