/* eslint-disable react/react-in-jsx-scope */

import styled from 'styled-components';

import VK from '../../img/icons/contactsIcons/social-vk.svg';
import Odnok from '../../img/icons/contactsIcons/social-odnok.svg';
import Telegramm from '../../img/icons/contactsIcons/social-telegram.svg';

const SocialsListStl = styled.ul`
    display: flex;
    list-style: none;
    li + li {
        margin-left: 20px;
    }
    li {
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: 24px;
            text-decoration: none;
        }
        &:nth-child(1) a img{
            height: 16px;
        }
        &:nth-child(2) a img{
            height: 17px;
        }
        &:nth-child(3) a img{
            height: 12px;
        }
    }
    
    @media(max-width: 768px) {
        li + li {
            margin-left: 16px;
        }
    }
`;

const Socials = () => {
    const socialNetwork = [
        {
            title: 'telegram',
            link: Telegramm,
            href: 'https://t.me/+8fkJzIb8wWMyYmZi',
        },
        {
            title: 'odnoklassniki',
            link: Odnok,
            href: 'https://ok.ru/group/61412337516611',
        },
        {
            title: 'vk',
            link: VK,
            href: 'https://vk.com/doct24',
        },
    ];

    return (
        <SocialsListStl>
            {socialNetwork.map((social) => (
                <li key={social.title}>
                    <a href={social.href}>
                        <img src={social.link} />
                    </a>
                </li>
            ))}
        </SocialsListStl>
    );
};

export default Socials;
