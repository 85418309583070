import React from 'react';
import Container from '../componets/share/Container';
import styled from 'styled-components';
import { TitleSection } from '../componets/share/TitleSection';
import Button from '../componets/share/Button';
import { NavLink, useNavigate } from 'react-router-dom';
import error from '../img/error.png';

const ErrorStl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .error_img {
    width: 55%;
    height: auto;
    object-fit: cover;
  }
  .error__text {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #062a3f;
    margin-bottom: 56px;
  }
  .error__wrapper {
    display: flex;
    justify-content: center;
  }
  .error__link {
    margin-left: 20px;
  }
`;

const Error = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <TitleSection height={'100vh'}>
      <Container>
        <ErrorStl>
          <img src={error} alt="Ошибка" className="error_img" />
          <p className="error__text">Похоже, мы не можем найти нужную Вам страницу.</p>
          <div className="error__wrapper">
            <Button green L onClick={goBack}>
              Назад
            </Button>
            <NavLink className="error__link" to="/">
              <Button green L>
                На главную
              </Button>
            </NavLink>
          </div>
        </ErrorStl>
      </Container>
    </TitleSection>
  );
};
export default Error;
