import styled from 'styled-components';

export const ReviewStyled = styled.div`

    .docReview__card_date {
        width: auto;
    }

    .docReview__card_body {
        margin: 0;
    }

    .docReview__card_description {
        padding: 0;
        font-size: 16px;
        line-height: 150%;
        margin: 0;
        overflow: hidden;
        transition: height 1s ease;
        @media (max-width: 350px) {
            font-size: 14px;
            line-height: 130%;
        }
    }

    .docReview__card_description_hidden {
        max-height: none;
        visibility: hidden;
        position: absolute;
        top: 0;
    }

    .btn_more {
        padding: 0;
    }
`;
