import React from 'react';
import styled from 'styled-components';
import Container from '../../share/Container';
import TitleH1 from '../../share/TitleH1';
import img from '../../../img/title-page-doctor.jpg';
import {Link } from 'react-router-dom';

const TopBoxStl = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 180px;
  @media (max-width: 660px) {
    flex-direction: column-reverse;
  }
  .top-box__info {
    width: 68%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    @media (max-width: 660px) {
      align-items: center;
      margin-right: 0;
    }
  }
  .top-box__text {
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    color: #062a3f;
    margin-top: 32px;
    
    @media ${({ theme }) => theme.device.laptop} {
      font-size: 18px;
    }
    @media ${({ theme }) => theme.device.tablet} {
      font-size: 16px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 14px;
    }
  }
  .top-box__wrapper-img {
    display: inline-block;
    overflow: hidden;
    width: 30%;
    flex-shrink: 0;
    @media ${({ theme }) => theme.device.laptop} {
      width: 302px;
      height: 302px;
    }
    @media ${({ theme }) => theme.device.tablet} {
      width: 226px;
      height: 226px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      width: 120px;
      height: 120px;
    }
    img{
      border-radius: 50%;
      width: 100%;
    }
  }
  .top-box__img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
  }
  .top-box__link {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 245px;
    height: 60px;
    background: #19BE6F;
    border-radius: 10px;
    font-size: 20px;
    color: #fff;
  }
`;

const TopBox = () => {
  return (
    <Container>
      <TopBoxStl>
        <div className="top-box__info">
          <TitleH1>Онлайн-консультации с врачами 24/7</TitleH1>
          <p className="top-box__text">
            Квалифицированные врачи всех специальностей проконсультируют Вас &nbsp; по любому
            интересующему вопросу
          </p>
          <Link className={'top-box__link'} to={'/doctors'}>Выбрать врача</Link>
        </div>
        <div className="top-box__wrapper-img">
          <img src={img} alt="top-box__img" />
        </div>
      </TopBoxStl>
    </Container>
  );
};
export default TopBox;