import styled from 'styled-components';

export const StyledConsultationButtons = styled.div`
    font-size: 14px;
    margin-top: 12px;
    display: flex;
    gap: 12px;
    button {
        padding: 8px 16px;
        /* height: 36px; */
        cursor: pointer;
        /* min-width: 245px; */
        min-width:  ${(props) => props.widthCard ? '100px' : '218px'};
        @media (max-width: 500px) {
            /* min-width: 218px; */
            flex: 1;
        }
    }

    .startBtn {
        border: 1px solid #19be6f;
        border-radius: 10px;
        background: #19be6f;
        color: #ffffff;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 20px;
        background: #19be6f;
        border-radius: 6px;
    }

    .closeBtn {
        color: #19be6f;
        background: #ffffff;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 20px;
        border: 2px solid #19be6f;
        border-radius: 6px;
    }
    @media (max-width: 500px) {
        grid-column: span 2 !important;
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;

        button {
            flex: 1;
        }
    }
    @media (max-width: 370px) {
        flex-direction: column;
    }
`;
