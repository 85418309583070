import React, { useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { DiseasesList } from '../../shared/DiseasesList';
import { filterDeletedBlocks } from '../../helpers/filterDeletedBlocks';
import { getPatientDiseases } from '../../../api/PatientProfileSlice';
import { deleteMyDiseasesById, postChronicDiseases, putMyDiseasesById } from '../../../api/patientApi';
import { useDispatch } from 'react-redux';
import { convertDateFields } from '../../helpers/convertDateFields';

export const EditDiseases = ({ diseases, toggle }) => {
    const [isLoader, setIsLoader] = useState(false);
    const dispatch = useDispatch();
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            diseases: diseases,
            // // (дефолтные поля)конвертируем дату из секунд в yyyy
            // diseases: convertDateFields(diseases),
        },
    });
    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: 'diseases',
    });

    // если поля испачканы - узнаем здесь
    const { dirtyFields, isValid, isSubmitting } = useFormState({ control: methods.control });

    const onSubmit = ({ diseases: currentList }) => {
        setIsLoader(true);
        const promises = [];

        const deletedBlocks = filterDeletedBlocks(diseases, currentList);
        deletedBlocks?.forEach((item) => {
            promises.push(deleteMyDiseasesById(item.id));
        });

        currentList.forEach((block, i) => {
            // const date = Date.UTC(block.date, 0, 1) / 1000;
            const newDiseases = {
                ...block,
                // date,
            };

            // если новый блок
            if (block.newField) {
                const { newField, ...request } = newDiseases;
                promises.push(postChronicDiseases({ ...request }));
            }
            // если измененный
            if (!block.newField && dirtyFields?.diseases?.[i]) {
                const { id, title, value } = newDiseases;
                promises.push(putMyDiseasesById({ id, title, value }));
            }
        });

        Promise.all(promises)
            .then(() => setIsLoader(false))
            .then(() => toggle(false))
            .then(() => {
                dispatch(getPatientDiseases());
            })
            .catch((err) => {
                console.log(err);
                return setIsLoader(false)
            });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const onAdd = () => {
        const newBlock = {
            title: '',
            value: '',
            // date: '',
            newField: true, // Дополнительное свойство
        };
        append(newBlock);
    };

    const inputs = fields.map((education, index) => {
        return (
            <section className="block" key={education.id}>
                <DiseasesList name={`diseases.${index}`} />
                <span className="delete" onClick={() => remove(index)}>
                    Удалить блок
                </span>
            </section>
        );
    });

    return (
        <>
            <FormProvider {...methods}>
                <form className="formDynamicInput" onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="inputs">{inputs}</div>
                    <button className="btn__addBlock" type="button" onClick={onAdd}>
                        {`+ Добавить запись`}
                    </button>
                    <button className="btn__recive" type="submit" disabled={!isValid || isSubmitting || isLoader}>
                        {isLoader ? 'Сохранение...' : 'Сохранить'}
                    </button>
                </form>
            </FormProvider>
        </>
    );
};
