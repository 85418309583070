import React from 'react';
import { DataBlockStl } from '../viewDoctor.styled';
import { dateFormatterForDoctorWork } from '../../../../share/helpers';

export const ViewWork = ({ work }) => {
    const workShow = work.map((w) => {
        return (
            <DataBlockStl key={w.id}>
                <div className="info__wrapper">
                    <p className="info__data">{w.organization}</p>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">{w.position}</p>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">
                        {`${dateFormatterForDoctorWork(w.start_date)}`}-
                        {/* {`${new Date(w.start_date * 1000).getMonth()}.${new Date(w.start_date * 1000).getFullYear()}`}- */}
                        {w.until_now ? 'по настоящее время' : `${dateFormatterForDoctorWork(w.end_date)} гг.`}
                        {/* {w.until_now ? 'по настоящее время' : `${new Date(w.end_date * 1000).getFullYear()} гг.`} */}

                    </p>
                </div>
            </DataBlockStl>
        );
    });

    return workShow;
};
