import styled from 'styled-components';

export const CodeStl = styled.form`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-self: center;
    .code {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        align-self: center;
        flex-wrap: wrap;
        > input {
            width: 44px;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #b6b7ba;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
            padding-left: 16.5px;
        }
        .fill {
            border-color: #19be6f;
        }
        .error {
            border-color: red;
        }
    }
    .button {
        .btn {
            display: inline-block;
            border-radius: 10px;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
            border: none;
            transition: opacity 0.5s linear;
            cursor: pointer;
            width: 100%;
            padding: 17px;
            background-color: #19be6f;
            color: #ffffff;
            outline: none;
            &:disabled {
                opacity: 0.5;
                cursor: default;
            }
        }
    }

    .warning {
        width: 100%;
        max-width: 206px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        > span {
            font-weight: 400;
            font-size: 12px;
            color: red;
        }
    }
    // убираем стрелки для инпута number
    .input-codeSms[type="number"] {
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
        appearance: textfield;
    }
    .input-codeSms[type="number"]::-webkit-outer-spin-button,
    .input-codeSms[type="number"]::-webkit-inner-spin-button {
        display: none;
    }
    //
`;
