import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ViewPatientStl } from './viewPatient.styled';
import { fetchPatientProfile } from '../../api/PatientProfileSlice';
import { PatientData } from './patientData/PatientData';
import { PatientParametrs } from './patientParametrs/PatientParametrs.jsx';
import { Allergy } from './allergy/Allergy.jsx';
import { Operations } from './operations/Operations.jsx';
import { Diseases } from './diseases/Diseases.jsx';
import { Medication } from './medication/Medication.jsx';
import { Loader } from '../../../lkDoctor/loader/loader.jsx';

export const ViewPatient = () => {
    const dispatch = useDispatch();
    const isLoaderProfile = useSelector((state) => state.patientProfile.isLoader);


    useEffect(() => {
        dispatch(fetchPatientProfile());
    }, []);

    if (isLoaderProfile) {
        return <Loader />;
    }

    return (
        <ViewPatientStl>
            <PatientData />
            <h2 className="title">
                Медицинские данные
                <span className="info__label">Не обязательно для заполнения</span>
            </h2>
            <PatientParametrs />
            <Allergy />
            <Operations />
            <Medication />
            <Diseases />
        </ViewPatientStl>
    );
};


