import styled from 'styled-components';

export const BlogMenuStyled = styled.div`
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        gap: 24px;
        margin-bottom: 25px;
        overflow-x: auto;
    }
    .themeLink {
        cursor: pointer;
        padding: 10px 15px 10px 15px;
        color: #212121;
        text-decoration: none;
        font-family: Rubik;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        &:hover {
            text-decoration: underline;
        }
        &.activeTheme {
            color: #19be6f;
            font-weight: 500;
            border-left: 2px solid #19be6f;
            padding-left: 13px;
        }
        @media (max-width: 768px) {
            padding: 12px 4px;
            font-weight: 400;

            &.activeTheme {
                color: #19be6f;
                font-weight: 400;
                padding: 12px 4px;
                border: none;
            }
        }
    }
`;
