import React from 'react';
import { useSelector } from 'react-redux';
import { DataBlockStl } from  '../viewPatient.styled';
import { Spinner } from '../../../../share/Spinner';
import { dateFormatterYears } from '../../../../share/helpers';

export const ViewDiseases = ({ diseases }) => {
    const isLoaderDiseases = useSelector((state) => state.patientProfile?.isLoaderDiseases);

    const diseasesShow = diseases.map((disease) => {
        return (
            <DataBlockStl key={disease.id}>
                <div className="info__wrapper">
                    <p className="info__data">{disease.title}</p>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">
                        {disease.value}
                        {/* {Number.isInteger(disease.value) ? dateFormatterYears(disease.value): disease.value} */}
                    </p>
                </div>
            </DataBlockStl>
        );
    });

    return isLoaderDiseases ? <Spinner/> : diseasesShow;
};