import styled from 'styled-components';

export const StyledOurDoctorsPage = styled.div`
    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .not_found {
        text-align: center;
        font-size: 20px;
        line-height: 26px;
        font-weight: 400;
    }
    .btns {
        text-align: right;
        span {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #19be6f;
            cursor: pointer;
            padding: 3px;
            &:first-child {
                margin-right: 42px;
            }
            &:last-child {
                svg {
                    margin-left: 4px;
                }
            }
        }
    }

    @media (max-width: 320px) {
        .not_found {
            font-size: 18px;
            line-height: 23.4px;
        }
        .doctors__list {
            margin: 0 -15px;
        }
    }
`;
