import styled from 'styled-components';

export const StyledAdvantages = styled.div`
    @media (max-width: 1040px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        & > div:nth-child(odd){
            margin-right: 100px;
        }
        & > div:nth-child(even){
            margin-left: 100px;
        }
    }
    @media (max-width: 350px) {
        gap: 12px;
        & > div:nth-child(odd){
            margin-right: 30px;
        }
        & > div:nth-child(even){
            margin-left: 30px;
        }
    }
    .about__advantages_text {
        display: block;
        margin: auto auto;
        color: #212121;
        font-weight: 400;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        @media (max-width: 780px) {
            font-size: 16px;
            line-height: 150%;
        }
    }
`;

export const StyledRectangle = styled.div`
    display: flex;
    box-sizing: border-box;
    position: absolute;
    width: 510px;
    height: 234px;
    left: ${props => props.left + 'px' || null};
    top: ${props => props.top + 'px' || null};
    background: ${props => props.background || '#E0EFF6'};
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    
    @media (max-width: 1040px) {
        position: unset;
    }

    @media (max-width: 780px) {
        width: 260px;
        height: 120px;
    }
`;