import { apiRequest } from '../../share/api';

export const AuthMe = async (phone) => {
    try {
        const response = await apiRequest.post(`authentification/auth_phone_number/`, {
            phone_number: phone,
        });
        return response;
    } catch (error) {
        console.log(error.response.data.code)
        return error.response;
    }
};

export const login = async ({ phone_number, pin, whoAreYou }) => {
    try {
        return await apiRequest.post(`authentification/login/`, {
            phone_number: phone_number,
            code: pin,
            who_am_i: whoAreYou,
        });
    } catch (error) {
        console.log(error.response.data.code)
        return error.response;
    }
};

export const checkEmailCode = async ({ email, pin }) => {
    try {
        return await apiRequest.patch(`user/me/user/update_email/`, {
            email: email,
            code_email: pin,
        });
    } catch (error) {
        console.log(error.response.data.code)
        return error.response;
    }
};
export const meUser = async () => {
    const response = await apiRequest.get('user/me/user');
    return response.data;
};

export const checkEmail = async (email) => {
    try {
        const response = await apiRequest.post(`user/me/user/get_code_email/`, {
            email: email,
        });
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};