import styled from 'styled-components';

export const FeedbackFormStl = styled.div`
    .form {
        width: 73.5%;
        display: flex;
        flex-direction: column;
    }

    .form_none {
        display: none;
    }

    .formHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .formHeader-title {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #212121;
    }

    .formHeader-symbols {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #666666;
    }

    .formHeader-symbols_error {
        color: red;
    }

    .reviewArea {
        resize: none;
        padding: 10px;
        width: 100%;
        border: 1px solid #f6f6f6;
        border-radius: 10px;
    }

    .reviewArea:focus + .formHeaderTextError {
        opacity: 1;
    }
    .formHeaderTextError {
        opacity: 0;
        margin: 0;
        min-height: 16px;
        color: #FF3636;
        font-family: Rubik;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
    }

    .rules {
        margin-top: 10px;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #666666;
    }

    .rules-link {
        color: #19be6f;
        text-decoration: none;
        transition: opacity 0.5s linear;
        cursor: pointer;
    }
    .rules-link:hover {
        opacity: 0.6;
    }

    .reviewBtn {
        margin-top: 24px;
        padding: 8px 16px;
        cursor: pointer;
        min-width: 178px;
        border: 1px solid #19be6f;
        border-radius: 10px;
        background: #19be6f;
        color: #ffffff;
        font-weight: 500;
        line-height: 20px;
        background: #19be6f;
        border-radius: 6px;
        &:hover {
            opacity: 0.6;
        }
        &:disabled {
            opacity: 0.6;
            cursor: auto;
        }
    
    }

    @media (max-width: 1000px) {
        form {
            width: 530px;
        }
    }

    @media (max-width: 768px) {
        margin-top: 32px;

        form {
            width: 100%;
        }

        .formHeader {
            display: none;
        }

        .reviewArea {
            width: 100%;
            max-height: 104px;
            border: 1px solid #b6b7ba;
        }

        .rules {
            display: none;
        }


    }
    // @media(max-width: 550px) {
    //   display: none;
    // }
`;
