import React from 'react';
import { dateFormatterYears } from '../../../../share/helpers';
import { PatientMedicalData } from './patientPageMedicalData.styled';
import { useSelector } from 'react-redux';

import { Spinner } from '../../../../share/Spinner';

export default function PatientPageMedicalData() {

    const patientFull = useSelector((state) => state.doctorPatients.patientFull);
    const isLoading = useSelector((state) => state.doctorPatients.isLoading);

    if (isLoading) return <Spinner />;

    return (
        <PatientMedicalData>
            {(patientFull.height || patientFull.weight || patientFull.blood_type) ?
                <li className="patient__medicalDataPoint">
                    <h3 className="patient__medicalDataHeading">Параметры</h3>
                    <ul className="patient__medicalDataSublistFirst">
                        {patientFull.height ? 
                            <li className="patient__medicalDataSubpoint">
                                <h4 className="patient__medicalDataSubtitle">Рост, см</h4>
                                <p className="patient__medicalDataText">{patientFull.height}</p>  
                            </li> : null
                        }
                        {patientFull.weight ? 
                            <li className="patient__medicalDataSubpoint">
                                <h4 className="patient__medicalDataSubtitle">Вес, кг</h4>
                                <p className="patient__medicalDataText">{patientFull.weight}</p>  
                            </li> : null
                        }
                        {patientFull.blood_type ? 
                            <li className="patient__medicalDataSubpoint">
                                <h4 className="patient__medicalDataSubtitle">Группа крови</h4>
                                <p className="patient__medicalDataText">{patientFull.blood_type}</p>  
                            </li> : null
                        }
                        
                    </ul>
                </li> : null
            }
            {patientFull.allergies?.length > 0 ?
                <li className="patient__medicalDataPoint">
                    <h3 className="patient__medicalDataHeading">Аллергии</h3>
                    <ul className="patient__medicalDataSublist">
                        {patientFull.allergies.map((allergie) => 
                            <li key={allergie.id} className="patient__medicalDataText">
                                <p>{allergie.title}</p>
                                <p>{allergie.value}</p>
                            </li>
                        )}
                    </ul>
                </li> : null
            }
            {patientFull.operations?.length > 0 ? 
                <li className="patient__medicalDataPoint">
                    <h3 className="patient__medicalDataHeading">Перенесенные операции</h3>
                    <ul className="patient__medicalDataSublist">
                        {patientFull.operations.map((operation) => 
                            <li key={operation.id} className="patient__medicalDataText">
                                <p>{operation.description}</p>
                                <p>{dateFormatterYears(operation.date)}</p>
                            </li>
                        )}
                    </ul>
                </li> : null
            }
            {patientFull.medicines?.length > 0 ?
                <li className="patient__medicalDataPoint">
                    <h3 className="patient__medicalDataHeading">Прием препаратов</h3>
                    <ul className="patient__medicalDataSublist">
                        {patientFull.medicines.map((medicine) => 
                            <li key={medicine.id} className="patient__medicalDataText">
                                <p>{medicine.title}</p>
                                <p>{medicine.dosage}, {medicine.frequency}</p>
                            </li>
                        )}
                    </ul>
                </li> : null
            }
            {patientFull.diseases?.length > 0 ?
                <li className="patient__medicalDataPoint">
                    <h3 className="patient__medicalDataHeading">Хронические заболевания</h3>
                    <ul className="patient__medicalDataSublist">
                        {patientFull.diseases.map((disease) => 
                            <li key={disease.id} className="patient__medicalDataText">
                                <p>{disease.title}</p>
                                <p>{disease.value}</p>
                            </li>
                        )}
                    </ul>
                </li> : null
            }
        </PatientMedicalData>
    );
}
