import React from 'react';
import Container from '../share/Container';
import { useSelector } from 'react-redux';
import DoctorsCard from '../doctorsList/doctorsCard/DoctorsCard';
import styled from 'styled-components';


const SearchDoctorsListStl = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

`;

const SearchDoctorsList = () => {

  const { doctors } = useSelector(state => state.mainSearch)

  const doctorCards = doctors.length > 0 ? doctors.map((doctor) => {
    return (
      <DoctorsCard
        key={doctor.id}
        name={doctor.name}
        patronymic={doctor.patronymic}
        lastname={doctor.lastname}
        speciality={doctor.speciality}
        avatar={doctor.avatar}
        liked={doctor.liked}
        rating={doctor.rating}
        id={doctor.id}
        specialization={doctor.specialization}
        price={doctor.video_chat_price}
        slug={doctor.slug}
      />)
  }) : null
  return (
    <Container>
      <SearchDoctorsListStl>
        {doctorCards}
      </SearchDoctorsListStl>
    </Container>
  );
};

export default SearchDoctorsList;