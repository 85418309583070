import styled from 'styled-components';
import loup from '../../../img/icons/loup.svg';

export const StyledLkPatientFavorites = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;

	.input_sort {
		color: red;
	}
	input {
		color: pink;
	}

	.inputSearch {
        position: relative;
		> label {
			max-width: 422px;
        }
        > label input {
            background-color: #f4f4f4;
            padding-left: 55px;
            padding-top: 13px;
            color: #b6b7ba;
            border-radius: 10px;
            border: none;
        }
		/* > label input:not(:focus) {
            border: 1px solid #f4f4f4;
        } */
        > svg {
            position: absolute;
            left: 17px;
            top: 12px;
        }
		> label svg {
            top: 11px;
        }
    }

	@media (max-width: 768px) {
	    .input_search {
			margin-top: 10px;
		}
	}
`;
