import styled from 'styled-components';
import checkboxAutorization from '../../../img/icons/autorization/checkboxAutorization.svg';

export const LabelAgreementStl = styled.label`
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    > input {
        width: 22px;
        height: 22px;
        margin-right: 10px;
    }
    .agreement {
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: #212121;
        max-width: 300px;

        @media(max-width: 350px) {
            font-size: 12px;
          }

          a {
            color: #19BE6F;
            text-decoration: none;
            cursor: pointer;
        }
    }
    .custom-checkbox {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        border: 2px solid #999;
        border-radius: 2px;
        outline: none;
        transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
        cursor: pointer;
    }
    .custom-checkbox:checked {
        border: none;
        background-color: #19BE6F;
        background-image: url(${checkboxAutorization});
        background-size: contain;
        background-repeat: no-repeat;
   }

   .custom-checkbox:hover {
        border-color: #19BE6F;
   }
`;
