import React from 'react';
import { useSelector } from 'react-redux';
import { ViewParametrs } from './ViewParametrs';
import { useState } from 'react';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { EditParametrs } from './EditParametrs';
import { PatientParametrsStl } from './patientParametrs.styled';
// import { ModerationLabel } from '../../../../share/ModerationLabel';

export const PatientParametrs = () => {
    const [editData, toggleEditData] = useState(false);
    const patientProfile = useSelector((state) => state.patientProfile?.patientProfile);

    // const { user, is_moderated } = useSelector((state) => state.doctorProfile?.doctorProfile);

    return (
        <PatientParametrsStl>
            <TitleWithPencil toggle={toggleEditData} title={'Параметры'} />
            {/* <ModerationLabel moderation={is_moderated}/> */}
            {editData ? (
                <EditParametrs patientProfile={patientProfile} toggle={toggleEditData} />
            ) : (
                <ViewParametrs patientProfile={patientProfile} />
            )}
        </PatientParametrsStl>
    );
};
