import { OnlineConsultationStl } from './onlineConsultation.styled';
import { React } from 'react';
import { Button } from '../Button';
import { useDispatch } from 'react-redux';
import { dateFormatterFullMnth3, getTimeFromDate } from '../../../share/helpers';
import { fetchCreatePayment } from '../../../lkPatient/api/paymentsSlice';

export const OnlineConsultation = ({ consultation, handleCancelClick }) => {
    const dispatch = useDispatch();
    const {
        id,
        time,
        doctor: {
            last_name,
            first_name,
            patronymic,
            specialization,
            video_chat_price },
    } = consultation

    const specializations = specialization.map(spec => {
        return <li key={spec.id}>{spec.name}</li>
    })

    const handleConfirumClick = () => {
        dispatch(fetchCreatePayment(id));
    };

    return (
        <OnlineConsultationStl>
            <div className="consultation">
                <img src="/img/V_telemedicine_patient.svg" alt="header logo" className="consultation__icon" />
                <h3 className="consultation__title">Онлайн-консультация</h3>
                <div className="consultation__content">
                    <div className="consultation__doctor">
                        <div className="consultation__doctor-name">
                            {last_name} {first_name} {patronymic}
                        </div>
                        <ul className="consultation__doctor-skill">
                            {specializations}
                        </ul>
                    </div>
                    <div className="consultation__details">
                        <div className="details">
                            <span className="details__cell">
                                <img src="/img/icons/calendar.png" alt="calendar" />
                                <span>{dateFormatterFullMnth3(time)}</span>
                            </span>
                            <span className="details__cell">
                                <img src="/img/icons/clock.png" alt="clock" />
                                <span>{getTimeFromDate(consultation.time)}</span>
                            </span>
                        </div>
                        <div className="details">
                            <span className="details__cell">
                                <img src="/img/icons/hourglass-03.png" alt="hourglass" />
                                <span>30 мин</span>
                            </span>
                            <span className="details__cell">
                                <img src="/img/icons/currency-rubel.png" alt="currency-rubel" />
                                <span>{video_chat_price}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <Button className="consultation__button" onClick={handleConfirumClick}>
                    Оплатить
                </Button>
            </div>
        </OnlineConsultationStl>
    );
};
