import styled from 'styled-components';

export const StyledLkDoctorProfile = styled.div`
    width: 100%;
    max-width: 600px;
    display: flex;
    padding: 0 0 108px;
    gap: 21px;
    row-gap: 40px;
    @media(max-width: 500px) {
        flex-direction: column;
    }
    .avatar {
        display: flex;
        flex-direction: column;
    }
    .deleteAvatarButton {
        width: 156px;
        margin-top: 8px;
        border: none;
        background-color: transparent;
        color: #666666;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        transition: opacity 0.5s linear;
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }
    .errorLoadAvatar {
        width: 156px;
        margin-top: 8px;
        color: #FF3636;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
    }
    .data {
        display: flex;
        flex-grow: 1;
    }
`;

export const StyledUserLogo = styled.div`
    position: relative;
    height: 156px;
    width: 156px;
    cursor: pointer;
    input {
        display: none;
    }
    > img {
        position: absolute;
        top: 0;
        object-fit: cover;
    }
    &:hover div > img {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
`;
