const build = process.env.NODE_ENV === 'production';

// Почему то не работает если дергать с ENV
// export const BASE_URL = process.env['REACT_APP_URL ']
// Временно используем BASE URL как основной

// ВЕРСИЯ АПИ ДЛЯ СБОРКИ НА ТЕСТ
// export const BASE_URL = 'https://dj-front.doct24.com/api/v1/';

// ВЕРСИЯ АПИ ДЛЯ СБОРКИ НА ПРОД
export const BASE_URL = 'https://api-prod.doct24.com/api/v1/';

// для сокет-соединения
export const SOCKET_HOST = 'dj-front.doct24.com';
//СОКЕТ ДЛЯ ПРОДУКТОВОЙ ВЕРСИИ
// export const SOCKET_HOST = 'api-prod.doct24.com';
