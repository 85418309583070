import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import star from '../../../../img/icons/star.png';
import { StyledCard } from './favoriteDoctorCard.styled';
import { useDispatch } from "react-redux";

import { fetchRemoveFavoriteDoctorUid, fetchFavoriteDoctors, removeCardFavoriteDoctor } from '../../api/favoriteDoctorsSlice';
import { Spinner } from '../../../share/Spinner';
import { getSeniorityWithEnd } from '../../../share/helpers';

const FavoriteDoctorCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPreloaderLike, setIsPreloaderLike] = useState(false);

  function showDoctor() {
    navigate(`/doctors/${props.id}/${props.slug}`)
  };

  function handleLike() {
    setIsPreloaderLike(true);
    dispatch(fetchRemoveFavoriteDoctorUid(props.topDoctorsId))
      .then(()=>
        dispatch(fetchFavoriteDoctors([props.search]))
          .then(()=>dispatch(removeCardFavoriteDoctor(props.topDoctorsId)))
      )
      .then(()=>{
        setIsPreloaderLike(false);
      })
  };

  return (
    <StyledCard avatar={props.avatar} isFavorite={props.isFavorite}>
      <div className="card__img"></div>
      <div className="card__content">
        <div className="card__nameLike">
          <p className="card__name">{props.lastname} {props.name} {props.patronymic}</p>
            {
              (isPreloaderLike) ?
                <Spinner marginTop={'0px'} width={'20px'} height={'20px'} /> :
                <button className="card__like" onClick={handleLike}></button>
            }
        </div>
        <p className="card__specialization">{props.specialization}</p>
        <div>
          <p className="card__experience">Стаж {getSeniorityWithEnd(props.seniority)}
           {props.rating > 0 &&
              <span className="card__rating">
                <img className="card__ratingImg" src={star} alt="rating" />
                {props.rating}
              </span>
            }
          </p>
          <p className="card__price">от {props.price}  &#8381;</p>
          <button className="card__button" onClick={showDoctor}>Записаться</button>
        </div>
      </div>
    </StyledCard>
  );
};

export default FavoriteDoctorCard;
