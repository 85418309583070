import React from 'react';
import SearchDoctorsList from '../../componets/searchDoctors/SearchDoctorsList';
import Container from '../../componets/share/Container';
import {MainHeader} from '../../componets/mainHeader/mainHeader';

const SearchDoctors = () => {
  return (
      <Container>
        <MainHeader title={'Результаты поиска'}/>
        <SearchDoctorsList/>
      </Container>
  )
};

export default SearchDoctors;


