import React from 'react';
import { AttentionStl } from './attention.styled';

export const Attention = () => {
  return (
    <AttentionStl>
      Если до начала консультации осталось менее 24 часов и вы хотите отменить запись — с вас будет удержана компенсация в размере 1/3 от стоимости несостоявшейся консультации.
    </AttentionStl>

  )
};
