import styled from 'styled-components';

export const ConsultationButtonsStl = styled.div`
    font-size: 14px;
    margin-top: ${(props) => props.pathname && '12px'};
    display: flex;
    gap: 12px;
    button {
        padding: 8px 16px;
        cursor: pointer;
        min-width:  ${(props) => props.widthCard ? '100px' : '206px'};
        /* width: 100px; */
        height: 36px;
        @media (max-width: 500px) {
            min-width: 100px;
            flex: 1;
        }
    }
    
    .startBtn {
        border: 1px solid #19be6f;
        border-radius: 10px;
        background: #19be6f;
        color: #ffffff;
        font-weight: 500;
        line-height: 20px;
        background: #19be6f;
        border-radius: 6px;
    }

    .closeBtn {
        color: #19be6f;
        background: #ffffff;
        font-weight: 500;
        line-height: 20px;
        border: 2px solid #19be6f;
        border-radius: 6px;
    }

    .more {
        align-items: center;
        display: flex;
        color: #19be6f;
        background: #ffffff;
        border: none;
        text-decoration: none;
        align-self: center;
        text-align: center;
        @media (min-width: 768px) {
            display: none;
        }
    }
    @media (max-width: 500px) {
        grid-column: span 2 !important;
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;

        .more {
            order: -1;
            width: 100%;
            padding-left: 0;
        }
        button {
            flex: 1;
        }
    }
`;
