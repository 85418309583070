import React from 'react';
import { WhoAreYouStl } from './whoAreYou.styled';
import RadioButton from '../../share/RadioButton';

export const WhoAreYou = ({ who, setWho }) => {

    const handleChangeWho = (e) => {
        setWho(e.target.id)
    }

    return (
        <WhoAreYouStl>
            <h5>Кто вы</h5>
            <RadioButton
                label={'Пациент'}
                id={'patient'}
                name={'who'}
                isChecked={who === 'patient'}
                onChange={handleChangeWho}
            />
            <RadioButton
                label={'Врач'}
                id={'doctor'}
                name={'who'}
                isChecked={who === 'doctor'}
                onChange={handleChangeWho}
            />
        </WhoAreYouStl>
    )
}