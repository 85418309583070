import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FlipSwitch from './flipSwitch';
import { PatientSettingsDiv } from './lkPatientSettings.styled';
import { Modal } from '../../share/Modal';
import PopUpConfirm from '../../share/PopUpConfirm';
import { fetchLogout } from '../../authentification/api/authentificationSlice';

const LkPatientSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [popUpExitProfile, setPopUpExitProfile] = useState(false);
    const [popUpDeleteProfile, setPopUpDeleteProfile] = useState(false);

    const handleLogOut = () => {;
        dispatch(fetchLogout());
        navigate('/');
    };

    const deleteProfile = () => { console.log("delete") };

    return (
        <PatientSettingsDiv>
            <h2 className='settings-header'>Управление личным кабинетом</h2>
            <div className='settings-item_wrapper'>
                <div className='switcher-box'>
                    <p className='settings__item'>Сохранить данные для входа в личный кабинет</p><FlipSwitch />
                </div>
                <div className='switcher-box'>
                    <p className='settings__item'>Данные о местоположении</p><FlipSwitch />
                </div>
            </div>
            <div className='divider' />
            <div className='settings-item_wrapper'>
                <p className='settings__item'><label onClick={() => setPopUpExitProfile(true)} className='settings__item-label'>Выйти</label> из личного кабинета.</p>
                {/* <p className='settings__item'>Вы можете <label onClick={() => setPopUpDeleteProfile(true)} className='settings__item-label'>удалить</label> свой личный кабинет.</p> */}
            </div>
            {popUpExitProfile && (
                <Modal
                    toggle={setPopUpExitProfile}>
                    <PopUpConfirm
                        onClose={() => setPopUpExitProfile(false)}
                        onConfirm={() => handleLogOut()}
                        contentHeader={
                            <span>
                                Вы уверены, что хотите <br /> выйти из личного кабинета?
                            </span>}
                        isClarification={false}
                    />
                </Modal>
            )
            }
            {popUpDeleteProfile && (
                <Modal
                    toggle={setPopUpDeleteProfile}>
                    <PopUpConfirm
                        onConfirm={deleteProfile}
                        onClose={() => setPopUpDeleteProfile(false)}
                        contentHeader={
                            <span>
                                Вы уверены, что хотите <br /> удалить личный кабинет?
                            </span>}
                        isClarification={false}
                    />
                </Modal>
            )
            }
        </PatientSettingsDiv>
    );
};

export default LkPatientSettings;