import React, { useState, useEffect } from 'react';
import { AuthenticationStl } from './authentication.styled';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../componets/share/Container';
import { WhoAreYou } from '../../componets/authentification/whoAraYou/WhoAreYou';
import { Phone } from '../../componets/authentification/phone/Phone';
import { Email } from '../../componets/authentification/email/Email';
import { InfoBlock } from '../../componets/authentification/infoBlock/InfoBlock';
import { Code } from '../../componets/authentification/code/Code';
import { setWhoAreYou, clearMessages, fetchMeUser } from '../../componets/authentification/api/authentificationSlice';
import { useNavigate } from 'react-router-dom';
import { ErrorBlock } from '../../componets/authentification/errorBlock/ErrorBlock';

export const Authentication = () => {
    const [who, setWho] = useState('');
    const [code, setCode] = useState({ first: '', second: '', third: '', fourth: '' });
    const { isAuth, phone_number, messagePageCode, isEmail, isEmailCheck, messagesError, headingError } = useSelector((state) => state.authentification);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(clearMessages());
    }, []);
    useEffect(() => {
        dispatch(setWhoAreYou(who));
        return () => {
            if (!isAuth) {
                dispatch(setWhoAreYou(''));
            }
        };
    }, [who, isAuth, dispatch]);

    useEffect(() => {
        // (isAuth && isEmailCheck) && dispatch(fetchMeUser());
        if (isAuth) {
            navigate(-1);
        }
    }, [isAuth]);

    useEffect(() => {
        setCode({ first: '', second: '', third: '', fourth: '' });
    }, [messagesError, messagePageCode]);

    const renderPageAutorization = () => {
        // let pageAutorization = <></>
        if (messagesError) {
            return <ErrorBlock
                heading={headingError}
                messages={messagesError}
                textButton={'Закрыть'}
                setCode={setCode}
            />
        } else {
            if (!phone_number) {
                return <>
                    <h2 className="auth__header">Вход / Регистрация</h2>
                    <WhoAreYou who={who} setWho={setWho} />
                    <Phone who={who} />
                </>

            } else {
                if (isEmail) {
                    return <>
                        <h2 className="auth__header">Вход / Регистрация</h2>
                        <InfoBlock />
                        <Code code={code} setCode={setCode} />
                    </>
                } else {
                    // if () {}
                    return <>
                        <h2 className="auth__header">Вход / Регистрация</h2>
                        <p className="auth__text">Для завершения регистрации необходимо ввести и подтвердить Email</p>
                        <Email who={who}/>
                    </>
                }
            }
        }
        // return pageAutorization
    }
    // useEffect(()=>{
    //     renderPageAutorization();
    // },[messagesError, phone_number])

    if (isAuth) return null;

    return (
        <AuthenticationStl>
            <Container>
                <div className="auth">
                    <div className="auth__wrapper">
                        {renderPageAutorization()}
                    </div>
                </div>
            </Container>
        </AuthenticationStl>
    );
};
