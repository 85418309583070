import React from 'react';
import styled from 'styled-components';

export const HintStl = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;

    .day {
        div {
            color: white;
            width: 40px;
            height: 40px;
            background-color: #19be6f;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .appointment {
        position: relative;

        :after {
            position: absolute;
            content: '';
            width: 4px;
            height: 4px;
            background-color: #fff;
            bottom: 4px;
            left: calc(50% - 2px);
            border-radius: 50%;
        }
    }

    .time {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 26px;
        background-color: #19be6f;
        color: #fff;
        border-radius: 52px;

        :after {
            bottom: 2px;
        }
    }

    @media (max-width: 767px) {
        .text {
            font-size: 12px;
        }
    }

    @media (max-width: 352px) {
        max-width: 136px;

        .day {
            div {
                height: 28px;
                width: 28px;
                font-size: 10px;
            }
        }

        .time {
            height: 18px;
            width: 38px;
            font-size: 10px;
        }

        .appointment:after {
            height: 3px;
            width: 3px;
            left: calc(50% - 1px);
        }
    }
`;

export const DateHint = () => {
    return (
        <HintStl>
            <div className="day">
                <div>1</div>
            </div>
            <span className="text">расписание создано</span>
        </HintStl>
    );
};
export const DateHintAppointment = () => {
    return (
        <HintStl>
            <div className="day appointment">
                <div>1</div>
            </div>
            <span className="text">есть запись</span>
        </HintStl>
    );
};

export const TimeHint = () => {
    return (
        <HintStl>
            <span className="time">1:00</span>
            <span className="text">рабочее время</span>
        </HintStl>
    );
};
export const TimeHintAppointment = () => {
    return (
        <HintStl>
            <span className="time appointment">1:00</span>
            <span className="text">есть запись</span>
        </HintStl>
    );
};
