import React, { memo, useEffect, useState } from 'react';
import { SimilarPostsStyled } from './similarPosts.styled';
import { FooterButtons } from '../../footerButtons/FooterButtons';
import { RowArticle } from '../../blogList/rowArticle/RowArticle';
import { Spinner } from '../../../share/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchPostsByTheme } from '../../api/blogSlice';

const SimilarPosts = () => {
    const dispatch = useDispatch();
    const { themeId, postId } = useParams();
    const [isDefaultCountSimilarPosts, setIsDefaultCountSimilarPosts] = useState(true);
    const [isLoadingPosts, setIsLoadingPosts] = useState(false);
    const { posts, isLoading, articles } = useSelector((state) => state.blog);
    const renderSimilarPosts = posts[themeId]?.postsByTheme
        .filter((post) => post.id !== +postId)
        ?.map((post) => <RowArticle key={post.id} props={post} />);

    useEffect(() => {
        if (!posts[themeId] && articles.some((article) => article.id === +postId)) {
            dispatch(fetchPostsByTheme({ themeId: themeId, getMorePosts: false }));
        }
    }, [posts]);

    useEffect(() => {
        if (!isLoading) {
            setIsLoadingPosts(false);
        }
    }, [isLoading]);

    return (
        <SimilarPostsStyled>
            <div className="similarPosts">
                {isDefaultCountSimilarPosts ? renderSimilarPosts?.slice(0, 2) : renderSimilarPosts}
            </div>
            {isLoadingPosts && (
                <div className="centerSpinner">
                    <Spinner />
                </div>
            )}
            <FooterButtons
                isDefaultCountSimilarPosts={isDefaultCountSimilarPosts}
                setIsDefaultCountSimilarPosts={setIsDefaultCountSimilarPosts}
                setIsLoadingPosts={setIsLoadingPosts}
            />
        </SimilarPostsStyled>
    );
};
export default memo(SimilarPosts);
