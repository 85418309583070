import React from 'react';
import {StyledLoader} from './loader.styled';

export const Loader = () => {
  return (
      <StyledLoader>
        <div className="loader"></div>
      </StyledLoader>
  );
};

