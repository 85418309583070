import styled from 'styled-components';

export const StyledPaymentPopUp = styled.section`
	box-sizing: border-box;
	padding: 50px;
	width: 400px;
	height:360px;
	border-radius: 15px;
	background-image: url('/img/payments/popUpBackground.png');;
	background-size: cover;
    position: absolute;
    top: 45%;
    right: 50%;
    transform: translate(50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.textAttention {
		font-weight: 500;
		font-size: 28px;
		color: #19BE6F;
	}

	.textRetry {
		display: block;
		margin-top: 16px;
		font-weight: 400;
		font-size: 16px;
		line-height: 40px;
		color: #062A3F;
	}
`;

export const StyledLink = styled.div`
	background-color: green;
	width:307px;
	height:54px;
	border-radius: 15px;
	display: flex;
    justify-content: center;
    align-items: center;
	margin-top: 48px;
	a {
		text-decoration:none;
		color: white;
		display: block;
	}
`
export const StyledLoader = styled.div`
	display: inline-block;
	width: 80px;
	height: 80px;
  :after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid green;
	border-color: green transparent green transparent;
	animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  `