import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ViewData } from './ViewData';
import { useState } from 'react';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { EditData } from './EditData';
import { SectionStl } from '../viewDoctor.styled';
import { ModerationLabel } from '../../../../share/ModerationLabel';

export const DoctorData = () => {
    const [editData, toggleEditData] = useState(false);
    const { user, is_moderated } = useSelector((state) => state.doctorProfile?.doctorProfile);

    return (
        <SectionStl>
            <TitleWithPencil toggle={toggleEditData} title={'Личные данные'} />
            <ModerationLabel moderation={is_moderated}/>
            {editData ? (
                
                <EditData user={user} toggle={toggleEditData} />
            ) : (
                <ViewData user={user} />
            )}
        </SectionStl>
    );
};
