import styled from 'styled-components';

export const PatientParametrsStl = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    .personalData {
        display: grid;
        grid-template-columns: 30% 30% 30%;
        gap: 20px;
        /* white-space: nowrap; */
    }
    .btn {
        margin-top: 60px;
        text-align: center;
        display: flex;
        width: 100%;
        padding-right: 120px;
    }

    .btnLink {
        display: block;
        width: 100%;
        background-color: #19be6f;
        text-decoration: none;
        border-radius: 10px;
        padding: 17px 30px;
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
    }
    .btn__recive {
        margin-top: 10px;
        padding: 10px 20px;
        border-radius: 10px;
        border: 2px solid #19be6f;
        font-weight: 500;
        font-size: 16px;
        line-height: 18.96px;
        color: #19be6f;
        background-color: #fff;
        transition: opacity 0.3s linear;
        width: 126px;
        height: 39px;
        cursor: pointer;
        :hover {
            opacity: 0.7;
        }
        &-disabled {
            text-align: center;
        }
        &:disabled {
            opacity: 0.4;
            cursor: default; 
        }
    }
`;

export const DataBlockStl = styled.div`
    display: flex;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    align-items: flex-start;
    gap: 16px;

    form {
        width: 100%;
    }

    svg {
        cursor: pointer;
    }

    .info__wrapper {
        width: 90px;
        display: flex;
        flex-direction: column;
        @media (max-width: 600px) {
            width: 80px;
        }
    }

    .info__label {
        font-size: 14px;
        line-height: 150%;
        color: #666;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }

    .info__data {
        font-size: 16px;
        line-height: 150%;
        color: #212121;
        @media (max-width: 600px) {
            font-size: 14px;
        }
    }
`;

export const SelectStl = styled.label`
    select {
        /* display: flex;
        flex-wrap: wrap;
        gap: 8px; */
        /* max-width: 422px;
        width: 100%; */
        border: 1px solid var(--secondary-grey);
        border-radius: 8px;
        padding: 10px 0px 10px 8px;
        font-size: 16px;
        margin-bottom: 3px;
        /* > option {
            border-radius: 10px;
        } */
    }
    .rs__value-container {
        padding: 10px 0 10px 8px;
    }
    .rs__single-value {
        height: 21px;
        margin: 0;
        font-size: 16px;
        font-style: normal;
        line-height: 130%;
    }
    .rs__indicators {
        padding: 0;
        /* width: 24px; */
        /* height: 24px; */
        padding: 0 8px 0 0;
    }

    .rs__indicator {
        width: 24px;
        height: 24px;
        padding: 10px 8px 10px 20px;
        padding: 0;
    }
`;