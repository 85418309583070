import styled from 'styled-components';

export const StyledMainHeader = styled.div`
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 130%;
    color: #212121;
    margin-top: 50px;
    margin-bottom: 60px;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 36px;
      line-height: 120%;
    }
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 30px;
      line-height: 130%;
    }
  }

  @media (max-width: 420px) {
    h1 {
      font-size: 22px;
    }
  }
`


