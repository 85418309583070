import React, { useState, memo } from 'react';
import styled from 'styled-components';

export const SearchResultsStyled = styled.div`
    border: 1px solid #b6b7ba;
    padding-right: 20px;
    border-radius: 10px;
    margin-top: 4px;
    margin-bottom: 15px;
    .wrapper {
        max-height: 132px;
        overflow-y: auto;
        /* Добавляем отрицательный margin-right, чтобы отодвинуть полосу прокрутки от правого края блока на 13px */
        /* margin-right: 13px; */
        /* Добавляем стили для скроллбара */
        ::-webkit-scrollbar {
            width: 4px; /* Ширина скроллбара */
            margin-right: 13px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #19be6f; /* Цвет самого скроллбара */
            border-radius: 17px; /* Радиус скроллбара */
            /* Добавляем паддинг слева и отрицательный margin-right, чтобы отодвинуть полосу прокрутки от края блока на 13px */
            padding-left: 13px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background-color: #555; /* Цвет скроллбара при наведении */
        }

        /* Добавляем паддинг для скроллбара */
        ::-webkit-scrollbar-track {
            background-color: #f1f1f1; /* Цвет паддинга скроллбара */
            /* Расстояние от паддинга до контента */
            /* padding-left: 10px; */
        }

        .result-item {
            padding: 12px 20px;
            margin-right: 20px;
            transition: all 0.2s;

            &:hover {
                background-color: #f4f4f4;
                cursor: pointer;
            }
        }
    }
`;

const SearchResultsInputEducation = ({ searchResults, onItemClick }) => {
    const handleClick = (result) => {
        onItemClick(result); // Вызываем функцию обратного вызова и передаем ей значение элемента списка
    };
    return (
        <SearchResultsStyled>
            <div className="wrapper">
                {searchResults.map((result, i) => (
                    <p
                        className="result-item"
                        style={{ borderRadius: 10 }}
                        onClick={() => handleClick(result.name)}
                        key={i}
                    >
                        {result.name}
                    </p>
                ))}
            </div>
        </SearchResultsStyled>
    );
};

export default SearchResultsInputEducation;
