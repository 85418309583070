import styled from 'styled-components';

export const SelectStl = styled.div`
    margin-bottom: 3px;
    white-space: nowrap;

    .rs {
        &__control {
            border-radius: 8px;
            box-shadow: none;
            --arrow-color: #212121;
            &--is-focused {
                --arrow-color: #19be6f;
            }
            &:hover {
                --arrow-color: #169056;
                border-color: #169056;
            }
        }
        &__value-container {
            padding: 11px 14px 12px ${(props) => (props.narrow ? 14 : 19)}px;
        }
        &__placeholder {
            text-align: left;
            margin-left: 0;
            color: #b6b7ba;
        }
        &__single-value {
            text-align: left;
            text-overflow: ellipsis;
            margin-left: 0;
        }
        &__indicators {
            padding-right: ${(props) => (props.narrow ? 0 : 11)}px;
        }
        &__indicator-separator {
            display: none;
        }
        &__dropdown-indicator {
            /* padding: ${(props) => (props.narrow ? '8px 5px' : '8px')}; */
            path {
                fill: var(--arrow-color);
            }
        }
        &__clear-indicator:hover {
            path {
                stroke: #19be6f;
            }
        }
        &__input-container {
            margin: 0;
            padding: 0;
        }
        &__menu {
            border-radius: 8px;
            overflow: hidden;
            box-shadow: none;
            border: 1px solid #b6b7ba;
        }
        &__menu-list {
            padding: 0;
            max-height: ${(props) => (props.radioButton ? 'none' : '175px')};
            &::-webkit-scrollbar {
                width: ${(props) => (props.narrow ? 36 : 30)}px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #19be6f;
                border-width: 10px ${(props) => (props.narrow ? 16 : 13)}px;
                border-radius: 30px;
                border-color: white;
                border-style: solid;
                height: 40px;
            }
        }
        &__option {
            text-align: left;
            padding-top: ${(props) => (props.radioButton ? 9 : 12)}px;
            padding-right: ${(props) => (props.radioButton ? 65 : 20)}px;
            padding-bottom: 11px;
            padding-left: ${(props) => (props.narrow ? 14 : 19)}px;
            background-color: transparent;
            color: #282828;
            line-height: 130%;
            &:active {
                background-color: transparent;
            }
            &:hover {
                background-color: #f4f4f4;
            }
            &--is-selected {
                background-color: #f4f4f4;
            }
            &_radbtn {
                display: flex;
                align-items: center;
                .radio {
                    &-dot {
                        fill: none;
                    }
                    &-outline {
                        stroke: #b6b7ba;
                    }
                }
                &.rs__option--is-selected .radio {
                    &-dot {
                        fill: #19be6f;
                    }
                    &-outline {
                        stroke: #19be6f;
                    }
                }
            }
            &-btn {
                width: 24px;
                height: 24px;
                margin-right: 8px;
                align-self: flex-start;
            }
        }
    }
    
    @media(max-width: 768px) {
        .rs__placeholder {
            font-size: 14px;
        }
    }
`;
