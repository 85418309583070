import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    consultationUpdate,
    consultationDelete,
    consultations,
    setClosePatientConsultation,
    getConsultation,
    getFeedback,
} from './patientApi';

export const fetchGetFutureConsultations = createAsyncThunk(
    'consultations/fetchFutureConsultations',
    async (_, { getState }) => {
        const next = getState().patientConsultations.patientConsultations.future.next;
        if (next) {
            const response = await consultations({ next });
            return response.data;
        }
        const response = await consultations({});

        return response.data;
    }
);

export const fetchGetPastConsultations = createAsyncThunk(
    'consultations/fetchPastConsultations',
    async (_, { getState }) => {
        const next = getState().patientConsultations.patientConsultations.past.next;
        if (next) {
            const response = await consultations({ status: '1,2,9', date: '-date', next });
            return response.data;
        }
        const response = await consultations({ status: '1,2,9', date: '-date' });

        return response.data;
    }
);

export const cancelPatientConsultation = createAsyncThunk('consultations/cancelPatientConsultation', async (id) => {
    const response = await setClosePatientConsultation(id);
    return response.data;
});

export const getPatientConsultation = createAsyncThunk(
    'consultations/getPatientConsultation',
    async (consultationId) => {
        const response = await getConsultation(consultationId);
        return response.data.data.consultation;
    }
);

export const fetchMyConsultationFullDelete = createAsyncThunk(
    'consultations/fetchMyConsultationFullDelete',
    async (id) => {
        try {
            const response = await consultationDelete(id);
            console.log(response.status, 'delStatus');
        } catch (err) {
            console.log(err, 'errDelConsult');
        }
    }
);

export const fetchMyConsultationDelete = createAsyncThunk('consultation/fetchConsultationDelete', async (data) => {
    const response = await consultationUpdate(data.id, {
        doctor_id: data.doctor.id,
        request: data.request,
        recommendation: data.recommendation,
        price: data.price,
        is_payed: data.is_payed,
        status: 2,
        date: data.date,
    });
    return response;
});

export const fetchMyConsultationUpdate = createAsyncThunk('consultation/fetchConsultationUpdate', async (data) => {
    const response = await consultationUpdate(data.id, {
        request: data.request,
    });
    return response;
});

export const fetchFeedback = createAsyncThunk('consultation/feedback', async (id) => {
    const response = await getFeedback(id);
    return response.data;
});

const onSortConsultsByYear = ({ created_at }, idx, arr, year) => {
    return new Date(+created_at * 1000).getFullYear() === year;
};

const initialState = {
    patientConsultations: {
        future: {
            // статус 0
            next: null,
            consultations: [],
        },
        past: {
            // статус 1, 2, 9
            next: null,
            consultations: [],
        },
    },
    currentConsultation: null,
    keyConsultationPatient: null,
    error: null,
    isLoading: false,
    isModalSuccessfulConsultation: false,
    timeConsultation: 0,
};

const patientConsultations = createSlice({
    name: 'patientConsultations',
    initialState,
    reducers: {
        removeConsultation: (state, action) => {
            const { typeConsult, id } = action.payload;
            state.patientConsultations[typeConsult].consultations = state.patientConsultations[
                typeConsult
            ].consultations.filter((item) => item.id !== id);
        },
        defineIdActiveConsult: (state, action) => {
            state.idActiveConsult = action.payload;
        },
        setDoctor: (state, action) => {
            const doctor = action.payload;
            if (state.currentConsultation) {
                state.currentConsultation.doctor = doctor;
            } else {
                state.patientConsultations.future.consultations.forEach((item) => {
                    if (item.doctor.id === doctor.id) {
                        item.doctor = doctor;
                    }
                });
                state.patientConsultations.past.consultations.forEach((item) => {
                    if (item.doctor.id === doctor.id) {
                        item.doctor = doctor;
                    }
                });
            }
        },
        clearConsultation: (state) => {
            state.currentConsultation = null;
        },
        resetPatientConsultations: (state) => {
            state.patientConsultations = {
                future: {
                    next: null,
                    consultations: [],
                },
                past: {
                    next: null,
                    consultations: [],
                },
            };
        },
        setIsModalSuccessfulConsultationPatient: (state, action) => {
            state.timeConsultation = 0;
            state.isModalSuccessfulConsultation = !state.isModalSuccessfulConsultation;
            state.timeConsultation = action.payload;
        },
        setKeyConsultationPatient: (state, action) => {
            state.keyConsultationPatient = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetFutureConsultations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchGetFutureConsultations.fulfilled, (state, action) => {
            const newConsultations = action.payload.data?.consultations?.filter(
                (consultation) => 
                    !state.patientConsultations.future.consultations.some((item) => item.id === consultation.id)
            );
            if (state.patientConsultations.future.next) {
                state.patientConsultations.future.consultations.push(...newConsultations);
            } else {
                // action.payload.count === (state.patientConsultations.future.consultations.length)  ?
                //     state.patientConsultations.future.consultations = action.payload.data.consultations :
                    state.patientConsultations.future.consultations = newConsultations;
            }
            state.patientConsultations.future.next = action.payload?.next;
            state.isLoading = false;
        });
        builder.addCase(fetchGetPastConsultations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchGetPastConsultations.fulfilled, (state, action) => {
            const newConsultations = action.payload.data?.consultations?.filter(
                (consultation) =>
                    !state.patientConsultations.past.consultations.some((item) => item.id === consultation.id)
            );
            state.patientConsultations.past.consultations.push(...newConsultations);
            state.patientConsultations.past.next = action.payload?.next;
            state.isLoading = false;
        });
        builder.addCase(cancelPatientConsultation.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(cancelPatientConsultation.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchMyConsultationUpdate.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchMyConsultationUpdate.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getPatientConsultation.fulfilled, (state, action) => {
            const consultationUpdated = action.payload;
            state.currentConsultation = consultationUpdated;
            const pastConsultations = state.patientConsultations.past.consultations;
            const futureConsultations = state.patientConsultations.future.consultations;
            const indexPast = pastConsultations.findIndex((item) => item.id === consultationUpdated.id);
            const indexFuture = futureConsultations.findIndex((item) => item.id === consultationUpdated.id);

            if (indexPast !== -1) {
                pastConsultations.splice(indexPast, 1, consultationUpdated);
            }
            if (indexFuture !== -1) {
                futureConsultations.splice(indexFuture, 1, consultationUpdated);
            }
        });
        builder.addCase(fetchFeedback.fulfilled, (state, action) => {
            state.currentConsultation.feedback = action.payload;
        });
    },
});

export const selectAllConsultations = (state) => state?.patientConsultations?.consultations?.data?.consultations;

// export const selectFutureConsultations = (state) => ({
//     consultationsList: state.patientConsultations.patientConsultations.future.consultations,
//     next: state.patientConsultations.patientConsultations.future.next,
//     isLoading: state.patientConsultations.isLoading,
// });

// export const selectPastConsultations = (state) => ({
//     consultationsList: state.patientConsultations.patientConsultations.past.consultations,
//     next: state.patientConsultations.patientConsultations.past.next,
//     isLoading: state.patientConsultations.isLoading,
// });

export default patientConsultations.reducer;

export const { 
    removeConsultation,
    defineIdActiveConsult,
    setDoctor,
    clearConsultation,
    resetPatientConsultations,
    setIsModalSuccessfulConsultationPatient,
    setKeyConsultationPatient,
} = patientConsultations.actions;
