import styled from 'styled-components';
import radioButtonFill from '../../img/radioButtonFill.svg';


export const RadioInput = styled.input.attrs({ type: 'radio' })`
  display: none;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  .image {
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 2px solid #B6B7BA;
    border-radius: 50%;
    transition: border-color 0.3s linear;
  }
  .image:hover {
    border-color: #19BE6F;
  }
  .checked {
    border: none;
    background-image: url(${radioButtonFill});
  }
`;

export const RadioText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;
