import React from 'react';
import { LabelAgreementStl } from './labelAgreement.styled';
import { Link } from 'react-router-dom';

export const LabelAgreement = ({ agreements, setAgreements }) => {

    return (
        <LabelAgreementStl>
            <input
                className='custom-checkbox'
                id="agreement"
                type="checkbox"
                width="24px"
                height="24px"
                checked={agreements}
                onChange={() => setAgreements(prev => !prev)}
            />
            <p className="agreement">
                Регистрируясь, вы выражаете
                <Link to={'/politics'} target='_blank'> согласие на&nbsp;обработку персональных данных </Link>
                и принимаете условия
                <Link to={'/politics'} target='_blank'> пользовательского соглашения </Link>
            </p>
        </LabelAgreementStl>
    )
}