import React from 'react';
import { ReactComponent as Certificate } from '../../../../../img/certificate.svg';
import { DataBlockStl } from '../viewDoctor.styled';
import { BASE_URL } from '../../../../../consts';

export const ViewAdvancedTraining = ({ advanced_training }) => {
    const openImageInNewTab = (imageURL) => {
        window.open(imageURL, '_blank');
    };
    const trainingShow = advanced_training?.map((training) => {

        // const nameFile = decodeURIComponent(training.file.split('/').slice(-1)[0]);

        return (
            <DataBlockStl key={training.id}>
                <div className="info__wrapper">
                    <p className="info__data">{training.organization}</p>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">{training.position}</p>
                </div>
                <div className="info__wrapper info__wrapper-training">
                    <div className="info__data info__data-training">
                        <p>{training.end_date ? new Date(training.end_date * 1000).getFullYear() : ''}</p>
                    </div>
                </div>
                <div
                    className="certificate-wrapper"
                    onClick={() => openImageInNewTab(`${BASE_URL.replace('/api/v1/', '')}${training.file}`)}
                >
                    <Certificate />
                    {/* <span>{nameFile}</span> */}
                    <span>Сертификат</span>
                </div>
            </DataBlockStl>
        );
    });

    return trainingShow;
};
