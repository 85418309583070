import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ViewData } from './ViewData';
import { useState } from 'react';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { EditData } from './EditData';
import { SectionStl } from '../viewPatient.styled';
import {Spinner} from '../../../../share/Spinner'
// import { ModerationLabel } from '../../../../share/ModerationLabel';

export const PatientData = () => {
    const [editData, toggleEditData] = useState(false);
    const { user } = useSelector((state) => state.patientProfile?.patientProfile);
    // const isLoader = useSelector((state) => state.patientProfile?.isLoader);

    // useEffect(() => {
    //     user?.first_name === '' && toggleEditData(true);
    // }, [isLoader]);

    // const { user, is_moderated } = useSelector((state) => state.doctorProfile?.doctorProfile);

    return (
        <SectionStl>
            <TitleWithPencil toggle={toggleEditData} title={'Личные данные'} />
            {/* <ModerationLabel moderation={is_moderated}/> */}
            {editData ?
                <EditData user={user} toggle={toggleEditData} /> :
                <ViewData user={user} />
            }
        </SectionStl>
    );
};

