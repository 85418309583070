import React, { useEffect } from 'react';
import { StyledLkPatient } from './lkPatient.styled';
import Container from '../../share/Container';
import { MainHeader } from '../../mainHeader/mainHeader';
import { LkPatientNavigation } from '../lkPatientNavigation/lkPatientNavigation';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { fetchPatientProfile } from '../api/PatientProfileSlice';
import { useDispatch } from 'react-redux';

export const LkPatient = () => {
    const isTablet = useMediaQuery({ query: '(max-width: 768px)' });
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchPatientProfile());
    }, []);

    useEffect(() => {
        if (pathname === '/lk/patient-profile') {
            navigate('/lk/patient-profile/profile', { replace: true });
        }
    }, [pathname]);

    return (
        <StyledLkPatient>
            <Container>
                <MainHeader title={'Личный кабинет'} />
                <div className="content">
                    {!isTablet && <LkPatientNavigation />}
                    <div className="rightBlock">
                        <Outlet />
                    </div>
                </div>
            </Container>
        </StyledLkPatient>
    );
};
