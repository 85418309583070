import React, { useEffect, useState } from 'react';
import { FeedbackFormStl } from './feedbackForm.styled';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { feedbackСonsultation } from '../../../../../api/patientApi';
import { getPatientConsultation } from '../../../../../api/PatientConsultationsSlice';
import { FeedbackValue } from '../../../../../../share/feedbackValue/FeedbackValue';

export const FeedbackForm = ({ consultationId }) => {
    const dispatch = useDispatch();
    const [reviewPost, setReviewPost] = useState(''); // текст в поле ввода
    const [validity, setValidity] = useState(''); // валидация tetxarea
    const [value, setValue] = useState(0); // колличество звезд

    const validateInput = () => {
        reviewPost.length < 2 && setValidity('Минимальная длина: 2 символа');
        reviewPost.length > 1000 && setValidity('Максимальная длина: 1000 символов');
        (reviewPost.length >= 2 && reviewPost.length <= 1000) && setValidity('');
    };

    useEffect(() => {
        validateInput();
    }, [reviewPost]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            text: reviewPost,
            rating_value: value,
        };
        feedbackСonsultation({ data, consultationId }).then(() => {
            dispatch(getPatientConsultation(consultationId));
        });
    };
    return (
        <FeedbackFormStl>
            <form onSubmit={handleSubmit}>
                <FeedbackValue value={value} setValue={setValue} />
                <div className="formHeader">
                    <p className={'formHeader-title'}>Отзыв</p>
                    <p className='formHeader-symbols'>
                        Введено символов {reviewPost.length} / 1000
                    </p>
                </div>
                <textarea
                    className={'reviewArea'}
                    name="reviewForm"
                    id="reviewForm"
                    cols="30"
                    rows="10"
                    value={reviewPost}
                    onChange={(e) => setReviewPost(e.target.value)}
                />
                <p className="formHeaderTextError">{validity}</p>
                <p className={'rules'}>
                    Перед отправкой отзыва, пожалуйста, ознакомьтесь с{' '}
                    <Link to="/regulations_reviews" className="rules-link">
                        {' '}
                        правилами публикации
                    </Link>
                    .
                </p>
                <button className={'reviewBtn'} disabled={validity.length > 0}>
                    Оставить отзыв
                </button>
            </form>
        </FeedbackFormStl>
    );
};
