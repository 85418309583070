import React from 'react';
import { useSelector } from 'react-redux';
import { UserNavigationStl } from './userNavigation.styled';
import { LkPatientNavigation } from '../../lkPatient/lkPatientNavigation/lkPatientNavigation';
import LkDoctorNavigation from '../../lkDoctor/lkDoctorNavigation/lkDoctorNavigation';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { fetchLogout } from '../../authentification/api/authentificationSlice';
// import PopUpConfirm from '../../share/PopUpConfirm';
// import { Modal } from '../../share/Modal';
// import { useState } from 'react';

export const UserNavigation = ({ setPopUpOpen }) => {
    const { isAuth, whoAreYou } = useSelector((state) => state.authentification);

    // const handleLogOut = () => {
    //     dispatch(fetchLogout());
    //     setPopUpOpen(false);
    //     navigate('/');
    // };

    return (
        <>
            <UserNavigationStl>
                {whoAreYou === 'patient' && <LkPatientNavigation />}
                {whoAreYou === 'doctor' && <LkDoctorNavigation />}
                {isAuth && (
                    <div className="exit" onClick={() => setPopUpOpen(true)}>
                        Выход
                    </div>
                )}
            </UserNavigationStl>
            {/* {isPopUpOpen && (
                <Modal toggle={setPopUpOpen}>
                    <PopUpConfirm
                        onClose={() => setPopUpOpen(false)}
                        onConfirm={() => handleLogOut()}
                        contentHeader={
                            <span>
                                Вы уверены, что хотите <br /> выйти из личного кабинета?
                            </span>
                        }
                        isClarification={false}
                    />
                </Modal>
            )} */}
        </>
    );
};
