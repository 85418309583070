import React from 'react';
import Container from '../../share/Container';
import { StyledInfoBlock } from './infoBlock.styled';

const InfoBlock = () => {
    const list = {
        'Онлайн-консультации': (
            <p className="infoBlock__list-description">Получайте профессиональную помощь из любой точки мира</p>
        ),
        'Мед. поддержка 24/7': (
            <p className="infoBlock__list-description">
                Врачи работают 24 часа <br /> в сутки
            </p>
        ),
        'Консилиум врачей': (
            <p className="infoBlock__list-description">
                Разносторонний подход <br /> к лечению
            </p>
        ),
    };

    return (
        <StyledInfoBlock>
            <Container>
                <div className="infoBlock__inner">
                    <h2 className="infoBlock__title">
                        Нам не безразличны
                        <br /> наши пациенты
                    </h2>
                    <ul className="infoBlock__list">
                        {Object.entries(list).map(([title, description], index) => (
                            <li className="infoBlock__list-item" key={index}>
                                <p className="infoBlock__list-title">{title}</p>
                                {description}
                            </li>
                        ))}
                    </ul>
                </div>
            </Container>
        </StyledInfoBlock>
    );
};

export default InfoBlock;
