import React from 'react';

export const consultationStatus = (status, isPatientCancelled) => {
    if (status === 0) {
        return <div className="statusConsultation">Онлайн-консультация</div>;
    } else if (status === 2) {
        return (
            <div className="statusConsultation red">
                {`${isPatientCancelled ? 'Вы отменили консультацию' : 'Врач отменил консультацию'}`}
            </div>
        );
    } else if (status === 1) {
        return <div className="statusConsultation green">Онлайн-консультация</div>;
    } else if (status === 9) {
        return <div className="statusConsultation red">Онлайн-консультация не состоялась</div>;
    }
};

export const consultationNewBtn = () => {
    return (
        <>
            <button onClick={() => {}} className="startBtn">
                Начать
            </button>
            <button onClick={() => {}} className="closeBtn">
                Отменить
            </button>
        </>
    );
};
