import React, { useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { MedicationList } from '../../shared/MedicationList';
import { filterDeletedBlocks } from '../../helpers/filterDeletedBlocks';
import { getPatientMedicines } from '../../../api/PatientProfileSlice';
import { deleteMyMedicinesById, postMyMedicines, putMyMedicinesById } from '../../../api/patientApi';
import { useDispatch } from 'react-redux';

export const EditMedication = ({ medicines, toggle }) => {
    const [isLoader, setIsLoader] = useState(false);

    const dispatch = useDispatch();
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            medicines: medicines,
        },
    });
    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: 'medicines',
    });

    // если поля испачканы - узнаем здесь
    const { dirtyFields, isValid, isSubmitting } = useFormState({ control: methods.control });

    const onSubmit = ({ medicines: currentList }) => {
        setIsLoader(true);
        const promises = [];

        const deletedBlocks = filterDeletedBlocks(medicines, currentList);
        deletedBlocks?.forEach((item) => {
            promises.push(deleteMyMedicinesById(item.id));
        });

        currentList.forEach((block, i) => {
            const newMedicines = {
                ...block,
            };

            // если новый блок
            if (block.newField) {
                const { newField, ...request } = newMedicines;
                promises.push(postMyMedicines({ ...request }));
            }
            // если измененный
            if (!block.newField && dirtyFields?.medicines?.[i]) {
                const { id, title, dosage, frequency } = newMedicines;
                promises.push(putMyMedicinesById({ id, title, dosage, frequency }));
            }
        });

        Promise.all(promises)
            .then(() => setIsLoader(false))
            .then(() => toggle(false))
            .then(() => {
                dispatch(getPatientMedicines());
            })
            .catch((err) => {
                console.log(err);
                return setIsLoader(false)
            });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const onAdd = () => {
        const newBlock = {
            title: '',
            dosage: '',
            frequency: '',
            newField: true, // Дополнительное свойство
        };
        append(newBlock);
    };

    const inputs = fields.map((education, index) => {
        return (
            <section className="block" key={education.id}>
                <MedicationList name={`medicines.${index}`} />
                <span className="delete" onClick={() => remove(index)}>
                    Удалить блок
                </span>
            </section>
        );
    });

    return (
        <>
            <FormProvider {...methods}>
                <form className="formDynamicInput" onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="inputs">{inputs}</div>
                    <button className="btn__addBlock" type="button" onClick={onAdd}>
                        {`+ Добавить запись`}
                    </button>
                    <button className="btn__recive" type="submit" disabled={!isValid || isSubmitting || isLoader}>
                        {isLoader ? 'Сохранение...' : 'Сохранить'}
                    </button>
                </form>
            </FormProvider>
        </>
    );
};
