import { configureStore } from '@reduxjs/toolkit';
import mainSearch from '../componets/header/mainSearchSlice';
// import blog from '../pages/blog/api/blogSlice'
import blog from '../componets/blog/api/blogSlice';
import reviewsSlice from '../componets/reviews/reviewsSlice';
import portalReviews from '../componets/newReviews/api/reviewsSlice';
import doctorsTopSlice from '../componets/home/doctorTop/api/doctorsTopSlice';

import authentification from '../componets/authentification/api/authentificationSlice';
import contacts from '../componets/contacts/contactsSlice';

import favoriteDoctors from '../componets/lkPatient/api/favoriteDoctorsSlice';
import patientProfile from '../componets/lkPatient/api/PatientProfileSlice';
import patientProfileEdit from '../componets/lkPatient/api/PatientProfileEditSlice';
import patientConsultations from '../componets/lkPatient/api/PatientConsultationsSlice';
import payments from '../componets/lkPatient/api/paymentsSlice';

import doctorsOLD from '../componets/doctorsList/doctorsSlise';
import doctors from '../componets/ourDoctors/api/doctorsSlise';
import doctorProfile from '../componets/lkDoctor/api/doctorUserProfileSlice';
import doctorCalendar from '../componets/lkDoctor/api/lkDoctorCalendarSlice';
import doctorCertificates from '../componets/lkDoctor/api/doctorCertificatesSlice';
import doctorPatients from '../componets/lkDoctor/api/doctorPatientsSlice';
import doctorWallet from '../componets/lkDoctor/api/doctorWalletSlice';

import doctorConsultations from '../componets/lkDoctor/api/lkDoctorConsultationsSlice';
import timer from '../componets/timerConsultation/api/timerSlice'
// import lkDoctorConsultationPageSlice from '../componets/lkDoctor/api/lkDoctorConsultationPageSlice';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

const store = configureStore({
    reducer: {
        doctorProfile,
        doctorCalendar,
        doctorConsultations,
        // lkDoctorConsultationPageSlice,
        doctorPatients,
        doctorCertificates,
        doctorWallet,
        doctors,
        doctorsOLD,
        payments,
        mainSearch,
        reviewsSlice,
    portalReviews,
        doctorsTopSlice,
        patientConsultations,
        patientProfile,
        patientProfileEdit,
        favoriteDoctors,
        contacts,
        blog,
        authentification,
        timer
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

    devTools: process.env.NODE_ENV !== 'production',
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
