import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getMyProfile,
    patient_create,
    postPatient,
    getMyAllergies, postMyAllergies, putMyAllergiesById, deleteMyAllergiesById,
    getMyOperation, postMyOperation, putMyOperationById, deleteMyOperationById,
    getMyMedicines, postMyMedicines, putMyMedicinesById, deleteMyMedicinesById,
    getMyDiseases, postChronicDiseases, putMyDiseasesById, deleteMyDiseasesById,
} from '../api/patientApi';
import { loadMyAvatar, getCity } from '../../share/api';
import { fetchMeUser } from '../../authentification/api/authentificationSlice';

export const fetchPatientProfile = createAsyncThunk('userPatient/patientProfile', async (_, {dispatch}) => {
    const response = await getMyProfile();
    dispatch(fetchMeUser())
    return response.data;
});

//аллергии пациента (проверить необходимость post, put, delete -запросов)
export const getPatientAllergies = createAsyncThunk('userPatient/getPatientAllergies', async () => {
    const response = await getMyAllergies();
    return response.data.results;
});
export const postPatientAllergies = createAsyncThunk('userPatient/postPatientAllergies', async (data) => {
    const response = await postMyAllergies(data);
    return response.data;
});
export const putPatientAllergies = createAsyncThunk('userPatient/putPatientAllergies', async ({id, title, value}) => {
    try {
        const response = await putMyAllergiesById({id, title, value});
        return response;
    } catch (err) {
        console.log(err);
    }
});
export const deletePatientAllergies = createAsyncThunk('userPatient/deletePatientAllergies', async (id) => {
    try {
        const response = await deleteMyAllergiesById(id);
        // console.log('delete-request')
        return response;
    } catch (err) {
        console.log(err);
    }
});

//операции пациента (проверить необходимость post, put, delete -запросов)
export const getPatientOperation = createAsyncThunk('userPatient/getPatientOperation', async () => {
    const response = await getMyOperation();
    return response.data.results;
});
export const postPatientOperation = createAsyncThunk('userPatient/patientOperation', async (data) => {
    const response = await postMyOperation(data);
    return response.data;
});
export const putPatientOperation = createAsyncThunk('userPatient/putPatientOperation', async ({id, description, date}) => {
    try {
        const response = await putMyOperationById({id, description, date});
        return response;
    } catch (err) {
        console.log(err);
    }
});
export const deletePatientOperation = createAsyncThunk('userPatient/deletePatientOperation', async (id) => {
    try {
        const response = await deleteMyOperationById(id);
        return response;
    } catch (err) {
        console.log(err);
    }
});

// препараты пациента (проверить необходимость post, put, delete -запросов)
export const getPatientMedicines = createAsyncThunk('userPatient/getPatientMedicines', async () => {
    const response = await getMyMedicines();
    return response.data.results;
});
export const postPatientMedicines = createAsyncThunk('userPatient/patientMedicines', async (data) => {
    const response = await postMyMedicines(data);
    return response.data;
});
export const putPatientMedicines = createAsyncThunk('userPatient/putPatientMedicines', async ({id, title, dosage, frequency}) => {
    try {
        const response = await putMyMedicinesById({id, title, dosage, frequency});
        return response;
    } catch (err) {
        console.log(err);
    }
});
export const deletePatientMedicines = createAsyncThunk('userPatient/deletePatientMedicines', async (id) => {
    try {
        const response = await deleteMyMedicinesById(id);
        return response;
    } catch (err) {
        console.log(err);
    }
});

// заболевания пациента (проверить необходимость post, put, delete -запросов)
export const getPatientDiseases = createAsyncThunk('userPatient/getPatientDiseases', async () => {
    const response = await getMyDiseases();
    return response.data.results;
});
export const postPatientChronicDiseases = createAsyncThunk('userPatient/patientDiseases', async (data) => {
    const response = await postChronicDiseases(data);
    return response.data;
});
export const putPatientDiseases = createAsyncThunk('userPatient/putPatientMedicines', async ({id, title, value}) => {
    try {
        const response = await putMyDiseasesById({id, title, value});
        return response;
    } catch (err) {
        console.log(err);
    }
});
export const deletePatientDiseases = createAsyncThunk('userPatient/deletePatientMedicines', async (id) => {
    try {
        const response = await deleteMyDiseasesById(id);
        return response;
    } catch (err) {
        console.log(err);
    }
});

export const fetchLoadMyAvatar = createAsyncThunk('userPatient/loadMyAvatar', async (file, {dispatch}) => {
    try {
        const response = await loadMyAvatar(file);
        dispatch(fetchMeUser())
        return response.data;
    } catch (err) {
        console.log(err);
    }
});

// создание профиля нового пациента (и редактирование параметров рост, вес, группа крови)
export const fetchPatient_create = createAsyncThunk('userPatient/patientCreate', async (data) => {
    try {
        const response = await patient_create(data);
        return response;
    } catch (err) {
        console.log(err);
    }
});

// редактирование личных данных в кабинете пациента
export const fetchPatient_post = createAsyncThunk('userPatient/patientCreate', async (data) => {
    try {
        const response = await postPatient(data);
        return response;
    } catch (err) {
        console.log(err);
    }
});

export const fetchCity = createAsyncThunk('UserDoctor/fetchCity', async (searchString) => {
    try {
        const response = await getCity(searchString);
        return response.data.results;
    } catch (err) {
        console.log(err);
    }
});

const patientProfile = createSlice({
    name: 'patientProfile',
    initialState: {
        patientProfile: null,
        patientProfileFull: null,
        isLoader: false,
        isLoaderAlleries: false,
        isLoaderOperation: false,
        isLoaderMedicines: false,
        isLoaderDiseases: false,
        isPreloaderCity: false,
        city: [],
    },
    reducers: {
        resetCity: (state) => {
            state.city = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPatientProfile.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchPatientProfile.fulfilled, (state, action) => {
            state.patientProfile = action.payload;
            state.isLoader = false;
        });
        builder.addCase(fetchPatientProfile.rejected, (state) => {
            state.isLoader = false;
        });
        builder.addCase(fetchLoadMyAvatar.fulfilled, (state, action) => {
            state.patientProfile.user.avatar = action.payload.file;
        });

        // запрос аллергий и лоадер для аллергий
        builder.addCase(getPatientAllergies.pending, (state) => {
            state.isLoaderAlleries = true;
        });
        builder.addCase(getPatientAllergies.fulfilled, (state, action) => {
            state.patientProfile.allergies = action.payload;
            state.isLoaderAlleries = false;
        });
        builder.addCase(getPatientAllergies.rejected, (state) => {
            state.isLoaderAlleries = false;
        });

        // запрос операций и лоадер для операций
        builder.addCase(getPatientOperation.pending, (state) => {
            state.isLoaderOperation = true;
        });
        builder.addCase(getPatientOperation.fulfilled, (state, action) => {
            state.patientProfile.operations = action.payload;
            state.isLoaderOperation = false;
        });
        builder.addCase(getPatientOperation.rejected, (state) => {
            state.isLoaderOperation = false;
        });

        // запрос препаратов и лоадер для препаратов
        builder.addCase(getPatientMedicines.pending, (state) => {
            state.isLoaderMedicines = true;
        });
        builder.addCase(getPatientMedicines.fulfilled, (state, action) => {
            state.patientProfile.medicines = action.payload;
            state.isLoaderMedicines = false;
        });
        builder.addCase(getPatientMedicines.rejected, (state) => {
            state.isLoaderMedicines = false;
        });

        // запрос заболеваний и лоадер для заболеваний
        builder.addCase(getPatientDiseases.pending, (state) => {
            state.isLoaderDiseases = true;
        });
        builder.addCase(getPatientDiseases.fulfilled, (state, action) => {
            state.patientProfile.diseases = action.payload;
            state.isLoaderDiseases = false;
        });
        builder.addCase(getPatientDiseases.rejected, (state) => {
            state.isLoaderDiseases = false;
        });
        builder.addCase(fetchCity.pending, (state, action) => {
            state.isPreloaderCity = true;
        });
        builder.addCase(fetchCity.fulfilled, (state, action) => {
            state.city = action.payload;
            state.isPreloaderCity = false;
        });
        builder.addCase(fetchCity.rejected, (state, action) => {
            state.isPreloaderCity = false;
        });
    },
});

export const { resetCity } = patientProfile.actions;
export default patientProfile.reducer;
