import React from 'react';
import styled from 'styled-components';

const TitleHeaderStl = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 96px;
  line-height: 120%;
  text-align: center;
  color: #06a7b3;
  @media ${({ theme }) => theme.device.laptop} {
    font-size: 64px;
  }
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 64px;
  }
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 58px;
  }
`;

export const TitleHeader = (props) => {
  return <TitleHeaderStl {...props} />;
};
