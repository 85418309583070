import React from 'react';
import { Link } from 'react-router-dom';
import { StyledAvatar } from './avatar.styled';
import { identifyWhoAreYou } from '../helpers/identifyWhoAreYou';
import { consultationDelete } from '../../lkPatient/api/patientApi';

function Avatar({ role, avatar }) {
    const path = identifyWhoAreYou(role);
    return (
        <Link to={path}>
            <StyledAvatar src={avatar? avatar : '/img/header/avatar.svg'} alt='search icon' />
        </Link>
    )

}

export default Avatar;