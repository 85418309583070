import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewsListStyled } from './reviewsList.styled';
import { fetchPortalReviews, resetState } from '../api/reviewsSlice';
import { ScrollToTopArrowUp } from '../../share/ScrollToTopArrowUp';
import Review from './Review';

export const ReviewsList = () => {
    const dispatch = useDispatch();
    const reviews = useSelector((state) => state.portalReviews.reviews);
    const next = useSelector((state) => state.portalReviews.next);
    const showMoreReviews = () => dispatch(fetchPortalReviews());

    useEffect(() => {
        dispatch(fetchPortalReviews());
        return () => {
            dispatch(resetState());
        };
    }, []);

    return (
        <ReviewsListStyled>
            {reviews?.length !== 0 && (
                <div className="list">
                    {reviews.map((review, index) => {
                        return <Review key={review.id} review={review} index={index}/>;
                    })}
                </div>
            )}
            <div className="reviews__buttons-container">
                {next !== null && (
                    <button className="reviews__btn" onClick={showMoreReviews}>
                        Показать больше
                    </button>
                )}
                <ScrollToTopArrowUp />
            </div>
        </ReviewsListStyled>
    );
};
