import React from 'react';
import styled from 'styled-components';

const InputStl = styled.input`
    appearance: none;
    background-color: white;
    width: 24px;
    height: 24px;
    border: 2px solid #b6b7ba;
    border-radius: 2px;
    &::before {
        display: block;
        content: '';
        width: 20px;
        height: 20px;
    }
    &:checked {
        background-color: #19be6f;
        background-image: url('/img/icons/Check.svg');
        background-position: center;
    }
    &:focus,
    &:hover {
        outline: none;
        border-color: #19be6f;
    }
    &:active {
        background-image: none;
        background-color: #c9fce3;
    }
`;

const Checkbox = ({ field, trigger, label }) => {
    return (
        <>
            {' '}
            <label>
                {label}
                <InputStl type="checkbox" {...field} onClick={() => trigger()} />
            </label>
        </>
    );
};

export default Checkbox;
