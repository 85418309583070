import styled from 'styled-components';

export const PhoneStl = styled.form`
    display: flex;
    margin: 16px 0 16px 0;
    flex-direction: column;
    gap: 30px;
    align-self: center;
    position: relative;

    @media (max-width: 500px) {
        width: 100%;
        flex-direction: column;
        gap: 32px;
        margin-top: 32px;
    }
    .select_number {
        font-size: 18px;
        outline: 0;
        height: 50px;
        padding: 0 25px;
        @media (max-width: 500px) {
            margin-right: 0;
            padding-left: 12px;
            font-size: 16px;
            width: 110px;
        }
        @media (max-width: 375px) {
            width: 74px;
            font-size: 16px;
        }
    }

    .auth_number {
        display: flex;
        flex-direction: column;
        @media (max-width: 500px) {
            gap: 0px;
        }
    }

    .input__field {
        position: relative;
        height: 50px;
        width: 100%;
        border-radius: 10px;
        font-size: 18px;
        padding: 0 15px 0 15px;
        border: 2px solid #19be6f;
        background-color: #ffffff;
        :focus {
            border: 2px solid #19be6f;
            outline: none;
        }
    }

    .button {
        display: inline-block;
        border-radius: 10px;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        border: none;
        transition: opacity 0.5s linear;
        cursor: pointer;
        width: 100%;
        padding: 17px;
        background-color: #19be6f;
        color: #ffffff;
        outline: none;
        &-disabled {
            opacity: 0.5;
            cursor: default;
            text-align: center;
        }
    }
`;
