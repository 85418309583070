import React from "react";
import styled, { css } from "styled-components";

const ContainerStl = styled.div`
  max-width: ${(props) => props.maxWidth || "1070px"};
  width: ${props => props.width || '100%'};
  margin: ${(props) => props.margin || "0 auto"};
  padding: ${props => props.padding || '0 16px'};
  height: 100%;

  @media ${({ theme }) => theme.device.laptop} {
    padding: 0 20px;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 16px;
  }

  ${(props) =>
    props.flex &&
    css`
      display: flex;
      justify-content: ${(props) => props.justContent || 'space-between'} ;
      flex-wrap: ${(props) => props.flexWrap || null};
    `}
  ${(props) =>
    props.relative &&
    css`
      position: relative;
    `}
`;

const Container = (props) => {
  return <ContainerStl {...props} />;
};
export default Container;
