import React from 'react';
import { FooterStyled } from './Footer.styled';
import googlePlayIcon from '../../img/icons/appLoadIcons/googlePlay.svg';
import appStoreIcon from '../../img/icons/appLoadIcons/appStore.svg';
import { Link } from 'react-router-dom';
import FooterMain from './footerComponents/FooterMain';
import FooterUseful from './footerComponents/FooterUseful';
import FooterContacts from './footerComponents/FooterContacts';
import Container from '../share/Container';

function Footer() {
  return (
    <FooterStyled>
      <Container>
        <div className='footer__top'>
          <div className="footer__logo">
            <Link to="/">
              <img alt="footerlogo" src={'/img/mainLogo/newLogoSvg.svg'} />
            </Link>
          </div>
          <FooterMain />
          <FooterUseful />
          <FooterContacts />
        </div>
        <div className='footer__bottom'>
          <div className='footer__app-load'>
            <Link to="#">
              <img alt="googlePlay" src={googlePlayIcon} />
            </Link>
            <Link to="#">
              <img alt="appStore" src={appStoreIcon} />
            </Link>
          </div>
          <p className='footer__text'>
            Информация на сайте является ознакомительной и не может заменить консультацию врача.
          </p>
        </div>
      </Container>
    </FooterStyled>
  );
}

export default Footer;