import styled from 'styled-components';

export const StyledReviewCard = styled.li`
  list-style-type: none;
  display: grid;
  grid-template-columns: 114px 1fr 1fr;
  grid-template-rows: 45px 1fr 20px; 
  width: 100%;
  border: 1px solid #F6F6F6;
  border-radius: 10px;
  padding: 16px 16px 31px;
  margin-bottom: 35px;
  
  .reviewUserPhoto {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    justify-self: center;
    align-self: start;
    width: 114px;
    height: 114px;
    object-fit: cover;
    padding: 10px;
    border-radius: 50%;
  }

  .userName {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    padding-left: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #212121;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 130%;
  }

  .addDate {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    justify-self: end;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #666666;
  }

  .userTextContent {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    padding-left: 13px;
    margin-bottom: 16px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #212121;
  }

  .responseСomment {
    // включить после основнго функционала добавления отзывов
    display: none;
    max-width: 105px;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    padding-left: 13px;
    background: #ffffff;
    border: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: #19BE6F;
    transition: opacity 0.5s linear;
    cursor: pointer;
  }

  .responseСomment:hover {
    opacity: 0.6;
  }
  
  @media(max-width: 768px) {
    grid-template-columns: 84px 1fr 1fr;
    grid-template-rows: 40px 1fr 18px; 
    padding: 16px;
    margin-bottom: 16px;
    
    .reviewUserPhoto {
      width: 84px;
      height: 84px;
      padding: 0;
    }

    .userName {
      padding-left: 16px;
      font-size: 18px;
    }

    .addDate {
      font-size: 13px;
      line-height: 26px;
    }

    .userTextContent {
      padding-left: 16px;
    }

    .responseСomment {
      padding-left: 16px;
      font-size: 18px;
    }

  @media(max-width: 600px) {
    grid-template-columns: 30px 1fr 1fr;
    grid-template-rows: 38px 1fr 19px; 
     
    .reviewUserPhoto {
      grid-row: 1 / 2;
      width: 30px;
      height: 30px;
    }
  
    .userName {
      color: #212121;
      font-size: 16px;
    }
  
    .userTextContent {
      grid-column: 1 / 4;
      padding-left: 0;
      margin-bottom: 8px;
      font-size: 14px;
    }
  
    .responseСomment {
      grid-column: 1 / 3;
      max-width: 75px;
      padding-left: 0;
      font-size: 16px;
      line-height: 19px;
    }
  }
`