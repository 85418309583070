import React from 'react';
import { SocialMediaStyled } from './socialMedia.styled';
import { ReactComponent as VKSvg } from '../../../../img/icons/contactsIcons/social-vk.svg';
import { ReactComponent as OdnokSvg } from '../../../../img/icons/contactsIcons/social-odnok.svg';
import { ReactComponent as TelegrammSvg } from '../../../../img/icons/contactsIcons/social-telegram.svg';

export const SocialMedia = () => {
    return (
        <SocialMediaStyled>
            <h3 className="shareTitle"></h3>
            <div className="shareIconsContainer">
                <a href="https://t.me/+8fkJzIb8wWMyYmZi" target="_blank" rel="noopener noreferrer">
                    <TelegrammSvg />
                </a>
                <a href="https://ok.ru/group/61412337516611" target="_blank" rel="noopener noreferrer">
                    <OdnokSvg />
                </a>
                <a href="https://vk.com/doct24" target="_blank" rel="noopener noreferrer">
                    <VKSvg />
                </a>
            </div>
        </SocialMediaStyled>
    );
};
