import styled from 'styled-components';

export const ConsultationListStl = styled.div`
    display: flex;
    gap: 35px;
    flex-direction: column;

    .no-consult {
        font-weight: 500;
        font-size: 24px;
        line-height: 130%;
        color: #666666;
        text-align: left;
        margin-bottom: 32px;
    }

    .no-consult-btn {
        border-radius: 8px;
        background: #19be6f;
        width: 204px;
        height: 47px;
        border: 0;
    }

    .link {
        color: white;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
    }

    .buttonsPaginate {
        display: flex;
        justify-content: flex-end;
        margin-top: 35px;
        max-width: 600px;
        width: 100%;
    }

    .buttonsPaginate button {
        border: none;
        background-color: transparent;
        color: #19be6f;
        font-size: 16px;
        line-height: 150%;
        cursor: pointer;
        transition: opacity 0.5s linear;
        &:hover {
            opacity: 0.7;
        }
    }

    .buttonAddDoctors {
        margin-right: 42px;
    }

    .buttonUp {
        display: flex;
        gap: 4px;
        align-items: center;
    }

    @media (max-width: 768px) {
        align-items: center;
        .no-consult {
            font-size: 20px;
        }
    }
    @media (max-width: 361px) {
        .no-consult {
            font-size: 18px;
        }
        .no-consult-btn {
            width: 100%;
        }
    }
`;
