import React from 'react';
import { ErrorBlockStl } from './errorBlock.styled';
import { ReactComponent as IconWarning } from '../../../img/icons/autorization/logo_popupErrorAutorization.svg';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessages, clearPhone, fetchAuthentification } from '../api/authentificationSlice';

export const ErrorBlock = ({ heading, messages, textButton, setCode }) => {
    const dispatch = useDispatch();
    const { phone_number } = useSelector((state) => state.authentification);
    const newCodeText = 'Последний запрос был более 10 минут назад';

    const handleClosePopup = () => {
        !(messages.startsWith('Осталось') || messages.startsWith('Неверный код')) ?
            dispatch(clearPhone()) :
            dispatch(clearMessages());
    };

    const handleRequestCode = () => {
        setCode({ first: '', second: '', third: '', fourth: '' });
        dispatch(clearMessages());
        dispatch(fetchAuthentification(phone_number));
    };

    return (
        <ErrorBlockStl>
            <IconWarning className="warningIcon" />
            <div>
                <h2 className="heading">{heading}</h2>
                <p className="text">{messages}</p>
            </div>
            <div className="buttons_contaiter">
                {messages === newCodeText && (
                    <button className="button" onClick={handleRequestCode}>
                        Запросить новый код
                    </button>
                )}
                <button className={messages === newCodeText ? 'buttonClose' : 'button'} onClick={handleClosePopup}>
                    {textButton}
                </button>
            </div>
        </ErrorBlockStl>
    );
};
