import React from 'react';
import Select from '../../../share/formFields/newElements/Select';
import RadioGroup from '../../../share/formFields/newElements/RadioGroup';
import { validateRequired } from '../../../share/formFields/validateInput';

export function SpecializationList({ allSpecializations, formatedSpecializations, scientific_degree, category }) {

    return (
        <>
            <Select
                isMulti
                name="spec"
                options={allSpecializations?.map((spec) => ({ value: spec.id, label: spec.name }))}
                defaultValue={formatedSpecializations}
                placeholder="Введите специализацию"
                validation={validateRequired(true)}
                isRequired={true}
                onChange={() => {}}
                label="Специализация"
            />
            <RadioGroup
                defaultChecked={category}
                name="category"
                label="Категория"
                options={['Категория отсутствует', 'Вторая категория', 'Первая категория', 'Высшая категория']}
            />
            <RadioGroup
                defaultChecked={scientific_degree}
                name="scientific_degree"
                label="Научная степень"
                options={['Степень отсутствует', 'Кандидат наук', 'Доктор наук']}
            />
        </>
    );
}
