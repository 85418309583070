import React, { useEffect } from 'react';
import { StyledDoctorCardProfile } from './doctorCardProfile.styled';
import { changeCategoryLabelsEnding } from '../../../share/helpers';
import { ReactComponent as LikeFilled } from '../../../../img/icons/doctorProfilIcons/like_profile.svg';
import { ReactComponent as LikeEmpty } from '../../../../img/icons/doctorProfilIcons/like_profile_empty.svg';
import { ReactComponent as Clock } from '../../../../img/icons/newDoctorIcons/clock.svg';
import { ReactComponent as StarIcon } from '../../../../img/icons/newDoctorIcons/star.svg';
import { ReactComponent as CategoryIcon } from '../../../../img/icons/newDoctorIcons/category.svg';
import { ReactComponent as EducationIcon } from '../../../../img/icons/newDoctorIcons/education.svg';
import { ReactComponent as Geo } from '../../../../img/icons/newDoctorIcons/geo.svg';
import { useSelector } from 'react-redux';
import { fetchRemoveFavoriteDoctorUid, fetchSetFavoriteDoctor } from '../../../lkPatient/api/favoriteDoctorsSlice';
import { useDispatch } from 'react-redux';
import { fetchGetDoctor } from '../../api/doctorsSlise';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../share/Spinner';
import { useState } from 'react';

export const DoctorCardProfile = ({ doctor }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const whoAreYou = useSelector((state) => state.authentification.whoAreYou);
    const [isLoading, setIsLoading] = useState(false);

    const specialization = doctor.specialization.map((spec) => {
        return <li key={spec.id}>{spec.name}</li>;
    });
    const onClickLike = (e) => {
        e.stopPropagation();
        setIsLoading(true);
        dispatch(fetchSetFavoriteDoctor(doctor.id))
            .then(() => {
                dispatch(fetchGetDoctor(doctor.id));
            })
            .finally(() => setIsLoading(false));
    };
    const onClickDisLike = (e) => {
        e.stopPropagation();
        setIsLoading(true);
        dispatch(fetchRemoveFavoriteDoctorUid(doctor.id))
            .then(() => {
                dispatch(fetchGetDoctor(doctor.id));
            })
            .finally(() => setIsLoading(false));
    };
    

    const jobUntilNow = doctor?.work_expirience?.find((job) => job.until_now);

    return (
        <StyledDoctorCardProfile>
            <div className="doctorPhoto mobileBlock">
                <img
                    className={'doctorMainPhoto'}
                    src={
                        doctor.avatar === null ||
                        doctor.avatar === 'https://dj-front.doct24.com/media/users_avatar/03.jpg'
                            ? 'https://imgholder.ru/143х143/8493a8/adb9ca&text=photo&font=kelson'
                            : `${doctor.avatar}`
                    }
                    alt="doctor"
                />
                {whoAreYou === 'patient' && (
                    <span className="like-wrapper">
                        {isLoading ? (
                            <Spinner marginTop={'0px'} width={'20px'} height={'20px'} />
                        ) : doctor.is_favorite ? (
                            <LikeFilled className="like" onClick={onClickDisLike} />
                        ) : (
                            <LikeEmpty className="like" onClick={onClickLike} />
                        )}
                    </span>
                )}
            </div>
            <div className="doctorInfo">
                <div className={'mobileProfileView'}>
                    <div className={'mobileViewPhoto'}>
                        <img
                            className={'doctorMainPhoto'}
                            src={
                                doctor.avatar === null ||
                                doctor.avatar === 'https://dj-front.doct24.com/media/users_avatar/03.jpg'
                                    ? 'https://imgholder.ru/143х143/8493a8/adb9ca&text=photo&font=kelson'
                                    : `${doctor.avatar}`
                            }
                            alt="doctor"
                        />
                        {whoAreYou === 'patient' && (
                            <span className="like-wrapper">
                                {isLoading ? (
                                    <Spinner marginTop={'0px'} width={'20px'} height={'20px'} />
                                ) : doctor.is_favorite ? (
                                    <LikeFilled className="like" onClick={onClickDisLike} />
                                ) : (
                                    <LikeEmpty className="like" onClick={onClickLike} />
                                )}
                            </span>
                        )}
                    </div>
                    <div className={'mobileViewText'}>
                        <p className={'name'}>{doctor.last_name}</p>
                        <p className={'name'}>{doctor.first_name} </p>
                        <p className={'name'}>{doctor.patronymic}</p>
                        <ul className={'specialization'}>
                            {specialization.length ? specialization : 'Доктор не указал специализацию'}
                        </ul>
                    </div>
                </div>
                <p className={'name mobileBlock'}>{doctor.last_name}</p>
                <span className={'name mobileBlock'}>{doctor.first_name} </span>
                <span className={'name mobileBlock'}>{doctor.patronymic}</span>
                <ul className={'specialization mobileBlock'}>
                    {specialization.length ? specialization : 'Доктор не указал специализацию'}
                </ul>
                <div className={'about'}>
                    <div className={'seniority mainFlex'}>
                        <Clock />
                        <span className={'infoText'}>Стаж — {doctor.seniority} лет</span>
                        <StarIcon />
                        <span className={'infoText star'}>{doctor.ratings_rating}</span>
                    </div>
                    {doctor.category !== 0 && (
                        <div className={'mainFlex'}>
                            <CategoryIcon />
                            <span className={'infoText'}>
                                Врач {changeCategoryLabelsEnding(doctor)} категории
                            </span>
                            {/* <span className={'infoText'}>{getDoctorCategory(doctor.category)}</span> */}
                        </div>
                    )}
                    {doctor.scientific_degree !== 0 && (
                        <div className={'mainFlex'}>
                            <EducationIcon />
                            <span className={'infoText'}>{doctor.scientific_degree_label}</span>
                            {/* <span className={'infoText'}>{getScientificDegree(doctor.scientific_degree)}</span> */}
                        </div>
                    )}
                    {jobUntilNow && (
                        <div className={'mainFlex'}>
                            <Geo />
                            <span className={'infoText'}>{jobUntilNow.organization}</span>
                        </div>
                    )}
                </div>
            </div>
        </StyledDoctorCardProfile>
    );
};
