import styled from 'styled-components';

export const ArticleDescriptionStyled = styled.div`
    .backToPostsButton {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 35px;
        p {
            color: var(--primary-accent, #19be6f);
            font-size: 16px;
            font-style: normal;
            line-height: 150%;
        }
    }

    .articleContent {
        display: grid;
        gap: 35px;

        .articleTopContent {
            .previewArticle {
                max-width: 100%;
                margin-bottom: 16px;
                border-radius: 10px;
            }
            .articleInfo,
            .infoBlock {
                display: flex;
                gap: 8px;
                align-items: center;
                font-size: 14px;

                svg path {
                    stroke: gray;
                }

                .infoCreated {
                    font-weight: 500;
                    line-height: 20px;
                }
            }
        }

        .articleText_container {
            word-break: break-word;
            h1,
            h2,
            p,
            li {
                font-style: normal;
                color: var(--primary-text, #212121);
                font-family: Rubik;
                font-weight: 400;
            }
            h1,
            h2 {
                font-weight: 500;
                margin-top: 35px;
                margin-bottom: 35px;
            }
            h1 {
                font-size: 36px;
                line-height: 120%;
            }
            p {
                font-size: 16px;
                line-height: 150%;
                margin-bottom: 15px;
                margin-top: 15px;
            }
            ol {
                font-size: 16px;
                line-height: 150%;
                margin-left: 20px;
            }
        }
    }
    @media (max-width: 768px) {
        .backToPostsButton {
            margin-bottom: 20px;
        }
        .articleText_container {
            h2 {
                font-size: 22px;
            }
            ol {
                line-height: 130% !important;
            }
        }
    }
    @media (max-width: 500px) {
        .backToPostsButton {
            margin-bottom: 20px;
        }
        .articleText_container {
            h1 {
                font-size: 28px !important;
                line-height: 110%;
            }
            h2 {
                font-size: 20px;
            }
            p {
                font-size: 14px !important;
                line-height: 130% !important;
            }
        }
    }
`;
