export const convertDateFields = (data) => {
    // Функция для преобразования полей типа "date" в дефолтных значениях
    return data.map((item) => {
        if (item.date) {
            return {
                ...item,
                date: new Date(item.date * 1000).getFullYear(),
            };
        }
    });
};
