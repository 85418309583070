import React, { useEffect, useState } from 'react';
import { StyledOurDoctorsPage } from './ourDoctorsPage.styled';
import Container from '../../componets/share/Container';
import { MainHeader } from '../../componets/mainHeader/mainHeader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetDoctors, resetDoctors } from '../../componets/ourDoctors/api/doctorsSlise';
import { Filters } from '../../componets/ourDoctors/filters/Filters';
import { DoctorList } from '../../componets/ourDoctors/doctorList/DoctorList';

export const OurDoctorsPage = () => {
  const dispatch = useDispatch()
  const allDoctors = useSelector(state => state.doctors.doctors)
  const [filters, setFilters] = useState({search: '', spec: '9999', sort: ''})

  useEffect(() => {
    const data = {
      search: filters.search,
      spec_ids: filters.spec !== '9999' ? filters.spec : '',
      ordering: filters.sort,
    }
    dispatch(resetDoctors())
    dispatch(fetchGetDoctors(data))
    
  }, [filters])

  return (
    <StyledOurDoctorsPage>
      <Container>
        <MainHeader title={'Наши врачи'} />
        <Filters
          setFilters={setFilters}
        />
        <DoctorList allDoctors={allDoctors} />
      </Container>
    </StyledOurDoctorsPage>
  )
}