import React, { useEffect } from 'react';
import { DataBlockStl } from '../viewDoctor.styled';

export const ViewEducation = ({ education }) => {
    const educationShow = education.map((edu) => {
        return (
            <DataBlockStl key={edu.id}>
                <div className="info__wrapper">
                    <p className="info__data">{edu.university}</p>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">{edu.specialization}</p>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">{edu.end_date ? new Date(edu.end_date * 1000).getFullYear() : ''}</p>
                </div>
            </DataBlockStl>
        );
    });

    return educationShow;
};
