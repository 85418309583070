import React from 'react';
import { useFormState } from 'react-hook-form';

export const SumbitButton = ({name, content, control }) => {
    const { errors } = useFormState({ control });

    return (
        <button name={name} disabled={Boolean(Object.keys(errors).length)} type="submit">
            {content}
        </button>
    );
};
