import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { NewFormSTL } from './newFormSTL.styled';
import { DiseasesList } from '../../shared/DiseasesList';

const PatientDiseasesEdit = () => {
    const { control, trigger } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: 'diseases',
        control,
    });

    const onAdd = () => {
        const newBlock = {
            title: '',
            value: '',
            newField: true, // Дополнительное свойство
        };
        append(newBlock);
    };

    return (
        <NewFormSTL>
            <h2 className="title">Хронические заболевания</h2>

            {fields.map((field, index) => {
                return (
                    <div key={field.id}>
                        <DiseasesList name={`diseases.${index}`} />
                        <button
                            type="button"
                            className="button button_del button_border"
                            onClick={() => remove(index)}
                        >
                            Удалить блок
                        </button>
                    </div>
                );
            })}
            <button
                type="button"
                className="button"
                onClick={() => {
                    onAdd();
                    trigger();
                }}
            >
                {`+ Добавить заболевание`}
            </button>
        </NewFormSTL>
    );
};

export default PatientDiseasesEdit;
