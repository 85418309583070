import React, { useState } from 'react';
import { ReviewsFormStyled } from './reviewsForm.styled';
import TextareaField from '../../share/formFields/newElements/TextareaField';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { FeedbackValue } from '../../share/feedbackValue/FeedbackValue';
import { useDispatch } from 'react-redux';
import { fetchPostPortalReview } from '../api/reviewsSlice';
import { useSelector } from 'react-redux';
import { Modal } from '../../share/Modal';
import { Appointment } from '../../ourDoctors/ourDoctorsModals/appointment/Appointment';

export const ReviewsForm = () => {
    const isAuth = useSelector((state) => state.authentification.isAuth);
    const firstName = useSelector((state) => state.patientProfile.patientProfile?.user?.first_name);
    const lastName = useSelector((state) => state.patientProfile.patientProfile?.user?.last_name);
    const patronymic = useSelector((state) => state.patientProfile.patientProfile?.user?.patronymic);
    const avatar = useSelector((state) => state.patientProfile.patientProfile?.user?.avatar);
    const [value, setValue] = useState(0); // количество звезд
    const [isModalOpen, setOpenModal] = useState(false);
    const dispatch = useDispatch();

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            reviews: {
                textarea: '',
            },
        },
    });

    const {
        formState: { isValid },
    } = methods;

    const reviewText = methods.getValues().reviews?.textarea;
    const closeModal = () => setOpenModal(false);

    const onSubmit = (data) => {
        if (!isAuth) {
            setOpenModal(true);
        }
        const review = data.reviews.textarea;
        const autor = `${lastName ?? ''} ${firstName ?? ''} ${patronymic ?? ''}`.trim();
        const post = {
            autor: autor.length ? autor : 'Гость',
            avatar: avatar,
            rating_value: value,
            text: review,
        };
        dispatch(fetchPostPortalReview(post));
        methods.setValue('reviews.textarea', '');
        setValue(0);
        
    };

    return (
        <ReviewsFormStyled>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} className={'form'}>
                    <FeedbackValue value={value} setValue={setValue} />
                    <p className={`comment`}>Введено символов {reviewText.length}/ 1000</p>
                    <TextareaField
                        defaultValue=""
                        name="reviews.textarea"
                        label="Отзыв"
                        placeholder=""
                        validation={{ required: false }}
                        maxLength={2000}
                    />
                    <p className={'rules'}>
                        Перед отправкой отзыва, пожалуйста, ознакомьтесь с{' '}
                        <Link to="/regulations_reviews" className="rules__link">
                            &thinsp;правилами публикации
                        </Link>
                    </p>
                    <button className={'reviewBtn'} disabled={!reviewText.length || !isValid || !value} type="submit">
                        Оставить отзыв
                    </button>
                </form>
            </FormProvider>
            {isModalOpen && (
                <Modal toggle={closeModal}>
                    <Appointment />
                </Modal>
            )}
        </ReviewsFormStyled>
    );
};
