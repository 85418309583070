import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearMessages, fetchLogin, clearPhone, fetchCheckPhone, fetchMCheckEmailCode, fetchMeUser } from '../api/authentificationSlice';
import { CodeStl } from './code.styled';
import { RequestAgain } from '../requestAgain/ReauestAgain';
// import { Warning } from './Warning';

export const Code = ({code, setCode}) => {
    const {phone_number, isEmailCheck, email, messagePageCode, whoAreYou, messagesError} = useSelector((state) => state.authentification);
    
    const dispatch = useDispatch();

    useEffect(() => {
        messagesError === "Достигнут лимит попыток авторизации. Попробуйте через 12 часов" && dispatch(clearPhone())
    },[messagesError]);

    const inputRefs = {
        first: useRef(),
        second: useRef(),
        third: useRef(),
        fourth: useRef(),
    };

    const handleCode = (e) => {
        const { name, value } = e.target;
        const truncatedValue = (value.slice(0, 1)); // Усекаем введенное значение до одной цифры
        setCode((prevCode) => ({ ...prevCode, [name]: truncatedValue }));
        if (truncatedValue !== '' && name !== 'fourth') {
            focusNextInput(name);
        }
    };

    const jump = (e) => {
        if (messagesError) {
            dispatch(clearMessages());
        }

        const { key, target } = e;

        if (key === 'Backspace' && code[target.name] === '') {
            focusPrevInput(target.name);
        } else if (/^\d$/.test(key) && code[target.name] !== '' && target.name !== 'fourth') {
            focusNextInput(target.name);
        }
    };

    const focusNextInput = (currentName) => {
        const nextInput = inputRefs[currentName].current.nextSibling;

        if (nextInput) {
            nextInput.focus();
        }
    };

    const focusPrevInput = (currentName) => {
        const prevInput = inputRefs[currentName].current.previousSibling;

        if (prevInput) {
            prevInput.focus();
        }
    };

    // отправка кода на почту зарегистрированного пользователя с телефоном и почтой
    const loginMe = (e) => {
        e.preventDefault();
        const pin = code.first + code.second + code.third + code.fourth;
        const data = { phone_number: phone_number, pin, whoAreYou };
        dispatch(fetchLogin(data))
    };

    // отправка кода на почту или телефон пользователя без телефоном или почтой
    const checkPhoneMe = async (e) => {
        e.preventDefault();
        const pin = code.first + code.second + code.third + code.fourth;
        const dataPhone = { phone_number: phone_number, pin, whoAreYou };
        const dataEmail = { email: email, pin };
        if (isEmailCheck) {
            await dispatch(fetchMCheckEmailCode(dataEmail)); // код на почту при подтверждении почты пользователя без почты
            await dispatch(fetchMeUser());
        } else {
            dispatch(fetchCheckPhone(dataPhone)) //код на телефон при регистрации нового пользователя
        }
    };

    return (
        <CodeStl>
            <div className="code">
                {Object.keys(code).map((name, index) => (
                    <input
                        key={name}
                        ref={inputRefs[name]}
                        type="number"
                        name={name}
                        value={code[name]}
                        onKeyUp={jump}
                        onChange={handleCode}
                        autoFocus={index === 0}
                        className={`${code[name] && messagesError === '' ? 'fill' : (messagesError?.length > 0 ? 'error' : '')} input-codeSms`}
                        autoComplete='off'
                    />
                ))}
                {/* <Warning error={messagesError} /> */}
            </div>
            <RequestAgain setCode={setCode} />
            <div className="button">
                    <button
                        className="btn"
                        type="submit"
                        onClick={
                            messagePageCode.includes('email') ? loginMe : checkPhoneMe
                        }
                        disabled={ !(code.first && code.second && code.third && code.fourth) || messagesError?.length > 0 }
                    >
                        Войти
                    </button>
            </div>
        </CodeStl>
    );
};
