import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AuthMe, login, meUser, checkEmail, checkEmailCode } from './authentificationApi';
import { resetDoctorConsultations } from '../../lkDoctor/api/lkDoctorConsultationsSlice';
import { resetPatientConsultations } from '../../lkPatient/api/PatientConsultationsSlice';

export const fetchAuthentification = createAsyncThunk(
    'authentification/fetchAuthentification',
    async (phone) => {
        localStorage.clear();
        sessionStorage.clear();
        return await AuthMe(phone);
    }
);

export const fetchLogout = createAsyncThunk('authentification/fetchLogout', async (_, { dispatch }) => {
    try {
        localStorage.clear();
        sessionStorage.clear();
        dispatch(resetDoctorConsultations());
        dispatch(resetPatientConsultations());
    } catch (e) {
        console.log('ошибка fetchLogout', e);
    }
});

export const fetchLogin = createAsyncThunk('authentification/fetchLogin', async (data) => {
    try {
        const response = await login(data);
        if (response.data.access) {
            sessionStorage.setItem('token', response.data.access);
            localStorage.setItem('refresh', response.data.refresh);
        }
        return response;
    } catch (e) {
        console.log('ошибка login', e);
        return e;
    }
});

export const fetchCheckPhone = createAsyncThunk('authentification/fetchCheckPhone', async (data) => {
    try {
        const response = await login(data);
        if (response.data.access) {
            sessionStorage.setItem('token', response.data.access);
            localStorage.setItem('refresh', response.data.refresh);
        }
        return response;
    } catch (e) {
        console.log('ошибка checkPhone', e);
        return e;
    }
});

export const fetchMeUser = createAsyncThunk('authentification/fetchMeUser', async () => {
    const response = await meUser();
    return response;
});

export const fetchMCheckEmail = createAsyncThunk('authentification/fetchMCheckinEmail', async (email) => {
    try {
        const response = await checkEmail(email);
        return response;
    } catch (e) {
        console.log('ошибка checkEmail', e);
        return e;
    }
});
export const fetchMCheckEmailCode = createAsyncThunk('authentification/fetchMCheckEmailCode', async (data) => {
    try {
        const response = await checkEmailCode(data);
        return response;
    } catch (e) {
        console.log('ошибка checkEmailCode', e);
        return e;
    }
});

const initialState = {
    user: null,
    phone_number: '',
    email: '',
    messagePageCode: '',
    isEmail: true,
    isEmailCheck: false,
    isPreloader: false,
    isAuth: false,
    whoAreYou: '',
    messagesError: '',
    headingError: '',
};

const authentification = createSlice({
    name: 'authentification',
    initialState,
    reducers: {
        setAuth: (state, bool) => {
            state.isAuth = bool.payload;
        },
        setWhoAreYou(state, role) {
            state.whoAreYou = role.payload;
        },
        setEmailSlice(state, action) {
            state.email = action.payload
        },
        clearMessages(state) {
            state.messagesError = '';
            state.headingError = '';
        },
        clearPhone(state) {
            state.phone_number = '';
            state.messagePageCode = '',
            state.messagesError = '';
            state.headingError = '';
        },
    },

    extraReducers: (builder) => {
        builder.addCase(fetchAuthentification.fulfilled, (state, action) => {
            if (action.payload.status !== 201) {
                state.messagesError = action.payload.data.code;
                state.headingError = action.payload.data.heading;
            } else {
                state.phone_number = action.payload.data.phone_number;
                state.messagePageCode = action.payload.data["message "];
            }
            state.isPreloader = false;
        });
        builder.addCase(fetchAuthentification.rejected, (state, action) => {
            console.log(action);
        });
        builder.addCase(fetchLogin.fulfilled, (state, action) => {
            if (action.payload.status !== 200) {
                state.messagesError = action.payload.data.code;
                state.headingError = action.payload.data.heading;
               
            } else {
                state.isAuth = true;
                state.isEmail = true;
            }
        });
        builder.addCase(fetchLogin.rejected, (state, action) => {
            console.log(action);
        });
        builder.addCase(fetchCheckPhone.fulfilled, (state, action) => {
            if (action.payload.status !== 200) {
                state.messagesError = action.payload.data.code;
                state.headingError = action.payload.data.heading;
               
            } else {
                state.isEmail = false;
            }
        });
        builder.addCase(fetchCheckPhone.rejected, (state, action) => {
            console.log(action);
        });
        builder.addCase(fetchMCheckEmail.fulfilled, (state, action) => {
            if (action.payload.status !== 201) {
                state.messagesError = action.payload.data.email[0];
                state.headingError = action.payload.data.heading;
               
            } else {
                state.isEmail = true;
                state.isEmailCheck = true;
                state.messagePageCode = action.payload.data["detail"];
            }
        });
        builder.addCase(fetchMCheckEmail.rejected, (state, action) => {
            console.log(action);
        });
        builder.addCase(fetchMCheckEmailCode.fulfilled, (state, action) => {
            if (action.payload.status !== 200) {
                state.messagesError = action.payload.data?.code_email[0];
                state.headingError = action.payload.data.heading;
               
            } else {
                state.isAuth = true;
                state.isEmail = true;
            }
        });
        builder.addCase(fetchMCheckEmailCode.rejected, (state, action) => {
            console.log(action);
        });
        builder.addCase(fetchLogout.fulfilled, (state) => {
            state.phone_number = '';
            state.email = '';
            state.messagePageCode = '';
            state.isEmail = true;
            state.isEmailCheck = false;
            state.isPreloader = false;
            state.messagesError = '';
            state.headingError = '';
            state.isAuth = false;
            state.whoAreYou = '';
            state.user = null;
        });
        builder.addCase(fetchMeUser.fulfilled, (state, action) => {
            state.user = action.payload;
        });
        // на случай если токен не валидный разлогиниваем юзера
        // сделано тк с бека для не активных пользователей токен приходит не валидный
        // бек починили, оствленно на всякий случай
        builder.addCase(fetchMeUser.rejected, (state, action) => {
            console.log(action);
            state.phone_number = '';
            state.email = '';
            state.messagePageCode = '';
            state.isEmail = true;
            state.isEmailCheck = false;
            state.isPreloader = false;
            state.messagesError = '';
            state.headingError = '';
            state.isAuth = false;
            state.whoAreYou = '';
            state.user = null;
        });
    },
});

export const { setAuth, clearError, setEmailSlice, setWhoAreYou, clearPhone, clearMessages } = authentification.actions;
export default authentification.reducer;
