import React, { memo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ArticleFormStyled } from './articleForm.styled';
import { useDispatch, useSelector } from 'react-redux';
import { postArticleComments } from '../../api/blogSlice';
import TextareaField from '../../../share/formFields/newElements/TextareaField';
import { Appointment } from '../articleModal/Appointment';
import { Modal } from '../../../share/Modal';

const ArticleForm = ({ id }) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const { isAuth } = useSelector((state) => state.authentification);
    const methods = useForm({
        mode: 'onChange',
    });
    function leaveComment({ comment }) {
        if (isAuth) {
            dispatch(
                postArticleComments({
                    post_id: id,
                    text: comment.field,
                })
            );
            methods.reset();
            alert('Комментарий был успешно отправлен на модерацию. Ожидайте публикацию');
        } else {
            setShowModal(true);
        }
    }

    return (
        <ArticleFormStyled>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(leaveComment)}>
                    <TextareaField defaultValue="" name="comment.field" label="" placeholder="Комментарий" />
                    <button className="commentSubmitButton" type="submit">
                        Оставить комментарий
                    </button>
                </form>
            </FormProvider>
            {showModal && (
                <Modal toggle={setShowModal}>
                    <Appointment />
                </Modal>
            )}
        </ArticleFormStyled>
    );
};
export default memo(ArticleForm);
