import styled from 'styled-components';

export const StyledSchedule = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow {
        display: block;
        padding: 5px 12px;
        border-radius: 100%;
        background-color: #f1f1f1f1;
    }

    .table {
        width: 90%;
        display: flex;
        justify-content: space-between;
    }

    .tableColumn {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 12%;

        & p {
            font-weight: 400;
            font-size: 16px;
        }

        & p:last-child {
            margin-top: 10px;
        }
    }

    .switch {
        transform: rotate(180deg);
        transition: all ease-in-out 0.7s;
        cursor: pointer;
    }

    .switchBack {
        transition: all ease-in-out 0.7s;
        cursor: pointer;
    }

    .day {
        border-radius: 100%;
        border: 2px solid transparent;
        padding: 8px;
        min-width: 40px;
        text-align: center;
        transition: all 0.3s linear;
        cursor: pointer;
        :hover {
            border-color: #19be6f;
        }
        &-is-receptions {
            color: #000;
        }
        &-no-receptions {
            color: #b6b7ba;
        }
        &-select {
            color: #000;
            border-color: #19be6f;
            background-color: #19be6f;
        }
        &-today {
            color: #19be6f;
        }
    }

    @media (max-width: 460px) {
        .tableColumn {
            & p {
                font-size: 14px;
            }
        }
    }
`;
