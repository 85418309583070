import styled from 'styled-components';

export const ScheduleViewerStl = styled.div`
    overflow-y: auto;
    height: 382px;
    margin-top: 23px;
    margin-bottom: 10px;
    padding: 13px 4px 13px 10px;
    border: 1px solid #b6b7ba;
    border-radius: 10px;
    display: flex;

    .schedule {
        &__placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 23px;
            overflow-y: hidden;
            text-align: center;
            font-size: 20px;
            line-height: 130%;
            color: #000;
            width: 100%;
        }

        &__list {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 4px;
            overflow-y: auto;

        }
        &__list::-webkit-scrollbar {
            width: 5px;
        }

        &__list::-webkit-scrollbar-thumb {
            background-color: #666666;
            border-radius: 5px;
        }

        &__item {
            display: flex;
            gap: 4px;
            align-items: flex-start;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;

            p:first-child {
                min-width: 50px;
            }
            .work-time {
                color: #666;
            }
            .black-color {
                color: #212121;
            }
            .no-work {
                color: #b6b7ba;
            }
        }
    }
`;
