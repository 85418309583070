import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import { fetchAuthentification, fetchMCheckEmail, setEmailSlice } from '../api/authentificationSlice';
import { LabelAgreement } from '../labelAgreement/LabelAgreement';
import { EmailStl } from './email.styled';
import InputEmail from '../../share/formFields/InputEmail';
import { useController, useForm } from 'react-hook-form';
import { validateRequired, validateInputEmail } from '../../share/formFields/validateInput';
export const Email = ({ who }) => {
    // const [agreements, setAgreements] = useState(false);
    const [email, setEmail] = useState('');
    const [isValid, setValid] = useState(false);
    const [enableBtn, setEnableBtn] = useState(false);
    const dispatch = useDispatch();
    const {
        register,
        control,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
    });
    const defaultValidation = {
        ...validateRequired(true),
        ...validateInputEmail
    }
    const { name } = register('email');
    const { field } = useController({ name, control, rules: defaultValidation});

    const handleChange = () => {
        const inputText = getValues(name).trim();
        setValue(name, inputText);
    };

    const handleBlur = () => {
        const emailInput = getValues(name).replace(/ /g, '');
        field.onChange(emailInput);
    };

    useEffect(() => {
        if (errors?.[name]?.message) {
            setValid(false)
        } else {
            setValid(true)
        }
    },[errors?.[name]?.message])

    useEffect(() => {
        if (who && isValid) {
            setEnableBtn(true);
        } else {
            setEnableBtn(false);
        }
    }, [email, who, isValid]);

    const authMe = (e) => {
        e.preventDefault();
        const email = getValues(name);
        if (enableBtn) {
            dispatch(setEmailSlice(email));
            dispatch(fetchMCheckEmail(email));
        }
    };

    return (
        <EmailStl onSubmit={authMe}>
            <div className="auth_email">
                <label htmlFor="email" className="input__label">Email</label>
                <input
                    className={`input__field ${!isValid ? "input__field_error" : ""}`}
                    id="email"
                    type="text"
                    placeholder={'Example@gmail.com'}
                    {...register('email', {
                        onChange: () => handleChange(),
                        onBlur: () => handleBlur(),
                    })}
                />
                <span className="input__error" >{errors?.email?.message}</span>
            </div>
            {/* <LabelAgreement agreements={agreements} setAgreements={setAgreements} /> */}
            <button className="button" type="submit" disabled={!enableBtn}>
                Далее
            </button>
        </EmailStl>
    );
};
