import styled from 'styled-components';
import arrow from '../../../../img/icons/arrow-transaction.svg';

// PayCard.jsx

export const PayCardStl = styled.div`
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 25px;

    .pay__info {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .pay__type {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #666666;
    }

    .pay__status {
        display: flex;
        flex-direction: column;
        align-self: flex-end;

        > p {
            font-weight: 400;
            font-size: 20px;
            color: #212121;
        }
    }
`;

export const FioStl = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    @media (max-width: 460px) {
        font-size: 16px;
    }
`;

export const DateStl = styled.div`
    font-size: 16px;
    line-height: 150%;
    color: #666666;
    align-items: center;
    display: flex;
`;

// Total.jsx

export const TotalStl = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;

    > span {
        padding: 12.5px 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        color: #212121;
        @media (max-width: 460px) {
            font-size: 16px;
            line-height: 120%;
        }
    }
`;

// Filters.jsx

export const FiltersStl = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    .filters__top {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .filter-container {
        gap: 12px;
        > label {
            width: 50%;
        }
    }

    .select__title {
        font-weight: 400;
        font-size: 14px;
        color: #282828;
    }
    .reset {
        border: none;
        background-color: transparent;
        text-align: right;
        font-weight: 400;
        font-size: 14px;
        color: #19BE6F;
        cursor: ${({selected}) => selected ? 'pointer' : 'default'};
        opacity: ${({selected}) => selected ? 1 : 0.5};
    }
`;


export const SelectWrapperStl = styled.div`
    // gap: 103px;
    padding-bottom: 22px;
    display: flex;
    width: 100%;

    .select-wrapper {
        position: relative;
        display: flex;
        width: 100%;
    }
    .select-wrapper:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 11px;
        display: block;
        transform: translateY(-50%);
        pointer-events: none;
        width: 20px;
        height: 20px;
        background-image: url(${arrow});
        background-repeat: no-repeat;
    }
`;

export const SelectStl = styled.select`
    width: 100%;
    height: 44px;
    border: 1px solid #a2a3a8;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 11px;
    font-size: 16px;
    line-height: 130%;
    cursor: pointer;
    appearance: none;
    .select__items {
        font-size: 16px;
        line-height: 150%;
    }
    .select__items:first-child {
        display: none;
    }
`;