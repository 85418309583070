import styled from 'styled-components';

export const ScheduleSettingsStl = styled.div`
    margin-top: 23px;
    margin-bottom: 10px;
    min-height: 382px;
    border: 1px solid #b6b7ba;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(10, 1fr) ;
    gap: 8px;
    padding: 16px;
    user-select: none;
    transition: background-color 0.2s linear;

    .time {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #b6b7ba;
        border: 1px solid transparent;
        border-radius: 32px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s linear;

        &:hover {
            border-color: #19be6f;
        }

        &__isWork {
            background-color: #19be6f;
            color: #fff;
            &:hover {
                background-color: #169056;
            }
        }

        &__consultation::before {
            position: absolute;
            content: '';
            width: 4px;
            height: 4px;
            background-color: #fff;
            left: 50%;
            bottom: 0;
            border-radius: 100%;
            transform: translate(-50%, -50%);
        }
    }
`;
