import React, { useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { AllergyList } from '../../shared/AllergyList';
import { filterDeletedBlocks } from '../../helpers/filterDeletedBlocks';
import { useDispatch } from 'react-redux';
import { getPatientAllergies } from '../../../api/PatientProfileSlice';
import { postMyAllergies, putMyAllergiesById, deleteMyAllergiesById } from '../../../api/patientApi';

export const EditAllergy = ({ allergies, toggle }) => {
    const [isLoader, setIsLoader] = useState(false);

    const dispatch = useDispatch();
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            allergies: allergies,
        },
    });

    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: 'allergies',
    });

    // если поля испачканы - узнаем здесь
    const { dirtyFields, isValid, isSubmitting } = useFormState({ control: methods.control });
    const onSubmit = ({ allergies: currentList }) => {
        setIsLoader(true);
        const promises = [];
        const deletedBlocks = filterDeletedBlocks(allergies, currentList);
        deletedBlocks?.forEach((item) => {
            promises.push(deleteMyAllergiesById(item.id));
        });

        currentList.forEach((block, i) => {
            const newAllergies = {
                ...block,
            };
            // если новый блок
            if (block.newField) {
                const { newField, ...request } = newAllergies;
                promises.push(postMyAllergies({ ...request }));
            }
            // если измененный
            if (!block.newField && dirtyFields?.allergies?.[i]) {
                const { id, title, value } = newAllergies;
                promises.push(putMyAllergiesById({ id, title, value }));
            }
        });

        Promise.all(promises)
            .then(() => setIsLoader(false))
            .then(() => toggle(false))
            .then(() => {
                dispatch(getPatientAllergies());
            })
            .catch((err) => {
                console.log(err);
                return setIsLoader(false);
            });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const onAdd = () => {
        const newBlock = {
            title: '',
            value: '',
            newField: true, // Дополнительное свойство
        };
        append(newBlock);
    };

    const inputs = fields.map((education, index) => {
        return (
            <section className="block" key={education.id}>
                <AllergyList name={`allergies.${index}`} />
                <span className="delete" onClick={() => remove(index)}>
                    Удалить блок
                </span>
            </section>
        );
    });

    return (
        <>
            <FormProvider {...methods}>
                <form className="formDynamicInput" onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="inputs">{inputs}</div>
                    <button className="btn__addBlock" type="button" onClick={onAdd}>
                        {`+ Добавить запись`}
                    </button>
                    <button className="btn__recive" type="submit" disabled={!isValid || isSubmitting || isLoader}>
                        {isLoader ? 'Сохранение...' : 'Сохранить'}
                    </button>
                </form>
            </FormProvider>
        </>
    );
};
