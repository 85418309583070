import styled from 'styled-components';

export const QuoteStyled = styled.div`
    display: flex;
    width: 100%;
    min-height: 120px;
    padding: 20px;
    justify-content: space-between;
    align-items: space-between;
    flex-wrap: wrap;
    gap: 10px;
    border-radius: 10px;
    background: var(--secondary-surface, #f4f4f4);
    margin-top: 60px;

    .quotes {
        align-self: flex-start;
        height: 30px;
        width: 30px;
        margin-right: 20px;
    }

    .text,
    .author {
        width: 100%;
        color: var(--primary-text, #212121);
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .text {
        flex: 1;
    }

    .author {
        text-align: right;
        align-self: end;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }

    @media (max-width: 768px) {
        .author {
            font-size: 14px;
            line-height: 150%;
        }
        .quotes {
            margin-right: 10px;
        }
    }
    @media (max-width: 500px) {
        margin-top: 40px;

        .quotes {
            height: 24px;
            width: 24px;
            margin-right: 0px;
        }

        .text {
            font-size: 18px;
            line-height: 130%;
        }
    }
`;
