import React from 'react';
import InputText from '../../../share/formFields/newElements/InputText';
import { validateInputLength } from '../../../share/formFields/validateInput';

export const MedicationList = ({ name }) => {

    return (
        <>
            <InputText
                label='Препарат'
                placeholder='Название'
                name={`${name}.title`}
                validation={validateInputLength(100)}
            />
            <InputText
                label='Дозировка'
                placeholder='Разовая'
                name={`${name}.dosage`}
                validation={validateInputLength(100)}
            />
            <div className='label-last'>
                <InputText
                    label='Периодичность приема'
                    placeholder='Сколько раз в день'
                    name={`${name}.frequency`}
                    validation={validateInputLength(100)}
                /> 
            </div>
        </>
        
    );
};


