import React, { useMemo } from 'react';
import { FormProvider, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { filterDeletedBlocks } from '../helpers/filterDeletedBlocks';
import { deleteDoctorHigherEducation, putDoctorHigherEducation, setDoctorHigherEducation } from '../../../api/api';
import { fetchMeDoctor } from '../../../api/doctorUserProfileSlice';
import { useDispatch } from 'react-redux';
import { convertDateFields } from '../helpers/convertDateFields';
import { EducationList } from '../../shared/EducationList';

export function EditDoctorEducation({ higher_education, toggle }) {
    const dispatch = useDispatch();
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            // (дефолтные поля)конвертируем дату из секунд в yyyy
            higher_education: convertDateFields(higher_education),
        },
    });
    // для управления массивом
    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: 'higher_education',
    });
    // если поля испачканы - узнаем здесь
    const { dirtyFields, isValid, isSubmitting } = useFormState({ control: methods.control });

    const onSubmit = ({ higher_education: currentList }) => {
        const promises = [];
        // вычислим удаленные блоки и удалим их с бека
        const deletedBlocks = filterDeletedBlocks(higher_education, currentList);
        deletedBlocks?.forEach((item) => {
            promises.push(deleteDoctorHigherEducation(item.id));
        });

        currentList.forEach((block, i) => {
            // форматируем дату для бека
            const end_date = Date.UTC(block.end_date, 0, 1) / 1000;
            const newUniver = {
                ...block,
                end_date,
            };

            // если новый блок
            if (block.newField) {
                const { newField, ...request } = newUniver;
                promises.push(setDoctorHigherEducation({ qualification: 'заглушка', ...request })); // убрать когда исправят на беке
            }
            // если измененный
            if (!block.newField && dirtyFields?.higher_education?.[i]) {
                const { university, specialization, end_date, id } = newUniver;
                promises.push(
                    putDoctorHigherEducation({ qualification: 'заглушка', university, specialization, end_date, id })
                );
            }
        });

        Promise.all(promises)
            .then(() => toggle(false))
            .then(() => {
                dispatch(fetchMeDoctor());
            });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const onAdd = () => {
        const newBlock = {
            university: '',
            specialization: '',
            end_date: '',
            newField: true,
        };
        append(newBlock);
    };

    const inputs = fields.map((education, index) => {
        return (
            <section className="block" key={education.id}>
                <EducationList name={`higher_education.${index}`} />
                {fields.length > 1 && (
                    <span className="delete" onClick={() => remove(index)}>
                        Удалить блок
                    </span>
                )}
            </section>
        );
    });

    return (
        <FormProvider {...methods}>
            <form className="formDynamicInput" onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="inputs">{inputs}</div>
                <button className="btn__addBlock" onClick={onAdd}>
                    {`+ Добавить место обучения`}
                </button>
                <button className="btn__recive" type="submit" disabled={!isValid || isSubmitting}>
                    Сохранить
                </button>
            </form>
        </FormProvider>
    );
}
