import styled from 'styled-components';

export const FooterButtonsStyled = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 42px;

    .toTopPage {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .toTopPageButton,
    .showMoreButton {
        cursor: pointer;
        color: var(--primary-accent, #19be6f);
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    @media (max-width: 600px) {
        .toTopPageButton,
        .showMoreButton {
            font-size: 14px;
        }

        .toTopPage {
            margin-left: auto;
        }
    }
`;
