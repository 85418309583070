import styled from 'styled-components';

export const ContactFormStl = styled.div`
    padding-bottom: 90px;
    .subtitle {
        color: #666;
        font-size: 20px;
        margin-bottom: 39px;
    }
    fieldset {
        border: none;
        display: grid;
        max-width: 863px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, auto);
        column-gap: 20px;
        label:has(> textarea) {
            grid-column: 1/-1;
        }
        textarea {
            max-width: none;
        }
        input {
            max-width: none;
        }
    }
    button {
        display: block;
        width: 245px;
        padding: 16px;
        background: #19be6f;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: white;
        cursor: pointer;
        &:disabled {
            opacity: 0.7;
            cursor: auto;
        }
    }

    @media (max-width: 767px) {
        .subtitle {
            font-size: 18px;
            margin-bottom: 26px;
        }
        fieldset {
            display: block;
            margin-bottom: 7px;
        }
    }
    @media (max-width: 380px) {
        button {
            width: 288px;
        }
    }
`;
