import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowUp } from '../../img/icons/newDoctorIcons/up-arrow.svg';
import { DoctorReviewsStl } from '../doctorProfile/doctorReviews/doctorReviews.styled';
import { ScrollToTopArrowUpStl } from './ScrollToTopArrowUpStl.style';

export const ScrollToTopArrowUp = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <ScrollToTopArrowUpStl>
            {isVisible && (
                <button className="buttonUp" onClick={scrollToTop}>
                    Наверх
                    <ArrowUp />
                </button>
            )}
        </ScrollToTopArrowUpStl>
    );
};