import styled from 'styled-components';

export const StyledDoctorsTop = styled.section`
  background-color: #f7f7f7;
  padding-bottom: 90px;
  .doctorsTop__heading {
    margin-bottom: 32px;
    // font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 130%;
    color: #212121;
  }
  ul {
    list-style: none;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
    justify-content: center;
    gap: 15px;
  }
  .doctorsTop__button {
    margin-top: 28px;
    border: none;
    background: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #19BE6F;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }

  @media(max-width: 800px) {
    .doctorsTop__heading {
      font-size: 24px;
    }
  }

  @media(max-width: 500px) {
    .doctorsTop__heading {
      font-size: 20px;
    }
  }
`