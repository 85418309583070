import { apiRequest } from '../../share/api';

export const getUpcomingConsultation = async (role) => {
    if (role === 'patient') {
        return await apiRequest.get('/consultations/?status=0');
    }
    if (role === 'doctor') {
        return await apiRequest.get('/consultations/doctor/?status=0');
    }
};
