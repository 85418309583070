import React, { useState, useEffect } from 'react';
// import { StyledLkDoctorNavigation } from '../../lkDoctor/lkDoctorNavigation/lkDoctorNavigation.styled';
import { NavLink, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MainMenu } from '../../share/mainMenu/mainMenu';
import { StyledLkPatientNavigation, StyledNavigation } from './lkPatientNavigation.styled';
import { useMediaQuery } from 'react-responsive';


const naviData = [
    {
        key: 1,
        title: 'Профиль',
        linkPathTo: '/lk/patient-profile/profile',
    },
    {
        key: 2,
        title: 'Мои консультации',
        linkPathTo: '/lk/patient-profile/consultation',
        children: [
            {
				key: 2.1,
                title: 'Запланированные',
                linkPathTo: '/lk/patient-profile/consultation/future-consultation',
            },
            {
				key: 2.2,
                title: 'Прошедшие',
                linkPathTo: '/lk/patient-profile/consultation/past-consultation',
            },
        ],
    },
    {
        key: 3,
        title: 'Избранные врачи',
        linkPathTo: '/lk/patient-profile/favorites',
    },
    {
        key: 4,
        title: 'Настройки',
        linkPathTo: '/lk/patient-profile/settings',
    },
];

export const LkPatientNavigation = () => {
    const { favorite } = useSelector((state) => state.favoriteDoctors);
    
    return (
        <StyledLkPatientNavigation>
            <MainMenu naviData={naviData} />
            
            {/* {(favorite.length === 0 && isTablet) ? 
                <NavLink className="nav-link nav-button" to="/doctors">
                    Записаться&nbsp;к&nbsp;врачу
                </NavLink> :
            null
            } */}
        </StyledLkPatientNavigation>
    );
};
