import { apiRequest } from '../../share/api';

export const requestReviews = async (countPage) => {
  const response = await apiRequest.get(`/feedback_service/?page=${countPage}`);
  const data = response.data;
  return data;
};

export const postReview = async (post) => {
  await apiRequest.post(`/feedback_service/`, post);
};

export const findCountConsultationsPatient = async () => {
  const response = await apiRequest.get(`/consultations/?page=1`);
  const data = response.data.count;
  return data;
};

export const findCountConsultationsDoctor = async () => {
  const response = await apiRequest.get(`/consultations/doctor/?page=1`);
  const data = response.data;
  return data.count;
};