import React, { useEffect } from 'react';
import { dateFormatter, ageCalculator } from '../../../../share/helpers';
import { DataBlockStl } from '../viewDoctor.styled';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const ViewData = ({ user }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user?.last_name === '') {
            navigate('/lk/doctor-profile/profile/edit');
        }
    }, [user]);

    if (!user) return null;

    const { first_name, last_name, patronymic, birthday, gender_label, phone, email, city } = user;

    const phoneV = `+7 (${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(8, 10)}-${phone.slice(10, 12)}`;

    return (
        <DataBlockStl>
            <div className="info__wrapper">
                <p className="info__data">{`${last_name} ${first_name} ${patronymic}`}</p>
            </div>
            <div className="info__wrapper">
                {/* <p className="info__data">{birthday ? dateFormatter(birthday).slice(0, 10) : ''}</p> */}
                <p className="info__data">{birthday ? ageCalculator(birthday) : ''}</p>
            </div>
            <div className="info__wrapper">
                <p className="info__data">{gender_label}</p>
            </div>
            <div className="info__wrapper">
                <p className="info__data">{phoneV}</p>
            </div>
            {email && (
                <div className="info__wrapper">
                    <p className="info__data">{email}</p>
                </div>
            )}
            {city && (
                <div className="info__wrapper">
                    <p className="info__data">{city}</p>
                </div>
            )}
        </DataBlockStl>
    );
};
