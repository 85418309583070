import React, { useRef, useState } from 'react';
import { LkPatientProfileStl, StyledUserLogo } from './lkPatientProfile.styled';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { fetchLoadMyAvatar } from '../api/PatientProfileSlice';
import RoundPhoto from '../../share/RoundPhoto';
import { uploadFile } from '../../share/fileUploader';
import { Loader } from '../../lkDoctor/loader/loader';
import PhotoAlt from '../../../img/photo_alt.png';
import ImageHover from '../../../img/photo_hover.png';
import { useSelector } from 'react-redux';

export const LkPatientProfile = () => {
    const [errorLoadAvatar, setErrorLoadAvatar] = useState(false);
    const [isLoad, setIsLoad] = useState(false);

    const dispatch = useDispatch();
    const path = useLocation();
    const redirect = useNavigate();

    const { patientProfile } = useSelector((state) => state.patientProfile);
    const refAvatar = useRef();

    useEffect(()=>{
        let delayAvatarId = setTimeout(() => setErrorLoadAvatar(false), 5000);
        return () => {clearTimeout(delayAvatarId)};
    },[isLoad]);

    const addPhoto = () => {
        const sendUpResult = (file) => {
            file.size > 2097152 ?
                (setErrorLoadAvatar(true), setIsLoad(!isLoad)) :
                dispatch(fetchLoadMyAvatar(file));
        };
        // const sendUpResult = (file) => {

        //     dispatch(fetchLoadMyAvatar(file));
        // };
        uploadFile(
            refAvatar,
            {
                multiple: false,
                accept: ['.jpg', '.png', '.jpeg', '.gif'],
            },
            sendUpResult
        );
    };

    const handleDeleteAvatar = () => {
        console.log('delete file')
    }

    useEffect(() => {
        if (path.pathname === '/lk/patient-profile/profile') {
            redirect('/lk/patient-profile/profile/view', { replace: true });
        }
    }, [path]);

    if (!patientProfile) {
        return <Loader />;
    }
    const { avatar } = patientProfile?.user;

    return (
        <LkPatientProfileStl>
            <div className='avatar'>
                <StyledUserLogo onClick={addPhoto}>
                    <img src={ImageHover} />
                    <RoundPhoto size="156px" addr={avatar ? `${avatar}` : PhotoAlt} />
                    <input type="file" name="" id="loadPhoto" ref={refAvatar} />
                </StyledUserLogo>
                {errorLoadAvatar || !avatar ? '' : 
                    <button className="deleteAvatarButton" onClick={handleDeleteAvatar}>Удалить фото</button>
                }
                {errorLoadAvatar && <span className="errorLoadAvatar">Размер файла должен быть не более 2 МБ</span>}    
            </div>
            <div className="data">
                <Outlet />
            </div>
        </LkPatientProfileStl>
    );
};
