import React from 'react';
import { useSelector } from 'react-redux';
import { DoctorDeseasesStyled } from './DoctorDeseases.styled';



export const ViewDeseases = () => {
    const {
        healing_diseases: { content },
    } = useSelector((state) => state.doctorProfile?.doctorProfile);

    

    return (
        <DoctorDeseasesStyled>
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </DoctorDeseasesStyled>
    );
};
