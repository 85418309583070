import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { SectionStl } from '../viewDoctor.styled';
import EditDeseases from './EditDeseases';
import { ViewDeseases } from './ViewDeseases';
import { ModerationLabel } from '../../../../share/ModerationLabel';

export const DoctorDeseases = () => {
    const [editData, toggleEditData] = useState(false);
    const {
        healing_diseases: { is_moderated },
    } = useSelector((state) => state.doctorProfile?.doctorProfile);
    return (
        <SectionStl>
            <TitleWithPencil toggle={toggleEditData} title={'Диагностика и лечение заболеваний'} />
            <ModerationLabel moderation={is_moderated} />
            {editData ? <EditDeseases toggle={toggleEditData} /> : <ViewDeseases />}
        </SectionStl>
    );
};
