import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../share/Container';
import Avatar from '../avatar/avatar';
import Navbar from '../navbar/navbar';
import SearchInput from '../search/searchInput';
import { StyledMobileMenu } from './mobileMenu.styled';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserNavigation } from '../userNavigation/UserNavigation';
import { Modal } from '../../share/Modal';
import PopUpConfirm from '../../share/PopUpConfirm';
import { fetchLogout } from '../../authentification/api/authentificationSlice';

const MobileMenu = ({ user }) => {
    const path = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mobileMenu = useRef(null);
    const [isOpenDropMenu, setIsOpenDropMenu] = useState(false);
    const isAuth = useSelector((state) => state.authentification.isAuth);

    const [isPopUpOpen, setPopUpOpen] = useState(false);

    const handleLogOut = () => {
        dispatch(fetchLogout());
        setPopUpOpen(false);
        navigate('/');
    };

    const toggleMenu = () => {
        setIsOpenDropMenu(prev => !prev);
        document.body.classList.toggle('stop-scroll')
    };

    useEffect(() => {
        setIsOpenDropMenu(false)
        document.body.classList.remove('stop-scroll')
    }, [path])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (mobileMenu.current && !mobileMenu.current.contains(event.target)) {
                setIsOpenDropMenu(false);
                document.body.classList.remove('stop-scroll')
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <StyledMobileMenu ref={mobileMenu}>
            <div className="mobileMenu__inner">
                <div className={`mobileMenu__burger ${isOpenDropMenu ? 'open' : ''}`} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className={`mobileMenu__dropMenu ${isOpenDropMenu ? 'open' : ''}`}>
                    <Container>
                        <SearchInput />
                        <div className='menu-wrapper'>
                            <div>
                                <Navbar />
                                {isAuth ? (
                                    <Avatar role={user?.role} avatar={user?.avatar} />
                                ) : (
                                    <Link className='header__signin-link' to={'signin'}> Войти </Link>
                                )}
                            </div>
                            <UserNavigation setPopUpOpen={setPopUpOpen}/>
                        </div>
                    </Container>
                </div>
                {isPopUpOpen && (
                <Modal toggle={setPopUpOpen}>
                    <PopUpConfirm
                        onClose={() => setPopUpOpen(false)}
                        onConfirm={() => handleLogOut()}
                        contentHeader={
                            <span>
                                Вы уверены, что хотите <br /> выйти из личного кабинета?
                            </span>
                        }
                        isClarification={false}
                    />
                </Modal>
            )}
            </div>
        </StyledMobileMenu>
    );
};

export default MobileMenu;
