// import { name } from "file-loader";

// Конвекртирует дату с Бэка в человекочитаемый код
export const dateFormatter = (time) => {
    const date = new Date(time * 1000);
    let dd = date.getDate().toString().padStart(2, '0');
    let mm = (date.getMonth() + 1).toString().padStart(2, '0');
    let yyyy = date.getFullYear();
    return `${dd}.${mm}.${yyyy}г.`;
};
export const dateFormatterForDoctorWork = (time) => {
    const date = new Date(time * 1000);
    let dd = date.getDate().toString().padStart(2, '0');
    let mm = (date.getMonth() + 1).toString().padStart(2, '0');
    let yyyy = date.getFullYear();
    return `${mm}.${yyyy}`;
};
export const dateFormatterForFormDoctor = (time) => {
    const date = new Date(time * 1000);
    let dd = date.getDate().toString().padStart(2, '0');
    let mm = (date.getMonth() + 1).toString().padStart(2, '0');
    let yyyy = date.getFullYear();
    return { dd, mm, yyyy };
};
export const dateFormatterShortened = (time) => {
    const date = new Date(time * 1000);
    let dd = date.getDate().toString().padStart(2, '0');
    let mm = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${dd}.${mm}`;
};
/**
 * Получаем дату в формате UNIX
 * @param {String} year - год строкой в формате yyyy
 * @param {String} month - месяц строкой в формате mm
 * @param {String} day - день строкой в формате dd
 * @returns {Number} возвращает числовое представление даты в формате UNIX
 */
export const dateToUnix = (year = '0000', month = '01', day = '01') => {
    return Math.floor(new Date(`${year}.${month}.${day}`).getTime() / 1000);
};
export const dateToUnixMY = (date) => {
    const [month, year] = date.split('/');
    const dateUnix = Math.floor(new Date(year, (month-1), 1, 4, 0, 0, 0).getTime() / 1000);
    return dateUnix;
};

export const dateFormatterYears = (time) => {
    const date = new Date(time * 1000);
    let yyyy = date.getFullYear();

    return `${yyyy}`;
};

// export const dateTimeFormatter = (time) => {
//     const date = new Date(time * 1000);
//     let dd = date.getDate().toString().padStart(2, '0');
//     let mm = date.getMonth().toString().padStart(2, '0');
//     let yyyy = date.getFullYear();
//     let hour = date.getHours();
//     let min = date.getMinutes();
//     return `${hour}:${min} ${dd}.${mm}.${yyyy}г.`;
// };

// export const tryDateTimeFormatter = (time) => {
//     const date = new Date(time * 1000);
//     let dd = date.getDate().toString().padStart(2, '0');
//     let mm = (date.getMonth() + 1).toString().padStart(2, '0');
//     let yyyy = date.getFullYear();
//     let hour = date.getHours().toString().padStart(2, '0');
//     let min = date.getMinutes().toString().padStart(2, '0');
//     return `${hour}:${min} ${dd}.${mm}.${yyyy}г.`;
// };

// Конвертирует дату в формат день(цифрами):месяц(прописью):год(цифрами)
export const dateFormatterFullMnth = (time) => {
    const date = new Date(time * 1000);
    let dd = date.getDate().toString().padStart(2, '0');
    let month = date.toLocaleString('ru', { month: 'long' });
    let yyyy = date.getFullYear();
    // Результат выполнения: 24 декабрь 2022 г.
    return `${dd} ${month} ${yyyy} г.`;
};
// Конвертирует дату в формат день(цифрами):месяц(прописью):год(цифрами) для PayCard в lkDoctorWallet/transactions
export const dateFormatterFullMnthForPayCard = (time) => {
    const date = new Date(time * 1000);
    const dd = date.getDate().toString().padStart(2, '0');
    const mm = date.getMonth();
    const yyyy = date.getFullYear();
    const monthNamesGenitive = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
    ];
    const monthGenitive = monthNamesGenitive[mm];
    // Результат выполнения: 24 декабря 2022
    return `${dd} ${monthGenitive} ${yyyy}`;
};

// export const dateFormatterFullMnth2 = (time) => {
//     const date = new Date(time * 1000);
//     let dd = date.getDate().toString().padStart(2, '0');
//     let month = date.getMonth();
//     let yyyy = date.getFullYear();

//     return `${dd} ${month} ${yyyy}г.`;
// };

// Получение текущего дня недели ("Понедельник")
export const getWeekDay = (time) => {
    const date = new Date(time * 1000).getDay();
    const days = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];
    const weekDay = days[date];
    return weekDay;
};

export const dateFormatterFullMnth3 = (time) => {
    const date = new Date(+time * 1000);
    let dd = date.getDate().toString().padStart(2, '0');
    let month = date.toLocaleString('ru', { month: 'long' });
    let dayWeek = getWeekDay(time);

    return `${dd} ${month}, ${dayWeek}`;
};
// принимает timestamp / 1000, возвращает формат yyyy-mm-dd
// export const dateFormatterFullMnth4 = (time) => {
//     const date = new Date(time * 1000);
//     let dd = date.getDate().toString().padStart(2, '0');
//     let month = date.getMonth() + 1;
//     let yyyy = date.getFullYear();
//     return `${yyyy}-${month < 10 ? '0' + month : month}-${dd}`;
// };

// Получение проведенного количество секунд в консультаци 
export const calculateElapsedTime = (startTime, endTime) => {
    if (startTime && endTime) {
        const elapsedMilliseconds = endTime - startTime;
        const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
        return elapsedSeconds;
    }
    return 0;
};

export const getTimeFromDate = (time) => {
    // const timeZone = new Date().getTimezoneOffset();
    const date = new Date(time * 1000);
    const hh = date.getHours().toString().padStart(2, '0');
    const mm = date.getMinutes().toString().padStart(2, '0');

    return `${hh}:${mm}`;
};

// export const dateFormatterFullLocal = (time) => {
//     let date;
//     if (time?.day) {
//         date = new Date(time.year, time.month, time.day);
//     } else date = time;
//     const weekDay = date.toLocaleDateString('ru-Ru', { weekday: 'long' });
//     const dd = date.getDate().toString();
//     const month = date.toLocaleString('ru', { month: 'long' });
//     const yyyy = date.getFullYear();

//     return `${weekDay} ${dd} ${month} ${yyyy} г.`;
// };

// сравнивает дату с текущей и возвращает булево значение в зависимости от момента, прошлое или будущее
export const dateComparator = (time) => {
    const now = new Date();
    return now.getTime() < time * 1000;
};

//сравнивает даты и возвращает булево значение в зависимости от того - разница больше или меньше суток
export const dateComparatorOneDay = (time) => {
    const now = new Date();
    const oneDay = 24 * 3600 * 1000;
    let res = time * 1000 - now.getTime() > oneDay;
    return res;
};

// вычисляем возраст (полных лет)
export function ageCalculator(date) {
    const now = new Date();
    const birth = new Date(date * 1000);
    let age = now.getFullYear() - birth.getFullYear();

    if (now.getMonth() < birth.getMonth() || (now.getMonth() === birth.getMonth() && now.getDate() < birth.getDate())) {
        age -= 1;
    }

    const titles = [' год', ' года', ' лет'];
    const cases = [2, 0, 1, 1, 1, 2];

    return age + titles[age % 100 > 4 && age % 100 < 20 ? 2 : cases[age % 10 < 5 ? age % 10 : 5]];
}

// принимает стаж (seniority) в годах, возвращает стаж в годах с правильным окончанием (год/года/лет)
getSeniorityWithEnd;
export function getSeniorityWithEnd(seniority) {
    const titles = [' год', ' года', ' лет'];
    const cases = [2, 0, 1, 1, 1, 2];
    return (
        seniority +
        titles[seniority % 100 > 4 && seniority % 100 < 20 ? 2 : cases[seniority % 10 < 5 ? seniority % 10 : 5]]
    );
}

// из числа n делает массив от 1 до n
export const numberToArray = (number) => {
    return Array.from({ length: number }, (_, i) => i + 1);
};

// export function generateSeriesTimeInterval(step) {
//     const dt = new Date(1970, 0, 1);
//     const rc = [];
//     while (dt.getDate() === 1) {
//         rc.push(dt.toLocaleTimeString('rus', { timeStyle: 'short' }));
//         dt.setMinutes(dt.getMinutes() + step);
//     }
//     // if (step === 30) {
//     //     // return rc.slice(16, 45);
//     //     return rc;
//     // } else {
//     return rc;
//     // }
// }

// export const transformDateIntoDigit = (date, time) => {
//     return Date.parse(`${date.year}-${date.month}-${date.day} ${time}`) / 1000;
// };

// /**
//  * Функция проверяет введенные пользователем данные на соответствие структуре
//  * с помощью регулярного выражения. Может проверять email, phone, INN.
//  * @param {string} payload проверяемое знаяение, например petrov@mail.ru
//  * @param {string} type тип регулярного выражения. Проверяет типы "email", "phone"
//  * @returns {boolean} возвращает true если формат соответствует или false
//  */
// export function regExpValidation(payload, type) {
//     switch (type) {
//         case 'email':
//             const emailRegexp = new RegExp(/^[\p{L}\d\S]+([\p{L}\d\S._-]*)@[a-z\d.-]+\.[a-z]{2,}$/u);

//             return emailRegexp.test(payload);

//         case 'phone':
//             const phoneRegexp = new RegExp(/(?:\+|\d)[\d\-\(\) ]{9,}\d/g);

//             return phoneRegexp.test(payload);

//         default:
//             console.error(`не найдено значение ${type} в структуре switch`);
//     }
// }
// переводит дату и время в timestamp
// export const transformDateToTimestamp = (time, day) => {
//     const res = day / 1000 + parseInt(time) * 3600;
//     return res;
// };

// сравнивает timestamp и день месяца, если день даты преобразованный из timestamp равен преданному дню - возвращает true
// export const dateCheck = (day, time) => {
//     const date = new Date(time * 1000);
//     if (new Date(time * 1000).getDate() === new Date(day).getDate()) {
//         return true;
//     } else return false;
// };

// export const getThisDate = () => {
//     return new Date();
// };

// export const getDayFromTS = (time) => {
//     const date = new Date(time).getDate();
//     return date;
// };
// export const getMonthFromTS = (time) => {
//     const date = new Date(time).getMonth();
//     return date;
// };
// export const getFullYearFromTS = (time) => {
//     const date = new Date(time).getFullYear();
//     return date;
// };

// начальные значения для фильтра в кошельке доктора
export const initialFilterYear = 'год';
export const initialFilterMonth = 'месяц';

export const monthsOfYear = [
    { name: 'все', code: initialFilterMonth },
    { name: 'январь', code: '1' },
    { name: 'февраль', code: '2' },
    { name: 'март', code: '3' },
    { name: 'апрель', code: '4' },
    { name: 'май', code: '5' },
    { name: 'июнь', code: '6' },
    { name: 'июль', code: '7' },
    { name: 'август', code: '8' },
    { name: 'сентябрь', code: '9' },
    { name: 'октябрь', code: '10' },
    { name: 'ноябрь', code: '11' },
    { name: 'декабрь', code: '12' },
];
// export const years = ['2024', '2023', '2022', '2021', '2020', '2019'];
function calcYearsArr() {
    const arr = [];
    let year = new Date().getFullYear();
    while (year >= 2019) {
        arr.push(year);
        year--;
    }
    return arr;
} 
export const years = calcYearsArr();

// функция проверяет что введены буквы на любом языке или символ - (названия городов, фамилии)
// функция запрещает два дефиса подряд
// также допутима пустая строка
// export const isEnteredLetterOrDash = (str) => {
//     return str === '' || RegExp(/^[\p{L}]+(-[\p{L}]+)*(-)?$/, 'u').test(str);
// };

// функция проверит есть ли символ дефиса в конце текста, если есть удалит и вызовет колбэк(работает в связке с onBlur и isEnteredLetterOrDash)
// export const onBlurEffect = (name, str, callback) => {
//     if (str[str.length - 1] === '-') {
//         const strWithRemovedDash = str.slice(0, -1);

//         callback(name, strWithRemovedDash);
//     }
// };

// функция проверяет что введены буквы на любом языке (имена, отчества)
export const isEnteredLetter = (str) => {
    return RegExp(/^[\p{L}]*$/, 'u').test(str);
};

// Функция для отрисовки правильной ученой степени доктора
// export const getScientificDegree = (number) => {
//     if (number === 0) {
//         return 'Ученая степень отсутствует';
//     } else if (number === 1) {
//         return 'Кандидат медицинских наук';
//     } else if (number === 2) {
//         return 'Доктор медицинских наук';
//     }
// };


// Изменяет окончание categoryLabel
export const changeCategoryLabelsEnding = (doctor) => {
    const splicedCategoryLabel = doctor.category_label.trim().slice(0, -2);
    if (doctor.category == 1 || doctor.category == 2) {
        return splicedCategoryLabel + 'ой';
    }
    return splicedCategoryLabel + 'ей';
};


// Получение категории доктора
// export const getDoctorCategory = (number) => {
//     if (number === 0) {
//         return 'Врач не указал категорию';
//     } else if (number === 1) {
//         return 'Врач первой категории';
//     } else if (number === 2) {
//         return 'Врач высшей категории';
//     } else if (number === 3) {
//         return 'Академик';
//     }
// };

// export function dateFormatForInputDate(timeStamp) {
//     const date = new Date(timeStamp * 1000);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const day = String(date.getDate()).padStart(2, '0');
//     const dateString = `${year}-${month}-${day}`;
//     return dateString;
// }

export function formatPhoneNumber(phoneNumber) {
    const countryCode = phoneNumber.slice(0, 2);
    const firstPart = phoneNumber.slice(2, 5);
    const secondPart = phoneNumber.slice(5, 8);
    const thirdPart = phoneNumber.slice(8, 10);
    const fourthPart = phoneNumber.slice(10, 12);
    return `${countryCode} (${firstPart}) ${secondPart} ${thirdPart} ${fourthPart}`;
}

// Функция конврертирует юник метку в формат
// return format: 22 мая, пн
// Число, полный месяц, сокращенный день недели
export function getDateForConsultation(unixDate) {
    const date = new Date(unixDate * 1000);
    const options = { day: 'numeric', month: 'long' };
    const dayOfWeek = date.toLocaleDateString('ru-RU', { weekday: 'short' });
    const formattedDate = date.toLocaleDateString('ru-RU', options);
    return `${formattedDate}, ${dayOfWeek}`;
}

// Функция конврертирует юник метку в формат
// return format: 22 августа
export function getDateForReviews(unixDate) {
    const date = new Date(unixDate * 1000);
    const options = { day: 'numeric', month: 'long' };
    const formattedDate = date.toLocaleDateString('ru-RU', options);
    return `${formattedDate}`;
}

/**
 * Возвращает полночь сегодняшнего дня по гринвичу.
 * @returns {Date}
 */
// export function UTCToday() {
//     const today = new Date();
//     return new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));
// }

/**
 * Возвращает настоящее время по гринвичу с обнуленными секундами и миллисекундами.
 * @returns {Date}
 */
// export function UTCNow() {
//     return new Date(new Date().setUTCSeconds(0, 0));
// }

const shortMonths = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

export function getDateForBlog(timestamp) {
    const date = new Date(timestamp * 1000);
    const month = shortMonths[date.getMonth()];
    const formattedDate = `${date.getDate()} ${month} ${date.getFullYear()}г.`;
    return formattedDate;
}

