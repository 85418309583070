import React, { useEffect, useState } from 'react';
import { ConsultationListStl } from './consultationList.styled';
import { useAppSelector } from '../../../../store/index.ts';
import { selectFutureConsultations, selectPastConsultations } from '../../api/PatientConsultationsSlice';
import { Outlet, useLocation } from 'react-router-dom';
import { ConsultationList } from './ConsultationList';
import { useDispatch, useSelector } from 'react-redux';
import { ModalSuccessfulConsultation } from '../../../lkDoctor/lkDoctorModals/modalSuccessfulConsultation/ModalSuccessfulConsultation.jsx';
import { Modal } from '../../../share/Modal.jsx';
import { setIsModalSuccessfulConsultationPatient } from '../../api/PatientConsultationsSlice';

export const PatientConsultationList = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const regex = /\/\d+$/;
    const {
        patientConsultations,
        isLoading,
        isModalSuccessfulConsultation,
        timeConsultation,
    } = useSelector((state) => state.patientConsultations);
    const [consultationsList, setConsultationsList] = useState({});

    useEffect(()=>{
        !pathname.includes('future') ?
            setConsultationsList(patientConsultations.past) :
            setConsultationsList(patientConsultations.future);
    },[pathname, patientConsultations])

    const closePopupSuccessfulConsultation = () => {
        dispatch(setIsModalSuccessfulConsultationPatient());
    }

    // const { isLoading, consultationsList, next } = useAppSelector(
    //     pathname.includes('future') ? selectFutureConsultations : selectPastConsultations
    // );
    return (
        <ConsultationListStl>
            {regex.test(pathname) ? (
                <Outlet />
            ) : (
                <ConsultationList
                    list={consultationsList}
                    pathname={pathname}
                    // next={next}
                    isLoading={isLoading}
                />
            )}
            {isModalSuccessfulConsultation &&
                <Modal toggle={closePopupSuccessfulConsultation}>
                    <ModalSuccessfulConsultation
                        closePopupSuccessfulConsultation={closePopupSuccessfulConsultation}
                        timeConsultation={timeConsultation}
                    />
                </Modal>
            }
        </ConsultationListStl>
    );
};
