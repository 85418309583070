import React from 'react';
import { StyledVideoChatPage } from './videoChatPage.styled';
import { MainHeader } from '../../componets/mainHeader/mainHeader';
// import { VideoChatWindow } from '../../componets/videoChatWindow/videoChatWindow/videoChatWindow';
import Container from '../../componets/share/Container';
// import PageNavigation from '../../componets/share/PageNavigation';
import VideoChatJitsi from '../../componets/videoChatJitsiPage/VideoChatJitsiPage';

export const VideoChatPage = () => {
    const title = 'Видео чат';

    return (
        <StyledVideoChatPage>
            <Container margin="0 auto" maxWidth="1040px">
                <MainHeader title={title} />
            </Container>
            <div className="videoChatWindow__wrapper">
                {/* <VideoChatWindow /> */}
                <VideoChatJitsi/>

            </div>
        </StyledVideoChatPage>
    );
};
