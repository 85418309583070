import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../share/Container';
import { StyledTopBanner } from './topBanner.styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

const TopBanner = () => {
    const navigate = useNavigate();
    const statistics = {
        'довольных клиентов': '900+',
        'нанятых сотрудников': '120+',
        'спасенных жизней': '40+',
        'оказанной помощи': '1200+',
    };
    const sliderSettings = {
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        speed: 600,
        autoplaySpeed: 3000,
        arrows: false,
        fade: true,
    };

    function showDoctorsTop() {
        navigate('/doctors');
    }
    return (
        <StyledTopBanner>
            <Container>
                <div className="topBanner__inner">
                    <div className="topBanner__top">
                        <div className="topBanner__top-left">
                            <Slider {...sliderSettings}>
                                <img
                                    src="img/homePage/homePage-top-1.webp"
                                    alt="homePage-top-1"
                                    className="topBanner__top-img"
                                />
                                <img
                                    src="img/homePage/homePage-top-2.webp"
                                    alt="homePage-top-2"
                                    className="topBanner__top-img"
                                />
                                <img
                                    src="img/homePage/homePage-top-3.webp"
                                    alt="homePage-top-3"
                                    className="topBanner__top-img"
                                />
                            </Slider>
                        </div>
                        <div className="topBanner__top-right">
                            <h1 className="topBanner__top-title">
                                <span className='topBanner__top-title--wrapper'>
                                    Заботимся
                                    <br /> о&nbsp;
                                </span>
                                <span className="topBanner__top-title--bold">вашем комфорте и</span>&nbsp;
                                <span className='topBanner__top-title--wrapper'>здоровье</span>
                            </h1>
                            <p className="topBanner__top-text">
                                Онлайн консультации с врачами&nbsp;
                                <span>24/7</span>
                            </p>
                            <button className="topBanner__top-btn" onClick={showDoctorsTop}>
                                Выбрать врача
                            </button>
                        </div>
                    </div>
                    <div className="topBanner__statistics">
                        {Object.entries(statistics).map(([description, number], index) => (
                            <div className="topBanner__statistics-item" key={index}>
                                <p className="topBanner__statistics-number">{number}</p>
                                <p className="topBanner__statistics-description">{description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </StyledTopBanner>
    );
};

export default TopBanner;
