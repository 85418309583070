import styled from 'styled-components';

export const StyledSearchButton = styled.img `
    width:35px;
    height:35px;
    cursor: pointer;
    transition: opacity 0.5s linear;

    :hover {
        opacity: .6;
    }
    @media(max-width: 400px) {
        display: none;
    }
`;