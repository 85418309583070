import React from 'react';
import { FooterButtonsStyled } from './footerButtons.styled';
import { ReactComponent as ArrowUp } from '../../../img/icons/newDoctorIcons/up-arrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPostsByTheme } from '../api/blogSlice';
import { useParams } from 'react-router-dom';

export const FooterButtons = ({ isDefaultCountSimilarPosts, setIsDefaultCountSimilarPosts, setIsLoadingPosts }) => {
    const dispatch = useDispatch();
    const { posts } = useSelector((state) => state.blog);
    const { themeId } = useParams();

    function setPageTopPosition() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    function showMorePosts() {
        if (isDefaultCountSimilarPosts) {
            setIsDefaultCountSimilarPosts(false);
        } else {
            if (setIsLoadingPosts) {
                setIsLoadingPosts(true);
            }
            dispatch(fetchPostsByTheme({ themeId: themeId, getMorePosts: true }));
        }
    }

    return (
        <FooterButtonsStyled>
            {posts[themeId]?.next || isDefaultCountSimilarPosts ? (
                <p className="showMoreButton" onClick={showMorePosts}>
                    Показать больше
                </p>
            ) : (
                ''
            )}

            <div className="toTopPage" onClick={setPageTopPosition}>
                <p className="toTopPageButton">Наверх</p>
                <ArrowUp />
            </div>
        </FooterButtonsStyled>
    );
};
