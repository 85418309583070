import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { useController, useFormContext } from 'react-hook-form';
import { FieldWrapper } from '../FieldWrapper';

const InputTel = ({ name, placeholder, validation, label, disabled, isRequired}) => {
    const { control } = useFormContext();

    const defaultValidation = {
        required: { value: true, message: 'Поле обязательно для заполнения' },
        pattern: {
            value: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
            message: 'Неполный номер',
        },
        ...validation,
    };
    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules: defaultValidation });

    return (
        <FieldWrapper
            error={error}
            label={label}
            clearCondition={field.value}
            isRequired={isRequired}
            onClear={() => field.onChange('')}
            disabled={disabled}
        >
            <InputMask
                {...field}
                onChange={(e) => field.onChange(e.target.value)}
                className={`${!error ? '' : 'invalid'}`}
                mask={'+7 (999) 999-99-99'}
                inputMode="numeric"
                placeholder={placeholder}
                id={name}
                type="tel"
                maskChar={''}
                disabled={disabled}
                style={{ color: disabled && 'gray' }}
            />
        </FieldWrapper>
    );
};

export default InputTel;
