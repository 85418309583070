import { apiRequest } from '../../share/api';

// получение всех докторов
export const getAllDoctors = async (data, next = '') => {
    if (next) {
        return await apiRequest.get(next)
    }
    return await apiRequest.get(`/doctors/?search=${data.search}&spec_ids=${data.spec_ids}&ordering=${data.ordering}`);
};

// получение данных доктора по id
export const getDoctor = async (id) => {
    const response = await apiRequest.get(`doctors/${id}`);
    return response.data
};

// получение id новой консультации (создается на сервере)
export const getСonsultationID = async (consultation) => {
    const response = await apiRequest.post(`consultations/`, consultation);
    return response.data;
};

// получение отзывов о докторе по id 
export const getReviews =  async ( id, countReviews ) => {
    const response = await apiRequest.get(`feedback/doctor/${id}/?page_size=${countReviews}`);
    return response.data;
};

// отправка заявки на визит в клинику или вызов врача на дом
export const postVizit =  async (data) => {
    const response = await apiRequest.post(`visit/`, data);
};