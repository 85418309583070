import React from 'react';
import { LikeWrapper } from './styled.like';

const Like = ({ imgSrc, altText, setFavorite }) => {
    return (
        <LikeWrapper onClick={() => setFavorite()}>
            <div className="inside_heart">
                <img src={imgSrc} alt={altText} />
            </div>
        </LikeWrapper>
    );
};

export default Like;
