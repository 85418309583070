import styled from 'styled-components';

export const StyledSearchContainer = styled.div`
    width: 100%;
`;

export const StyledSearchInput = styled.fieldset`
    border: none;
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 422px;

    background: #f4f4f4;
    border-radius: 10px;
    padding-left: 10px;
    margin-left: auto;
    margin-right: 40px;

    @media (max-width: 1024px) {
        width: 448px;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin: auto;
    }

    .search__input {
        width: 100%;
        padding: 9px 10px;
        background: #f4f4f4;
        font-size: 18px;
        line-height: 29px;
        color: #666666;
        border: none;
        border-radius: 10px;
        outline: none;
        &::placeholder {
            font-size: 16px;
            color: #b6b7ba;
            @media (max-width: 500px) {
                font-size: 12px;  
            }
        }
    }

    .input__icon {
        cursor: pointer;
        z-index: 1;
        transition: opacity 0.5s linear;
        margin-right: 10px;
        &:hover {
            opacity: 0.6;
        }
    }
    .search__btn-close {
        background-repeat: no-repeat;
        border: none;
        background-color: inherit;
        width: 40px;
        height: 40px;
        transition: opacity 0.5s linear;
        cursor: pointer;
        background-image: url('/img/header/search-close.svg');
        background-position: center center;
        margin-right: 10px;
    }
`;
