import styled from 'styled-components';

export const ErrorBlockStl = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;
    box-sizing: border-box;
    min-width: 268px;
    max-width: 279px;
    padding-top: 22px;
    align-self: center;

    .warningIcon {
        margin: 0 auto;
    }
    .heading {
        padding-bottom: 8px;
        font-size: 20px;
        line-height: 1.3;
        font-weight: 500;
        text-align: center;
    }
    .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.3;
        color: #666666;
        text-align: center;
    }
    .buttons_contaiter {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
    .button {
        width: 100%;
        padding: 12px 22px;
        background: #ffffff;
        border: 2px solid #19be6f;
        border-radius: 8px;
        color: #19be6f;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        text-align: center;
        cursor: pointer;
        transition: opacity 0.5s linear;
        &:hover {
            opacity: 0.7;
        }
    }
    .buttonClose {
        border: none;
        font-size: 16px;
        line-height: 1.5;
        color: #19be6f;
        background-color: transparent;
        cursor: pointer;
        transition: opacity 0.5s linear;
        &:hover {
            opacity: 0.7;
        }
    }

    @media screen and (max-width: 430px) {
        display: flex;
        flex-direction: column;
        gap: 44px;

        .text {
            font-size: 14px;
        }
        .heading {
            margin-bottom: 8px;
            font-size: 18px;
        }
        .buttons_container {
            gap: 15px;
        }
        .button {
            font-size: 14px;
            line-height: 21px;
        }
    }
`;
