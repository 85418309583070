import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { patient_create } from "./patientApi";

export const fetchPatient_create = createAsyncThunk(
    'patient_create/fetchPatient_create',
    async (data) => {
        try {
            const response = await patient_create(data);
        }
        catch(e) {
            console.log(e);
         }
    }
)

const patientEditProfile= createSlice({
    name: 'authenticationPatient',
    initialState: {
        phone_number: "",
        isPreloader: false,
        error: null,
        isAuth: false,
        showAuth: false,
        accessToken: "",
        refreshToken: "",
        whoAreYou: '',
    },
    reducers: {
        setAuth: (state, bool) => {
            state.isAuth = bool.payload;
        },
        setShowAuth(state, bool) {
            state.showAuth = bool.payload;
        },
        setWhoAreYou(state, role) {
            state.whoAreYou = role.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPatient_create.fulfilled, (state, action) => {
            state.phone_number = action.payload;
        });
        builder.addCase(fetchPatient_create.pending, (state, action) => {
        });
        builder.addCase(fetchPatient_create.rejected, (state, action) => {
            state.error = action.payload;
        });
    },
});
    
export const { setAuth, setShowAuth,setWhoAreYou } = patientEditProfile.actions;
export default patientEditProfile.reducer;