/* eslint-disable react/react-in-jsx-scope */
// import { YMaps, Map, Placemark } from "react-yandex-maps";

const YandexMap = ({className}) => {
    return (
        <>
            <div className={className} style={{ position: 'relative', overflow: 'hidden', borderRadius: '10px' }} id="yandex-map">
                <iframe
                    src= 
						  "https://yandex.ru/map-widget/v1/?um=constructor%3Ab8e1899d4d0dfece15a41dfc57e159d89f1660feb517831e8a077a9e72276e92&amp;source=constructor"
						// "https://yandex.ru/map-widget/v1/?um=constructor%3A4fd2311e8d1498a2532ca571da79018f73ec0d3bb7061d92ad330134f5118353&source=constructor"
                    width="100%"
                    height={422}
                    frameBorder={0}
                />
            </div>
        </>
    );
};

// <YMaps>
//   <Map
//     defaultState={{
//       center: [55.701525906576066, 37.62136944047478],
//       zoom: 15,
//       controls: ["zoomControl", "fullscreenControl"],
//     }}
//     modules={["control.ZoomControl", "control.FullscreenControl"]}
//     style={{ width: "100%", height: "300px",}}
//   >
//     <Placemark defaultGeometry={[55.701525906576066, 37.62136944047478]} />
//   </Map>
// </YMaps>

export default YandexMap;
