import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    getDoctorDisease,
    getDoctorHigherEducation,
    getMyAdvancedTraining,
    getMyDoctorCertificate,
    getMyProfileDoctor,
    getMyWorkExpiriens,
    getSpecializations,
    getUniversity,
    setDoctorDisease,
    setDoctorHigherEducation,
    setDoctorSertificate,
    setMyProfileDoctor,
    setMyAdvancedTraining,
    deleteCertificate,
    postSpecialization,
    getDoctorSpecializations,
    deleteDoctorHigherEducation,
    putDoctorHigherEducation,
    setMyWorkExpiriens,
    loadAdvancedTraining,
    deleteMyAdvancedTraining,
    putMyAdvancedTraining,
    deleteMyWorkExpiriens,
    putMyWorkExpiriens,
} from './api';
import { loadMyAvatar, deleteMyAvatar, getCity } from '../../share/api';
import { fetchMeUser } from '../../authentification/api/authentificationSlice';

export const fetchMeDoctor = createAsyncThunk('UserDoctor/fetchUserDoctor', async (_, { dispatch }) => {
    try {
        const response = await getMyProfileDoctor();
        dispatch(fetchMeUser());
        return response.data;
    } catch (error) {
        console.log(error);
    }
});
// получение всех специализаций
export const fetchSpecializations = createAsyncThunk('UserDoctor/fetchSpecializations', async () => {
    try {
        const response = await getSpecializations();
        return response;
    } catch (err) {
        console.log(err);
    }
});
// установка специальности(требует модерацию)
export const postDoctorSpecialization = createAsyncThunk('UserDoctor/postSpecialization', async (data) => {
    try {
        const response = await postSpecialization(data);
        return response;
    } catch (err) {
        console.log(err);
    }
});
// получаем специальности доктора с информацией о модерации(не пригодилось)
export const fetchDoctorSpecializations = createAsyncThunk('UserDoctor/getDoctorSpecializations', async () => {
    try {
        const response = await getDoctorSpecializations();
        return response.data;
    } catch (err) {
        console.log(err);
    }
});
export const fetchDoctorExpiriens = createAsyncThunk('UserDoctor/fetchDoctor_expiriens', async () => {
    try {
        const response = await getMyWorkExpiriens();
        ``;
        return response.data;
    } catch (err) {
        console.log(err);
    }
});
export const postDoctorExpiriens = createAsyncThunk('UserDoctor/postDoctorExpiriens', async (data) => {
    try {
        const response = await setMyWorkExpiriens(data);
        return response;
    } catch (err) {
        console.log(err);
    }
});
export const deleteDoctorExpiriens = createAsyncThunk('UserDoctor/deleteDoctorExpiriens', async (id) => {
    try {
        const response = await deleteMyWorkExpiriens(id);
        return response;
    } catch (err) {
        console.log(err);
    }
});
export const putDoctorExpiriens = createAsyncThunk('UserDoctor/putDoctorExpiriens', async (data) => {
    try {
        const response = await putMyWorkExpiriens(data);
        return response;
    } catch (err) {
        console.log(err);
    }
});
export const fetchDoctorAdvancedTraining = createAsyncThunk('UserDoctor/fetchDoctorAdvancedTraining', async () => {
    try {
        const response = await getMyAdvancedTraining();
        return response.data;
    } catch (err) {
        console.log(err);
    }
});
export const PostDoctorAdvancedTraining = createAsyncThunk('UserDoctor/PostDoctorAdvancedTraining', async (data) => {
    try {
        const response = await setMyAdvancedTraining(data);
        return response;
    } catch (err) {
        console.log(err);
    }
});

export const fetchMyCertificate = createAsyncThunk('UserDoctor/fetchMyCertificate', async () => {
    try {
        const response = await getMyDoctorCertificate();
        return response.data;
    } catch (err) {
        console.log(err);
    }
});
export const PostMyCertificate = createAsyncThunk('UserDoctor/PostMyCertificate', async ({ title, file }) => {
    try {
        const response = await setDoctorSertificate({ title, file });
        return response;
    } catch (err) {
        console.log(err);
    }
});

export const fetcnDoctorDisease = createAsyncThunk('UserDoctor/fetchDoctorDisease', async () => {
    try {
        const response = await getDoctorDisease();
        return response.data;
    } catch (err) {
        console.log(err);
    }
});
export const PostDoctorCreateDisease = createAsyncThunk('UserDoctor/fetchDoctorCreateDisease', async (data) => {
    try {
        const response = await setDoctorDisease(data);
        return response;
    } catch (err) {
        console.log(err);
    }
});
export const fetchUniversity = createAsyncThunk('UserDoctor/fetchUniversity', async () => {
    try {
        const response = await getUniversity();
        return response.data;
    } catch (err) {
        console.log(err);
    }
});
export const fetchDoctor_create = createAsyncThunk('UserDoctor/fetchDoctor_create', async (data) => {
    try {
        const response = await setMyProfileDoctor(data);
        return response;
    } catch (err) {
        console.log(err);
    }
});
export const fetchDoctorHigherEducation = createAsyncThunk('UserDoctor/fetchDoctorHigherEducation', async () => {
    try {
        const response = await getDoctorHigherEducation();
        return response.data;
    } catch (err) {
        console.log(err);
    }
});
export const PostDoctorHigherEducation = createAsyncThunk('UserDoctor/PostDoctorHigherEducation', async (data) => {
    try {
        const response = await setDoctorHigherEducation(data);
        return response;
    } catch (err) {
        console.log(err);
    }
});
export const PutDoctorHigherEducation = createAsyncThunk('UserDoctor/PutDoctorHigherEducation', async (data) => {
    try {
        const response = await putDoctorHigherEducation(data);
        return response;
    } catch (err) {
        console.log(err);
    }
});

export const deleteDoctorCertificate = createAsyncThunk('UserDoctor/deleteDoctorCertificate', async (data) => {
    try {
        const response = await deleteCertificate(data);
        return response;
    } catch (err) {
        console.log(err);
    }
});

export const fetchLoadMyAvatar = createAsyncThunk('UserDoctor/fetchLoadMyAvatar', async (file, { dispatch }) => {
    try {
        const response = await loadMyAvatar(file);
        dispatch(fetchMeUser());
        return response.data;
    } catch (err) {
        console.log(err);
    }
});
// export const fetchDeleteMyAvatar = createAsyncThunk('UserDoctor/fetchDeleteMyAvatar', async (_, { dispatch }) => {
//     try {
//         const response = await deleteMyAvatar();
//         dispatch(fetchMeUser());
//         return response.data;
//     } catch (err) {
//         console.log(err);
//     }
// });

export const fetchLoadAdvancedTraining = createAsyncThunk('UserDoctor/fetchLoadAdvancedTraining', async (data) => {
    try {
        const response = await loadAdvancedTraining(data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
});
export const deleteAdvancedTraining = createAsyncThunk('UserDoctor/deleteAdvancedTraining', async (id) => {
    console.log('deleteAdvancedTraining');
    try {
        const response = await deleteMyAdvancedTraining(id);
        return response;
    } catch (err) {
        console.log(err);
    }
});
export const putAdvancedTraining = createAsyncThunk('UserDoctor/putAdvancedTraining', async (data) => {
    try {
        const response = await putMyAdvancedTraining(data);
        return response;
    } catch (err) {
        console.log(err);
    }
});
export const fetchCity = createAsyncThunk('UserDoctor/fetchCity', async (searchString) => {
    try {
        const response = await getCity(searchString);
        return response.data.results;
    } catch (err) {
        console.log(err);
    }
});

const doctorProfile = createSlice({
    name: 'doctorProfile',
    initialState: {
        isPreloader: false,
        isPreloaderCity: false,
        doctorProfile: null,
        isError: '',
        city: [],
    },
    reducers: {
        resetCity: (state) => {
            state.city = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSpecializations.fulfilled, (state, action) => {
            state.allSpecializations = action.payload?.data?.data?.specializations;
        });

        builder.addCase(fetchUniversity.fulfilled, (state, action) => {
            state.allUniversity = action.payload?.data?.schools;
        });
        builder.addCase(fetchMeDoctor.pending, (state) => {
            state.isPreloader = true;
        });
        builder.addCase(fetchMeDoctor.rejected, (state) => {
            state.isPreloader = false;
        });
        builder.addCase(fetchMeDoctor.fulfilled, (state, action) => {
            state.doctorProfile = action.payload;
            state.doctorProfile?.higher_education?.sort((a, b) => +a.end_date - +b.end_date);
            state.doctorProfile?.advanced_training?.sort((a, b) => +a.end_date - +b.end_date);
            state.doctorProfile?.work
                ?.sort((a, b) => +a.end_date - +b.end_date)
                .sort((a, b) => +a.until_now - +b.until_now);
            state.isPreloader = false;
        });
        builder.addCase(fetchDoctor_create.pending, (state) => {
            state.isPreloader = true;
        });
        builder.addCase(fetchDoctor_create.fulfilled, (state) => {
            state.isPreloader = false;
        });
        builder.addCase(PostDoctorHigherEducation.pending, (state) => {
            state.isPreloader = true;
        });
        builder.addCase(PostDoctorHigherEducation.fulfilled, (state) => {
            state.isPreloader = false;
        });
        builder.addCase(postDoctorExpiriens.pending, (state) => {
            state.isPreloader = true;
        });
        builder.addCase(postDoctorExpiriens.fulfilled, (state) => {
            state.isPreloader = false;
        });
        builder.addCase(postDoctorSpecialization.pending, (state) => {
            state.isPreloader = true;
        });
        builder.addCase(postDoctorSpecialization.fulfilled, (state) => {
            state.isPreloader = false;
        });
        builder.addCase(PostDoctorAdvancedTraining.pending, (state) => {
            state.isPreloader = true;
        });
        builder.addCase(PostDoctorAdvancedTraining.fulfilled, (state) => {
            state.isPreloader = false;
        });
        builder.addCase(fetchDoctorExpiriens.fulfilled, (state, action) => {
            state.doctorProfile.work_experience = action.payload;
        });
        builder.addCase(fetchDoctorAdvancedTraining.fulfilled, (state, action) => {
            state.doctorProfile.advancedTraining = action.payload;
        });
        builder.addCase(fetchMyCertificate.fulfilled, (state, action) => {
            state.doctorProfile.certificate = action.payload;
        });
        builder.addCase(fetcnDoctorDisease.fulfilled, (state, action) => {
            state.doctorProfile.healing_diseases = action.payload;
        });
        builder.addCase(fetchDoctorHigherEducation.fulfilled, (state, action) => {
            state.education_story = action.payload;
            state.doctorProfile.education_story = action.payload;
        });
        builder.addCase(fetchLoadMyAvatar.fulfilled, (state, action) => {
            state.doctorProfile.user.avatar = action.payload.file;
        });

        // builder.addCase(fetchDeleteMyAvatar.fulfilled, (state, action) => {
        //     console.log(action);
        //     // state.doctorProfile.user.avatar = null;
        // });

        builder.addCase(fetchCity.pending, (state, action) => {
            state.isPreloaderCity = true;
        });
        builder.addCase(fetchCity.fulfilled, (state, action) => {
            state.city = action.payload;
            state.isPreloaderCity = false;
        });
        builder.addCase(fetchCity.rejected, (state, action) => {
            state.isPreloaderCity = false;
        });
    },
});

export const { resetCity } = doctorProfile.actions;
export const selectDoctorPersonalData = (state) => state.doctorProfile?.doctorProfile;
export default doctorProfile.reducer;
