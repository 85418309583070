import styled from 'styled-components';

export const AuthenticationStl = styled.div`
    padding: 60px 0 180px;
    margin-bottom: -180px;
    background-image: url('/img/auth/auth-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    .auth {
        display: flex;
        align-items: center;
        justify-content: center;
        &__wrapper {
            padding: 52px 40px 40px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            background: #ffffff;
            border-radius: 10px;
            box-shadow: 0px 0px 30px rgba(40, 40, 40, 0.1);
        }
        &__header {
            font-weight: 500;
            font-size: 24px;
            line-height: 130%;
            text-align: center;
            color: #282828;
            width: 334px;
        }
        &__text {
            width: 334px;
            text-align: center;
            word-wrap: normal;
        }
    }

    @media screen and (max-width: 430px) {
        padding: 0;
        background-image: none;
        margin-bottom: 0;
        .auth__wrapper {
            min-width: auto;
            max-width: none;
            width: 100%;
            box-shadow: none;
            padding: 30px 0px 0px;
        }
        .auth__header {
            font-size: 20px;
            line-height: 26px;
            width: 100%;
        }
    }
`;
