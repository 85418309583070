import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// хук возвращвает месяц для отображения это 6 недель включая дни предыдущего
// и следующего месяца
// а также дни для настройки расписания

export const useCalendar = (date) => {
    const [selectedDate, setSelectedDate] = useState(date);
    const receptionsList = useSelector((state) => state.doctorCalendar.receptions);
    const [currentMonthDaysWithSettings, setCurrentMonthDaysWithSettings] = useState([]);

    useEffect(() => {
        setSelectedDate(date);
    }, [date]);

    const firstDayOfMonth = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 1));

    const daysInMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1).getUTCDate();

    const firstDayOfWeek = firstDayOfMonth.getDay();
    const daysBeforeMonth = firstDayOfWeek !== 0 ? firstDayOfWeek - 1 : 6;
    const daysAfterMonth = 42 - daysBeforeMonth - daysInMonth;

    const previousMonthDays = [];
    const currentMonthDays = [];
    const nextMonthDays = [];

    const daysInPrevMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1).getUTCDate();

    for (let i = daysInPrevMonth - daysBeforeMonth + 1; i <= daysInPrevMonth; i++) {
        previousMonthDays.push({
            day: i,
            month: selectedDate.getMonth() - 1,
            year: selectedDate.getFullYear(),
        });
    }

    for (let i = 1; i <= daysInMonth; i++) {
        currentMonthDays.push({
            day: i,
            month: selectedDate.getUTCMonth(),
            year: selectedDate.getFullYear(),
            dayOfWeek: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), i).getDay(),
            choosen: false,
        });
    }

    for (let i = 1; i <= daysAfterMonth; i++) {
        nextMonthDays.push({
            day: i,
            month: selectedDate.getMonth() + 1,
            year: selectedDate.getFullYear(),
        });
    }

    const [days, setDays] = useState([]);
    const [beforeSettingsDays, setBeforeSettingsDays] = useState([]);
    const [settingsDays, setSettingsDays] = useState([]);
    const [afterSettingsDays, setAfterSettingsDays] = useState([]);

    useEffect(() => {
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const daysArray = [];

        for (let i = 1; i <= daysInMonth; i++) {
            const date = new Date(year, month, i);
            const timeStamp = date.setHours(0, 0, 0, 0);
            daysArray.push({ day: i, month: month, year, timeStamp });
        }

        setDays(daysArray);
    }, [selectedDate]);

    const today = new Date().setHours(0, 0, 0, 0);
    const todayPlas14Days = today + 1123200000;
    const beforeToday = [];
    const upTo14FromToday = [];
    const after14FromToday = [];

    useEffect(() => {
        for (let day of days) {
            if (day.timeStamp < today) {
                beforeToday.push(day);
            }
            if (day.timeStamp >= today && day.timeStamp <= todayPlas14Days) {
                upTo14FromToday.push(day);
            }
            if (day.timeStamp > todayPlas14Days) {
                after14FromToday.push(day);
            }
        }

        const updatedSettingsDays = upTo14FromToday.map((day) => {
            const receptions = [];
            const date = new Date(day.timeStamp);
            for (let i = 0; i < 48; i++) {
                const time = new Date(date.setHours(Math.floor(i / 2), (i % 2) * 30, 0, 0));
                receptions.push({
                    id: null,
                    consultation: null,
                    // timeStamp: time.getTime() / 1000,
                    timeStamp: time.getTime() / 1000,
                    timeString: `${time.getHours().toString().padStart(2, '0')}:${time
                        .getMinutes()
                        .toString()
                        .padStart(2, '0')}`,
                    workTime: false,
                });
            }
            return { ...day, choosen: false, receptions, timeStamp: receptions[0].timeStamp };
        });

        const result = updatedSettingsDays.map((obj1) => {
            const receptions = obj1.receptions.map((reception) => {
                const { id, consultation } = receptionsList?.find((obj2) => obj2.time === reception.timeStamp) || {
                    id: null,
                    consultation: null,
                };
                const workTime = id ? true : false;
                return { ...reception, id: id, consultation: consultation, workTime: workTime };
            });
            if (receptions.some((el) => el.id)) {
                return { ...obj1, receptions, isReceptions: true };
            }
            return { ...obj1, receptions, isReceptions: false };
        });

        setBeforeSettingsDays(beforeToday);
        setSettingsDays(result);
        setAfterSettingsDays(after14FromToday);
    }, [days, receptionsList]);

    useEffect(() => {
        const newArray = currentMonthDays.map((item1) => {
            const matchingItem = settingsDays.find((item2) => item2.day === item1.day);
            return matchingItem ? matchingItem : item1;
        });

        setCurrentMonthDaysWithSettings(newArray);
    }, [settingsDays]);

    return {
        previousMonthDays,
        nextMonthDays,
        beforeSettingsDays,
        settingsDays,
        afterSettingsDays,
        currentMonthDaysWithSettings,
    };
};
