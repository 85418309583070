import styled from 'styled-components';
import closeIcon from '../../../../../img/icons/icon-close.svg';

export const StyledPatientConsultationCard = styled.div`
    max-width: 600px;
    width: 100%;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    display: grid;
    column-gap: 60px;
    row-gap: 8px;
    grid-template-columns: auto 1fr;
    padding: 20px;
    position: relative;
    font-weight: 400;
    line-height: 150%;
    transition: all 0.3s;

    & > div {
        grid-column: 2;
    }

    .photoBlock {
        /* grid-row: 1/4; */
        grid-column: 1;
        grid-row: span 4;
        width: 107px;
        height: 107px;
        border-radius: 50%;
        /* overflow: hidden; */
        position: relative;
        z-index: 10;
        /* flex-shrink: 0; */
        
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    .patientData {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .statusConsultation {
        font-size: 14px;
        color: #666666;
    }
    .red {
        color: #ff3636;
    }
    .patientName {
        font-size: 18px;
        color: #212121;
        /* grid-column: 2/3; */
        word-break: break-word;
    }
    .dateAndTime {
        display: flex;
        flex-wrap: wrap;
        /* grid-column: 2/3; */
        gap: 16px;
        & img {
            margin-right: 8px;
        }

        & span {
            font-weight: 400;
            font-size: 14px;
            color: #666;
        }
    }

    .date,
    .time,
    .price {
        display: flex;
        align-items: center;
    }

    /* .time {
        display: flex;
        text-align: center;
    } */
    .closeBtnX {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 21px;
        right: 21px;
        cursor: pointer;
        background-image: url(${closeIcon});
        background-size: contain;
        background-repeat: no-repeat;
        color: #b6b7ba;
    }

    .specialization {
        display: flex;
        flex-direction: column;
        > span {
            font-size: 14px;
            color: #666;
            line-height: 130%;
        }
    }

    .like-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 100%;
        padding: 10px;
        background-color: #fff;
        cursor: pointer;
        z-index: 100;
    }

    .like {
        width: 19px;
        height: 18px;
    }

    @media (max-width: 810px) {
        padding: 16px;
        grid-column-gap: 20px;
        .photoBlock {
            width: 65px;
            height: 65px;
        }
    }
    @media (max-width: 500px) {
        .statusConsultation {
            order: -1;
            grid-column: span 2;
            font-size: 12px;
        }
        .photoBlock {
            grid-row: span 3;
        }
        .dateAndTime {
            grid-column: 1 / 3;
            & img {
                width: 15px;
                height: 15px;
                margin-right: 5px;
            }
        }
        .patientName {
            font-size: 16px;
            line-height: 19px;
        }
    }

    &:hover {
        background-color: #f4f4f4;
        cursor: pointer;
    }

    @media (max-width: 370px) {
        .dateAndTime {
            gap: 8px;
            span {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
`;
