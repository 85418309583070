import styled from 'styled-components';

export const LikeWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    .inside_heart {
        margin: auto;
        display: inline-flex;
    }
`;
