import React from 'react';
import { useSelector } from 'react-redux';
import { NewFormSTL } from './newFormSTL.styled';
import { EditParametrsList } from '../../shared/EditParametrsList';

const PatientParametrsEdit = () => {
    const patientProfile = useSelector((state) => state.patientProfile?.patientProfile);

    return (
        <NewFormSTL>
            <h2 className="title">Параметры</h2>
            <div className="personalData">
                <EditParametrsList patientProfile={patientProfile} />
            </div>
        </NewFormSTL>
    );
};

export default PatientParametrsEdit;