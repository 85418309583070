import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { NewFormSTL } from './newFormSTL.styled';
import { WorkList } from '../../shared/WorkList';
const WorkExperience = () => {
    const { control, getValues } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: 'work',
        control,
    });

    const onAdd = () => {
        const newBlock = {
            organization: '',
            position: '',
            start_date: '',
            end_date: '',
            until_now: '',
            newField: true,
        };
        append(newBlock);
    };

    return (
        <NewFormSTL>
            <h2 className="title">Опыт работы</h2>

            {fields.map((field, index) => {
                return (
                    <div key={field.id}>
                        <WorkList name={`work.${index}`} />
                        {fields.length > 1 && (
                            <button
                                type="button"
                                className="button button_del button_border"
                                onClick={() => remove(index)}
                            >
                                Удалить блок
                            </button>
                        )}
                    </div>
                );
            })}
            <button type="button" className="button" onClick={() => onAdd()}>
                <span className="span">+</span> Добавить место работы
            </button>
        </NewFormSTL>
    );
};

export default WorkExperience;
