import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { ViewDiseases } from './ViewDiseases';
import { SectionStl } from '../viewPatient.styled';
import { EditDiseases } from './EditDiseases';

export const Diseases = () => {
    const [editDiseases, toggleEditDiseases] = useState(false);
    const [diseasesSort, setDiseasesSort] = useState([]);

    const patientProfile = useSelector((state) => state.patientProfile?.patientProfile);
    const {diseases} = patientProfile;

    useEffect(()=>{
        if (diseases) {
            let sort = Array.from(diseases).sort((a, b) => a.value - b.value);
            setDiseasesSort(sort);
        }
    },[diseases])

    return (
        <SectionStl>
            <TitleWithPencil toggle={toggleEditDiseases} title={'Хронические заболевания'} />
            {editDiseases ? (
                <EditDiseases diseases={diseasesSort} toggle={toggleEditDiseases} />
            ) : (
                <ViewDiseases diseases={diseasesSort} />
            )}
        </SectionStl>
    );
};