import { apiRequest } from '../../share/api';

export const requestReviews = async (next) => {
  const response = await apiRequest.get(next);
  const data = response.data;
  return data;
};

export const postReview = async (post) => {
  await apiRequest.post(`/feedback_service/`, post);
};
