import styled from 'styled-components';

export const PatientConsultation = styled.div`
    
    .patient__consultations {
        max-width: 455px;
        margin: 35px 0 180px;
    }
    .patient__consultationsHeader {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 130%;
        color: #212121;
    }
    .patient__consultation {
        display: grid;
        grid-template-columns: 62px 1fr 86px;
        gap: 12px;
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 150%;
        color: #666666;
    }
    .patient__consultationData {
        color: #666;
    }
    .patient__consultationStatus {
        color: #666;
    }
    .patient__consultationStatusCancel {
        color: #ff3636;
    }
    .patient__consultationEnter {
        text-decoration: none;
        font-size: 16px;
        color: #19be6f;
        cursor: pointer;
        transition: opacity 0.5s linear;
        &:hover {
            opacity: 0.7;
        }
    }

    @media (max-width: 800px) {
        .patient__consultation {
            margin-bottom: 14px;
            line-height: 130%;
        }
        .patient__consultationEnter {
            line-height: 150%;
        }
    }

    @media (max-width: 767px) {
        .patient__consultations {
            margin: 35px auto 118px;
        }
    }

    @media (max-width: 500px) {
        .patient__consultations {
            margin: 34px 0 80px;
        }
        .patient__consultationsHeader {
            margin-bottom: 24px;
            font-size: 18px;
        }
        .patient__consultation {
            /* grid-template-columns: 62px 1fr 86px; */
            gap: 8px;
            margin-bottom: 16px;
            font-size: 16px;
        }
        .patient__consultationEnter {
            font-size: 14px;
        }
    }
    @media (max-width: 450px) {
        .patient__consultation {
            grid-template-columns: 40px 1fr;
            grid-template-rows: 1fr 1fr;
        }
        .patient__consultationEnter {
            grid-column: 2 / 3;
            grid-row: 2 / 3;
            text-align: left;
        }
    }
`;
