import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useDebounce from '../../../hooks/useDebounce';
import { fetchDoctorPatients, resetPatients } from '../api/doctorPatientsSlice';
import { SelectedPatientStyled } from './patients.stlyled';
import { Search } from './Search';
import { PatientList } from './PatientList';

export function Patients() {
    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const debounced = useDebounce(search, 300);

    useEffect(() => {
        dispatch(resetPatients());
        dispatch(fetchDoctorPatients(debounced));
    }, [debounced]);

    return (
        <SelectedPatientStyled>
            <Search setSearch={setSearch} />
            <PatientList />
        </SelectedPatientStyled>
    );
}
