import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDoctorReceptions, updateDoctorReceptions, getDoctorConsultationsByStatus } from './api';

export const fetchDoctorReceptions = createAsyncThunk('doctorReceptions/fetchDoctorReceptions', async () => {
    try {
        const response = await getDoctorReceptions();
        return response.data.data.reception_time;
    } catch (error) {
        console.error(error);
    }
});

export const fetchGetConsultationsForCalendar = createAsyncThunk(
    'doctorReceptions/fetchConsultationsForCalendar',
    async () => {
        try {
            const response = await getDoctorConsultationsByStatus(0);
            const consultations = response.data.data.consultations;
            if (consultations.length) {
                return consultations;
            }
            return [];
        } catch (error) {
            console.error(error);
        }
    }
);

export const fetchUpdateDoctorReception = createAsyncThunk(
    'doctorReceptions/fetchUpdateDoctorReception',
    async (data) => {
        try {
            await updateDoctorReceptions(data);
        } catch (error) {
            console.error(error);
        }
    }
);

const initialState = {
    receptions: null,
    consultations: [],
    error: null,
    isLoading: false,
};

const doctorCalendar = createSlice({
    name: 'doctorCalendar',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUpdateDoctorReception.pending, (state) => {
            state.isLoading = true;
        });
        builder
            .addCase(fetchDoctorReceptions.fulfilled, (state, action) => {
                state.receptions = action.payload;
            })
            .addCase(fetchDoctorReceptions.rejected, (state, action) => {
                state.error = action.error.message;
            });
        builder
            .addCase(fetchGetConsultationsForCalendar.fulfilled, (state, action) => {
                state.consultations = action.payload;
            })
            .addCase(fetchGetConsultationsForCalendar.rejected, (state, action) => {
                state.error = action.error.message;
            });
    },
});

export const { setLoading } = doctorCalendar.actions;
export default doctorCalendar.reducer;
