import React from 'react';
import { useSelector } from 'react-redux';
import { NewFormSTL } from './newFormSTL.styled';
import { EditDataList } from '../../shared/EditDataList';

const PatientData = () => {
    const { user } = useSelector((state) => state.patientProfile?.patientProfile);

    return (
        <NewFormSTL>
            <h2 className="title">Личные данные</h2>
            <EditDataList user={user} />
        </NewFormSTL>
    );
};

export default PatientData;
