import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TitleWithPencil } from '../titleWithPencil/TitleWithPencil';
import { ViewOperations } from './ViewOperations';
import { SectionStl } from '../viewPatient.styled';
import { EditOperations } from './EditOperations';

export const Operations = () => {
    const [editOperations, toggleEditOperations] = useState(false);
    const [operationsSort, setOperationsSort] = useState([]);

    const patientProfile = useSelector((state) => state.patientProfile?.patientProfile);
    const {operations} = patientProfile;

    useEffect(()=>{
        if (operations) {
            let sort = Array.from(operations).sort((a, b) => a.date - b.date);
            setOperationsSort(sort);
        }
    },[operations])
 
    return (
        <SectionStl>
            <TitleWithPencil toggle={toggleEditOperations} title={'Перенесенные операции'} />
            {editOperations ? (
                <EditOperations operations={operationsSort} toggle={toggleEditOperations} />
            ) : (
                <ViewOperations operations={operationsSort} />
            )}
        </SectionStl>
    );
};