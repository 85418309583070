import React, { useState, useLayoutEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DoctorDeseasesStyled } from './DoctorDeseases.styled';
import { useDispatch, useSelector } from 'react-redux';
import { PostDoctorCreateDisease, fetchMeDoctor } from '../../../api/doctorUserProfileSlice';

const EditDeseases = ({ toggle = () => {} }) => {
    const dispatch = useDispatch();
    const {
        healing_diseases: { content },
    } = useSelector((state) => state.doctorProfile?.doctorProfile);

    const modules = {
        toolbar: [[{ header: '2' }], ['bold'], [{ list: 'bullet' }]],
        clipboard: {
            matchVisual: false, // Отключить стандартную обработку вставки
        },
    };

    const formats = ['header', 'list', 'bold'];
    const [editorHtml, setEditorHtml] = useState('');
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    // Эффект для обновления данных в редакторе при получении данных с сервера
    // layout - чтобы не прыгала ошибка перед монтированием
    useLayoutEffect(() => {
        if (content) {
            setEditorHtml(content);
        }
    }, [content]);

    // это так называемая валидация, кто придумает лучше - с меня пиво
    useLayoutEffect(() => {
        const regex = /<[^>]+>/g;
        // находим все теги и вырезаем
        const textField = editorHtml.replace(regex, '');

        // if (!textField) {
        //     setError(true);
        //     setErrorMsg('Поле обязательно для заполнения');
        // } else
        if (textField.length < 2) {
            setError(true);
            setErrorMsg('Минимальная длина: 2 символа');
        } else if (textField.length > 2000) {
            setError(true);
            setErrorMsg('Максимальная длина: 2000 символов');
        } else {
            setError(false);
            setErrorMsg('');
        }
    }, [editorHtml]);

    const handleChange = (html) => {
        setEditorHtml(html);
    };
    const handleSubmit = () => {
        dispatch(PostDoctorCreateDisease(editorHtml))
            .then(() => toggle(false))
            .then(() => {
                dispatch(fetchMeDoctor());
            });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    const handleBlur = () => {
        let inputValue = editorHtml;
        if (typeof inputValue === 'string') {
            // Удаление пробелов в начале и в конце строки
            inputValue = inputValue?.trim();
            // Сокращение всех последовательностей пробелов внутри строки до одного
            inputValue = inputValue?.replace(/ +/g, ' ');
            // Сокращение всех последовательностей дефисов внутри строки до одного
            inputValue = inputValue?.replace(/( -+)|(-+ )/g, '-').replace(/-+/g, '-');
            // Удаление дефисов по краям строки
            inputValue = inputValue?.replace(/^-+/, '').replace(/-+$/, '');
        }
        handleChange(inputValue);
    };

    return (
        <DoctorDeseasesStyled>
            <ReactQuill
                placeholder='Опишите заболевания, которые Вы лечили'
                contentEditable
                value={editorHtml}
                onChange={handleChange}
                onBlur={handleBlur}
                modules={modules}
                formats={formats}
                style={{ height: 200 }}
            />
            {error && <p className="error">{errorMsg}</p>}
            <button
                onClick={handleSubmit}
                className="btn__recive"
                type="submit"
                style={{ marginTop: 60 }}
                disabled={error}
            >
                Сохранить
            </button>
        </DoctorDeseasesStyled>
    );
};

export default EditDeseases;
