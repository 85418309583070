import { apiRequest } from '../../share/api';

export const getThemes = async () => {
    return await apiRequest.get('/news/themes/');
};

export const getPosts = async (data, next = '') => {
    if (!data.themeId) {
        return await apiRequest.get('/news/posts/');
    }
    if (data.getMorePosts && next) {
        return await apiRequest.get(next);
    }
    return await apiRequest.get(`/news/posts/?theme_ids=${data.themeId}`);
};

export const getArticlePage = async (articleId, next = '') => {
    if (next) {
        const comments = await apiRequest.get(next);
        const article = '';
        return { comments, article };
    }
    const comments = await apiRequest.get(`/news/post_chat/${articleId}/`);
    const article = await apiRequest.get(`/news/posts/${articleId}/`);
    return { comments, article };
};

export const postComments = async (data) => {
    return await apiRequest.post('/news/post_chat/', data);
};

export const getQuotes = async () => {
    return await apiRequest.get('/system/text/quotes/');
};
