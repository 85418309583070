import styled from 'styled-components';

export const StyledLkDoctorNavigation = styled.nav`
    display: flex;
    flex-direction: column;
    width: 200px;
    overflow-x: hidden;
    /* gap: 23px; */

    .date-time-hint {
        margin-top: 40px;
        div + div {
            margin-top: 16px;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        .date-time-hint {
            display: none;
        }
    }
`;
