import styled from 'styled-components';

export const StyledResults = styled.div`
    display: block;
    position: absolute;
    top: 61px;
    right: 0;
    width: 400px;
    min-height: 70px;
    padding: 12px 16px 24px 16px;
    background-color: white;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 10px 10px;
    z-index: 1003;

    .search__rating {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #212121;
    }
    @media (max-width: 768px) {
        width: 100%;
        .search__rating {
            font-size: 14px;
        }
    }

    .showallresults__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f4f4f4;
        border-radius: 10px;
        font-size: 18px;
        line-height: 130%;
        padding: 16px;
        margin-bottom: 16px;
        @media (max-width: 550px) {
            font-size: 14px;
        }
    }
    .showallresults__text {
        // padding-left: 20px;
        text-decoration: none;
        font-size: 18px;
        color: #19be6f;
        @media (max-width: 550px) {
            font-size: 14px;
        }
    }
    .showallresults__arrow {
        width: 10px;
        height: 20px;
    }
`;

export const StyledDoctors = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 82px;
    padding: 16px;
    background: #f4f4f4;
    border-radius: 10px;
    margin-bottom: 8px;
    gap: 8px;

    .doctor__rating {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .searchresultdoctors__img {
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        @media (max-width: 500px) {
            min-width: 25px;
            height: 25px;
        }
    }
    .doctor_descr {
        width: 100%;
        padding: 0 10px 0 0;
    }
    .doctor_name {
        color: #062a3f;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #212121;
        @media (max-width: 550px) {
            font-size: 14px;
        }
    }
    .doctor_spec {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-top: 8px;
        @media (max-width: 550px) {
            font-size: 14px;
        }
    }
`;
