import React from 'react';
import styled from 'styled-components';

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: auto;

  img {
    align-self: center;
  }

  p {
    align-self: center;
    position: relative;
    top: 7px;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 48px;
    color: #212121;
    text-align: center;
    width: auto;
    height: auto;
  }

  h4 {
    align-self: center;
    
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #212121;
    text-align: center;
  }
`;

function StatisticsCard(props) {
  return (
      <Article>
        <p>{props.quantity}</p>
        <h4>{props.description}</h4>
      </Article>
  )
}

export default StatisticsCard;