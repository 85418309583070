import React from 'react';
import ReactSelect, { components } from 'react-select';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import { SelectStl } from './selectSpec.styled';
import { ReactComponent as Cross } from '../../../../../img/icons/cross-gray.svg';
import { ReactComponent as Arrow } from '../../../../../img/icons/Arrow WEB.svg';
import { FieldWrapperStl, ErrorStl, LabelStl } from '../../FieldWrapper';

const SelectCommonFieldStl = styled(FieldWrapperStl)`
    .selected {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8px;
        gap: 8px;
        &__option {
            border-radius: 10px;
            background-color: #8af0bf;
            display: flex;
            flex-direction: row;
            &-label {
                padding: 7px 8px 6px 15px;
                user-select: none;
            }
            &-clear {
                display: flex;
                align-items: center;
                padding-right: 8px;
                path {
                    stroke: #19be6f;
                }
                &:hover path {
                    stroke: #169056;
                }
            }
        }
    }
    > svg {
        position: absolute;
        right: 24px;
        bottom: 27px;
        display: none;
        &.visible {
            display: block;
        }
        &:hover path {
            stroke: #19be6f;
        }
    }
`;

const SingleValue = (props) => {
    return <components.SingleValue {...props}></components.SingleValue>;
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Arrow />
        </components.DropdownIndicator>
    );
};

const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <Cross />
        </components.ClearIndicator>
    );
};

const Option = (props) => {
    return (
        <components.Option {...props} className="rs__option_radbtn">
            <div className="rs__option-btn">
                <svg className="svg" height="24px" width="24px">
                    <circle className="radio-outline" cx="12" cy="12" r="11" fill="white" strokeWidth="2" />
                    <circle className="radio-dot" cx="12" cy="12" r="8" />
                </svg>
            </div>
            <span>{props.children}</span>
        </components.Option>
    );
};

const SelectSpecializaton = ({
    name,
    control,
    validation,
    options,
    placeholder,
    label,
    isMulti,
    isSearchable = false,
    radioButton,
    narrow,
    trigger,
    defaultValue,
    onChange = () => {},
}) => {
    const defaultValidation = {
        required: 'Поле обязательно для заполнения',
        ...validation,
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, defaultValue, rules: defaultValidation });

    let components = {
        DropdownIndicator,
        ClearIndicator,
        ...(radioButton && { SingleValue, Option }),
    };

    return (
        <SelectCommonFieldStl>
            {label !== undefined && <LabelStl>{label}</LabelStl>}
            <SelectStl
                as={ReactSelect}
                {...field}
                onChange={(value) => {
                    field.onChange(value);
                    onChange(value);
                    trigger();
                }}
                components={components}
                options={options}
                placeholder={placeholder}
                hideSelectedOptions={false}
                isSearchable={isSearchable}
                noOptionsMessage={() => 'Специализация не найдена'}
                isClearable
                {...(isMulti && {
                    isMulti,
                    isSearchable: true,
                    controlShouldRenderValue: false,
                    closeMenuOnSelect: false,
                    noOptionsMessage: () => 'Нет совпадений',
                })}
                classNamePrefix="rs"
                narrow={narrow}
                radioButton={radioButton}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        borderColor: error ? '#ff3636' : state.isFocused ? '#19BE6F' : '#B6B7BA',
                    }),
                    clearIndicator: (base, state) => ({
                        ...base,
                        display: !isMulti && state.hasValue ? 'flex' : 'none',
                    }),
                    dropdownIndicator: (base, state) => ({
                        ...base,
                        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                        display: state.selectProps.inputValue || (!isMulti && state.hasValue) ? 'none' : 'flex',
                    }),
                }}
            />
            <ErrorStl>{error?.message}</ErrorStl>
        </SelectCommonFieldStl>
    );
};

export default SelectSpecializaton;
