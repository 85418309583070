import React from 'react';
import {useEffect} from 'react';
// import MainReviews from './../componets/reviews/MainReviews';
import { ReviewsMain } from '../componets/newReviews/reviewsMain/ReviewsMain';

const Reviews = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
      <ReviewsMain />
  );
};
export default Reviews;
