import { BASE_URL } from '../../../consts';

export default function dataCardDTO(data) {
    
    const card = {};
    const { avatar, autor, rating_value, text, created_at } = data;
    const fullName = `${autor.first_name} ${autor.last_name}`;
    // Поиск обертки <p></p>
    const regexp = /<\/?p>/g; 

    const url = BASE_URL.slice(0, -7);
    card.patient_avatar = avatar ? `${url}media/${avatar}` : null;
    card.patient_first_name = fullName?.trim() ? fullName.trim() : 'Гость';
    card.rating_value = rating_value;
    card.text = text.replace(regexp, "");
    card.created_at = created_at;

    return card;
}
