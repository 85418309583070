import styled from 'styled-components';

export const BlogListStyled = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 60px;
    max-width: 775px;

    .centerSpinner {
        height: 70px;
        justify-self: center;
    }

    @media (max-width: 500px) {
        gap: 40px;
    }
`;
