import React from 'react';
import { useState } from 'react';
import { Modal } from '../../../share/Modal';
import { CloseConsultationModal } from '../../lkPatientModalsNew/closeConsultationModal/closeConsultationModal';
import { StartConsultationModal } from '../../lkPatientModalsNew/startConsultationModal/startConsultationModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConsultationButtonsStl } from './consultationButtons.styled';
import { useDispatch } from 'react-redux';
import { setKeyConsultationPatient } from '../../api/PatientConsultationsSlice';

export const ConsultationButtons = ({ consultation }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const regex = /\/\d+$/;
    const [startConsultation, setStartConsultation] = useState(false);
    const [firstCloseConfirm, setFirstCloseConfirm] = useState(false);
    const { status, doctor, id, date, feedback, security_key } = consultation;
    const { last_name, first_name, patronymic, slug, id: doctorId } = doctor;
    let fullName = `${last_name} ${first_name} ${patronymic}`;
    let buttons;

    const closeConsultation = (e) => {
        e.stopPropagation();
        setFirstCloseConfirm(true);
    };

    const openStartModal = (e) => {
        e.stopPropagation();
        setStartConsultation(true);
        dispatch(setKeyConsultationPatient(security_key))
    };

    const anotherDoctorChoose = (e) => {
        e.stopPropagation();
        navigate('/doctors');
    };

    const registerAgain = (e) => {
        e.stopPropagation();
        navigate(`/doctors/${consultation.doctor.id}/${slug}}`, { state: { id: doctorId } });
    };

    switch (status) {
        case 0:
            buttons = (
                <>
                    <button onClick={openStartModal} className="startBtn">
                        Начать
                    </button>
                    <button onClick={closeConsultation} className="closeBtn">
                        Отменить
                    </button>
                </>
            );
            break;
        case 1:
            buttons = (
                <>
                    <button onClick={registerAgain} className="startBtn">
                        Записаться повторно
                    </button>
                    {!regex.test(pathname) && !feedback && (
                        <button className="closeBtn">
                            Оставить отзыв
                        </button>
                    )}
                </>
            );
            break;
        case 2:
        case 9:
            buttons = (
                <>
                    <button onClick={anotherDoctorChoose} className="startBtn">
                        Выбрать другого врача
                    </button>
                </>
            );
            break;
        default:
            buttons = null;
    }

    return (
        <ConsultationButtonsStl
            pathname={'/lk/patient-profile/consultation/future-consultation' === pathname}
            widthCard={
                '/lk/patient-profile/consultation/future-consultation' === pathname || 
                '/lk/patient-profile/consultation/past-consultation' === pathname
            }
        >
            {buttons}
            {startConsultation && (
                <Modal toggle={setStartConsultation}>
                    <StartConsultationModal
                        consultation={id}
                        isOpen={startConsultation}
                        setIsOpen={setStartConsultation}
                        patient={fullName}
                        date={date}
                    />
                </Modal>
            )}
            {firstCloseConfirm && (
                <Modal toggle={setFirstCloseConfirm}>
                    <CloseConsultationModal
                        consultation={consultation}
                        setFirstConfirm={setFirstCloseConfirm}
                        reload={true}
                    />
                </Modal>
            )}
        </ConsultationButtonsStl>
    );
};
