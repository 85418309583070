import styled from 'styled-components';

export const ReviewsMainStyled = styled.div``;

export const InfoContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    @media (max-width: 767px) {
        row-gap: 40px;
    }

    p {
        font-family: 'Rubik';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin: 0;
        padding-bottom: 20px;
        @media (max-width: 768px) {
            line-height: 130%;
        }
        @media (max-width: 600px) {
            font-size: 16px;
            padding-bottom: 24px;
        }
    }

    div.moderator_container > div > .docReview__card_body > .docReview__card_description {
        color: #B6B7BA;
    }
`;
