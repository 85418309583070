import { useState, useEffect } from 'react';

export const useSchedule = () => {
    const [currentDate, setCurrentDate] = useState({});
    const [currentDaysPeriod, setCurrentDaysPeriod] = useState([]);
    const [currentNextWeek, setCurrentNextWeek] = useState([]);

    useEffect(() => {
        const today = new Date();
        setCurrentDate({
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate(),
        });
    }, []);

    useEffect(() => {
        const fistWeekDays = [];
        const secondWeekDays = [];

        for (let i = 0; i < 14; i++) {
            const today = new Date();
            today.setDate(today.getDate() + i);
            if (i < 7) {
                fistWeekDays.push({
                    day: today.getDate(),
                    month: today.getMonth() + 1,
                    year: today.getFullYear(),
                    dayOfWeek: today.toLocaleString('ru-RU', { weekday: 'short' }).toLowerCase(),
                    dayOfWeekNumber: today.getDay(),
                });
            } else {
                secondWeekDays.push({
                    day: today.getDate(),
                    month: today.getMonth() + 1,
                    year: today.getFullYear(),
                    dayOfWeek: today.toLocaleString('ru-RU', { weekday: 'short' }).toLowerCase(),
                    dayOfWeekNumber: today.getDay(),
                });
            }
        }

        setCurrentDaysPeriod(fistWeekDays);
        setCurrentNextWeek(secondWeekDays);
    }, []);

    return { currentDate, currentDaysPeriod, currentNextWeek };
};
