import styled from 'styled-components';

export const TimerConsultationStl = styled.div`
    grid-area: timer;
    @media (max-width: 1024px) {
        margin: 0 -16px;
    }

    .btn-link {
        width: 285px;
        padding: 8px 9px 8px 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 26px;
        background-color: #f4f4f4;
        text-decoration: none;
        cursor: pointer;
        color: #666;
        font-size: 14px;
        line-height: 120%;
        z-index: 1;
        transition: box-shadow 0.3s;

        &:hover {
            box-shadow: ${({ isStartConsultation }) => (isStartConsultation ? 'inset 0 0 0 1px #FF3636' : 'inset 0 0 0 1px #19be6f')}

        }

        @media (max-width: 1024px) {
            /* position: absolute; */
            padding: 6px 16px;
            width: 100%;
            gap: 39px;
            border-radius: 0;
            justify-content: end;
            /* bottom: -38px; */
            /* left: 0; */
        }
        @media (max-width: 768px) {
            padding: 6px 16px;
            width: 100%;
            gap: 39px;
            border-radius: 0;
            justify-content: end;
        }

        &_pending {
            border: none;
        }

        &_active {
            border: ${({ isStartConsultation }) => (isStartConsultation ? '2px solid #FF3636' : '2px solid #19be6f')}
        }

        &__timer {
            padding: 4px 20px;
            border-radius: 24px;
            color: #fff;
            font-size: 16px;
            line-height: 150%;
            width: 80px;
            background-color: ${({ isStartConsultation }) => (isStartConsultation ? '#FF3636' : '#19be6f')}
        }
    }
`;
