import styled from 'styled-components';

export const LoadFileStl = styled.div`
    input {
        display: none;
    }
    .btn {
        color: #19be6f;
        font-size: 16px;
        cursor: pointer;
    }
    .files {
        display: flex;
        gap: 20px;
        margin-top: 16px;
        .file {
            display: flex;
            align-items: center;
            gap: 10px;
            position: relative;
            cursor: pointer;
            max-width: fit-content;
            
            > a {
                gap: 5px;
                color: #212121;
                font-size: 16px;
                text-decoration: none;
                display: flex;
                align-items: center;
            }
            > span {
                color: red;
                cursor: pointer;
            }
        }
    }
`;
